import { Pipe, PipeTransform } from '@angular/core';
import { UrlService } from '../../model/url.service';
import { GlobalService } from '../../model/global.service';

@Pipe({
  name: 'replacePlaceholder',
  pure: true,
})
export class ReplacePlaceholderPipe implements PipeTransform {

  private _placeholders: { [key: string]: string };

  constructor(
    private _urlService: UrlService,
    private _globalService: GlobalService,
  ) {
    this._placeholders = {
      '%administrator%': typeof this._globalService.settings.contact === 'undefined'
        ? ''
        : this._globalService.settings.contact.gallery_name[this._urlService.lang] + ', ' +
        this._globalService.settings.contact.address[this._urlService.lang] +
        (
          this._globalService.settings.contact.place[this._urlService.lang] ?
            '/' + this._globalService.settings.contact.place[this._urlService.lang] : ''
        ) + ', ' +
        this._globalService.settings.contact.postcode[this._urlService.lang] + ' ' +
        this._globalService.settings.contact.city[this._urlService.lang],

      '%gallery%': typeof this._globalService.settings.contact === 'undefined'
        ? ''
        : this._globalService.settings.contact.gallery_name[this._urlService.lang],
    };
  }

  transform(content: string, replace?: string, replacement?: string): string {
    if (typeof replace === 'undefined') {
      for (const _rplc in this._placeholders) {
        if (!this._placeholders[_rplc]) {
          continue;
        }
        content = this.transform(content, _rplc, this._placeholders[_rplc]);
      }

      return content;
    }

    if (typeof replacement === 'undefined') {
      if (typeof this._placeholders[replace] === 'undefined') {
        return content;
      }

      replacement = this._placeholders[replace];
    }

    const rplc = typeof this._placeholders[replace] !== 'undefined' ? replace : ' ' + replace + ' ';
    const word = typeof this._placeholders[replace] !== 'undefined' ? replacement : ' ' + replacement + ' ';

    while (content.indexOf(rplc) > -1) {
      content = content.replace(rplc, word);
    }

    return content.trim();
  }

}
