import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { P404Component } from './p404.component';

const routes: Routes = [
  {
    path: '',
    component: P404Component,
    data: {
      cmsCategory: 'p404',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class P404RoutingModule {
}
