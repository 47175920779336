import { ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { UniversalPlatformService } from './universal-platform.service';

@Injectable()
export class UniversalRendererHelper {

  public readonly classNameHelper = StringClassNameHelper;
  private _renderer: Renderer2;

  protected static arrayUnique(arr: any[]): any[] {
    return arr.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  }

  protected static arrayFilter(arr: any[], deleteValue?: any): any[] {
    let i = 0;

    if (typeof deleteValue === 'undefined') {

      for (; i < (arr || []).length; ++i) {
        if (false === arr[i] || null === arr[i] || undefined === arr[i] || '' === arr[i]) {
          arr.splice(i, 1);
          --i;
        }
      }

    } else {

      for (; i < (arr || []).length; ++i) {
        if (arr[i] === deleteValue) {
          arr.splice(i, 1);
          --i;
        }
      }

    }

    return arr;
  }

  private static getNode(element: ElementRef | HTMLElement | any, inBrowser = true): any {
    let node: any;

    if (element instanceof ElementRef) {
      node = inBrowser ? <HTMLElement>element.nativeElement : element.nativeElement;
    } else if (inBrowser && element instanceof HTMLElement) {
      node = element;
    } else {
      node = element ?
        (inBrowser ? <HTMLElement>element : element) :
        null;
    }

    return node;
  }

  constructor(
    private rendererFactory: RendererFactory2,
    private platformService: UniversalPlatformService,
  ) {
    this._renderer = rendererFactory.createRenderer(null, null);
  }

  hasClass(element: ElementRef | HTMLElement | any, className: string | string[]): boolean {
    const node = UniversalRendererHelper.getNode(element, this.platformService.isBrowser);

    if (node === null) {
      return;
    }

    const useClassList = typeof node.classList !== 'undefined';

    if (!useClassList && typeof node.className !== 'string') {
      return false;
    }

    return useClassList ?
      this.classListContains(node, className) :
      StringClassNameHelper.has(node.className, className);
  }

  hasAnyClass(element: ElementRef | HTMLElement | any, className: string | string[]): boolean {
    const node = UniversalRendererHelper.getNode(element, this.platformService.isBrowser);

    if (node === null) {
      return;
    }

    const useClassList = typeof node.classList !== 'undefined';

    if (!useClassList && typeof node.className !== 'string') {
      return false;
    }

    return useClassList ?
      this.classListContains(node, className, true) :
      StringClassNameHelper.hasAny(node.className, className);
  }

  addClass(element: ElementRef | any, className: string | string[]): void {
    const node = UniversalRendererHelper.getNode(element, this.platformService.isBrowser);

    if (node === null) {
      return;
    }

    let classes = typeof className === 'string' ? className.split(' ') : className;

    classes = UniversalRendererHelper.arrayUnique(classes);
    classes = UniversalRendererHelper.arrayFilter(classes);

    for (let i = 0; i < (classes || []).length; ++i) {
      this._renderer.addClass(
        node,
        classes[i],
      );
    }
  }

  removeClass(element: ElementRef | any, className: string | string[]): void {
    const node = UniversalRendererHelper.getNode(element, this.platformService.isBrowser);

    if (node === null) {
      return;
    }

    let classes = typeof className === 'string' ? className.split(' ') : className;

    classes = UniversalRendererHelper.arrayUnique(classes);
    classes = UniversalRendererHelper.arrayFilter(classes);

    for (let i = 0; i < (classes || []).length; ++i) {
      this._renderer.removeClass(
        node,
        classes[i],
      );
    }
  }

  hasAttribute(el: ElementRef | any, name: string): boolean {
    const node = UniversalRendererHelper.getNode(el, this.platformService.isBrowser);

    if (node === null || typeof node.hasAttribute === 'undefined') {
      return false;
    }

    return node.hasAttribute(name);
  }

  setAttribute(el: ElementRef | any, name: string, value: string, namespace?: string | null): void {
    const node = UniversalRendererHelper.getNode(el, this.platformService.isBrowser);

    if (node === null) {
      return;
    }

    this._renderer.setAttribute(
      node,
      name,
      value,
      namespace,
    );
  }

  getAttribute(el: ElementRef | any, name: string): any | null {
    const node = UniversalRendererHelper.getNode(el, this.platformService.isBrowser);

    if (node === null || typeof node.getAttribute === 'undefined') {
      return null;
    }

    return node.getAttribute(name);
  }

  removeAttribute(el: ElementRef | any, name: string, namespace?: string | null): void {
    const node = UniversalRendererHelper.getNode(el, this.platformService.isBrowser);

    if (node === null) {
      return;
    }

    this._renderer.removeAttribute(
      node,
      name,
      namespace,
    );
  }

  setProperty(el: ElementRef | any, name: string, value: any): void {
    const node = UniversalRendererHelper.getNode(el, this.platformService.isBrowser);

    if (node === null) {
      return;
    }

    this._renderer.setProperty(
      node,
      name,
      value,
    );
  }

  createElement(name: string, namespace?: string | null): any {
    return this._renderer.createElement(name, namespace);
  }

  parentNode(el: ElementRef | any): any {
    const node = UniversalRendererHelper.getNode(el, this.platformService.isBrowser);

    if (node === null) {
      return null;
    }

    return this._renderer.parentNode(node);
  }

  appendChild(parent: ElementRef | any, newChild: any): void {
    const parentNode = UniversalRendererHelper.getNode(parent, this.platformService.isBrowser);
    const child = UniversalRendererHelper.getNode(newChild, this.platformService.isBrowser);

    if (parentNode === null || child === null) {
      return;
    }

    this._renderer.appendChild(parentNode, child);
  }

  removeChild(parent: ElementRef | any, oldChild: any): void {
    const child = UniversalRendererHelper.getNode(oldChild, this.platformService.isBrowser);

    if (child === null) {
      return;
    }

    this._renderer.removeChild(parent.nativeElement, child);
  }

  insertBefore(parent: ElementRef | any, newChild: any, refChild: any): void {
    const parentNode = UniversalRendererHelper.getNode(parent, this.platformService.isBrowser);
    const child = UniversalRendererHelper.getNode(newChild, this.platformService.isBrowser);
    const ref = UniversalRendererHelper.getNode(refChild, this.platformService.isBrowser);

    if (parentNode === null || child === null || ref === null) {
      return;
    }

    this._renderer.insertBefore(parentNode, child, ref);
  }

  contains(container: ElementRef | any, element: ElementRef | any): boolean {
    const containerNode = UniversalRendererHelper.getNode(container, this.platformService.isBrowser);

    if (containerNode === null || typeof containerNode.contains === 'undefined') {
      return false;
    }

    const node = UniversalRendererHelper.getNode(element, this.platformService.isBrowser);

    return node === null ? false : containerNode.contains(node);
  }

  addEventListener(element: 'window' | 'document' | 'body' | any, event: string, callback: (event: any) => boolean | void): void {
    this._renderer.listen(
      (
        typeof element === 'string' ?
          element :
          (element instanceof ElementRef ?
            element.nativeElement :
            element)
      ),
      event,
      callback,
    );
  }

  trigger(element: ElementRef | any, eventName: string): void {
    const node = UniversalRendererHelper.getNode(element, this.platformService.isBrowser);

    if (node !== null && typeof node[eventName] === 'function') {
      node[eventName]();
    }
  }

  protected classListContains(node: HTMLElement, className: string | string[], ifAny = false): boolean {
    let m = 0;
    let c = 0;
    let cls: string;
    let contains: boolean;

    let classes = typeof className === 'string' ? className.split(' ') : className;

    classes = UniversalRendererHelper.arrayUnique(classes);
    classes = UniversalRendererHelper.arrayFilter(classes);

    for (let i = 0; i < (classes || []).length; ++i) {
      cls = classes[i].trim();

      if (!(cls || []).length) {
        continue;
      }

      ++c;
      contains = node.classList.contains(cls);

      if (contains) {
        if (ifAny) {
          return true;
        }

        ++m;
      }
    }

    return c > 0 && m === c;
  }

}

class StringClassNameHelper {

  static has(className: string, search: string | string[]): boolean {
    const detect = StringClassNameHelper.detect(className, search);

    return (detect.has || []).length > 0 && (detect.missing || []).length === 0;
  }

  static hasAny(className: string, search: string | string[]): boolean {
    const detect = StringClassNameHelper.detect(className, search);

    return (detect.has || []).length > 0;
  }

  static detect(className: string, search: string | string[]): { has: string[], missing: string[] } {
    const detect = {
      has: [],
      missing: [],
    };

    const classes = typeof search === 'string' ? search.split(' ') : search;
    const cn = ' ' + className + ' ';
    let cls: string;
    let has: boolean;

    for (let i = 0; i < (classes || []).length; ++i) {
      cls = classes[i].trim();

      if (!(cls || []).length) {
        continue;
      }

      cls = ' ' + classes[i] + ' ';
      has = cn.indexOf(cls) > -1;

      cls = cls.trim();

      if (has) {
        detect.has.push(cls);
      } else {
        detect.missing.push(cls);
      }
    }

    return detect;
  }

  static add(className: string, add: string | string[]): string {
    const detect = StringClassNameHelper.detect(className, add);

    className = className.trim();

    for (let i = 0; i < (detect.missing || []).length; ++i) {
      className += ' ' + detect.missing[i];
    }

    return className;
  }

  static remove(className: string, remove: string | string[]): string {
    const detect = StringClassNameHelper.detect(className, remove);

    let cls: string;

    className = ' ' + className.trim() + ' ';

    for (let i = 0; i < (detect.has || []).length; ++i) {
      cls = ' ' + detect.has[i] + ' ';

      className = className.replace(cls, ' ');
    }

    return className.trim();
  }

  static replaceAllButKeep(className: string, replace: string | string[], keep: string | string[]): string {
    const detect = StringClassNameHelper.detect(className, keep);
    const rpl = typeof replace === 'string' ? replace : replace.join(' ');

    className = rpl.trim();

    for (let i = 0; i < (detect.has || []).length; ++i) {
      if (!StringClassNameHelper.has(className, detect.has[i])) {
        className += ' ' + detect.has[i];
      }
    }

    return className;
  }

  static removeAllButKeep(className: string, keep: string | string[]): string {
    const detect = StringClassNameHelper.detect(className, keep);
    const cn = className.trim().split(' ');

    className = '';

    for (let i = 0; i < (cn || []).length; ++i) {
      if (detect.has.indexOf(cn[i]) > -1) {
        className += ' ' + cn[i];
      }
    }

    return className;
  }

}
