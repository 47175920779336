<section *ngIf="items && items?.length" class="page-content">

    <div class="container">
        <div class="row">

            <div *ngIf="title" class="col-12">
                <div class="page-title">
                    {{ title }}
                </div>
            </div>

            <div class="col-12">

                <div class="masonry-grid row">
                    <ngx-masonry #masonry *ngIf="items && items?.length" [options]="{
                        horizontalOrder:true,
                        itemSelector:'.masonry-grid-item',
                        columnWidth:'.grid-sizer',
                        percentPosition:true
                    }" [useImagesLoaded]="false"
                                 class="masonry-grid">
                        <ngxMasonryItem *ngFor="let work of items; let wIndex = index; trackBy:trackByFn"
                                        [class.grid-sizer]="wIndex === 0" [ngClass]="{'no-zoom': !zoomImages}"
                                        class="masonry-grid-item">

                            <div class="grid-item-inner">
                                <article class="masonry-grid-item-article">
                                    <a [queryParams]="{group:'sold-items', offset:(wIndex+1)}"
                                       [routerLink]="[urlService.link('products-single', work)]"
                                       class="masonry-grid-item-link">
                                        <div class="grid-item-figure">

                                            <img *ngIf="work.images?.length" appLazyLoad
                                                 class="img-fluid" src="{{work.images[0]?.thumbnails?.medium.url}}"/>

                                            <img *ngIf="!work.images?.length" appLazyLoad class="img-fluid"
                                                 src="assets/images/placeholder-450x340.jpg"/>

                                        </div>

                                        <div class="grid-item-content">
                                            <div *ngIf="work.artists?.length" class="grid-item-title">
                                                <ng-container
                                                    *ngFor="let artist of work.artists | artistsAZ; let indexA = index">
                                                    <span [innerHTML]="artist | artistName: urlService.langCode"></span><span
                                                    *ngIf="globalService.settings.offer.display_artist_dates">&nbsp;</span><span
                                                    [innerHTML]="artist | artistDates:urlService.lang"></span>
                                                    <ng-container *ngIf="indexA < (work.artists?.length - 1)">,<br/>
                                                    </ng-container>
                                                </ng-container>
                                            </div>

                                            <p class="grid-item-text">{{work.name[urlService.langCode]}}
                                                <span
                                                    *ngIf="work.year || work.year_to || work.year_text[urlService.langCode]"
                                                    class="work-date">
                                                    , {{work | workDates:urlService.langCode}}</span>
                                                <br/>
                                                <span
                                                    *ngIf="work.medium[urlService.langCode]">{{work.medium[urlService.langCode]}}
                                                    <br/></span>

                                            </p>
                                            <p [innerHTML]="work | workDimensions:urlService.lang:true"
                                               class="work-dimensions"></p>
                                        </div>
                                    </a>
                                </article>
                            </div>

                        </ngxMasonryItem>
                    </ngx-masonry>

                    <!-- <div *ngFor="let work of items; let index = index" class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 masonry-grid-item">

                        <div class="grid-item-inner">
                            <article class="masonry-grid-item-article">
                                <a [routerLink]="[urlService.link('products-single', work)]" [queryParams]="{group:'sold-items', offset:(index+1)}" class="masonry-grid-item-link">
                                    <div class="grid-item-figure">

                                        <img appLazyLoad *ngIf="work.images?.length" src="{{work.images[0]?.thumbnails?.medium.url}}" class="img-fluid" />

                                        <img appLazyLoad *ngIf="!work.images?.length" src="assets/images/placeholder-450x340.jpg" class="img-fluid" />

                                    </div>

                                    <div class="grid-item-content">
                                        <div class="grid-item-title" *ngIf="work.artists?.length">
                                            <ng-container *ngFor="let artist of work.artists | artistsAZ; let indexA = index"><span [innerHTML]="artist | artistName: urlService.langCode"></span><span *ngIf="globalService.settings.offer.display_artist_dates">&nbsp;</span><span [innerHTML]="artist | artistDates:urlService.lang"></span><ng-container *ngIf="indexA < (work.artists?.length - 1)">,<br /></ng-container></ng-container>
                                        </div>

                                        <p class="grid-item-text">{{work.name[urlService.langCode]}}<ng-container *ngIf="work.year || work.year_to || work.year_text[urlService.langCode]">, {{work | workDates:urlService.langCode}}</ng-container><br />
                                            <span *ngIf="work.medium[urlService.langCode]">{{work.medium[urlService.langCode]}}<br /></span>

                                            <span [innerHTML]="work | workDimensions:urlService.lang:true"></span>
                                        </p>
                                    </div>
                                </a>
                            </article>
                        </div>

                    </div> -->
                </div>

            </div>

        </div>
    </div>

</section>
