import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { WINDOW } from './window.providers';
import { UniversalStorageAbstract } from './universal-storage-abstract.class';
import { UniversalStorage } from './universal-storage.class';

@Injectable()
export class UniversalPlatformService {

  get localStorage(): UniversalStorageAbstract {
    return this._localStorage;
  }

  get window(): Window {
    return this._window;
  }

  get document(): any {
    return this._document;
  }

  get windowWidth(): number {
    return this.isBrowser
      ? (this.window.innerWidth
        || this.document.documentElement.clientWidth
        || this.document.getElementsByTagName('body')[0].clientWidth)
      : 0;
  }

  get isBrowser(): boolean {
    return this._isBrowser;
  }

  get isServer(): boolean {
    return this._isServer;
  }

  private _isBrowser: boolean;

  private _isServer: boolean;

  constructor(
    @Inject(PLATFORM_ID) private _platformID: Object,
    @Inject(WINDOW) private _window: Window,
    @Inject(DOCUMENT) private _document: any,
    private _localStorage: UniversalStorage,
  ) {
    this._isBrowser = isPlatformBrowser(_platformID);
    this._isServer = isPlatformServer(_platformID);
  }

}
