/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../shared/translate-pipes/translate.pipe";
import * as i3 from "../../model/global.service";
import * as i4 from "../../model/url.service";
import * as i5 from "../../shared/translate-pipes/replace-placeholder.pipe";
import * as i6 from "./partners-block.component";
import * as i7 from "@angular/platform-browser";
var styles_PartnersBlockComponent = [];
var RenderType_PartnersBlockComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PartnersBlockComponent, data: {} });
export { RenderType_PartnersBlockComponent as RenderType_PartnersBlockComponent };
function View_PartnersBlockComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "partner-link"]], [[8, "href", 4], [8, "title", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["appLazyLoad", ""], ["class", "img-fluid partner-logo"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.url, ""); var currVal_1 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.title[_co.urlService.lang], ""); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.image, ""); _ck(_v, 1, 0, currVal_2); }); }
function View_PartnersBlockComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "partner-nolink"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["appLazyLoad", ""], ["class", "img-fluid partner-logo"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.image, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_PartnersBlockComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "li", [["class", "partners-item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PartnersBlockComponent_3)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["noLinkPartner", 2]], null, 0, null, View_PartnersBlockComponent_4))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.url; var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_PartnersBlockComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "partners-block"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "partners-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵppd(3, 2), (_l()(), i0.ɵeld(4, 0, null, null, 2, "ul", [["class", "partners-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PartnersBlockComponent_2)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.partners; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), "general.our-partners", _co.urlService.lang)); _ck(_v, 2, 0, currVal_0); }); }
export function View_PartnersBlockComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i2.TranslatePipe, [i3.GlobalService, i4.UrlService, i5.ReplacePlaceholderPipe]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PartnersBlockComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasPartners(); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PartnersBlockComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-partners-block", [], null, null, null, View_PartnersBlockComponent_0, RenderType_PartnersBlockComponent)), i0.ɵdid(1, 4440064, null, 0, i6.PartnersBlockComponent, [i3.GlobalService, i4.UrlService, i7.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PartnersBlockComponentNgFactory = i0.ɵccf("app-partners-block", i6.PartnersBlockComponent, View_PartnersBlockComponent_Host_0, { context: "context", animation_main: "animation_main" }, { viewInit: "viewInit" }, []);
export { PartnersBlockComponentNgFactory as PartnersBlockComponentNgFactory };
