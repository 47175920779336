var ElementServiceBase = /** @class */ (function () {
    function ElementServiceBase(rendererHelper) {
        this.rendererHelper = rendererHelper;
    }
    Object.defineProperty(ElementServiceBase.prototype, "element", {
        get: function () {
            return this.root;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ElementServiceBase.prototype, "className", {
        get: function () {
            return this.root.className;
        },
        set: function (className) {
            this.rendererHelper.setAttribute(this.root, 'class', '');
            this.addClass(className);
        },
        enumerable: true,
        configurable: true
    });
    ElementServiceBase.prototype.hasClass = function (className) {
        return this.rendererHelper.hasClass(this.root, className);
    };
    ElementServiceBase.prototype.addClass = function (className) {
        this.rendererHelper.addClass(this.root, className);
        return this;
    };
    ElementServiceBase.prototype.removeClass = function (className) {
        this.rendererHelper.removeClass(this.root, className);
        return this;
    };
    ElementServiceBase.prototype.hasAttribute = function (name) {
        return this.rendererHelper.hasAttribute(this.root, name);
    };
    ElementServiceBase.prototype.setAttribute = function (name, value, namespace) {
        this.rendererHelper.setAttribute(this.root, name, value, namespace);
        return this;
    };
    ElementServiceBase.prototype.getAttribute = function (name) {
        return this.rendererHelper.getAttribute(this.root, name);
    };
    ElementServiceBase.prototype.removeAttribute = function (name, namespace) {
        this.rendererHelper.removeAttribute(this.root, name, namespace);
        return this;
    };
    ElementServiceBase.prototype.setProperty = function (name, value) {
        this.rendererHelper.setProperty(this.root, name, value);
        return this;
    };
    return ElementServiceBase;
}());
export { ElementServiceBase };
