import { Injectable } from '@angular/core';
import { RoutingDataService } from './model/routing-data.service';
import { SettingDataService } from './model/setting-data.service';

@Injectable()
export class AppInitService {

  protected toLoad: number;
  protected loaded: number;
  protected timeout: any;

  constructor(
    protected routingService: RoutingDataService,
    protected settingDataService: SettingDataService,
  ) {
    this.toLoad = 2;
    this.loaded = 0;
  }

  init(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.timeout = setTimeout(() => {
        console.error('AppInitService could not initialize App.');
        reject();
      }, 60000);

      this.routingService.getRouting().subscribe(() => {
        if (!this.routingService.isEmpty) {
          this.loadedAndMaybeResolved(resolve);
        }
      });

      this.settingDataService.refresh().subscribe(() => {
        if (this.settingDataService.settings.__loaded) {
          this.loadedAndMaybeResolved(resolve);
        }
      });
    });
  }

  protected loadedAndMaybeResolved(resolver): void {
    ++this.loaded;

    if (this.toLoad === this.loaded) {
      clearTimeout(this.timeout);
      resolver();
    }
  }
}
