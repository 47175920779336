import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { OwlModule } from 'ngx-owl-carousel';
import { BlocksPlacement } from '../../blocks-wrapper/blocks-wrapper.component';
import { AutoUnsubscribe } from '../../decorators/autounsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-small-slider-block',
  templateUrl: './small-slider-block.component.html',
})
export class SmallSliderBlockComponent implements OnInit, AfterViewInit {

  public options: { [key: string]: any };

  @Input() context: BlocksPlacement;
  @Input() slides: any[];

  @ViewChild(OwlModule) owlElement: OwlModule;

  @Output() viewInit = new EventEmitter<string>();

  constructor(
    public sanitizer: DomSanitizer,
  ) {

    this.options = {
      items: 1,
      margin: 6,
      dragEndSpeed: 1000,
      nav: false,
      dots: true,
      dotsContainer: '.small-slider-block .carousel-indicators',
      dotsSpeed: 1500,
      loop: false,
    };
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.viewInit.emit('small-slider');
  }

}
