<form (submit)="onSubmit($event)" [class.doing-submit]="doingSubmit()" [formGroup]="newsletterFields"
      class="newsletter-form"
      method="post">

    <div class="row">
        <div class="col">
            <div class="input-group">
                <input class="form-control footer-newsletter-form-email" formControlName="email" type="text"/>
                <button (click)="onClickSubmit($event); false" [disabled]="doingSubmit()"
                        class="footer-newsletter-request-submit"
                        type="submit">OK
                </button>

                <span [class.show]="displayInvalidityInfo('email')" class="form-control-invalid">
                    <span
                        [hidden]="!newsletterFields.get('email').hasError('required')">{{'form-validation.fill-in-required-field' | translate:urlService.lang}}</span>
                    <span
                        [hidden]="!newsletterFields.get('email').hasError('email')">{{'form-validation.enter-correct-email' | translate:urlService.lang}}</span>
                </span>
            </div>
        </div>
    </div>

</form>

<div #modal="bs-modal" (click)="maybeCloseModal($event); false" bsModal class="modal fade newsletter-modal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog-content">

        <button (click)="closeModal($event); false" aria-label="Zamknij" class="close close-icon"
                type="button"></button>

        <div class="modal-dialog-inner modal-dialog" role="document">

            <div class="modal-step modal-stepless modal-step-ty show">

                <div #confirmTitle class="modal-step-title"></div>

                <p #confirmMessage></p>

            </div>

        </div>

    </div>
</div>
