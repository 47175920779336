// Fork kodu z @ngx-cache - konieczny ze wzglęcdu na brak metody keys() w źródle
import { Injector } from '@angular/core';
// module
import { CACHE, CacheLoader } from '@ngx-cache/core';
import { ReturnType } from './models/return-type';
var CacheService = /** @class */ (function () {
    function CacheService(loader, platformId, injector) {
        this.loader = loader;
        this.platformId = platformId;
        this.injector = injector;
        CacheService.instance = this;
        this.cache = this.injector.get(CACHE);
        this.lifeSpan = loader.lifeSpan;
    }
    Object.defineProperty(CacheService.prototype, "key", {
        get: function () {
            return this.loader.key;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CacheService.prototype, "keys", {
        get: function () {
            return this.cache.keys;
        },
        enumerable: true,
        configurable: true
    });
    CacheService.normalizeKey = function (key) {
        if (CacheService.validateKey(key)) {
            throw new Error('Please provide a valid key to save in the CacheService');
        }
        return "" + key;
    };
    CacheService.validateKey = function (key) {
        return !key
            || typeof key === 'boolean'
            || Number.isNaN(key);
    };
    CacheService.validateValue = function (value) {
        return value.lifeSpan.expiry && value.lifeSpan.expiry > Date.now();
    };
    CacheService.prototype.has = function (key) {
        key = CacheService.normalizeKey(key);
        return this.cache.keys.indexOf(key) !== -1;
    };
    CacheService.prototype.set = function (key, value, returnType, lifeSpan) {
        if (returnType === void 0) { returnType = ReturnType.Scalar; }
        key = CacheService.normalizeKey(key);
        lifeSpan = lifeSpan || this.lifeSpan;
        return this.cache.setItem(key, {
            data: value,
            returnType: returnType,
            lifeSpan: this.parseLifeSpan(lifeSpan),
        });
    };
    CacheService.prototype.get = function (key) {
        key = CacheService.normalizeKey(key);
        var cached = this.cache.getItem(key);
        var res;
        if (cached) {
            if (CacheService.validateValue(cached)) {
                res = cached.data;
            }
            else {
                this.remove(key);
            }
        }
        return res;
    };
    CacheService.prototype.remove = function (key, wild) {
        if (wild === void 0) { wild = false; }
        key = CacheService.normalizeKey(key);
        this.cache.removeItem(key, wild);
    };
    CacheService.prototype.clear = function () {
        this.cache.clear();
    };
    CacheService.prototype.parseLifeSpan = function (lifeSpan) {
        return {
            expiry: lifeSpan.expiry || (lifeSpan.TTL ? Date.now() + (lifeSpan.TTL * 1000) : this.lifeSpan.expiry),
            TTL: lifeSpan.TTL || this.lifeSpan.TTL,
        };
    };
    CacheService.instance = undefined;
    return CacheService;
}());
export { CacheService };
