import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { UniversalPlatformService } from './platform/universal-platform.service';

@Injectable()
export class CookiesService {

  constructor(
    @Inject(DOCUMENT) private _document,
    private _platformService: UniversalPlatformService,
  ) {
  }

  getCookie(name: string): string {
    if (this._platformService.isBrowser) {
      const cookie = this._document.cookie;
      const ca: string[] = cookie.split(';');
      const caLen: number = (ca || []).length;
      const cookieName = `${name}=`;
      let c: string;

      for (let i = 0; i < caLen; i += 1) {
        c = ca[i].replace(/^\s+/g, '');

        if (c.indexOf(cookieName) === 0) {
          return c.substring((cookieName || []).length, (c || []).length);
        }
      }
    }

    return '';
  }

  setCookie(name: string, value: string, expireDays: number, path = ''): void {
    if (this._platformService.isBrowser) {
      const d: Date = new Date();

      d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);

      const expires = `expires=${d.toUTCString()}`;
      const cpath: string = path ? `; path=${path}` : '';

      this._document.cookie = `${name}=${value}; ${expires}${cpath}`;
    }
  }

}
