<ng-container *ngIf="pageBlocks && pageBlocks?.length > 0">
    <ng-container *ngFor="let block of pageBlocks">
        <ng-container [ngSwitch]="block.type">

            <ng-container *ngSwitchCase="'maincontents'">
                <section *ngIf="block.title" class="page-section about-section about-us-section">

                    <div *ngIf="block.image" [ngStyle]="{
                        'background-image':'url('+block.image+')'
                    }" class="hero-image hero-image-fixed" role="presentation"></div>

                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-lg-6 col-xl-8 about-content">

                                <div class="page-title">{{ block.title }}</div>

                                <div [innerHTML]="block.text | keepHtml" class="page-content"></div>

                            </div>

                            <div class="col-12 col-lg-6 col-xl-4 about-aside"></div>
                        </div>
                    </div>
                </section>
            </ng-container>

            <app-slider-block (viewInit)="blockLoaded()" *ngSwitchCase="'slider'" [block]="block"
                              [context]="context" [slides]="block.slides"
                              [wow]="block.animation_text"></app-slider-block>

            <app-small-slider-block (viewInit)="blockLoaded()" *ngSwitchCase="'small-slider'"
                                    [context]="context"
                                    [slides]="block.slides"></app-small-slider-block>

            <app-products-carousel-block (viewInit)="blockLoaded()" *ngSwitchCase="'products_featured'"
                                         [blockConfig]="block"
                                         [context]="context"></app-products-carousel-block>
            <app-products-carousel-block (viewInit)="blockLoaded()" *ngSwitchCase="'products_latest'"
                                         [blockConfig]="block"
                                         [context]="context"></app-products-carousel-block>
            <app-products-carousel-block (viewInit)="blockLoaded()"
                                         *ngSwitchCase="'products_auction_featured'"
                                         [blockConfig]="block"
                                         [context]="context"></app-products-carousel-block>
            <app-products-carousel-block (viewInit)="blockLoaded()" *ngSwitchCase="'products_same_category'"
                                         [blockConfig]="block"
                                         [context]="context"></app-products-carousel-block>

            <app-offer-form-block (viewInit)="blockLoaded()" *ngSwitchCase="'offerform'"
                                  [context]="context"></app-offer-form-block>

            <app-news-slider-block (viewInit)="blockLoaded()" *ngSwitchCase="'latestnews'" [context]="context"
                                   [perPage]="block.number"></app-news-slider-block>

            <app-text-image-block (viewInit)="blockLoaded()" *ngSwitchCase="'textimage'" [context]="context"
                                  [excerpt]="block.excerpt" [image]="block.image" [text]="block.text"
                                  [title]="block.title"></app-text-image-block>

            <app-big-image-block (viewInit)="blockLoaded()" *ngSwitchCase="'bigimage'"
                                 [background]="block.image" [context]="context"
                                 [text]="block.text" [title]="block.title"
                                 [url]="block.url"></app-big-image-block>

            <app-text-block (viewInit)="blockLoaded()" *ngSwitchCase="'textblock'" [context]="context"
                            [text]="block.text"
                            [title]="block.title"></app-text-block>

            <app-partners-block (viewInit)="blockLoaded()" *ngSwitchCase="'partners'"
                                [animation_main]="block.animation_main"
                                [context]="context"></app-partners-block>

            <app-sold-items-block (viewInit)="blockLoaded()" *ngSwitchCase="'sold'" [context]="context"
                                  [title]="block.title"></app-sold-items-block>

            <app-product-counter-block (viewInit)="blockLoaded()" *ngSwitchCase="'product_counter'"
                                       [context]="context" [firstLine]="block.line_1"
                                       [secondLine]="block.line_2"></app-product-counter-block>

            <!--<div *ngSwitchDefault>&nbsp;<p>Brak komponentu {{block.type}} :( </p>&nbsp;</div>-->
        </ng-container>
    </ng-container>

    <div [class.show]="showPreloader()" class="page-preloader show">
        <div class="loader-image"></div>
    </div>
</ng-container>
