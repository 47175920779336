export interface ApiRoutingItem {
  type: string,
  slugs: { [key: string]: string },
}

export declare type ApiRouting = ApiRoutingItem[];

export interface RoutingMapItemChild {
  type: string,
  segment?: number,
}

export declare type RoutingMapItemChildren = RoutingMapItemChild[];

export interface AppRoutingMapItem {
  type: string,
  module: string,
  children?: RoutingMapItemChildren, // kolejność tutaj ma znaczenie dla kolejności w ścieżce w adresie url!
  segment?: number,
}

export declare type AppRoutingMap = AppRoutingMapItem[];

export interface RoutingItem {
  type: string,
  module: string,
  slugs: { [key: string]: string },
  children?: RoutingMapItemChildren,
  parent?: string,
}

export declare type RoutingMap = RoutingItem[];

export const routingBaseMap: AppRoutingMap = [
  {
    type: 'home',
    module: 'app/home/home.module#HomeModule',
  },
  {
    type: 'news',
    module: 'app/news/news.module#NewsModule',
    children: [{ type: 'news-single' }],
  },
  {
    type: 'exhibitions',
    module: 'app/exhibitions/exhibitions.module#ExhibitionsModule',
    children: [{ type: 'exhibitions-single' }],
  },
  {
    type: 'auctions',
    module: 'app/auctions/auctions.module#AuctionsModule',
    children: [
      { type: 'auctions-single', segment: 1 },
      { type: 'auctions-product', segment: 2 },
    ],
  },
  {
    type: 'artists',
    module: 'app/artists/artists.module#ArtistsModule',
    children: [{ type: 'artists-single' }],
  },
  {
    type: 'products',
    module: 'app/offer/offer.module#OfferModule',
    children: [{ type: 'products-single' }],
  },
  {
    type: 'search-results',
    module: 'app/search-results/search-results.module#SearchResultsModule',
  },
  {
    type: 'how-to-buy',
    module: 'app/how-to-buy/how-to-buy.module#HowToBuyModule',
  },
  {
    type: 'how-to-sell',
    module: 'app/how-to-sell/how-to-sell.module#HowToSellModule',
  },
  {
    type: 'text-page',
    module: 'app/text-page/text-page.module#TextPageModule',
  },
  {
    type: 'contact',
    module: 'app/text-page/text-page.module#TextPageModule',
  },
  {
    type: 'sold',
    module: 'app/text-page/text-page.module#TextPageModule',
  },
  {
    type: '404',
    module: 'app/p404/p404.module#P404Module',
  },
  {
    type: 'privacy-policy',
    module: 'app/text-page/text-page.module#TextPageModule',
  },
  {
    type: 'verification-page',
    module: 'app/text-page/text-page.module#TextPageModule',
  },
  {
    type: 'auction-terms-and-conditions',
    module: 'app/text-page/text-page.module#TextPageModule',
  },
  {
    type: 'order-confirm-page',
    module: 'app/text-page/text-page.module#TextPageModule',
  },
  {
    type: 'newsletter-verify',
    module: 'app/text-page/text-page.module#TextPageModule',
  },
];
