import { BehaviorSubject, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ObjectUtils } from '../shared/utils/object-utils';
import { environment } from '../../environments/environment';
import { routingBaseMap } from './routing';
import { HttpClient } from '@angular/common/http';
// mechanizm wypalarki nie respektuje zmian ustawień dostępnych języków - obejście:
// export const allowedLangs = environment.langs.allowed;
export var allowedLangs = [
    { lang: 'pl', code: 'pl_pl' },
    { lang: 'en', code: 'en_us' },
];
var RoutingDataService = /** @class */ (function () {
    function RoutingDataService(http) {
        var _this = this;
        this.http = http;
        this.routingData = [];
        this.data = new BehaviorSubject(this.routingData);
        this.data.subscribe(function (routing) {
            _this.routingData = routing;
        });
    }
    Object.defineProperty(RoutingDataService.prototype, "routing", {
        get: function () {
            return this.routingData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoutingDataService.prototype, "isEmpty", {
        get: function () {
            return (this.routingData || []).length === 0;
        },
        enumerable: true,
        configurable: true
    });
    RoutingDataService.prototype.getRouting = function (force) {
        if (force === void 0) { force = false; }
        if (!this.isEmpty && !force) {
            return this.data;
        }
        return this.refresh();
    };
    RoutingDataService.prototype.refresh = function () {
        var _this = this;
        var url = environment.apiHost + '/routing';
        var params = {};
        this.http.get(url, params).pipe(map(function (response) {
            return _this.createRoutes(response.data);
        }), catchError(function (error) {
            return _this.handleError(error, url, params);
        }))
            .subscribe(function (routing) {
            _this.data.next(routing);
        });
        return this.data;
    };
    RoutingDataService.prototype.handleError = function (error, url, params) {
        var errorMessage;
        if (error.status === 0) {
            errorMessage = {
                success: false,
                status: 0,
                data: 'Sorry, there has an connection error occurred while trying to get routing configuration. Please try again.',
                url: url,
                params: params,
            };
        }
        else if (error.json) {
            errorMessage = error;
        }
        else {
            errorMessage = {
                success: false,
                status: error.status,
                data: error.message,
                url: url,
                params: params,
            };
        }
        return observableThrowError(errorMessage);
    };
    RoutingDataService.prototype.createRoutes = function (routingData) {
        var routes = [];
        var getChildrenTypes = function (children) {
            var types = [];
            for (var j = 0; j < (children || []).length; ++j) {
                types.push(children[j].type);
            }
            return types;
        };
        var i = 0;
        var apiItem;
        var routingItem;
        var parentsChildren = [];
        var hasItems = {
            searchResults: false,
            artists: false,
            auctions: false,
            exhibitions: false,
            news: false,
            products: false,
        };
        // poszukiwania niezbędnych, choć prawdopodobnie brakujących routes
        for (var rdsi = 0; rdsi < (routingData || []).length; ++rdsi) {
            apiItem = routingData[rdsi];
            // wyniki wyszukiwania
            if (apiItem.type === 'search-results') {
                hasItems.searchResults = true;
            }
            else if (typeof hasItems[apiItem.type] !== 'undefined') { // pozostałe
                hasItems[apiItem.type] = true;
            }
        }
        // uzupełnienia: wyniki wyszukiwania
        if (!hasItems.searchResults) {
            var slugs = {};
            var possibleSlugs = {
                pl: 'wyniki-wyszukiwania',
                en: 'search-results',
            };
            // mechanizm wypalarki nie respektuje zmian ustawień dostępnych języków
            // for (let i = 0; i < (environment.langs.allowed || []).length; ++i) {
            //   this.langsAllowed.push(environment.langs.allowed[i].lang);
            // }
            // obejście:
            for (var li = 0; li < (allowedLangs || []).length; ++li) {
                var lang = allowedLangs[li].lang;
                slugs[lang] = typeof possibleSlugs[lang] === 'undefined'
                    ? possibleSlugs.en
                    : possibleSlugs[lang];
            }
            var searchRoute = {
                slugs: slugs,
                type: 'search-results',
            };
            routingData.push(searchRoute);
        }
        // uzupełnienia: routing potomny
        var hasChildren = ['artists', 'auctions', 'exhibitions', 'news', 'products'];
        var cProp;
        var childSlugs;
        var childRoute;
        var parentRoute;
        for (var hci = 0; hci < (hasChildren || []).length; ++hci) {
            cProp = hasChildren[hci];
            if (typeof hasItems[cProp] === 'undefined') {
                continue;
            }
            childRoute = ObjectUtils.arrayFindFirstObject(routingData, 'type', cProp + '-single');
            if (childRoute) {
                continue;
            }
            parentRoute = ObjectUtils.arrayFindFirstObject(routingData, 'type', cProp);
            if (!parentRoute) {
                continue;
            }
            childSlugs = {};
            for (var li = 0; li < (environment.langs.allowed || []).length; ++li) {
                var lang = environment.langs.allowed[li].lang;
                childSlugs[lang] = cProp + '-single';
            }
            childRoute = {
                slugs: childSlugs,
                type: cProp + '-single',
            };
            routingData.push(childRoute);
        }
        for (; i < (routingData || []).length; ++i) {
            apiItem = ObjectUtils.copy(routingData[i]);
            var ii = 0;
            var routingBaseItem = void 0;
            var routingMapItem = void 0;
            for (; ii < (routingBaseMap || []).length; ++ii) {
                routingBaseItem = routingBaseMap[ii];
                if (routingBaseItem.type === apiItem.type) {
                    routingMapItem = routingBaseItem;
                    break;
                }
                if (routingBaseItem.children) {
                    for (var ci = 0; ci < (routingBaseItem.children || []).length; ++ci) {
                        if (routingBaseItem.children[ci].type === apiItem.type) {
                            routingMapItem = {
                                type: apiItem.type,
                                module: routingBaseItem.module,
                                segment: routingBaseItem.children[ci].segment,
                            };
                            break;
                        }
                    }
                }
            }
            if (!routingMapItem) {
                continue;
            }
            routingItem = {
                type: apiItem.type,
                slugs: apiItem.slugs,
                module: routingMapItem.module,
            };
            if (routingMapItem.children) {
                routingItem.children = routingMapItem.children;
                parentsChildren.push({
                    parent: routingMapItem.type,
                    children: routingMapItem.children,
                    childrenTypes: getChildrenTypes(routingMapItem.children),
                });
            }
            routes.push(routingItem);
        }
        for (i = 0; i < (parentsChildren || []).length; ++i) {
            var pcItem = parentsChildren[i];
            for (var ii = 0; ii < (routes || []).length; ++ii) {
                if (pcItem.childrenTypes.indexOf(routes[ii].type) > -1) {
                    routes[ii].parent = pcItem.parent;
                }
            }
        }
        this.routingData = routes;
        return routes;
    };
    return RoutingDataService;
}());
export { RoutingDataService };
