import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { GlobalService } from '../../model/global.service';
import { TranslatePipe } from '../translate-pipes/translate.pipe';

@Pipe({
  name: 'artistDates',
  pure: true,
})
export class ArtistDatesPipe implements PipeTransform {

  constructor(
    private translatePipe: TranslatePipe,
    private sanitizer: DomSanitizer,
    private globalService: GlobalService,
  ) {
  }

  transform(artist: any, lang = 'pl', useBrackets = true, sanitize = true): SafeHtml | string {
    let dates = '';

    if (this.globalService.settings.__loaded && this.globalService.settings.offer.display_artist_dates) {

      // jest data urodzenia nie ma daty śmierci
      if (artist.date_of_birth && !artist.date_of_death) {
        dates += '<span class="lowercase">' + this.translatePipe.transform('artists.born-abbr', lang) + '</span>' + ' ';

        if (Number(artist.date_of_birth_circa) === 1) {
          dates += '<span class="lowercase">' + this.translatePipe.transform('artists.circa-abbr', lang) + '</span>' + ' ';
        }
        dates += artist.date_of_birth;
      }

      // jest data urodzenia i jest data śmierci
      if (artist.date_of_birth && artist.date_of_death) {

        if (Number(artist.date_of_birth_circa) === 1) {
          dates += '<span class="lowercase">' + this.translatePipe.transform('artists.circa-abbr', lang) + '</span>' + ' ';
        }

        dates += artist.date_of_birth;

        if (artist.date_of_death) {
          dates += ' - ';

          if (Number(artist.date_of_birth_circa) === 1) {
            dates += '<span class="lowercase">' + this.translatePipe.transform('artists.circa-abbr', lang) + '</span>' + ' ';
          }

          dates += artist.date_of_death;
        }
      }

      // daty aktywności
      if (artist.active_from) {
        if (artist.date_of_birth) {
          dates += ', ';
        }

        dates += '<span class="lowercase">' + this.translatePipe.transform('artists.active-abbr', lang) + '</span> ';

        if (Number(artist.active_to_circa) === 1) {
          dates += '<span class="lowercase">' + this.translatePipe.transform('artists.circa-abbr', lang) + '</span>' + ' ';
        }

        dates += artist.active_from;

        if (artist.active_to) {
          dates += ' - ';

          if (Number(artist.active_to_circa) === 1) {
            dates += '<span class="lowercase">' + this.translatePipe.transform('artists.circa-abbr', lang) + '</span>' + ' ';
          }

          dates += artist.active_to;
        }
      }

    }

    if ((dates || []).length && useBrackets) {
      dates = '(' + dates + ')';
    }

    return sanitize ? this.sanitizer.bypassSecurityTrustHtml(dates) : dates;
  }

}
