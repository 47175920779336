import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../model/global.service';
import { UrlService } from '../../model/url.service';
import { ObjectUtils } from '../../shared/utils/object-utils';
import { BlocksPlacement } from '../../blocks-wrapper/blocks-wrapper.component';
import { AutoUnsubscribe } from '../../decorators/autounsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-partners-block',
  templateUrl: './partners-block.component.html',
})
export class PartnersBlockComponent implements OnInit, AfterViewInit, OnDestroy {

  public partners: { [key: string]: any }[];
  @Input() context: BlocksPlacement;
  @Input() animation_main = '';
  @Output() viewInit = new EventEmitter<string>();
  private gsSub: Subscription;

  constructor(
    public globalService: GlobalService,
    public urlService: UrlService,
    protected sanitizer: DomSanitizer,
  ) {
    this.partners = [];
  }

  ngOnInit(): void {
    this.gsSub = this.globalService.updated.subscribe((settings) => {
      if (!settings.partners || (settings.partners || []).length === 0) {
        return;
      }

      this.partners = ObjectUtils.copy(settings.partners);
    });
  }

  ngAfterViewInit(): void {
    this.viewInit.emit('partners');
  }

  ngOnDestroy(): void {
    if (this.gsSub) {
      this.gsSub.unsubscribe();
    }
  }

  hasPartners(): boolean {
    return (this.partners || []).length > 0;
  }

}
