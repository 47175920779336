import { BehaviorSubject, Observable, Subject, throwError as observableThrowError } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { UrlService } from './url.service';
import { UniversalPlatformService } from '../shared/platform/universal-platform.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CmsService {

  get menuItems(): BehaviorSubject<{ [key: string]: any }[]> {
    return this._menuItems;
  }

  public contents: any = {};
  public menu: any = [];
  public requestError = new Subject<any>();

  private _menuItems: BehaviorSubject<{ [key: string]: any }[]>;

  constructor(
    private _http: HttpClient,
    private _urlService: UrlService,
    private _globalService: GlobalService,
    private _platformService: UniversalPlatformService,
  ) {
    _urlService.langChange.subscribe(() => {
      this.refresh();
    });

    this._menuItems = new BehaviorSubject(null);
  }

  getMenu(): Observable<any> {
    const langParam: string = this._urlService.lang;

    const url = this._globalService.apiHost + '/menu?lang=' + langParam;
    const params = {};

    return this._http.get<any>(url, params).pipe(
      map(response => response),
      map((response) => {
        return <any>response;
      }),
      catchError((error: Response | any): Observable<any> => {
        return this.handleError(error, url, params);
      }),
    );
  }

  refresh(): void {
    this.getMenu().subscribe((menu: any[]) => {
      this._platformService.localStorage
        .setItem('menu', JSON.stringify(menu), this._platformService.localStorage.countMinutes(2));

      const client = this._globalService.apiHost.split('.')[0].split('//')[1];
      // if (this._urlService.lang === 'en' && client === 'wejmangallery') {
      //   menu.splice(1, 0, {
      //     children: [],
      //     content_type: 'products',
      //     parameters: { category_id: '2' },
      //     target: '_self',
      //     title: 'Painting',
      //     url: 'offer',
      //   });
      // }
      menu = menu.filter((item, index) => {
        const _item = JSON.stringify(item);
        return index === menu.findIndex(obj => {
          return JSON.stringify(obj) === _item;
        });
      });

      this.menu = menu;
      this._menuItems.next(menu);
    });
  }

  handleError(error: Response | any, url: string, params: { [key: string]: any } | null): Observable<any> {
    let errorMessage: any;

    if (error.status === 0) {
      errorMessage = {
        success: false,
        status: 0,
        data: 'Sorry, there was a connection error. Please try again.',
        url: url,
        params: params,
      };
    } else if (error.json) {
      errorMessage = error;
    } else {
      errorMessage = {
        success: false,
        status: error.status,
        data: error.message,
        url: url,
        params: params,
      };
    }

    this.requestError.next(errorMessage);
    return observableThrowError(errorMessage);
  }
}
