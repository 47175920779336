import { ElementRef, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { FacebookService } from 'ngx-facebook';
import { GlobalService } from '../model/global.service';
import { UrlService } from '../model/url.service';
import { MetaData } from './metadata/metadata.service';
import { UniversalPlatformService } from './platform/universal-platform.service';
import { UniversalRendererHelper } from './platform/universal-renderer.helper';
var FbService = /** @class */ (function () {
    function FbService(_rendererHelper, _platformService, _globalService, _urlService, _metadata, _fbService) {
        var _this = this;
        this._rendererHelper = _rendererHelper;
        this._platformService = _platformService;
        this._globalService = _globalService;
        this._urlService = _urlService;
        this._metadata = _metadata;
        this._fbService = _fbService;
        this._sic = 0;
        this._fbInitialized = new BehaviorSubject(0);
        this._scriptRemoved = new Subject();
        this._scriptRefreshed = new Subject();
        this._langSub = _urlService.langChange.subscribe(function () {
            if (_this._platformService.isBrowser) {
                _this.reload();
            }
            _this.updateCommonOg();
        });
    }
    Object.defineProperty(FbService.prototype, "initialized", {
        get: function () {
            return this._fbInitialized;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FbService.prototype, "fbRemoved", {
        get: function () {
            return this._scriptRemoved;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FbService.prototype, "fbRefreshed", {
        get: function () {
            return this._scriptRefreshed;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FbService.prototype, "script", {
        get: function () {
            return this._script;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FbService.prototype, "_fbScriptUrl", {
        get: function () {
            var langCode = this._urlService.langCode.substr(0, 3) + this._urlService.langCode.substr(3).toUpperCase();
            return 'https://connect.facebook.net/' + langCode + '/sdk.js';
        },
        enumerable: true,
        configurable: true
    });
    FbService.prototype.ngOnDestroy = function () {
        this._langSub.unsubscribe();
        if (this._settingsSub && !this._settingsSub.closed) {
            this._settingsSub.unsubscribe();
        }
    };
    FbService.prototype.updateCommonOg = function () {
        if (this._globalService.settings.__loaded) {
            this._metadata.setFbAppID(this._globalService.settings.general.fb_app_id);
            this._metadata.setOg('type', 'website');
            this._metadata.setOg('url', this._urlService.currentURL);
        }
    };
    FbService.prototype.initialize = function () {
        if (this._platformService.isBrowser) {
            this._insertFbConnectScript();
        }
    };
    FbService.prototype.parse = function () {
        var _this = this;
        clearTimeout(this._parseTimeout);
        this._parseTimeout = setTimeout(function () {
            if (typeof _this._platformService.window['FB'] !== 'undefined'
                && _this._platformService.window['FB'] && _this._platformService.window['FB'].XFBML) {
                _this._platformService.window['FB'].XFBML.parse();
            }
        }, 1000);
    };
    FbService.prototype.reload = function () {
        clearTimeout(this._parseTimeout);
        if (this._platformService.isBrowser) {
            var fbRoot = this._platformService.document.getElementById('fb-root');
            if (fbRoot) {
                fbRoot.parentNode.removeChild(fbRoot);
                this._scriptRemoved.next();
            }
            // this._rendererHelper.removeChild(this._script.nativeElement.parentNode, this._script);
            this._script.nativeElement.parentNode.removeChild(this._script.nativeElement);
            if (typeof this._platformService.window['FB'] !== 'undefined') {
                delete this._platformService.window['FB'];
            }
            this._insertFbConnectScript(true);
        }
    };
    FbService.prototype._insertFbConnectScript = function (replay) {
        var _this = this;
        if (replay === void 0) { replay = false; }
        var script = this._platformService.document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.charset = 'utf-8';
        script.id = 'facebook-jssdk';
        script.async = true;
        this._script = new ElementRef(script);
        this._rendererHelper.addEventListener(this._script, 'load', function () {
            _this._fbInit();
            if (replay) {
                _this.fbRefreshed.next();
            }
        });
        script.src = this._fbScriptUrl;
        this._platformService.document.head.appendChild(script);
    };
    FbService.prototype._fbInit = function () {
        var _this = this;
        this._settingsSub = this._globalService.updated.subscribe(function (settings) {
            if (!settings.__loaded || !settings.general.fb_app_id) {
                return;
            }
            var params = {
                appId: settings.general.fb_app_id,
                version: settings.general.fb_api_version,
                xfbml: true,
            };
            _this._fbService.init(params).then(
            // ładowanie zakończone sukcesems:
            function () {
                ++_this._sic;
                _this._fbInitialized.next(_this._sic);
                // this.parse();
            });
        });
    };
    return FbService;
}());
export { FbService };
