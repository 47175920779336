import * as tslib_1 from "tslib";
import { AfterViewInit, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalService } from '../../model/global.service';
import { SugarService } from '../../model/sugar.service';
import { UrlService } from '../../model/url.service';
import { UniversalPlatformService } from '../../shared/platform/universal-platform.service';
import { OfferColletionSetter } from '../../shared/collections/api-collection-setters';
import { AutoUnsubscribe } from '../../decorators/autounsubscribe.decorator';
var SoldItemsBlockComponent = /** @class */ (function () {
    function SoldItemsBlockComponent(globalService, urlService, sanitizer, platformService, sugarService) {
        this.globalService = globalService;
        this.urlService = urlService;
        this.sanitizer = sanitizer;
        this.platformService = platformService;
        this.sugarService = sugarService;
        this.title = '';
        this.maxItems = 50;
        this.viewInit = new EventEmitter();
        // this.options = {
        //   horizontalOrder:true,
        //   itemSelector:'.masonry-grid-item',
        //   columnWidth:'.grid-sizer',
        //   percentPosition:true
        // };
        this.zoomImages = this.globalService.settings.global.sliderRotation === 'true';
    }
    SoldItemsBlockComponent.prototype.ngOnInit = function () {
        var _this = this;
        // this.sugarService.setCollection('sold-items', {
        //   "sugarNode": 'groups',
        //   "parameters": {
        //     "expand": 'items,products,products.images,products.artists',
        //     "filter[type]": 'exhibition_category_sold',
        //   }
        // });
        this.sugarService.setCollection('sold-items', {
            sugarNode: 'products',
            parameters: {
                expand: 'images,artists',
                'filter[group.type]': 'exhibition_category_sold',
                'filter[archive]': '1',
            },
        });
        this._dataSub = this.sugarService.getData().subscribe(function (data) {
            if (data.collectionId === 'sold-items') {
                // const products: {[key: string]: string}[] = data.data && (data.data.items || []).length ? data.data.items[0].products : [];
                var products = data.data;
                if ((products || []).length) {
                    var items = products;
                    var collectionSetter = new OfferColletionSetter(_this.urlService, _this.sugarService, _this.platformService, _this.globalService, _this.sanitizer);
                    _this.items = collectionSetter.setup(items, 'sold-items');
                }
            }
        });
        this.sugarService.refreshData('sold-items');
    };
    SoldItemsBlockComponent.prototype.ngOnDestroy = function () {
        if (this._dataSub) {
            this._dataSub.unsubscribe();
        }
        this.sugarService.unsetCollection('sold-items');
    };
    SoldItemsBlockComponent.prototype.ngAfterViewInit = function () {
        this.viewInit.emit('sold');
    };
    SoldItemsBlockComponent.prototype.trackByFn = function (index, item) {
        return item.id;
    };
    SoldItemsBlockComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [GlobalService,
            UrlService,
            DomSanitizer,
            UniversalPlatformService,
            SugarService])
    ], SoldItemsBlockComponent);
    return SoldItemsBlockComponent;
}());
export { SoldItemsBlockComponent };
