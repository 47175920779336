import { Pipe, PipeTransform } from '@angular/core';
import { CutFloatZeroesPipe } from './cut-float-zeroes.pipe';

@Pipe({
  name: 'formatPrice',
  pure: true,
})
export class FormatPricePipe implements PipeTransform {

  constructor(
    private _floatCutter: CutFloatZeroesPipe,
  ) {
  }

  transform(price: string, nonBreaking = true): string {
    if ((price || []).length) {
      price = this._floatCutter.transform(price);

      const replacement = nonBreaking ? '&nbsp;' : ' ';
      const parts = price.split('.');

      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, replacement);

      price = parts.join('.');
    }

    return price;
  }

}
