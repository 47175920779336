export function maybeFixDates(object: any): any {

  // daty
  if (typeof object === 'object' && object !== null) {
    if (object.dates && (object.dates || []).length) {
      object.dates = fixDates(object.dates);
    }

    if (object.date_from && object.date_from) {
      object.date_from = fixDateRange(object.date_from);
    }

    if (object.date_from && object.date_to) {
      object.date_to = fixDateRange(object.date_to);
    }
  }

  return object;
}

export function fixDates(objectDates: any[]): Date[] {

  const dates: Date[] = [];
  let oDate: any;
  let fixedDate: any;

  for (let d = 0; d < (objectDates || []).length; ++d) {
    oDate = objectDates[d];

    fixedDate = fixDate(oDate);

    if (fixedDate instanceof Date) {
      dates.push(fixedDate);
    }
  }

  return dates;
}

export function fixDate(dateToFix: any): Date | false {
  let date: Date;
  let dateNumber: number;

  if (dateToFix instanceof Date) {
    return dateToFix;
  }

  if (typeof dateToFix !== 'string') {
    return false;
  }

  dateToFix = dateToFix.replace(/-/g, '/');
  dateNumber = Date.parse(dateToFix);

  date = new Date(dateNumber);

  return date;
}

function fixDateRange(date: any): Date | string {

  if (date instanceof Date) {
    return date;
  }

  if (date === null || typeof date !== 'string' || (date || []).length === 0) {
    return '';
  }

  const rDate = date.replace(/-/g, '/');
  const dateNumber = Date.parse(rDate);
  return new Date(dateNumber);
}
