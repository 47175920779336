import * as tslib_1 from "tslib";
import { Nl2BrPipe } from '../nl2br.pipe';
import { SanitizedDataCollection } from './sanitized-data-collection.class';
import { Sanitizer } from './api-collection-setters';
var DataExtractor = /** @class */ (function () {
    function DataExtractor(sanitizer, urlService) {
        this.sanitizer = sanitizer;
        this.urlService = urlService;
    }
    DataExtractor.prototype.extract = function (data, dataMap, skipEmpty) {
        if (skipEmpty === void 0) { skipEmpty = false; }
        var collection = new SanitizedDataCollection();
        var nl2br = new Nl2BrPipe();
        var dataProp;
        var confProp;
        var sanitize;
        var sanitizationType;
        var key;
        var value;
        var sanitizer = new Sanitizer(this.sanitizer);
        for (key in dataMap) {
            if (!dataMap.hasOwnProperty(key)) {
                continue;
            }
            confProp = dataMap[key];
            if (typeof confProp === 'string') {
                dataProp = confProp;
                sanitize = false;
            }
            else {
                dataProp = confProp[0];
                sanitize = confProp[1];
                sanitizationType = typeof confProp[2] === 'undefined' ? null : confProp[2];
            }
            if (data[dataProp]) {
                if (typeof data[dataProp] === 'string') {
                    value = data[dataProp];
                }
                else if (typeof data[dataProp] === 'object' && typeof data[dataProp][this.urlService.lang] !== 'undefined') {
                    value = data[dataProp][this.urlService.lang];
                }
                else if (typeof data[dataProp] !== 'undefined') {
                    value = data[dataProp];
                }
                else {
                    value = null;
                }
            }
            else {
                value = null;
            }
            if (!value && skipEmpty) {
                continue;
            }
            if (value !== null && sanitize) {
                value = nl2br.transform(value);
                value = sanitizer.sanitize(value, sanitizationType);
            }
            collection.set(key, value, sanitize);
        }
        return collection;
    };
    return DataExtractor;
}());
export { DataExtractor };
var ContactDataExtractor = /** @class */ (function (_super) {
    tslib_1.__extends(ContactDataExtractor, _super);
    function ContactDataExtractor() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ContactDataExtractor.prototype.create = function (contactSettings) {
        var dataMap = {
            name: ['gallery_name', true, 'html'],
            street: 'address',
            place: 'place',
            postcode: 'postcode',
            city: 'city',
            phone: 'phone',
            phone2: 'phone2',
            fax: 'fax',
            email: 'email',
            bank_account: 'bankaccountnumber',
            openinghours: ['openinghours', true, 'html'],
            nip: 'nip',
            regon: 'regon',
        };
        return this.extract(contactSettings, dataMap);
    };
    return ContactDataExtractor;
}(DataExtractor));
export { ContactDataExtractor };
var SocialsDataExtractor = /** @class */ (function (_super) {
    tslib_1.__extends(SocialsDataExtractor, _super);
    function SocialsDataExtractor() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SocialsDataExtractor.prototype.create = function (socialsSettings) {
        var dataMap = {};
        var socialName;
        for (socialName in socialsSettings) {
            if (!socialsSettings.hasOwnProperty(socialName) || 'fb_app_id' === socialName) {
                continue;
            }
            dataMap[socialName] = [socialName, true, 'url'];
        }
        return this.extract(socialsSettings, dataMap, true);
    };
    return SocialsDataExtractor;
}(DataExtractor));
export { SocialsDataExtractor };
