import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipesModule } from '../shared/translate-pipes.module';
import { P404Component } from './p404.component';
import { P404RoutingModule } from './p404-routing.module';
import { BlocksWrapperModule } from '../blocks-wrapper/blocks-wrapper.module';

@NgModule({
  imports: [
    CommonModule,
    P404RoutingModule,
    TranslatePipesModule,
    BlocksWrapperModule,
  ],
  declarations: [
    P404Component,
  ],
})
export class P404Module {
}
