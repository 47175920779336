import * as tslib_1 from "tslib";
import { AfterViewInit, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ComponentsService } from '../model/components.service';
import { UniversalPlatformService } from '../shared/platform/universal-platform.service';
import { ObjectUtils } from '../shared/utils/object-utils';
import { AutoUnsubscribe } from '../decorators/autounsubscribe.decorator';
var BlocksWrapperComponent = /** @class */ (function () {
    function BlocksWrapperComponent(domSanitizer, platformService, _componentsService) {
        this.domSanitizer = domSanitizer;
        this.platformService = platformService;
        this._componentsService = _componentsService;
        this.viewInit = new EventEmitter();
        this.maincontents = 1; // deprecated?
        this.placement = 'whole';
        this.mbIndex = -1;
        this.receivedBlocks = false;
        this.preloading = true;
        this.totalBlocks = 0;
        this.loadedBlocks = 0;
        this.pageBlocks = [];
    }
    BlocksWrapperComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sub = this._componentsService.getComponents().subscribe(function (apiData) {
            if (typeof apiData.blocks === 'undefined') {
                return;
            }
            var i, pushIn, block;
            var blocks = Array.isArray(apiData.blocks) ? apiData.blocks : [];
            var pageBlocks = [];
            _this.pageBlocks = [];
            _this.loadedBlocks = 0;
            _this.totalBlocks = 0;
            // najpierw znajdujemy główny blok...
            for (i = 0; i < (blocks || []).length; ++i) {
                if (blocks[i].type === 'mainblock') {
                    _this.mbIndex = i;
                    break;
                }
            }
            // const skipped = [];
            // ... a następnie przeprowadzamy właściwe rozpisanie
            for (i = 0; i < (blocks || []).length; ++i) {
                block = blocks[i];
                // if (skipped.indexOf(block.type) > -1) {
                //   --this.totalBlocks;
                //   continue;
                // }
                if (block.type === 'mainblock') {
                    continue; // mainblock nie jest ładowany
                }
                pushIn = _this.placement === 'whole'
                    || (_this.placement === 'before' && i < _this.mbIndex)
                    || (_this.placement === 'after' && i > _this.mbIndex);
                if (pushIn) {
                    pageBlocks.push(ObjectUtils.copy(block));
                    ++_this.totalBlocks;
                }
            }
            _this.receivedBlocks = true;
            _this.preloading = _this.totalBlocks > 0;
            _this.pageBlocks = pageBlocks;
        });
    };
    BlocksWrapperComponent.prototype.ngOnDestroy = function () {
        this.sub.unsubscribe();
    };
    BlocksWrapperComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.blockLoaded();
        });
    };
    BlocksWrapperComponent.prototype.showPreloader = function () {
        return this.preloading;
    };
    BlocksWrapperComponent.prototype.blockLoaded = function () {
        var _this = this;
        if (!this.receivedBlocks) {
            return;
        }
        ++this.loadedBlocks;
        if (this.loadedBlocks >= this.totalBlocks) {
            var finishLoading_1 = function () {
                _this.preloading = false;
                _this.viewInit.emit();
            };
            if (this.platformService.isBrowser) {
                setTimeout(function () {
                    finishLoading_1();
                }, 500);
            }
            else {
                finishLoading_1();
            }
        }
    };
    BlocksWrapperComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [DomSanitizer,
            UniversalPlatformService,
            ComponentsService])
    ], BlocksWrapperComponent);
    return BlocksWrapperComponent;
}());
export { BlocksWrapperComponent };
