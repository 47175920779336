/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/router";
import * as i3 from "../../../../node_modules/ngx-owl-carousel/src/owl-carousel.component.ngfactory";
import * as i4 from "ngx-owl-carousel/src/owl-carousel.component";
import * as i5 from "./slider-block.component";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../model/url.service";
import * as i8 from "../../shared/platform/universal-platform.service";
import * as i9 from "../../model/global.service";
var styles_SliderBlockComponent = [".carousel-caption[_ngcontent-%COMP%] {\n            visibility: visible !important\n        }"];
var RenderType_SliderBlockComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_SliderBlockComponent, data: {} });
export { RenderType_SliderBlockComponent as RenderType_SliderBlockComponent };
function View_SliderBlockComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "header", [["class", "carousel-article-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "carousel-article-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.item.title; _ck(_v, 2, 0, currVal_0); }); }
function View_SliderBlockComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "carousel-article-text"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.item.bodySanitized; _ck(_v, 0, 0, currVal_0); }); }
function View_SliderBlockComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SliderBlockComponent_2)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SliderBlockComponent_3)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.item.title; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.item.bodySanitized; _ck(_v, 3, 0, currVal_1); }, null); }
function View_SliderBlockComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_SliderBlockComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "a", [["class", "carousel-article-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_SliderBlockComponent_7)), i0.ɵdid(4, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(5, { item: 0 })], function (_ck, _v) { var currVal_2 = _v.parent.parent.context.item.url; _ck(_v, 2, 0, currVal_2); var currVal_3 = _ck(_v, 5, 0, _v.parent.parent.context.item); var currVal_4 = i0.ɵnov(_v.parent.parent.parent, 1); _ck(_v, 4, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_SliderBlockComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_SliderBlockComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [["class", "carousel-article-link"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_SliderBlockComponent_9)), i0.ɵdid(3, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(4, { item: 0 })], function (_ck, _v) { var currVal_1 = _ck(_v, 4, 0, _v.parent.parent.context.item); var currVal_2 = i0.ɵnov(_v.parent.parent.parent, 1); _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.item.url; _ck(_v, 1, 0, currVal_0); }); }
function View_SliderBlockComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SliderBlockComponent_6)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SliderBlockComponent_8)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.item.isInternal; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.parent.context.item.isInternal; _ck(_v, 4, 0, currVal_1); }, null); }
function View_SliderBlockComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_SliderBlockComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_SliderBlockComponent_11)), i0.ɵdid(2, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(3, { item: 0 }), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.parent.context.item); var currVal_1 = i0.ɵnov(_v.parent.parent, 1); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_SliderBlockComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "carousel-caption d-block"]], [[1, "data-wow-delay", 0], [1, "data-wow-duration", 0], [1, "data-wow-iteration", 0], [1, "data-wow-offset", 0]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SliderBlockComponent_5)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SliderBlockComponent_10)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = "carousel-caption d-block"; var currVal_5 = _co.wowClasses; _ck(_v, 1, 0, currVal_4, currVal_5); var currVal_6 = _v.context.item.url; _ck(_v, 3, 0, currVal_6); var currVal_7 = !_v.context.item.url; _ck(_v, 5, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = (_v.context.wow ? _v.context.wow.delay : null); var currVal_1 = (_v.context.wow ? _v.context.wow.duration : null); var currVal_2 = (_v.context.wow ? _v.context.wow.iteration : null); var currVal_3 = (_v.context.wow ? _v.context.wow.offset : null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_SliderBlockComponent_15(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["class", "carousel-bg-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null)], function (_ck, _v) { var currVal_2 = _v.parent.parent.context.$implicit.url; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_SliderBlockComponent_16(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "a", [["class", "carousel-bg-link"]], [[8, "href", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.url; _ck(_v, 1, 0, currVal_0); }); }
function View_SliderBlockComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SliderBlockComponent_15)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SliderBlockComponent_16)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.isInternal; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.parent.context.$implicit.isInternal; _ck(_v, 4, 0, currVal_1); }, null); }
function View_SliderBlockComponent_18(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_SliderBlockComponent_17(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_SliderBlockComponent_18)), i0.ɵdid(2, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(3, { item: 0, wow: 1 }), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.parent.context.$implicit, _co.wow); var currVal_1 = i0.ɵnov(_v.parent.parent.parent, 2); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_SliderBlockComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "owl-slide"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "carousel-item-bg-position"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "carousel-item-bg"]], null, null, null, null, null)), i0.ɵdid(3, 278528, null, 0, i1.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(4, { "background-image": 0 }), i0.ɵpod(5, { "background-image": 0, "transform": 1 }), (_l()(), i0.ɵeld(6, 0, null, null, 4, "article", [["class", "carousel-article"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SliderBlockComponent_14)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SliderBlockComponent_17)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.rotation ? _ck(_v, 4, 0, (("url(" + _v.context.$implicit.image) + ")")) : _ck(_v, 5, 0, (("url(" + _v.context.$implicit.image) + ")"), "none")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.url; _ck(_v, 8, 0, currVal_1); var currVal_2 = (_v.context.$implicit.title || _v.context.$implicit.bodySanitized); _ck(_v, 10, 0, currVal_2); }, null); }
function View_SliderBlockComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "section", [["class", "page-top"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "carousel-home-top"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 4, "owl-carousel", [], null, null, null, i3.View_OwlCarousel_0, i3.RenderType_OwlCarousel)), i0.ɵdid(4, 311296, [[1, 4], ["owl", 4]], 0, i4.OwlCarousel, [i0.IterableDiffers], { carouselClasses: [0, "carouselClasses"], options: [1, "options"] }, null), i0.ɵpad(5, 4), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_SliderBlockComponent_13)), i0.ɵdid(7, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "wrapper-container carousel-indicators-wrapper"]], [[1, "id", 0]], null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "div", [["class", "carousel-indicators"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "carousel-home-top"; var currVal_1 = _co.styles; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 5, 0, "carousel", "slide", "carousel-home-top", "owl-theme"); var currVal_3 = _co.options; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = _co.slides; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.dotsContainerId; _ck(_v, 8, 0, currVal_5); }); }
export function View_SliderBlockComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { owlElement: 0 }), (_l()(), i0.ɵand(0, [["slideContent", 2]], null, 0, null, View_SliderBlockComponent_1)), (_l()(), i0.ɵand(0, [["slideContentBlock", 2]], null, 0, null, View_SliderBlockComponent_4)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SliderBlockComponent_12)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.slides && ((_co.slides == null) ? null : _co.slides.length)); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_SliderBlockComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-slider-block", [], null, null, null, View_SliderBlockComponent_0, RenderType_SliderBlockComponent)), i0.ɵdid(1, 4308992, null, 0, i5.SliderBlockComponent, [i6.DomSanitizer, i7.UrlService, i8.UniversalPlatformService, i9.GlobalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SliderBlockComponentNgFactory = i0.ɵccf("app-slider-block", i5.SliderBlockComponent, View_SliderBlockComponent_Host_0, { context: "context", wow: "wow", block: "block", slides: "slides" }, { viewInit: "viewInit" }, []);
export { SliderBlockComponentNgFactory as SliderBlockComponentNgFactory };
