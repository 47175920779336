/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./big-image-block.component";
import * as i3 from "@angular/platform-browser";
var styles_BigImageBlockComponent = [];
var RenderType_BigImageBlockComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BigImageBlockComponent, data: {} });
export { RenderType_BigImageBlockComponent as RenderType_BigImageBlockComponent };
function View_BigImageBlockComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.url, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 1, 0, currVal_1); }); }
function View_BigImageBlockComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_BigImageBlockComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "big-image-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 1, 0, currVal_0); }); }
export function View_BigImageBlockComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "section", [["class", "big-image-wrapper hero-image-fixed page-section"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "big-image-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "big-image-title"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BigImageBlockComponent_1)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BigImageBlockComponent_2)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BigImageBlockComponent_3)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.url; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.url; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.text; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.backgroundStyle; _ck(_v, 0, 0, currVal_0); }); }
export function View_BigImageBlockComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-big-image-block", [], null, null, null, View_BigImageBlockComponent_0, RenderType_BigImageBlockComponent)), i0.ɵdid(1, 4308992, null, 0, i2.BigImageBlockComponent, [i3.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BigImageBlockComponentNgFactory = i0.ɵccf("app-big-image-block", i2.BigImageBlockComponent, View_BigImageBlockComponent_Host_0, { context: "context", url: "url", title: "title", text: "text", background: "background" }, { viewInit: "viewInit" }, []);
export { BigImageBlockComponentNgFactory as BigImageBlockComponentNgFactory };
