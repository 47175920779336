// Locale
import { registerLocaleData } from '@angular/common';
// Routing Module
import { AppInitService } from './app-init.service';
import localePl from '@angular/common/locales/pl';
import localeEn from '@angular/common/locales/pl';
registerLocaleData(localePl);
registerLocaleData(localeEn);
var ɵ0 = function (appInitService) {
    return function () {
        return appInitService.init();
    };
};
// export function cacheFactory(): CacheLoader {
//   return new CacheStaticLoader({
//     key: 'NGX_CACHE',
//     lifeSpan: {
//       "expiry": Number.MAX_VALUE,
//       "TTL":  Number.MAX_VALUE
//     }
//   });
// }
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
