import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UrlService } from '../../model/url.service';
import { BlocksPlacement } from '../../blocks-wrapper/blocks-wrapper.component';
import { AutoUnsubscribe } from '../../decorators/autounsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-product-counter-block',
  templateUrl: './product-counter-block.component.html',
})
export class ProductCounterBlockComponent implements AfterViewInit {
  @Input() context: BlocksPlacement;
  @Input() firstLine: { [key: string]: string };
  @Input() secondLine: { [key: string]: string };

  @Output() viewInit = new EventEmitter<string>();

  public firstLineSanitized: SafeHtml | string;
  public secondLineSanitized: SafeHtml | string;

  constructor(
    protected sanitizer: DomSanitizer,
    protected urlService: UrlService,
  ) {
    this.firstLine = {};
    this.secondLine = {};

    for (const lang in this.urlService.allowedLangs) {
      if (this.urlService.allowedLangs.hasOwnProperty(lang)) {
        this.firstLine[lang] = '';
        this.secondLine[lang] = '';
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      let line: string;

      if (this.firstLine[this.urlService.lang]) {
        line = this.replaceStrongWithLink(this.firstLine[this.urlService.lang]);
        this.firstLineSanitized = this.sanitizer.bypassSecurityTrustHtml(line);
      } else {
        this.firstLineSanitized = '';
      }

      if (this.secondLine[this.urlService.lang]) {
        line = this.replaceStrongWithLink(this.secondLine[this.urlService.lang]);
        this.secondLineSanitized = this.sanitizer.bypassSecurityTrustHtml(line);
      } else {
        this.secondLineSanitized = '';
      }

      this.viewInit.emit('product_counter');
    });
  }

  protected replaceStrongWithLink(text: string): string {
    if (text.indexOf('<strong>') > -1) {
      const offerLink = (this.urlService.usingHash ? '#' : '') + this.urlService.link('products');
      text = text.replace('<strong>', '<a href="' + offerLink + '"><strong>').replace('</strong>', '</strong></a>');
    }

    return text;
  }

}
