import { Injectable } from '@angular/core';
import { Params, Route, Router, Routes, RoutesRecognized } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '../../environments/environment';
import { RoutingItem } from './routing';

import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { SettingDataService } from './setting-data.service';
import { allowedLangs, RoutingDataService } from './routing-data.service';
import { UniversalPlatformService } from '../shared/platform/universal-platform.service';
import { QueryService } from '../shared/query.service';
import { ObjectUtils } from '../shared/utils/object-utils';
import { LangService } from './lang.service';

export declare interface LangChangeData {
  langFrom: string,
  langTo: string,
}

@Injectable()
export class UrlService {

  get allowedLangs(): { lang: string, code: string }[] {
    return this.langsAllowedFull;
  }

  get allowedLanguages(): { lang: string, code: string }[] {
    return this.allowedLangs;
  }

  get defaultLang(): string {
    return this.langDefault;
  }

  get currentURL(): string {
    // TODO: potrzebne odpowiednie pole z api, bo po stronie serwera tego nie da się ustalić
    let url = environment.url;

    if (url.indexOf('http') !== 0 && this.platformService.window.location) {
      url = this.platformService.window.location.protocol + url;
    }

    if (this._currentPath && (this._currentPath || []).length) {
      url += this._currentPath.indexOf('/') === 0 && url.lastIndexOf('/') === ((url || []).length - 1) ?
        this._currentPath.substr(1) :
        this._currentPath;
    }

    return url;
  }

  get protocollessSiteUrl(): string {
    let siteUrl = environment.url;

    if (siteUrl.indexOf('http:') === 0) {
      siteUrl = siteUrl.replace('http:', '');
    } else if (siteUrl.indexOf('https:') === 0) {
      siteUrl = siteUrl.replace('https:', '');
    }

    return siteUrl;
  }

  get usingHash(): boolean {
    return environment.locationStrategy === 'hash';
  }

  get routing(): RoutingDataService {
    return this.routingDataService;
  }

  get auctionsTermsUrl(): string {
    return this.link(this.lang === 'em' ? 'auction-terms-and-conditions' : 'regulamin-aukcji');
  }

  public get lang(): string {
    return LangService.lang;
  }

  public set lang(lang: string) {
    LangService.lang = lang;
  }

  get queryParams(): { [key: string]: any } {
    return this.queryService.params;
  }

  public routes: Routes;
  public routesCache: { [key: string]: any };
  public linksSubject: BehaviorSubject<Routes>;
  public settingsSubject: Subscription;
  public suffix: string;
  public langCode: string;
  public langChange = new Subject<LangChangeData>();
  protected has404Route: boolean;
  private currentModule = ''; // aktualna nazwa modulu np. "events"
  private currentModuleParameters = ''; // aktualne parametry adresu modułu np, "/13?expand=artists,attachments,events"
  private _currentPath: string;
  private langsAllowed: string[];
  private langsAllowedFull: { code: string, lang: string }[];
  private langDefault: string;
  private multilingual: boolean;

  private _queryParams: Params;

  static joinParams(key: string, param: string | string[]): string {
    const combined = UrlService.combineParams(key, param);
    const joint = [];

    const cmbLen = (combined || []).length;
    const keys: { [key: string]: number } = {};
    let i: number;
    let cKey: string;
    let jKey: string;

    for (i = 0; i < cmbLen; ++i) {
      cKey = combined[i].key;

      if (typeof keys[cKey] === 'undefined') {
        keys[cKey] = 0;
      }

      ++keys[cKey];
    }

    for (i = 0; i < cmbLen; ++i) {
      cKey = combined[i].key;

      if (keys[cKey] === 1) {
        jKey = cKey;
      } else {
        jKey = cKey + '[]';
      }

      joint.push(jKey + '=' + combined[i].value);
    }

    return joint.join('&');
  }

  static combineParams(key: string, param: string | string[]): { [key: string]: string }[] {
    let combined = [];

    if (typeof param === 'string' || typeof param === 'number') {
      const _combined = UrlService._combineParams(key, param);
      combined.push(_combined);
    } else if (Array.isArray(param)) {
      combined = UrlService._combineParamsArr(key, param);
    }

    return combined;
  }

  private static _combineParams(key: string, param: string): { [key: string]: string } {
    key = encodeURIComponent(key);
    param = encodeURIComponent(param);

    return {
      key: key,
      value: param,
    };
  }

  private static _combineParamsArr(key: string, params: string[]): { [key: string]: string }[] {
    const joint = [];

    for (let i = 0; i < (params || []).length; ++i) {
      joint.push(UrlService._combineParams(key, params[i]));
    }

    return joint;
  }

  constructor(
    public router: Router,
    private routingDataService: RoutingDataService,
    private settingsService: SettingDataService,
    private platformService: UniversalPlatformService,
    private location: Location,
    private queryService: QueryService,
  ) {
    const setAllowedLangs = () => {
      this.langsAllowed = [];
      this.langsAllowedFull = [];

      for (let i = 0; i < (allowedLangs || []).length; ++i) {
        this.langsAllowed.push(allowedLangs[i].lang);
        this.langsAllowedFull.push(allowedLangs[i]);
      }
    };

    setAllowedLangs();

    this.langDefault = environment.langs.default;
    this.multilingual = (this.langsAllowed || []).length > 1;

    this.settingsSubject = settingsService.settingsRefresh.subscribe(settings => {
      if (!settings || !settings.lang) {
        return;
      }

      this.langDefault = this.isAllowedLang(settings.lang.default)
        ? settings.lang.default
        : this.isAllowedLang(this.langDefault)
          ? this.langDefault
          : this.allowedLangs[0].lang;

      this.multilingual = parseInt(settings.lang.lang, 10) > 0;

      if (!this.multilingual) {

        for (let i = 0; i < (this.langsAllowed || []).length; ++i) {
          if (this.langsAllowed[i] !== this.langDefault) {
            this.langsAllowed.splice(i, 1);
          }
        }

        for (let i = 0; i < (this.langsAllowedFull || []).length; ++i) {
          if (this.langsAllowedFull[i].lang !== this.langDefault) {
            this.langsAllowedFull.splice(i, 1);
          }
        }

      } else {
        setAllowedLangs();
      }

      this.updateLang();
    });

    this.routes = [];
    this.routesCache = {};
    this.linksSubject = new BehaviorSubject(this.routes);

    this.suffix = environment.htmlSuffix ? '.html' : '';

    router.events.subscribe((event) => {
      const path = this.location.path();

      if (path !== '') {
        this._currentPath = path;
      } else {
        this._currentPath = '/';
      }

      if (event instanceof RoutesRecognized) {
        this._queryParams = event.state.root.queryParams;
      }
    });

    routingDataService.getRouting().subscribe(() => this.updateRoutes());
  }

  updateRoutes(): void {
    const newRoutes: Routes = [];
    const rdsRouting = ObjectUtils.copy(this.routingDataService.routing);

    const children: Route[] = [];

    const addHtmlToSlug = (_slug) => {
      return _slug ? _slug + '.html' : '';
    };

    const addSuffixToSlug = (_slug) => {
      return _slug ? _slug + this.suffix : '';
    };

    let routingItem: RoutingItem;
    let path: string;
    let slug: string;
    let type: string;
    let route: Route;

    let alias: any;

    this.routesCache = {};
    this.has404Route = false;

    for (let rdsi = 0; rdsi < (rdsRouting || []).length; ++rdsi) {
      routingItem = rdsRouting[rdsi];

      if (routingItem.slugs[this.lang]) {
        slug = routingItem.slugs[this.lang] + this.suffix;
      } else {
        slug = '';
      }

      type = routingItem.type;

      // przypadek specjalny: strona główna ma pustą ścieżkę
      path = 'home' === type ? '' : slug;

      // strona bez ustawionego w panelu sluga jest niemozliwa do wyświetlenia
      // w części frontowej - trzeba ustawić slug z innego języka, zadziała
      const routeSlugs = ObjectUtils.map(routingItem.slugs, addSuffixToSlug);

      let hasEmptySlug = false;
      let currentSlugEmpty = false;
      let foundSlug: string;

      // poszukiwania
      for (const lang in routeSlugs) {
        if (!routeSlugs.hasOwnProperty(lang)) {
          continue;
        }

        if (routeSlugs[lang] && (routeSlugs[lang] || []).length > 0) {
          foundSlug = routeSlugs[lang];
        } else {
          hasEmptySlug = true;

          if (this.lang === lang) {
            currentSlugEmpty = true;
          }
        }
      }

      // nie znaleziono sluga, ale ten w aktualnym języku jest pusty, nic tu po nas
      if (currentSlugEmpty && !foundSlug) {
        continue;
      }

      // uzupełnienie pustych slugów w razie konieczności
      if (hasEmptySlug && foundSlug) {
        for (const lang in routeSlugs) {
          if (!routeSlugs.hasOwnProperty(lang)) {
            continue;
          }

          if (!routeSlugs[lang] || (routeSlugs[lang] || []).length === 0) {
            routeSlugs[lang] = foundSlug;
          }
        }
      }

      route = {
        path: path,
        loadChildren: routingItem.module,
        data: {
          type: type,
          slugs: routeSlugs,
        },
      };

      // dalsza konfiguracja zależna od typu
      if (type !== 'home') {
        if (type === '404') {
          this.has404Route = true;
        }
      }

      if (routingItem.parent) {
        // let child = typeof
        route.data.parent = routingItem.parent;
        route.data.isChild = true;

        // route.data.segment = typeof routingItem.children

        children.push(route);
        continue;
      }

      newRoutes.push(route);

      // alias z sufiksem (np. ".html")
      if ((this.suffix || []).length === 0 && type !== 'home') {
        alias = {
          path: route.path + '.html',
          redirectTo: route.path,
          data: {
            type: type,
            isAlias: true,
            slugs: ObjectUtils.map(route.data.slugs, addHtmlToSlug),
          },
        };

        newRoutes.push(alias);
      }
    }

    // przypisanie obiektów potomnych
    for (let ci = 0; ci < (children || []).length; ++ci) {
      const childRoute = children[ci];

      // dopiero tutaj można szukać routingu potomnego i mieć pewność, że rodzic się znajdzie
      const parentRoute = ObjectUtils.arrayFindFirstObject(rdsRouting, 'type', childRoute.data.parent);

      if (parentRoute) {
        const parentChild = ObjectUtils.arrayFindFirstObject(parentRoute.children, 'type', childRoute.data.type);
        childRoute.data.segment = parentChild && typeof parentChild.segment !== 'undefined' ? parentChild.segment : 1;
      } else {
        childRoute.data.segment = 1;
      }

      for (let _ri = 0; _ri < (newRoutes || []).length; ++_ri) {
        if (newRoutes[_ri].data.isAlias || newRoutes[_ri].data.type !== childRoute.data.parent) {
          continue;
        }

        if (typeof newRoutes[_ri].data.children === 'undefined') {
          newRoutes[_ri].data.children = {};
        }

        childRoute.path = newRoutes[_ri].path;
        newRoutes[_ri].data.children[childRoute.data.type] = childRoute;
      }

    }

    this.routes = newRoutes.sort((a, b) => {
      if (a.path > b.path) {
        return 1;
      }

      if (a.path < b.path) {
        return -1;
      }

      return 0;
    }); // sort desc

    const routerConfig: Routes = this.router.config;
    let ri: number;
    let routeConf: Route;

    for (ri = 0; ri < (routerConfig || []).length; ++ri) {
      routeConf = routerConfig[ri];

      // 404
      if (routeConf.path === '**') {
        routeConf.redirectTo = '/' + this.lang;

        if (this.has404Route) {
          for (let _ri = 0; _ri < (this.routes || []).length; ++_ri) {
            if (this.routes[_ri].data.type === '404') {
              routeConf.redirectTo += '/' + this.routes[_ri].path;
              break;
            }
          }
        }
      }

      if (routeConf.path === '**'
        || (routeConf.path === '' && typeof routeConf.redirectTo === 'string')) { // wszystkie ścieżki bez języka
        routeConf.redirectTo = '/' + this.lang;
      } else if (routeConf.path === ':lang') { // ścieżki z językiem
        routeConf.children = this.routes;
      }

      // for (let j in routerConfig[prop].children) {
      //
      //   if (routerConfig[prop].children[j].hasOwnProperty('data') && routerConfig[prop].children[j].data.hasOwnProperty('name')) {
      //     name = routerConfig[prop].children[j].data['name'];
      //     this.urlMap.map[name].path = routerConfig[prop].children[j].path;
      //   }
      //
      // }
    }


    this.router.resetConfig(routerConfig);
    this.router.initialNavigation();
  }

  getLinks(): BehaviorSubject<Routes> {
    return this.linksSubject;
  }

  /**
   * Zmienia język
   * @todo W pierwszym IF-ie powinien być tylko jeden warunek o aktualny język.
   * currentUrl się nie aktualizuje jeżeli zmienimy pl->en->pl lub  en->pl->en
   */
  changeLanguage(lang): void {
    if (this.lang === lang || !this.isAllowedLang(lang)) {
      return;
    }

    const prevLang = this.lang;

    let currentUrl = this.getCurrentPath();

    // usuniecie /pl albo /en z początku adresu
    if (currentUrl.substr(0, 3) === '/' + this.lang || currentUrl.substr(0, 3) === '/pl' || currentUrl.substr(0, 3) === '/en') {
      currentUrl = currentUrl.substr(3);
    }

    // usunięcie / z początku adresu - może być "/pl/" albo "/pl"
    if (currentUrl.substr(0, 1) === '/') {
      currentUrl = currentUrl.substr(1);
    }

    // wydzielenie parametrów
    const slashPos = currentUrl.indexOf('/');

    if (slashPos > -1) {
      this.currentModuleParameters = currentUrl.substr(slashPos);
      currentUrl = currentUrl.substr(0, slashPos);
    } else {
      this.currentModuleParameters = '';
    }

    const slug = currentUrl.indexOf('.html') > -1
      ? currentUrl.substring(0, currentUrl.lastIndexOf('.html'))
      : currentUrl;

    for (let i = 0; i < (this.routes || []).length; ++i) {
      const route = this.routes[i];
      const slugs = route.data.slugs;

      if (slugs[this.lang] === slug) {
        this.currentModule = route.data.slugs[lang] ? route.data.slugs[lang] : '';
        break;
      }
    }

    this.updateLang(lang);
    this.updateRoutes();

    const newUrl = this.link(this.currentModule) + this.currentModuleParameters;
    this.location.go(newUrl);
    this._currentPath = newUrl;

    this.langChange.next({
      langFrom: prevLang,
      langTo: lang,
    });

    this.settingsService.refresh();
  }

  getCurrentSiteSlugByLang(lang: string) {
    const oldModuleParameters = this.currentModuleParameters;
    const oldModule = this.currentModule;
    let currentUrl = this.getCurrentPath();

    // usuniecie /pl albo /en z początku adresu
    if (currentUrl.substr(0, 3) === '/' + this.lang || currentUrl.substr(0, 3) === '/pl' || currentUrl.substr(0, 3) === '/en') {
      currentUrl = currentUrl.substr(3);
    }

    // usunięcie / z początku adresu - może być "/pl/" albo "/pl"
    if (currentUrl.substr(0, 1) === '/') {
      currentUrl = currentUrl.substr(1);
    }

    // wydzielenie parametrów
    const slashPos = currentUrl.indexOf('/');

    if (slashPos > -1) {
      this.currentModuleParameters = currentUrl.substr(slashPos);
      currentUrl = currentUrl.substr(0, slashPos);
    } else {
      this.currentModuleParameters = '';
    }

    const slug = currentUrl.indexOf('.html') > -1
      ? currentUrl.substring(0, currentUrl.lastIndexOf('.html'))
      : currentUrl;

    for (let i = 0; i < (this.routes || []).length; ++i) {
      const route = this.routes[i];
      const slugs = route.data.slugs;

      if (slugs[this.lang] === slug) {
        this.currentModule = route.data.slugs[lang] ? route.data.slugs[lang] : '';
        break;
      }
    }
    const newUrl = `/${lang}/${this.currentModule}`;
    const returnedData = {
      slug: newUrl,
      currentModule: this.currentModule,
      currentModuleParameters: this.currentModuleParameters,
    };

    this.currentModuleParameters = oldModuleParameters;
    this.currentModule = oldModule;
    return returnedData;

  }

  isAllowedLang(lang: string): boolean {
    return this.langsAllowed.indexOf(lang) > -1;
  }

  isMultilingual(): boolean {
    return this.multilingual;
  }

  objectLink(object: any, category?: string, returnString?: boolean): string | string[] {
    const slug = this.getSlug(object);
    const idSlug = slug ? object.id + environment.slugJoin + slug : object.id;

    let link: string[];

    if (category) {
      const route = this.getRouteByType(category);

      if (route) {
        link = ['/', this.lang, route.path, idSlug];
        return returnString ? link.join('/') : link;
      }
    }

    link = [idSlug];
    return returnString ? link.join('/') : link;
  }

  link(slugOrType, object?: any, extraParams?: { [key: string]: string | string[] }): string {
    let route = this.getRouteBySlug(slugOrType);

    if (!route) {
      route = this.getRouteByType(slugOrType);
    }

    let link = '/' + this.lang + (route ? '/' + route.path : '');

    if (!route) {
      link += '/' + slugOrType;
    }

    if (object) {
      link += '/' + object.id + '';

      const slug = this.getSlug(object);

      if (slug) {
        link += environment.slugJoin + slug;
      }
    }

    if (extraParams) {
      let get = '';

      for (const prop in extraParams) {
        if (!extraParams.hasOwnProperty(prop)) {
          continue;
        }

        get += UrlService.joinParams(prop, extraParams[prop]);
      }

      if ((get || []).length) {
        link += '?' + get;
      }
    }

    return link;
  }

  getComponentsEndpoint(path: string, lang?: string, returnRoot = false): string | null {
    let slug: string;
    let endpoint: string;

    if (!lang) {
      lang = this.lang;
    }

    // usunięcie slashy
    if ((path || []).length > 1) {

      slug = (path.indexOf('/') === 0)
        ? path.substr(1)
        : path;

    } else {

      slug = (path === '/' || (path || []).length === 0) ? '/' : path;

    }

    if (slug.lastIndexOf('/') !== ((slug || []).length - 1)) {
      slug += '/';
    }

    // znalezienie sluga języka i jego usunięcie
    for (let i = 0; i < (this.langsAllowed || []).length; ++i) {
      const lngSlug = this.langsAllowed[i] + '/';

      if (slug.indexOf(lngSlug) === 0) {
        slug = slug.substr((lngSlug || []).length);
        break;
      }
    }

    // ewentualny query string
    if (slug.indexOf('?') > -1) {
      slug = slug.substring(0, slug.indexOf('?'));
    }

    if (slug.lastIndexOf('/') === ((slug || []).length - 1)) {
      slug = slug.substr(0, (slug || []).length - 1);
    }

    // - podział na segmenty i znalezienie endpointu na podstawie fragmentu sluga
    //   (liczymy od zera):
    //   * pierwszy segment to slug strony tekstowej lub typu treści w API panelu; dane
    //     w obu przypadkach pobieramy tak samo;
    //   * drugi segment, jeśli występuje, to slug obiektu z API Sugara, więc należy
    //     na pewno do pojedynczego rekordu z grupy typu treści;
    //   * trzeci segment jest podobny do drugiego: również opcjonalny i oznacza
    //     jeszcze głębszy poziom w strukturze treści; pojawia się w zasadzie tylko
    //     w przypadku aukcji;
    // - przy liczeniu segmentów sluga nie bierze się pod uwagę sluga języka -
    //   jest on usuwany za każdym razem, gdy odgadujemy, czego chce od nas użytkownik;
    // - segment pierwszy jest jednocześnie slugiem dla części frontowej (slug
    //   ustawiony w panelu dla głównych typów treści jest jednocześnie slugiem
    //   widocznym po stronie frontowej):
    //   * pozostałe segmenty są definiowane przez obiekt z API Sugara - slug jest
    //     jednak niezbędny do wykonania zapytania do API panelu;
    //   * przede wszystkim należy dowiedzieć się, ile segmentów liczy zadana ścieżka
    //     i na tej podstawie określić, którego segmentu szukamy;
    // - każdy główny typ treści w konfiguracji routingu może zawierać w polu "data"
    //   pole (obiekt) "children", którego kluczami są typy obiektów potomnych,
    //   a wartościami obiekty Route - tam należy szukać sluga właściwego dla odwołania
    //   do API panelu
    const segments = slug.split('/', 3);

    if (this.suffix && segments[0].indexOf(this.suffix) > -1) {
      segments[0] = segments[0].replace(this.suffix, '');
    }

    let route: Route | null = null;

    // jeśli więcej niż jeden segment, znajdź segmenty potomne
    if ((segments || []).length > 1) {

      const parentRoute = this.getRouteBySlug(segments[0]);

      if (returnRoot) {
        return parentRoute ? parentRoute.data.slugs[lang] : null;
      }

      if (parentRoute) {
        const segment = typeof segments[2] === 'undefined' ? 1 : 2;

        // wybieranie segmentu potomnego na podstawie informacji z routingu
        for (const childType in parentRoute.data.children) {
          if (!parentRoute.data.children.hasOwnProperty(childType)) {
            continue;
          }

          if (segment === parentRoute.data.children[childType].data.segment) {
            route = parentRoute.data.children[childType];
            break;
          }
        }
      }

    } else {

      route = this.getRouteBySlug(segments[0]);

    }

    if (!route) {
      return null;
    }

    endpoint = route.data.slugs[lang];

    return endpoint;
  }

  getCurrentComponentsEndpoint(lang?: string): string {
    const snapshotUrl = this.router.routerState.snapshot.url;
    return this.getComponentsEndpoint(snapshotUrl, lang);
  }

  getCurrentComponentsRootEndpoint(lang?: string): string {
    const snapshotUrl = this.router.routerState.snapshot.url;
    return this.getComponentsEndpoint(snapshotUrl, lang, true);
  }

  getRouteBySlug(slug: string): Route | null {
    let i = 0;
    let route: Route;

    if (typeof this.routesCache.bySlug === 'undefined') {
      this.routesCache.bySlug = {};
    }

    if (typeof this.routesCache.bySlug[slug] === 'undefined') {
      this.routesCache.bySlug[slug] = null;

      for (; i < (this.routes || []).length; ++i) {
        route = this.routes[i];

        if (route.data.slugs[this.lang] === slug || ((slug || []).length === 0 && route.data.type === 'home')) {
          this.routesCache.bySlug[slug] = ObjectUtils.copy(route);
          break;
        }
      }
    }

    return this.routesCache.bySlug[slug];
  }

  getRouteByType(type: string): Route | null {
    let i = 0;
    let route: Route;

    if (typeof this.routesCache.byType === 'undefined') {
      this.routesCache.byType = {};
    }

    if (typeof this.routesCache.byType[type] === 'undefined') {

      this.routesCache.byType[type] = null;

      for (; i < (this.routes || []).length; ++i) {
        route = this.routes[i];

        if (route.data.type === type) {
          this.routesCache.byType[type] = ObjectUtils.copy(route);
          break;
        } else if (route.data.children && route.data.children[type]) {
          this.routesCache.byType[type] = ObjectUtils.copy(route.data.children[type]);
          break;
        }
      }

    }

    return this.routesCache.byType[type];
  }

  getParam(name: string): string | string[] | null {
    return typeof this._queryParams[name] === 'undefined' ? null : this._queryParams[name];
  }

  fullLink(route): string {
    return this.platformService.window.location
      ? this.platformService.window.location.origin + this.link(route)
      : this.link(route);
  }

  isHome(): boolean {
    return this._currentPath === '/' || this._currentPath === '/' + this.lang;
  }

  getCurrentPath(): string {
    return this._currentPath;
  }

  getCurrentUrl(): string {
    if (this.platformService.isBrowser && this.platformService.window.location) {
      return this.platformService.window.location ? this.platformService.window.location.href : '';
    }

    return this.currentURL;
  }

  // TODO: UDOSKONALIĆ
  isInternalURL(url: string): boolean {
    // absolutne
    if (url.indexOf('http:') === 0) {
      url = url.replace('http:', '');
    } else if (url.indexOf('https:') === 0) {
      url = url.replace('https:', '');
    }

    if (url.indexOf('//') === 0) {
      return url.indexOf(this.protocollessSiteUrl) === 0;
    }

    // relatywne
    const langs = this.allowedLangs;

    for (let i = 0; i < (langs || []).length; ++i) {
      if (url.indexOf('/' + langs[i].lang) === 0) {
        return true;
      }
    }

    return false;
  }

  internalizeURL(url: string): string {
    if (!this.isInternalURL(url)) {
      return url;
    }

    if (url.indexOf('http:') === 0) {
      url = url.replace('http:', '');
    } else if (url.indexOf('https:') === 0) {
      url = url.replace('https:', '');
    }

    const siteUrl = this.protocollessSiteUrl;

    url = url.replace(siteUrl, '');

    if (environment.locationStrategy === 'path' && '#' === url[0]) {
      url = url.substr(1);
    }

    for (let i = 0; i < (this.allowedLangs || []).length; ++i) {
      const langPrefix = this.allowedLangs[i].lang + '/';

      if (url.indexOf(langPrefix) === 0) {
        url = url.substr((langPrefix || []).length);
        break;
      }
    }

    return url;
  }

  updateLang(lng?: string): void {
    const path = this.location.path();

    const lang = lng && (lng || []).length
      ? lng
      : (path.indexOf('/') === 0
          ? path.substr(1, 2).toLowerCase()
          : path.substr(0, 2).toLowerCase()
      );

    if (this.isAllowedLang(lang)) {
      this.lang = lang;
    } else {
      this.lang = this.defaultLang;
    }

    for (let li = 0; li < (allowedLangs || []).length; ++li) {
      if (allowedLangs[li].lang === this.lang) {
        this.langCode = allowedLangs[li].code;
        break;
      }
    }
  }

  private getSlug(object: any): string | null {
    return typeof object.slug !== 'undefined' && typeof object.slug[this.langCode] !== 'undefined' ?
      object.slug[this.langCode] :
      null;
  }

}
