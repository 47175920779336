import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../model/global.service';
import { SugarService } from '../../model/sugar.service';
import { UrlService } from '../../model/url.service';
import { UniversalPlatformService } from '../../shared/platform/universal-platform.service';
import { OfferColletionSetter } from '../../shared/collections/api-collection-setters';
import { BlocksPlacement } from '../../blocks-wrapper/blocks-wrapper.component';
import { AutoUnsubscribe } from '../../decorators/autounsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-sold-items-block',
  templateUrl: './sold-items-block.component.html',
})
export class SoldItemsBlockComponent implements OnInit, OnDestroy, AfterViewInit {

  public items: { [key: string]: any }[] | null;
  // public options: {[key: string]: any};
  @Input() context: BlocksPlacement;
  @Input() title = '';
  @Input() maxItems = 50;
  @Output() viewInit = new EventEmitter<string>();
  zoomImages: boolean;
  private _dataSub: Subscription;

  constructor(
    public globalService: GlobalService,
    public urlService: UrlService,
    public sanitizer: DomSanitizer,
    protected platformService: UniversalPlatformService,
    protected sugarService: SugarService,
  ) {
    // this.options = {
    //   horizontalOrder:true,
    //   itemSelector:'.masonry-grid-item',
    //   columnWidth:'.grid-sizer',
    //   percentPosition:true
    // };
    this.zoomImages = this.globalService.settings.global.sliderRotation === 'true';
  }

  ngOnInit(): void {
    // this.sugarService.setCollection('sold-items', {
    //   "sugarNode": 'groups',
    //   "parameters": {
    //     "expand": 'items,products,products.images,products.artists',
    //     "filter[type]": 'exhibition_category_sold',
    //   }
    // });
    this.sugarService.setCollection('sold-items', {
      sugarNode: 'products',
      parameters: {
        expand: 'images,artists',
        'filter[group.type]': 'exhibition_category_sold',
        'filter[archive]': '1',
      },
    });

    this._dataSub = this.sugarService.getData().subscribe(data => {
      if (data.collectionId === 'sold-items') {
        // const products: {[key: string]: string}[] = data.data && (data.data.items || []).length ? data.data.items[0].products : [];
        const products: { [key: string]: string }[] = data.data;

        if ((products || []).length) {
          const items = products;

          const collectionSetter = new OfferColletionSetter(
            this.urlService,
            this.sugarService,
            this.platformService,
            this.globalService,
            this.sanitizer,
          );

          this.items = collectionSetter.setup(items, 'sold-items');
        }
      }
    });

    this.sugarService.refreshData('sold-items');
  }

  ngOnDestroy(): void {
    if (this._dataSub) {
      this._dataSub.unsubscribe();
    }

    this.sugarService.unsetCollection('sold-items');
  }

  ngAfterViewInit(): void {
    this.viewInit.emit('sold');
  }

  trackByFn(index: number, item: { [key: string]: any }): number {
    return item.id;
  }
}
