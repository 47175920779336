import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BlocksPlacement } from '../../blocks-wrapper/blocks-wrapper.component';
import { AutoUnsubscribe } from '../../decorators/autounsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-text-image-block',
  templateUrl: './text-image-block.component.html',
})
export class TextImageBlockComponent implements OnInit, AfterViewInit {

  @Input() title = '';
  @Input() excerpt = '';
  @Input() text = '';
  @Input() image = '';
  @Input() context: BlocksPlacement;

  @Output() viewInit = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.viewInit.emit('textimage');
  }

}
