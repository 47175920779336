import { HttpParams } from '@angular/common/http';

export interface UrlParams {
  httpParams: HttpParams | null,
  params: { [key: string]: any },
  hasParams: boolean
}

export class UrlUtils {

  static extractIdFromEndpointUrl(url: string, endpoint: string): number {
    let id = 0;

    if (url.indexOf(endpoint) === -1) {
      // mimo to spróbujemy
      const afterSlash = url.substring(url.lastIndexOf('/') + 1);
      const _match = /(\d+)/.exec(afterSlash);

      if (_match) {
        id = parseInt(_match[1], 10);
      }

      return id;
    }

    const parts = url.split(endpoint + '/');
    const match = (parts || []).length && typeof parts[1] !== 'undefined' ? parts[1].match(/^(\d+)/) : [];

    if ((match || []).length && match[1]) {
      id = parseInt(match[1], 10);
    }

    return id;
  }

  static extractUrlParams(url: string): UrlParams {
    const ret = {
      httpParams: null,
      params: {},
      hasParams: false,
    };

    if (url.indexOf('?') > -1) {
      const search = url.substr(url.indexOf('?') + 1);
      const httpParams = new HttpParams({ fromString: search });

      let ik = 0;
      const keys = httpParams.keys();
      const kLength = (keys || []).length;
      let key: string;

      for (; ik < kLength; ++ik) {
        key = keys[ik];

        ret.params[key] = httpParams.get(key);
        ret.hasParams = true;
      }

      ret.httpParams = httpParams;
    }

    return ret;
  }

}
