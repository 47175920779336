import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, EventEmitter, QueryList } from '@angular/core';
import { ScrollToService } from 'ng2-scroll-to-el';
import { UrlService } from '../../../model/url.service';
import { GlobalService } from '../../../model/global.service';
import { CmsService } from '../../../model/cms.service';
import { UniversalPlatformService } from '../../../shared/platform/universal-platform.service';
import { UniversalRendererHelper } from '../../../shared/platform/universal-renderer.helper';
import { MenuService } from './menu/menu.service';
import { AutoUnsubscribe } from '../../../decorators/autounsubscribe.decorator';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(urlService, globalService, cmsService, _platformService, _rendererHelper, _scrollService, _menu) {
        var _this = this;
        this.urlService = urlService;
        this.globalService = globalService;
        this.cmsService = cmsService;
        this._platformService = _platformService;
        this._rendererHelper = _rendererHelper;
        this._scrollService = _scrollService;
        this._menu = _menu;
        this.logoLarge = {
            url: '',
            width: '',
            height: '',
        };
        this.logoSmall = {
            url: '',
            width: '',
            height: '',
        };
        this.mobileBreakpoint = 992;
        this._isMenuOpen = false;
        this.backgroundColor = this._menu.backgroundColor;
        this.searchTrigger = new EventEmitter();
        this.langChange = new EventEmitter();
        this.submenuStates = [];
        this.settingsSub = this.globalService.updated.subscribe(function (settings) {
            if (settings.__loaded && settings.general) {
                _this.logoLarge.url = settings.general.logo;
                _this.logoLarge.width = settings.general.logo_width.pl || settings.general.logo_width.en;
                _this.logoLarge.height = settings.general.logo_height.pl || settings.general.logo_height.en;
                _this.logoSmall.url = settings.general.logo_mini;
                _this.logoSmall.width = settings.general.logo_mini_width.pl || settings.general.logo_mini_width.en;
                _this.logoSmall.height = settings.general.logo_mini_height.pl || settings.general.logo_mini_height.en;
                _this.isSingleMenuVersion = settings.global.oneMenuVersion === 'true';
            }
        });
        this.menuSub = this._menu.ready.subscribe(function (ready) {
            if (ready) {
                var i = 0;
                var len = (_this._menu.items || []).length;
                for (; i < len; ++i) {
                    _this.submenuStates.push({
                        exists: _this._menu.items[i].hasChildren,
                        opening: false,
                        open: false,
                        closing: false,
                        closed: !!_this._menu.items[i].hasChildren,
                    });
                }
            }
        });
    }
    Object.defineProperty(HeaderComponent.prototype, "isLogoLink", {
        get: function () {
            if (typeof this.globalService.settings.global.logoLink === 'object') {
                return !!Object.keys(this.globalService.settings.global.logoLink).length;
            }
            return !!this.globalService.settings.global.logoLink;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "menu", {
        get: function () {
            return this._menu;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "menuIsMobile", {
        get: function () {
            return this._platformService.windowWidth < this.mobileBreakpoint;
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.onResizeWindow = function () {
        if (this.menuIsMobile) {
            this.closeSubmenus();
        }
    };
    HeaderComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        // fix dla przeskakującego menu
        setTimeout(function () {
            _this._rendererHelper.addClass(_this.header, 'site-header-animated');
        }, 300);
    };
    HeaderComponent.prototype.onClickMenuItem = function (evt, index) {
        if (this.menuIsMobile && this.isSubmenuState(index, 'closed')) {
            evt.stopPropagation();
            evt.preventDefault();
            this.closeSubmenus();
            this.openSubmenu(index);
            return false;
        }
        this.closeMenu();
        this.scrollTop();
    };
    HeaderComponent.prototype.onClickSubmenuItem = function () {
        this.closeMenu();
        this.scrollTop();
    };
    HeaderComponent.prototype.openMenu = function () {
        if (!this._isMenuOpen) {
            this._isMenuOpen = true;
            this._rendererHelper.addClass(this.navbar, 'opening');
            if (this._platformService.isBrowser) {
                this._platformService.document.documentElement.className = this._rendererHelper.classNameHelper.add(this._platformService.document.documentElement.className, 'has-open-menu');
                // this.bodyService.bodyClass = this._rendererHelper.classNameHelper.add(this.bodyService.bodyClass, " has-open-menu");
            }
        }
    };
    HeaderComponent.prototype.closeMenu = function () {
        if (this._isMenuOpen) {
            this._isMenuOpen = false;
            this._rendererHelper.removeClass(this.navbar, 'show');
            this.closeSubmenus();
            if (this._platformService.isBrowser) {
                this._platformService.document.documentElement.className = this._rendererHelper.classNameHelper.remove(this._platformService.document.documentElement.className, 'has-open-menu');
            }
        }
    };
    HeaderComponent.prototype.onTransitionEndMenu = function (evt) {
        if (this._isMenuOpen) {
            this._rendererHelper.addClass(this.navbar, 'show');
            if (this.navbar.nativeElement !== evt.target) {
                return;
            }
            this._rendererHelper.removeClass(this.navbar, 'opening');
        }
        else {
            if (this.navbar.nativeElement === evt.target) {
                this._rendererHelper.removeClass(this.navbar, 'opening');
            }
        }
    };
    HeaderComponent.prototype.onMouseenterMenuItem = function (evt, index) {
        if (this.menuIsMobile || typeof this.submenuStates[index] === 'undefined') {
            return;
        }
        var open;
        if (this._rendererHelper.hasAnyClass(evt.target, ['nav-link', 'nav-item'])) {
            open = true;
        }
        else {
            open = !this.isSubmenuState(index, 'closed');
        }
        if (open) {
            this.openSubmenu(index);
        }
    };
    HeaderComponent.prototype.onMouseleaveMenuItem = function (evt, index) {
        if (!this.menuIsMobile) {
            this.closeSubmenu(index);
        }
    };
    HeaderComponent.prototype.openSubmenu = function (index) {
        this.setSubmenuState(index, 'opening', true);
        this.setSubmenuState(index, 'closed', false);
        this.setSubmenuState(index, 'closing', false);
    };
    HeaderComponent.prototype.closeSubmenu = function (index) {
        this.setSubmenuState(index, 'opening', false);
        this.setSubmenuState(index, 'open', false);
        this.setSubmenuState(index, 'closing', true);
    };
    HeaderComponent.prototype.onTransitionEndSubmenu = function (evt, index) {
        if (this.isSubmenuState(index, 'opening')) {
            this.setSubmenuState(index, 'open', true);
            this.setSubmenuState(index, 'opening', false);
            this.setSubmenuState(index, 'closing', false);
            this.setSubmenuState(index, 'closed', false);
        }
        else if (this.isSubmenuState(index, 'closing')) {
            this.setSubmenuState(index, 'open', false);
            this.setSubmenuState(index, 'opening', false);
            this.setSubmenuState(index, 'closing', false);
            this.setSubmenuState(index, 'closed', true);
        }
    };
    HeaderComponent.prototype.scrollTop = function () {
        this._scrollService.scrollTo('#top', 600);
    };
    HeaderComponent.prototype.onTransitionendBrand = function () {
        if (this._rendererHelper.hasClass(this.brand, 'brand-small-in')
            && !this._rendererHelper.hasClass(this.brand, 'brand-small-delayed')) {
            this._rendererHelper.addClass(this.brand, 'brand-small-delayed');
        }
        else if (this._rendererHelper.hasClass(this.brand, 'brand-small-delayed')) {
            this._rendererHelper.removeClass(this.brand, 'brand-small-delayed');
        }
    };
    HeaderComponent.prototype.openSearchModal = function () {
        this.searchTrigger.emit({});
    };
    HeaderComponent.prototype.changeLang = function (lang) {
        this.langChange.emit(lang);
    };
    HeaderComponent.prototype.isSubmenuState = function (index, state) {
        return this.submenuHasState(index, state) ? this.submenuStates[index][state] : false;
    };
    HeaderComponent.prototype.setSubmenuState = function (index, state, value) {
        if (!this.submenuHasState(index, state)) {
            return;
        }
        this.submenuStates[index][state] = value;
    };
    HeaderComponent.prototype.submenuHasState = function (index, state) {
        if (typeof this.submenuStates[index] === 'undefined' || !this.submenuStates[index].exists) {
            return false;
        }
        return typeof this.submenuStates[index][state] !== 'undefined';
    };
    HeaderComponent.prototype.closeSubmenus = function () {
        if (this.menu.items) {
            var menuLen = (this._menu.items || []).length;
            var i = 0;
            for (; i < menuLen; ++i) {
                this.closeSubmenu(i);
            }
        }
    };
    HeaderComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [UrlService,
            GlobalService,
            CmsService,
            UniversalPlatformService,
            UniversalRendererHelper,
            ScrollToService,
            MenuService])
    ], HeaderComponent);
    return HeaderComponent;
}());
export { HeaderComponent };
