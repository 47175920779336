import { SimpleCollection } from './simple-collection.class';

export class SanitizedDataCollection extends SimpleCollection {

  protected sanitized: string[];

  constructor() {
    super();

    this.sanitized = [];
  }

  set(name: string, value: any, safeValue?: boolean): void {
    super.set(name, value);

    if (safeValue) {
      this.sanitized.push(name);
    }
  }

}
