import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OwlModule } from 'ngx-owl-carousel';
import { TranslatePipesModule } from '../../shared/translate-pipes.module';
import { SmallSliderBlockComponent } from './small-slider-block.component';

@NgModule({
  imports: [
    CommonModule,
    OwlModule,
    TranslatePipesModule,
  ],
  exports: [
    SmallSliderBlockComponent,
  ],
  declarations: [
    SmallSliderBlockComponent,
  ],
})
export class SmallSliderBlockModule {
}
