export class WaitToBeTrue {

  private timeout: any;

  private tickInterval: number;
  private timesTicked: number;
  private maxTicks: number;

  constructor(
    private callbackConditional: any,
    private callbackSuccess?: any,
    private callbackFail?: any,
  ) {
    this.timesTicked = 0;
    this.maxTicks = 100;
    this.tickInterval = 50;
  }

  start(): void {
    this.timeout = setTimeout(() => {
      this.timeoutCb();
    }, this.tickInterval);
  }

  stop(): void {
    clearTimeout(this.timeout);
  }

  setMaxTicks(max: number): void {
    this.maxTicks = max;
  }

  setTickInterval(interval: number): void {
    this.tickInterval = interval;
  }

  private timeoutCb(): void {
    const cond = this.callbackConditional();

    ++this.timesTicked;

    if (cond) {
      clearTimeout(this.timeout);

      if (typeof this.callbackSuccess === 'function') {
        this.callbackSuccess();
      }
    } else if (this.timesTicked === this.maxTicks) {
      clearTimeout(this.timeout);

      if (typeof this.callbackFail === 'function') {
        this.callbackFail();
      }
    } else {
      this.timeout = setTimeout(() => {
        if (typeof this.timeoutCb === 'function') {
          this.timeoutCb();
        }
      });
    }
  }

}
