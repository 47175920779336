import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArtistDatesPipe } from './artsaas-pipes/artist-dates.pipe';
import { ArtistNamePipe } from './artsaas-pipes/artist-name.pipe';
import { CutFloatZeroesPipe } from './artsaas-pipes/cut-float-zeroes.pipe';
import { EscapeHtmlPipe } from './artsaas-pipes/escape-html.pipe';
import { FormatPricePipe } from './artsaas-pipes/format-price.pipe';
import { WorkDatesPipe } from './artsaas-pipes/work-dates.pipe';
import { WorkDimensionsPipe } from './artsaas-pipes/work-dimensions.pipe';
import { WorkEstimationPipe } from './artsaas-pipes/work-estimation.pipe';
import { WorkPricePipe } from './artsaas-pipes/work-price.pipe';
import { WorkStartingPricePipe } from './artsaas-pipes/work-starting-price.pipe';
import { WorkPriceReachedPipe } from './artsaas-pipes/work-price-reached.pipe';
import { YoutubeEmbedUrlPipe } from './artsaas-pipes/youtube-embed-url.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ArtistDatesPipe,
    ArtistNamePipe,
    CutFloatZeroesPipe,
    EscapeHtmlPipe,
    FormatPricePipe,
    WorkDatesPipe,
    WorkDimensionsPipe,
    WorkPricePipe,
    WorkEstimationPipe,
    WorkStartingPricePipe,
    WorkPriceReachedPipe,
    YoutubeEmbedUrlPipe,
  ],
  exports: [
    ArtistDatesPipe,
    ArtistNamePipe,
    CutFloatZeroesPipe,
    EscapeHtmlPipe,
    FormatPricePipe,
    WorkDatesPipe,
    WorkDimensionsPipe,
    WorkPricePipe,
    WorkEstimationPipe,
    WorkStartingPricePipe,
    WorkPriceReachedPipe,
    YoutubeEmbedUrlPipe,
  ],
  providers: [
    CutFloatZeroesPipe,
    FormatPricePipe,
    ArtistNamePipe,
  ],
})
export class ArtsaasPipesModule {
}
