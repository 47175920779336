import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ComponentsService } from '../model/components.service';
import { UniversalPlatformService } from '../shared/platform/universal-platform.service';
import { ObjectUtils } from '../shared/utils/object-utils';
import { AutoUnsubscribe } from '../decorators/autounsubscribe.decorator';

export declare type BlocksContext = 'home' |
  'artist' | 'artists' |
  'auction-product' | 'auction' | 'auctions' |
  'exhibition' | 'exhibitions' |
  'news-item' | 'news' |
  'work' | 'works';

export declare type BlocksPlacement = 'before' | 'after' | 'whole';

@AutoUnsubscribe()
@Component({
  selector: 'app-blocks-wrapper',
  templateUrl: './blocks-wrapper.component.html',
})
export class BlocksWrapperComponent implements OnInit, OnDestroy, AfterViewInit {

  @Output()
  viewInit = new EventEmitter<any>();

  @Input()
  maincontents: any = 1; // deprecated?

  @Input()
  context: BlocksContext;

  @Input()
  placement: BlocksPlacement = 'whole';

  public pageBlocks: { [key: string]: any }[];

  private preloading: boolean;
  private loadedBlocks: number;
  private totalBlocks: number;
  private sub: any;

  private receivedBlocks: boolean;

  private mbIndex = -1;

  constructor(
    public domSanitizer: DomSanitizer,
    protected platformService: UniversalPlatformService,
    private _componentsService: ComponentsService,
  ) {
    this.receivedBlocks = false;
    this.preloading = true;

    this.totalBlocks = 0;
    this.loadedBlocks = 0;

    this.pageBlocks = [];
  }

  ngOnInit(): void {
    this.sub = this._componentsService.getComponents().subscribe(apiData => {
      if (typeof apiData.blocks === 'undefined') {
        return;
      }

      let i: number,
        pushIn: boolean,
        block: { [key: string]: any };

      const blocks = Array.isArray(apiData.blocks) ? apiData.blocks : [];
      const pageBlocks = [];

      this.pageBlocks = [];

      this.loadedBlocks = 0;
      this.totalBlocks = 0;

      // najpierw znajdujemy główny blok...
      for (i = 0; i < (blocks || []).length; ++i) {
        if (blocks[i].type === 'mainblock') {
          this.mbIndex = i;
          break;
        }
      }

      // const skipped = [];

      // ... a następnie przeprowadzamy właściwe rozpisanie
      for (i = 0; i < (blocks || []).length; ++i) {
        block = blocks[i];

        // if (skipped.indexOf(block.type) > -1) {
        //   --this.totalBlocks;
        //   continue;
        // }

        if (block.type === 'mainblock') {
          continue; // mainblock nie jest ładowany
        }

        pushIn = this.placement === 'whole'
          || (this.placement === 'before' && i < this.mbIndex)
          || (this.placement === 'after' && i > this.mbIndex);

        if (pushIn) {
          pageBlocks.push(ObjectUtils.copy(block));
          ++this.totalBlocks;
        }
      }

      this.receivedBlocks = true;
      this.preloading = this.totalBlocks > 0;

      this.pageBlocks = pageBlocks;
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.blockLoaded();
    });
  }

  showPreloader(): boolean {
    return this.preloading;
  }

  blockLoaded(): void {
    if (!this.receivedBlocks) {
      return;
    }

    ++this.loadedBlocks;

    if (this.loadedBlocks >= this.totalBlocks) {
      const finishLoading = () => {
        this.preloading = false;
        this.viewInit.emit();
      };

      if (this.platformService.isBrowser) {
        setTimeout(() => {
          finishLoading();
        }, 500);
      } else {
        finishLoading();
      }
    }
  }

}
