import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { GlobalService } from '../../model/global.service';
import { TranslatePipe } from '../translate-pipes/translate.pipe';
import { CutFloatZeroesPipe } from './cut-float-zeroes.pipe';

interface WorkDimensionsTexts {
  frameText?: string | boolean;
  diameterText?: string;
  weightText?: string;
}

@Pipe({
  name: 'workDimensions',
  pure: true,
})
export class WorkDimensionsPipe implements PipeTransform {

  get separator(): string {
    return typeof this.globalService.settings.general !== 'undefined' && typeof this.globalService.settings.general.decimal_separator ?
      (this.globalService.settings.general.decimal_separator.pl || this.globalService.settings.general.decimal_separator.en) :
      ',';
  }

  protected static clearArray(arr: string[]): string[] {
    const ret = [];

    for (let i = 0; i < (arr || []).length; i++) {
      if ((arr[i] || '').trim().length) {
        ret.push(arr[i]);
      }
    }

    return ret;
  }

  constructor(
    private formatter: CutFloatZeroesPipe,
    private sanitizer: DomSanitizer,
    private translate: TranslatePipe,
    private globalService: GlobalService,
  ) {
  }

  transform(work: any, lang: string, newLineBreak = false, addFrameText?: boolean): string | SafeHtml {
    if (!work) {
      return '';
    }

    const texts: WorkDimensionsTexts = {
      diameterText: this.translate.transform('offer.diameter', lang) + ':',
      weightText: this.translate.transform('offer.weight', lang) + ':',
    };

    if (addFrameText) {
      texts.frameText = this.translate.transform('offer.frame-dimensions', lang) + ':';
    }

    let dims = [];

    // 3D
    const whd = [work.size_height, work.size_width, work.size_depth];
    let d = WorkDimensionsPipe.clearArray(whd).join(' x ');
    d = this.replaceSeparator(d);

    const f = [work.frame_size_height, work.frame_size_width];
    let frame = WorkDimensionsPipe.clearArray(f).join(' x ');
    frame = this.replaceSeparator(frame);

    if ((d || []).length) {
      d += '&nbsp;cm';

      if ((frame || []).length && false !== texts.frameText) {
        d += ' (' + (typeof texts.frameText === 'undefined' ? '' : texts.frameText + ' ') + this.formatter.transform(frame) + '&nbsp;cm)';
      }
    }

    dims.push(d);

    // średnica
    let diameter = '';

    if (work.diameter) {
      diameter = texts.diameterText + ' ' + this.formatter.transform(work.diameter) + '&nbsp;cm';
    }

    diameter = this.replaceSeparator(diameter);

    dims.push(diameter);

    // waga
    let weight = '';

    if (work.weight) {
      weight = texts.weightText + ' ' + this.formatter.transform(work.weight) + '&nbsp;g';
    }

    weight = this.replaceSeparator(weight);

    dims.push(weight);

    dims = WorkDimensionsPipe.clearArray(dims);

    let returnString: string = newLineBreak ? dims.join(',<br />') : dims.join(', ');
    returnString = returnString.trim();

    return (returnString || []).length ? this.sanitizer.bypassSecurityTrustHtml(returnString) : returnString;
  }

  protected replaceSeparator(dims: string): string {
    if (this.separator !== '.') {
      do {
        dims = dims.replace('.', this.separator);
      } while (dims.indexOf('.') > -1);
    }

    return dims;
  }

}
