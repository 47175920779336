import * as tslib_1 from "tslib";
import { MenuItemAbstract } from './menu-item-abstract.class';
var MenuItem = /** @class */ (function (_super) {
    tslib_1.__extends(MenuItem, _super);
    function MenuItem(hydratedItem, childItems, featuredObj) {
        var _this = _super.call(this, hydratedItem) || this;
        _this.hydratedItem = hydratedItem;
        _this.childItems = childItems;
        _this.featuredObj = featuredObj;
        _this.styles = ['underlining', 'lowercase', 'background'];
        _this.hasFeaturedItem = false;
        _this.colOne = [];
        _this.colTwo = [];
        var ci = 0;
        var cLen = (childItems || []).length;
        for (; ci < cLen; ++ci) {
            if (childItems[ci].column === 0) {
                _this.colOne.push(childItems[ci]);
            }
            else {
                _this.colTwo.push(childItems[ci]);
            }
        }
        _this.colOneLength = (_this.colOne || []).length;
        _this.colTwoLength = (_this.colTwo || []).length;
        if (featuredObj && featuredObj.isValid) {
            _this.hasFeaturedItem = true;
        }
        return _this;
    }
    Object.defineProperty(MenuItem.prototype, "manyFeaturedItems", {
        get: function () {
            return !!(this.manyFeaturedItemsList && (this.manyFeaturedItemsList || []).length);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuItem.prototype, "target", {
        get: function () {
            return this.getValue('target');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuItem.prototype, "children", {
        get: function () {
            return this.childItems;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuItem.prototype, "hasChildren", {
        get: function () {
            return (this.childItems || []).length > 0 || this.featuredObj !== null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuItem.prototype, "hasFeatured", {
        get: function () {
            return this.hasFeaturedItem;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuItem.prototype, "contentType", {
        get: function () {
            return this.ct;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuItem.prototype, "columns", {
        get: function () {
            return this.cols;
        },
        enumerable: true,
        configurable: true
    });
    MenuItem.prototype.setup = function (hydratedItem) {
        this.ct = hydratedItem.contentType;
        this.cols = hydratedItem.columns;
    };
    return MenuItem;
}(MenuItemAbstract));
export { MenuItem };
