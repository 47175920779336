/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./price-exchange.component";
import * as i3 from "../model/currency.service";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../model/global.service";
import * as i6 from "../model/url.service";
var styles_PriceExchangeComponent = ["div[_ngcontent-%COMP%] {\n      font-weight: 300;\n      font-size: 14px;\n      padding-top: 5px;\n      text-align: right;\n    }"];
var RenderType_PriceExchangeComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_PriceExchangeComponent, data: {} });
export { RenderType_PriceExchangeComponent as RenderType_PriceExchangeComponent };
function View_PriceExchangeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "col-xs-12"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(2, { "padding-left": 0, "text-align": 1 }), (_l()(), i0.ɵted(3, null, [" ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.paddingLeft, "left"); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.afterExchange; _ck(_v, 3, 0, currVal_1); }); }
export function View_PriceExchangeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_PriceExchangeComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.afterExchange && _co.globalService.settings.offer.show_prices_conversion); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PriceExchangeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-price-exchange", [], null, null, null, View_PriceExchangeComponent_0, RenderType_PriceExchangeComponent)), i0.ɵdid(1, 49152, null, 0, i2.PriceExchangeComponent, [i3.CurrencyService, i4.DomSanitizer, i5.GlobalService, i6.UrlService], null, null)], null, null); }
var PriceExchangeComponentNgFactory = i0.ɵccf("app-price-exchange", i2.PriceExchangeComponent, View_PriceExchangeComponent_Host_0, { price: "price", type: "type", item: "item", paddingLeft: "paddingLeft" }, {}, []);
export { PriceExchangeComponentNgFactory as PriceExchangeComponentNgFactory };
