import * as tslib_1 from "tslib";
import { filter } from 'rxjs/operators';
import { AfterViewInit, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ScrollToService } from 'ng2-scroll-to-el';
import { CmsService } from '../model/cms.service';
import { SugarService } from '../model/sugar.service';
import { GlobalService } from '../model/global.service';
import { UrlService } from '../model/url.service';
import { ComponentsService } from '../model/components.service';
import { UniversalPlatformService } from '../shared/platform/universal-platform.service';
import { UniversalRendererHelper } from '../shared/platform/universal-renderer.helper';
import { CookiesService } from '../shared/cookies.service';
import { Utils } from '../shared/utils.class';
import { ContactDataExtractor, SocialsDataExtractor } from '../shared/collections/data-extractors';
import { SanitizedDataCollection } from '../shared/collections/sanitized-data-collection.class';
import { HeaderComponent } from './partials/header/header.component';
import { AppComponent } from '../app.component';
import { removeDiacritics } from '../helpers/string-helpers';
import { AutoUnsubscribe } from '../decorators/autounsubscribe.decorator';
var SimpleLayoutComponent = /** @class */ (function () {
    function SimpleLayoutComponent(_document, globalService, cmsService, urlService, _rendererHelper, _platformService, _sugarService, _componentsService, _router, _platformLocation, _sanitizer, _formBuilder, _cookiesService, _scrollService, _rootComp) {
        var _this = this;
        this._document = _document;
        this.globalService = globalService;
        this.cmsService = cmsService;
        this.urlService = urlService;
        this._rendererHelper = _rendererHelper;
        this._platformService = _platformService;
        this._sugarService = _sugarService;
        this._componentsService = _componentsService;
        this._router = _router;
        this._platformLocation = _platformLocation;
        this._sanitizer = _sanitizer;
        this._formBuilder = _formBuilder;
        this._cookiesService = _cookiesService;
        this._scrollService = _scrollService;
        this._rootComp = _rootComp;
        this._logoLargeUrl = '';
        this._logoSmallUrl = '';
        this.customFooterClasses = {
            left: {},
            center: {},
            right: {},
        };
        this.isSafari = false;
        this.checkIfIsSafari();
        this._href = _platformService.window.location ? _platformService.window.location.href : '';
        this._hasCookieConsent = _platformService.isServer ? true : this._cookiesService.getCookie('cookie-consent') === 'yes';
        this._createForms();
        this._sugarErrorSub = _sugarService.requestError.subscribe(function () {
            _this._router.navigateByUrl(_this.urlService.link('404'));
        });
        this._cmsErrorSub = cmsService.requestError.subscribe(function () {
            _this._router.navigateByUrl(_this.urlService.link('404'));
        });
        this.socialData = new SanitizedDataCollection();
        if (_platformService.isBrowser) {
            this._routerSub = _router.events.pipe(filter(function (event) { return event instanceof NavigationStart; })).subscribe(function () {
                var pageYHistory = _this._platformService.localStorage.getItem('pageYHistory') || [];
                var pageY = _this._getPageY();
                pageYHistory.push(pageY);
                if ((pageYHistory || []).length === 50) {
                    pageYHistory.shift();
                }
                _this._platformService.localStorage.setItem('pageYHistory', pageYHistory, _this._platformService.localStorage.countDays(1));
                _this._rendererHelper.addEventListener(_this._platformService.window, 'beforeunload', function () {
                    _this._platformService.localStorage.removeItem('pageYHistory');
                });
            });
            _platformLocation.onPopState(function () {
                if (_this._popSub) {
                    var pageYHistory = _this._platformService.localStorage.getItem('pageYHistory') || [];
                    var pageY_1 = (pageYHistory || []).length > 0 ? pageYHistory.pop() : 0;
                    setTimeout(function () {
                        _this._platformService.window.scrollTo(0, pageY_1);
                    });
                    _this._platformService.localStorage.setItem('pageYHistory', pageYHistory, _this._platformService.localStorage.countDays(1));
                }
            });
            this._popSub = true;
        }
        this.contact = new SanitizedDataCollection;
        this._sdsSub = this.globalService.updated.subscribe(function (settings) {
            if (settings.__loaded) {
                _this.settings = settings;
                _this.isFooterVisible = _this.settings.global.footerVisible === 'true';
                _this.showCustomFooterContent = _this.settings.global.showCustomFooterContent === 'true';
                _this.footerBlocksWidth = _this.settings.global.footerBlocksWidth;
                _this.customFooterClass();
                _this.footerContent = {
                    left: {
                        pl: _this._sanitizer.bypassSecurityTrustHtml(typeof _this.settings.global.footerContent.left.pl === 'string'
                            ? _this.settings.global.footerContent.left.pl
                            : ''),
                        en: _this._sanitizer.bypassSecurityTrustHtml(typeof _this.settings.global.footerContent.left.en === 'string'
                            ? _this.settings.global.footerContent.left.en
                            : ''),
                    },
                    center: {
                        pl: _this._sanitizer.bypassSecurityTrustHtml(typeof _this.settings.global.footerContent.center.pl === 'string'
                            ? _this.settings.global.footerContent.center.pl
                            : ''),
                        en: _this._sanitizer.bypassSecurityTrustHtml(typeof _this.settings.global.footerContent.center.en === 'string'
                            ? _this.settings.global.footerContent.center.en
                            : ''),
                    },
                };
                _this._logoLargeUrl = _this.settings.general.logo ? _this.settings.general.logo : '/assets/images/logo.png';
                _this._logoSmallUrl = _this.settings.general.logo_mini ? _this.settings.general.logo_mini : '/assets/images/logo-alt.png';
                // favicon
                if (settings.general.favicon) {
                    _this._setFavicon(settings.general.favicon);
                }
                _this.onChangeLangOrData();
            }
            _this.urlService.langChange.subscribe(function () { return _this.onChangeLangOrData(); });
        });
    }
    Object.defineProperty(SimpleLayoutComponent.prototype, "socialMedia", {
        get: function () {
            return this.socialData;
        },
        enumerable: true,
        configurable: true
    });
    SimpleLayoutComponent.prototype.customFooterClass = function () {
        var leftClass = this.footerBlocksWidth ? (this.footerBlocksWidth.left || 'col-md-4') : 'col-md-4';
        var centerClass = this.footerBlocksWidth ? (this.footerBlocksWidth.center || 'col-md-4') : 'col-md-4';
        var rightClass = this.showCustomFooterContent
            ? (this.footerBlocksWidth
                ? (this.footerBlocksWidth.right || 'col-md-4')
                : 'col-md-4')
            : 'col-md-6';
        this.customFooterClasses.left[leftClass] = true;
        this.customFooterClasses.center[centerClass] = true;
        this.customFooterClasses.right[rightClass] = true;
    };
    SimpleLayoutComponent.prototype.checkIfIsSafari = function () {
        if (this._platformService.isBrowser) {
            this.isSafari = navigator && navigator.vendor && navigator.vendor.includes('Apple') &&
                navigator.userAgent &&
                !navigator.userAgent.includes('CriOS') &&
                !navigator.userAgent.includes('FxiOS');
        }
    };
    SimpleLayoutComponent.prototype.ngOnInit = function () {
        this.checkIfIsSafari();
        this._rootComp.fbService.updateCommonOg();
        this._getPageY();
        this._lastpageY = this._pageY;
        // @TODO: powiązać z ustawieniami
        this._logoLargeUrl = '/assets/images/logo.png';
        this._logoSmallUrl = '/assets/images/logo-alt.png';
    };
    SimpleLayoutComponent.prototype.ngOnDestroy = function () {
        this._sugarErrorSub.unsubscribe();
        this._cmsErrorSub.unsubscribe();
        if (this._searchTriggerSub) {
            this._searchTriggerSub.unsubscribe();
        }
        if (this._langChangeSub) {
            this._langChangeSub.unsubscribe();
        }
        if (this._sdsSub) {
            this._sdsSub.unsubscribe();
        }
        if (this._platformService.isBrowser) {
            if (this._routerSub) {
                this._routerSub.unsubscribe();
            }
            this._popSub = false;
        }
    };
    SimpleLayoutComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this._platformService.window.location.hash.endsWith('#archived')) {
            setTimeout(function () {
                try {
                    _this._document.querySelector('#archived').scrollIntoView();
                }
                catch (e) {
                }
            }, 1500);
        }
        var headerSubs = function () {
            _this._searchTriggerSub = _this.header.searchTrigger.subscribe(function () {
                _this.openSearchModal();
            });
            _this._langChangeSub = _this.header.langChange.subscribe(function (lang) {
                _this.changeLang(lang);
            });
        };
        var headerWaiter = new Utils.waiter(function () {
            return _this.header !== null && typeof _this.header !== 'undefined';
        }, headerSubs, function () {
        });
        headerWaiter.start();
        var searchSubs = function () {
            _this.searchModal.onShown.subscribe(function () {
                _this._rootComp.bodyClass = _this._rendererHelper.classNameHelper.add(_this._rootComp.bodyClass, ['search-modal-open', 'modal-open']);
                _this._rendererHelper.trigger(_this.searchInput, 'focus');
            });
            _this.searchModal.onHide.subscribe(function () {
                _this._rootComp.bodyClass = _this._rendererHelper.classNameHelper.remove(_this._rootComp.bodyClass, ['search-modal-open', 'modal-open']);
            });
        };
        var searchWaiter = new Utils.waiter(function () {
            return _this.searchModal !== null && typeof _this.searchModal !== 'undefined';
        }, searchSubs, function () {
        });
        searchWaiter.start();
        setTimeout(function () {
            var siteTransitionPreloader = _this._platformService.document.getElementById('site-transition-preloader');
            if (siteTransitionPreloader) {
                _this._rendererHelper.removeClass(siteTransitionPreloader, 'site-transition-preloader--visible');
            }
        }, 300);
    };
    /** SEARCH **/
    SimpleLayoutComponent.prototype.searchEvent = function (evt) {
        var searchPhrase = this.searchFields.get('phrase');
        if (evt && evt.keyCode === 13) {
            if (!searchPhrase.valid) {
                if ((searchPhrase.value || []).length) {
                    this._showSearchValidation = true;
                }
                evt.preventDefault();
                return false;
            }
            this._showSearchValidation = false;
            this.globalSearch();
        }
        else {
            if ((searchPhrase.value || []).length === 0) {
                this._showSearchValidation = false;
            }
        }
    };
    SimpleLayoutComponent.prototype.globalSearch = function (evt) {
        var searchPhrase = this.searchFields.get('phrase');
        if (!searchPhrase.valid) {
            if (evt && evt.preventDefault) {
                evt.preventDefault();
            }
            this._showSearchValidation = true;
            return false;
        }
        this._showSearchValidation = false;
        var urlPhrase = searchPhrase.value;
        this.searchFields.patchValue({
            phrase: '',
        });
        this.closeSearchModal();
        this._router.navigateByUrl(this.urlService.link('search-results') + '/' + urlPhrase);
    };
    SimpleLayoutComponent.prototype.displaySearchInvalidityInfo = function (name) {
        var field = this.searchFields.get(name);
        if (!field) {
            return false;
        }
        //    if (field.touched) {
        //      return field.invalid;
        //    }
        return this._showSearchValidation ? field.invalid : false;
    };
    SimpleLayoutComponent.prototype.openSearchModal = function (evt) {
        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }
        if (!this.searchModal.isShown) {
            this.searchModal.show();
        }
    };
    SimpleLayoutComponent.prototype.maybeCloseSearchModal = function (evt) {
        if (!evt || !evt.target) {
            return;
        }
        var targetTag = evt.target.tagName.toLowerCase();
        if (targetTag === 'input' || targetTag === 'button') {
            return;
        }
        this.closeSearchModal(evt);
    };
    SimpleLayoutComponent.prototype.closeSearchModal = function (evt) {
        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }
        if (this.searchModal.isShown) {
            this.searchModal.hide();
        }
    };
    /** COOKIES **/
    SimpleLayoutComponent.prototype.consent = function (isConsent, evt) {
        if (isConsent) {
            evt.preventDefault();
            this._cookiesService.setCookie('cookie-consent', 'yes', 30);
            this._hasCookieConsent = true;
        }
        else {
            return this._hasCookieConsent;
        }
    };
    SimpleLayoutComponent.prototype.hasCookieConsent = function () {
        return this._hasCookieConsent;
    };
    SimpleLayoutComponent.prototype.scrollTop = function (evt) {
        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }
        this._scrollService.scrollTo('#top', 600);
    };
    /** VARIA **/
    SimpleLayoutComponent.prototype.changeLang = function (lang) {
        this.urlService.changeLanguage(lang);
    };
    SimpleLayoutComponent.prototype.onChangeLangOrData = function () {
        // kontakt
        var contactExtractor = new ContactDataExtractor(this._sanitizer, this.urlService);
        this.contact = contactExtractor.create(this.settings.contact);
        // społeczności
        var socialsExtractor = new SocialsDataExtractor(this._sanitizer, this.urlService);
        this.socialData = socialsExtractor.create(this.settings.social);
        // notka o prawach autorskich
        this.copyrightNote = this.settings.contact.copyright && this.settings.contact.copyright[this.urlService.lang]
            ? this._sanitizer.bypassSecurityTrustHtml(this.settings.contact.copyright[this.urlService.lang])
            : '';
        var stringsToReplace = ['polityka prywatnosci', 'polityki prywatnosci', 'polityce prywatnosci', 'privacy policy'];
        var cookie_text = this.settings.general.cookie_text[this.urlService.lang];
        if (cookie_text) {
            for (var _i = 0, stringsToReplace_1 = stringsToReplace; _i < stringsToReplace_1.length; _i++) {
                var str = stringsToReplace_1[_i];
                var index = removeDiacritics(cookie_text).toLowerCase().indexOf(str);
                if (index >= 0) {
                    var replaceInParagraph = cookie_text.substr(index, 20);
                    var replaceWith = "<a rel=\"nofollow\" href=\"" + this.urlService.link('privacy-policy') + "\" class=\"cookies-message-link\">" + replaceInParagraph + "</a>";
                    cookie_text = cookie_text.replace(new RegExp(replaceInParagraph, 'g'), replaceWith);
                }
            }
            this.settings.general.cookie_text[this.urlService.lang] = cookie_text;
        }
    };
    SimpleLayoutComponent.prototype._setFavicon = function (url) {
        if (typeof url === 'object') {
            url = '';
        }
        var ext = url.split('.').pop();
        var type;
        if (ext === 'jpg' || ext === 'jpeg') {
            type = 'image/jpeg';
        }
        else if (ext === 'png') {
            type = 'image/png';
        }
        else if (ext === 'bmp') {
            type = 'image/bmp';
        }
        else {
            type = 'image/x-icon';
        }
        var favicon = this._document.getElementById('website-favicon');
        if (!favicon) {
            favicon = this._document.createElement('link');
            favicon.setAttribute('id', 'website-favicon');
            favicon.setAttribute('rel', 'shortcut icon');
            this._document.head.appendChild(favicon);
        }
        favicon.setAttribute('type', type);
        favicon.setAttribute('href', url);
    };
    /** FORMS **/
    SimpleLayoutComponent.prototype._createForms = function () {
        this.searchFields = this._formBuilder.group({
            phrase: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
        });
        this._showSearchValidation = false;
    };
    /** SCROLL **/
    SimpleLayoutComponent.prototype._getPageY = function () {
        if (this.isSafari) {
            return;
        }
        if (typeof this._platformService.window.pageYOffset !== 'undefined') {
            this._pageY = this._platformService.window.pageYOffset;
        }
        else {
            var d = this._document, r = d.documentElement, b = d.body;
            this._pageY = r.scrollTop || b.scrollTop || 0;
        }
        return this._pageY;
    };
    SimpleLayoutComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [Object, GlobalService,
            CmsService,
            UrlService,
            UniversalRendererHelper,
            UniversalPlatformService,
            SugarService,
            ComponentsService,
            Router,
            PlatformLocation,
            DomSanitizer,
            FormBuilder,
            CookiesService,
            ScrollToService,
            AppComponent])
    ], SimpleLayoutComponent);
    return SimpleLayoutComponent;
}());
export { SimpleLayoutComponent };
