import * as tslib_1 from "tslib";
import { TextPageComponent } from '../text-page/text-page.component';
import { AutoUnsubscribe } from '../decorators/autounsubscribe.decorator';
var P404Component = /** @class */ (function (_super) {
    tslib_1.__extends(P404Component, _super);
    function P404Component() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    P404Component.prototype.initialize = function () {
        _super.prototype.initialize.call(this);
        this.classes.basic = 'e404';
    };
    P404Component = tslib_1.__decorate([
        AutoUnsubscribe()
    ], P404Component);
    return P404Component;
}(TextPageComponent));
export { P404Component };
