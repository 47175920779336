export function thousandSeparator(text: string | number, separator = ' ') {
  return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

export function onlyUnique(value: any, index: number, self: any[]) {
  return self.indexOf(value) === index;
}

export const getExpandText = (isDescriptionExpanded: boolean): {
  pl: string,
  en: string
} => (isDescriptionExpanded ? {
  pl: 'Pokaż mniej',
  en: 'Show less',
} : {
  pl: 'Pokaż więcej',
  en: 'Show more',
});

export const getArtistName = (artist: any, langCode = 'pl_pl', asHtml: 'asHtml' | '' = '') => {
  let name = '?';

  if (typeof artist.nick_name !== 'undefined' && artist.nick_name[langCode]) {
    name = artist.nick_name[langCode].trim();
  } else if (typeof artist.first_name !== 'undefined' || typeof artist.last_name !== 'undefined') {
    name = '';

    if (artist.first_name[langCode]) {
      const firstName = artist.first_name[langCode].trim();
      name = asHtml === 'asHtml'
        ? '<span class="first-name">' + firstName + ' </span>'
        : firstName + ' ';
    }

    if (artist.last_name[langCode]) {
      const lastName = artist.last_name[langCode].trim();
      name += asHtml === 'asHtml'
        ? '<span class="last-name">' + lastName + '</span>'
        : lastName;
    }
  }
  return name.trim();
};
