import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AfterViewInit, ElementRef, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReCaptchaComponent } from 'angular5-recaptcha';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from '../../../environments/environment';
import { GlobalService } from '../../model/global.service';
import { UniversalPlatformService } from '../../shared/platform/universal-platform.service';
import { UniversalRendererHelper } from '../../shared/platform/universal-renderer.helper';
import { UrlService } from '../../model/url.service';
import { FormValidators } from '../../shared/form-utils';
import { AutoUnsubscribe } from '../../decorators/autounsubscribe.decorator';
var OfferFormBlockComponent = /** @class */ (function () {
    function OfferFormBlockComponent(urlService, platformService, renderer, globalService, http, formBuilder) {
        this.urlService = urlService;
        this.platformService = platformService;
        this.renderer = renderer;
        this.globalService = globalService;
        this.http = http;
        this.formBuilder = formBuilder;
        this.viewInit = new EventEmitter();
        this.maxFileSizeMb = 4;
        this.maxFileSize = this.maxFileSizeMb * 1024 * 1024;
        this.maxFilesCount = 5;
        this.fileTypes = ['image/png', 'image/jpeg'];
        this.resetMessage();
        this.recaptchaKey = environment.reCaptchaKey;
        this.formFields = this.formBuilder.group({
            name: ['', Validators.compose([
                    Validators.required,
                    Validators.minLength(5),
                ])],
            phone: ['', Validators.compose([
                    Validators.required,
                    FormValidators.phone(urlService.lang.toUpperCase()),
                ])],
            email: ['', Validators.compose([
                    Validators.required,
                    FormValidators.email(),
                ])],
            message: ['', Validators.compose([
                    Validators.required,
                    Validators.minLength(10),
                ])],
            file: ['', Validators.required],
        });
        this.files = [];
        this.recaptchaToken = false;
        this.doingSubmit = false;
    }
    Object.defineProperty(OfferFormBlockComponent.prototype, "isSubmitting", {
        get: function () {
            return this.doingSubmit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OfferFormBlockComponent.prototype, "fileError", {
        get: function () {
            return this.fileErrorText;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OfferFormBlockComponent.prototype, "canShowCaptcha", {
        get: function () {
            return this.platformService.isBrowser;
        },
        enumerable: true,
        configurable: true
    });
    OfferFormBlockComponent.prototype.ngAfterViewInit = function () {
        this.viewInit.emit('offerform');
    };
    OfferFormBlockComponent.prototype.displayInvalidityInfo = function (name) {
        if ('_captcha' === name) {
            return this.showValidation
                ? this.recaptchaToken === false
                : false;
        }
        if ('files' === name) {
            return this.showValidation
                ? (this.files || []).length === 0
                : false;
        }
        var field = this.formFields.get(name);
        if (!field) {
            return false;
        }
        if (field.touched) {
            return field.invalid;
        }
        return this.showValidation ? field.invalid : false;
    };
    OfferFormBlockComponent.prototype.handleCaptchaResponse = function (token) {
        this.recaptchaToken = typeof token === 'string' ? token : false;
    };
    OfferFormBlockComponent.prototype.resetForm = function () {
        this.renderer.removeClass(this.formNode, 'submit-tried');
        this.showValidation = false;
        this.resetAndUpdate();
        this.formFields.markAsUntouched();
        this.files = [];
    };
    OfferFormBlockComponent.prototype.validateForm = function () {
        this.formFields.updateValueAndValidity();
        this.showValidation = true;
    };
    OfferFormBlockComponent.prototype.onClickFiles = function (evt) {
        if ((this.files || []).length === this.maxFilesCount) {
            if (evt && evt.preventDefault) {
                evt.preventDefault();
            }
            this.showFileError('tooMany');
            return false;
        }
    };
    OfferFormBlockComponent.prototype.onClickFilesItem = function (index, evt) {
        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }
        if (typeof this.files[index] !== 'undefined') {
            this.files.splice(index, 1);
        }
        return false;
    };
    OfferFormBlockComponent.prototype.onFileChange = function (evt) {
        var i = 0;
        var added = 0;
        var files, file, length;
        if (!evt || !evt.target.files) {
            return false;
        }
        if ((this.files || []).length === this.maxFilesCount) {
            this.showFileError('tooMany');
            return false;
        }
        added += (this.files || []).length;
        files = evt.target.files;
        length = (files || []).length;
        for (; i < length; ++i) {
            file = files[i];
            if (this.maxFileSize < file.size || this.fileTypes.indexOf(file.type.toLowerCase()) === -1) {
                this.showFileError('tooLarge');
                continue;
            }
            this.files.push(file);
            ++added;
            if (added === this.maxFilesCount) {
                break;
            }
        }
        return false;
    };
    OfferFormBlockComponent.prototype.onSubmitForm = function (evt) {
        if (evt && typeof evt.preventDefault !== 'undefined') {
            evt.preventDefault();
        }
        this.renderer.addClass(this.formNode, 'submit-tried');
        this.validateForm();
        if (this.formFields.invalid || (this.recaptcha && !this.recaptchaToken)) {
            return false;
        }
        this.submitForm();
        return false;
    };
    OfferFormBlockComponent.prototype.onClickSubmit = function (evt) {
        return this.onSubmitForm(evt);
    };
    OfferFormBlockComponent.prototype.onClickCancel = function (evt) {
        if (evt && typeof evt.preventDefault !== 'undefined') {
            evt.preventDefault();
        }
        this.resetForm();
        return false;
    };
    OfferFormBlockComponent.prototype.closeModal = function (evt) {
        if (evt && typeof evt.prefentDefault !== 'undefined') {
            evt.preventDefault();
        }
        this.modal.hide();
    };
    OfferFormBlockComponent.prototype.closeByBackdrop = function (evt) {
        if (evt && evt.target) {
            if (this.renderer.hasClass(evt.target.className, 'modal-dialog-content')) {
                this.closeModal();
            }
        }
        else {
            this.closeModal();
        }
    };
    OfferFormBlockComponent.prototype.resetMessage = function () {
        var allowedLangs = this.urlService.allowedLangs;
        var i = 0;
        this.formMessageContent = {};
        this.formMessageTitle = {};
        for (; i < (allowedLangs || []).length; ++i) {
            this.formMessageTitle[allowedLangs[i].lang] = '';
            this.formMessageContent[allowedLangs[i].lang] = '';
        }
        this.showMessage = false;
    };
    OfferFormBlockComponent.prototype.showFileError = function (which) {
        var _this = this;
        clearTimeout(this.fileErrorTO);
        switch (which) {
            case 'tooMany':
                this.fileErrorText = 'Jednorazowo można przesłać maksymalnie 5 zdjęć.';
                break;
            case 'tooLarge':
                this.fileErrorText = "Przynajmniej jeden wybrany plik przekracza dozwolony rozmiar " + this.maxFileSizeMb + "MB.";
                break;
            default:
                this.fileErrorText = 'Błąd pliku.';
        }
        this.fileErrorTO = setTimeout(function () {
            _this.fileErrorText = '';
        }, 5000);
    };
    OfferFormBlockComponent.prototype.submitForm = function () {
        var _this = this;
        if (this.doingSubmit) {
            return;
        }
        var httpOptions = {
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
            }),
        };
        var postUrl = this.globalService.apiHost + '/product-offer';
        var formValues = new FormData();
        var controls = this.formFields.controls;
        this.doingSubmit = true;
        formValues.set('lang', this.urlService.lang);
        for (var field in controls) {
            if (!controls.hasOwnProperty(field)) {
                continue;
            }
            if ('file' === field) {
                continue;
            }
            formValues.set(field, controls[field].value);
        }
        for (var i = 0; i < (this.files || []).length; ++i) {
            formValues.set('file_' + i, this.files[i], this.files[i].name);
        }
        this.resetMessage();
        this.http.post(postUrl, formValues, httpOptions).pipe(catchError(function (error) {
            _this.doingSubmit = false;
            return _this.handlePostError(error);
        })).subscribe(function (response) {
            if (response.status === 'success') {
                _this.resetForm();
            }
            _this.formMessageTitle = response.message_title;
            _this.formMessageContent = response.message_text;
            _this.displayMessage();
            _this.doingSubmit = false;
        });
    };
    OfferFormBlockComponent.prototype.displayMessage = function () {
        var _this = this;
        this.modal.show();
        setTimeout(function () { return _this.closeModal(); }, 5000);
    };
    OfferFormBlockComponent.prototype.resetAndUpdate = function () {
        this.formFields.reset();
        this.formFields.updateValueAndValidity();
    };
    OfferFormBlockComponent.prototype.handlePostError = function (error) {
        var message;
        if (!error.status) {
            message = {
                success: false,
                status: 0,
                data: 'Sorry, a connection error has occurred. Please try again.',
            };
            for (var key in this.formMessageTitle) {
                if (this.formMessageTitle.hasOwnProperty(key)) {
                    this.formMessageTitle[key] = message.data;
                }
            }
        }
        else if (error.json) {
            message = error;
            if (message.message_title || message.message_text) {
                if (message.message_text) {
                    this.formMessageTitle = message.message_text;
                }
                if (message.message_title) {
                    this.formMessageTitle = message.message_title;
                }
            }
            else if (message.data) {
                this.formMessageTitle = message.data;
            }
            else {
                this.formMessageTitle = message;
            }
        }
        else {
            message = {
                success: false,
                status: error.status,
                data: error.formMessage,
            };
            this.formMessageTitle = error.formMessage;
        }
        this.displayMessage();
        return observableThrowError(message);
    };
    OfferFormBlockComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [UrlService,
            UniversalPlatformService,
            UniversalRendererHelper,
            GlobalService,
            HttpClient,
            FormBuilder])
    ], OfferFormBlockComponent);
    return OfferFormBlockComponent;
}());
export { OfferFormBlockComponent };
