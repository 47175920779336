import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OwlModule } from 'ngx-owl-carousel';
import { ArtsaasPipesModule } from '../../shared/artsaas-pipes.module';
import { TranslatePipesModule } from '../../shared/translate-pipes.module';
import { ProductsCarouselBlockComponent } from './products-carousel-block.component';
import { PriceExchangeModule } from '../../price-exchange/price-exchange.module';

@NgModule({
  imports: [
    CommonModule,
    OwlModule,
    RouterModule,
    ArtsaasPipesModule,
    TranslatePipesModule,
    PriceExchangeModule,
  ],
  exports: [
    ProductsCarouselBlockComponent,
  ],
  declarations: [
    ProductsCarouselBlockComponent,
  ],
})
export class ProductsCarouselBlockModule {
}
