import { TypeUtils } from './type-utils';

export class ObjectUtils {

  static extend(
    targetOrDeep: { [key: string]: any } | boolean | any[],
    sourceOrTarget: { [key: string]: any } | any[],
    maybeSource?: any,
  ): { [key: string]: any } | any[] {
    let target = targetOrDeep,
      source = sourceOrTarget,
      deep = false,
      name, src, copy, copyIsArray, clone;

    if (typeof target === 'boolean') {
      deep = target;

      target = sourceOrTarget || {};
      source = maybeSource || {};
    }

    if (typeof target !== 'object' && !TypeUtils.isFunction(target)) {
      target = {};
    }

    if (source != null) {
      for (name in source) {
        if (!source[name]) {
          continue;
        }
        src = target[name];
        copy = source[name];

        // Prevent never-ending loop
        if (target === copy) {
          continue;
        }

        // Recurse if we're merging plain objects or arrays
        if (
          deep && copy && (TypeUtils.isPlainObject(copy) ||
          (copyIsArray = Array.isArray(copy)))
        ) {

          if (copyIsArray) {
            copyIsArray = false;
            clone = src && Array.isArray(src) ? src : [];
          } else {
            clone = src && TypeUtils.isPlainObject(src) ? src : {};
          }

          target[name] = ObjectUtils.extend(deep, clone, copy);
        } else if (typeof copy !== 'undefined') { // Don't bring in undefined values
          target[name] = copy;
        }
      }
    }

    return target;
  }

  static copy(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  static map(obj: { [key: string]: any } | any[], callbackFn: any): { [key: string]: any } | any[] {
    let newObj: { [key: string]: any } | any[];

    if (TypeUtils.type(obj) === 'object') {

      newObj = {};

      for (const prop in obj) {
        if (!obj.hasOwnProperty(prop)) {
          continue;
        }

        newObj[prop] = callbackFn(obj[prop], prop, obj);
      }

    } else if (Array.isArray(obj)) {

      newObj = obj.map(callbackFn);

    }

    return newObj ? newObj : obj;
  }

  static arrayFindObjects(arr: any[], value: any, property: string): any[] {
    const objects = [];
    let obj: any;

    for (let i = 0; i < (arr || []).length; ++i) {
      obj = arr[i];

      if (typeof obj[property] === 'undefined' || !obj.hasOwnProperty(property)) {
        continue;
      }

      if (obj[property] === value) {
        objects.push(obj);
      }
    }

    return objects;
  }

  static arrayFindFirstObject(arr: any[], property: string, value: any): any {
    const objects = ObjectUtils.arrayFindObjects(arr, value, property);
    return (objects || []).length > 0 ? objects[0] : null;
  }

}
