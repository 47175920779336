import { map } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { UrlService } from '../../../../model/url.service';
import { CmsService } from '../../../../model/cms.service';
import { SugarService } from '../../../../model/sugar.service';
import { GlobalService } from '../../../../model/global.service';
import { UniversalPlatformService } from '../../../../shared/platform/universal-platform.service';
import { MenuItem } from './menu-item.class';
import { MenuItemChild } from './menu-item-child.class';
import { MenuItemFeatured } from './menu-item-featured.class';
import { HydratedMenuItem, HydratedMenuItemBase, HydratedSubmenuItem, RawMenuItem, RawSubmenuItem } from './menu-item.interfaces';
import { HttpClient } from '@angular/common/http';

const featuredChildrenTypes = [
  'auction-newest',
  'auction-current',
  'exhibition-newest',
  'exhibition-current',
  'offer-newest',
  'offer-current',
  'news-newest',
  'news-current',
];

const contentTypes = {
  auctions: [
    'auction-newest',
    'auction-current',
    'auctions-current',
    'auction-archive',
    'auctions-archive',
    'auctions',
  ],
  exhibitions: [
    'exhibition-newest',
    'exhibition-current',
    'exhibitions-current',
    'exhibition-archive',
    'exhibitions-archive',
    'exhibitions',
  ],
  products: [
    'offer-newest',
    'offer-current',
    'works-current',
    'offer-archive',
    'works-archive',
    'offer-category',
    'products-category',
    'offer',
    'products',
  ],
  events: [
    'news',
    'news-newest',
    'news-current',
    'news-category',
    'event-category',
    'events',
  ],
  artists: [
    'artist',
    'artists',
  ],
  'text-page': [
    'text-page',
    'contact',
    'how-to-sell',
    'how-to-buy',
    'sold',
  ],
  external: ['external'],
};

const ctCache: { [key: string]: string } = {};

@Injectable()
export class MenuService implements OnDestroy {

  get items(): MenuItem[] {
    return this.menuItems;
  }

  public ready: BehaviorSubject<boolean>;
  public backgroundColor: string;
  protected remoteCount: number;
  protected remoteLoaded: number;
  protected catCache: { [key: string]: any } = {};
  private menuSub: Subscription;
  private hydratedItems: HydratedMenuItem[];
  private menuItems: MenuItem[];

  static getContentType(keyword: string): string {
    if (typeof ctCache[keyword] === 'string') {
      return ctCache[keyword];
    }

    if (typeof contentTypes[keyword] !== 'undefined') {
      ctCache[keyword] = keyword;
      return keyword;
    }

    let prop: string;

    for (prop in contentTypes) {
      if (!contentTypes.hasOwnProperty(prop)) {
        continue;
      }

      if (contentTypes[prop].indexOf(keyword) > -1) {
        ctCache[keyword] = prop;
        return prop;
      }
    }

    return '';
  }

  constructor(
    protected urlService: UrlService,
    protected cmsService: CmsService,
    protected sugarService: SugarService,
    protected globalService: GlobalService,
    protected platformService: UniversalPlatformService,
    private _http: HttpClient,
  ) {
    this.menuItems = [];
    this.hydratedItems = [];

    this.backgroundColor = '#333';

    this.remoteCount = 0;
    this.remoteLoaded = 0;

    this.ready = new BehaviorSubject(false);

    this.menuSub = this.cmsService.menuItems.subscribe((menu) => {
      let sub: Subscription;

      if (!this.globalService.settings.__loaded) {
        sub = globalService.updated.subscribe((settings) => {
          if (!settings.__loaded) {
            this.setupMenu(menu);
            sub.unsubscribe();
          }
        });
      } else {
        this.setupMenu(menu);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.menuSub) {
      this.menuSub.unsubscribe();
    }
  }

  reset(full?: boolean): void {
    this.menuItems = [];
    this.remoteCount = 0;
    this.remoteLoaded = 0;

    if (full) {
      this.hydratedItems = [];
    }
  }

  protected setupMenu(menu: RawMenuItem[] | { [key: string]: any }): void {
    if (!menu || !Array.isArray(menu)) {
      return;
    }

    let rawItem: RawMenuItem;
    let hydratedItem: HydratedMenuItem;

    const mLen = (menu || []).length;
    let i = 0;

    this.reset(true);

    for (; i < mLen; ++i) {
      rawItem = <RawMenuItem>menu[i];
      hydratedItem = this.hydrateMenuItem(menu[i]);

      if (!hydratedItem) {
        continue;
      }

      this.hydratedItems.push(hydratedItem);
    }

    if (this.remoteCount > 0) {
      const hLen = (this.hydratedItems || []).length;

      let item: HydratedMenuItem;
      let hcLen: number;

      for (i = 0; i < hLen; ++i) {
        let child: HydratedSubmenuItem;
        let ci: number;

        item = this.hydratedItems[i];
        hcLen = (item.children || []).length;

        for (ci = 0; ci < hcLen; ++ci) {
          child = item.children[ci];

          if (!child.isRemote) {
            continue;
          }

          this.fetchRemoteItem(child);
        }

        if (item.featured && item.featured.isRemote) {
          this.fetchRemoteItem(item.featured);
        }
      }
    } else {
      this.onRemoteLoaded();
    }
  }

  protected hydrateMenuItem(rawItem: RawMenuItem): HydratedMenuItem | null {
    // główna pozycja
    const item: HydratedMenuItem = {
      title: '',
      type: 'text-page',
      url: '',
      target: '',
      slug: '',
      link: '',
      contentType: '',
      params: null,
      children: [],
      external: false,
      featured: null,
      columns: 1,
      index: 0,
      hasRemote: false,
      remoteCount: 0,
      styles: [],
    };

    const skipProp = ['children', 'featured', 'hasRemote', 'remoteCount'];
    const lang = this.urlService.lang;

    let prop: string;

    for (prop in item) {
      if (skipProp.indexOf(prop) > -1 || typeof rawItem[prop] === 'undefined' || rawItem[prop] === null) {
        continue;
      }

      if (typeof rawItem[prop] === 'string' || typeof rawItem[prop] === 'number' || typeof rawItem[prop] === 'boolean') {

        item[prop] = rawItem[prop];

      } else if (typeof rawItem[prop][lang] !== 'undefined' || typeof rawItem[prop]['en'] !== 'undefined') {

        if ('slug' === prop && typeof rawItem[prop]['en'] !== 'undefined') {
          item[prop] = rawItem[prop]['en'];
        } else {
          item[prop] = rawItem[prop][lang];
        }

      }
    }

    item.contentType = MenuService.getContentType(rawItem.content_type);
    item.type = item.contentType;
    item.params = rawItem.parameters ? { cat: rawItem.parameters.category_id } : null;

    const improper = (!this.globalService.settings.offer.show_archive
      && (item.type === 'offer-archive' || item.type === 'works-archive'));

    if (improper) {
      return null;
    }

    if (!item.contentType) {
      item.external = true;
      item.link = item.url;

    } else {
      item.link = this.urlService.link(item.url);
    }

    item.index = (this.hydratedItems || []).length;

    // dziecka
    let children = typeof rawItem.children !== 'undefined' && (rawItem.children || []).length ? rawItem.children : [];
    const itemChildren = [];
    let cLen = (children || []).length;

    let ci = 0;
    let child: HydratedSubmenuItem;

    let featuredIndex = -1;
    let columns = 1;
    let in2ndColumn = 0;
    if (rawItem.content_type === 'auctions') {
      children = children.filter(it => it.type !== '"auction-current"');
      cLen = (children || []).length;
    }
    for (; ci < cLen; ++ci) {
      children[ci].index = ci;
      child = this.hydrateChildItem(children[ci]);

      if (!child) {
        continue;
      }

      if (child.isRemote) {
        item.hasRemote = true;
        ++item.remoteCount;

        ++this.remoteCount;
      }

      if (child.column > 0) {
        ++in2ndColumn;
        columns = 2;
      }

      if (child.isFeatured) {
        featuredIndex = ci;
      }

      itemChildren.push(child);
    }

    if (featuredIndex > -1 && in2ndColumn === 1) {
      const spliced = itemChildren.splice(featuredIndex, 1);
      item.featured = spliced[0];
      columns = -1;
    }

    item.children = this.groupChildrenByColumn(itemChildren);
    item.columns = columns;

    return item;
  }

  protected fetchRemoteItem(hydratedItem: HydratedSubmenuItem): void {
    if (hydratedItem.isFeatured) {
      this.getFeaturedObject(hydratedItem).subscribe(data => {
        this.setRemoteObject(hydratedItem, data);
        ++this.remoteLoaded;
        this.startWhenReady();
      });
    } else {
      this.getCategories(hydratedItem).subscribe((cats) => {
        this.setCategories(hydratedItem, cats);
        ++this.remoteLoaded;
        this.startWhenReady();
      });
    }
  }

  protected startWhenReady(): void {
    if (this.remoteLoaded === this.remoteCount) {
      this.onRemoteLoaded();
    }
  }

  protected onRemoteLoaded(): void {
    let hydrated: HydratedMenuItem;
    let childrenItems: MenuItemChild[];

    let ii = 0;
    const hLen = (this.hydratedItems || []).length;
    this.reset();

    for (; ii < hLen; ++ii) {
      hydrated = this.hydratedItems[ii];

      let cii: number;
      let child: HydratedSubmenuItem;
      const cLen = (hydrated.children || []).length;
      let childItem: MenuItemChild;

      childrenItems = [];

      for (cii = 0; cii < cLen; ++cii) {
        child = hydrated.children[cii];

        childItem = new MenuItemChild(child);
        childrenItems.push(childItem);
      }

      let featured: MenuItemFeatured | null;

      if (hydrated.featured) {
        featured = new MenuItemFeatured(hydrated.featured, hydrated.featured.remoteObject);
      } else {
        featured = null;
      }
      const item = new MenuItem(hydrated, childrenItems, featured);
      item.index = (this.menuItems || []).length;
      this.menuItems.push(item);
    }

    this.menuItems.forEach((val: any, index) => {
      if (val.ct === 'auctions') {
        const maxCount = this.globalService.settings.global.auctionsSubmenuMaxCount || 1;
        const url = this.globalService.sugarApiHost + '/auctions?expand=images&sort=date&filter[status][0]=live_auction_status_ongoing&filter[status][1]=live_auction_status_in_preparation&per-page=' + maxCount + '&page=1';

        const params: { [key: string]: any } = {
          observe: 'response',
        };

        this._http
          .get(url, params).pipe(
          map((resp: any) => ({ body: resp.body, headers: [] })))
          .subscribe(data => {
            this.menuItems[index].manyFeaturedItemsList = data.body.items;
          });
      }
    });

    this.ready.next(true);
  }

  protected setCategories(item: HydratedMenuItemBase, categories: { [key: string]: any }[]): void {
    if (!categories) {
      return;
    }

    let i = 0;

    if (null === item.params) {
      item.params = {};
    }

    for (; i < (categories || []).length; ++i) {
      if ('events' === item.contentType) {
        if (item.title === categories[i].name) {
          item.params.cat = categories[i].slug;
        }
      } else {
        item.params.cat = categories[i].id;
        item.params.catName = categories[i].name[this.urlService.langCode];
      }
    }
  }

  protected getCategories(hydrated: HydratedMenuItemBase): Subject<{ [key: string]: any }[]> {
    const subject = new Subject<{ [key: string]: any }[]>();

    const endpoint = hydrated.contentType;
    let params: string;

    if (typeof this.catCache[endpoint] !== 'undefined') {
      const categories = this.catCache[endpoint];

      setTimeout(() => {
        subject.next(categories);
      });

      return subject;
    }

    switch (hydrated.contentType) {
      case 'text-page':
      case 'external':
        params = null;
        break;

      default:
        params = 'expand=filter_categories&per-page=1';
    }

    if (!params) {
      setTimeout(() => {
        subject.next(null);
      });

      return subject;
    }

    this.sugarService.getItems(endpoint, params, '', 1).subscribe((data) => {
      const categories: { [key: string]: any }[] = [];

      if (data && data.body && data.body && data.body.filters && data.body.filters.categories) {
        const cats = data.body.filters.categories;
        let prop: string | number;

        if (Array.isArray(cats)) {

          for (prop = 0; prop < (cats || []).length; ++prop) {
            categories.push({
              id: cats[prop].id ? cats[prop].id : null,
              name: typeof cats[prop].name === 'string' ? cats[prop].name : cats[prop].name[this.urlService.langCode],
              slug: cats[prop].ckey,
            });
          }

        } else {

          for (prop in cats) {
            if (!cats[prop]) {
              continue;
            }
            categories.push({
              id: cats[prop].id ? cats[prop].id : null,
              name: typeof cats[prop].name === 'undefined' ?
                cats[prop][this.urlService.langCode]
                : cats[prop].name[this.urlService.langCode],
              slug: prop,
            });
          }

        }

        if ((categories || []).length) {
          let i = 0;

          if (null === hydrated.params) {
            hydrated.params = {};
          }

          for (; i < (categories || []).length; ++i) {
            if ('events' === hydrated.contentType) {
              if (hydrated.title === categories[i].name) {
                hydrated.params.cat = categories[i].slug;
              }
            } else {
              hydrated.params.cat = categories[i].id;
              hydrated.params.catName = categories[i].name[this.urlService.langCode];
            }
          }
        }
      }

      this.catCache[endpoint] = categories;

      subject.next(categories);
    });

    return subject;
  }

  protected getFeaturedObject(item: HydratedSubmenuItem): Observable<any> | null {
    let endpoint = item.contentType;
    let params: string;

    if (!item.isFeatured) {
      return;
    }

    switch (item.type) {
      case 'auction-newest':
      case 'auction-current':
        params = 'expand=images&sort=-date&filter[status][0]=live_auction_status_in_preparation&filter[status][1]=live_auction_status_ongoing&per-page=1';
        break;

      case 'exhibition-newest':
      case 'exhibition-current':
        params = 'expand=images&sort=-date_from&filter[status][0]=exhibition_status_in_preparation&filter[status][1]=exhibition_status_ongoing&per-page=1';
        break;

      case 'offer-newest':
      case 'offer-current':
      case 'offer-archive':
        endpoint = '';
        break;

      case 'offer':
        params = 'expand=images&sort=-created_at';
        break;

      case 'news-newest':
      case 'news-current':
        params = 'expand=images&sort=-date_from&per-page=1';
        break;
    }

    if (!(endpoint || []).length) {
      return null;
    }

    return this.sugarService.getItems(endpoint, params, '', 1);
  }

  protected setRemoteObject(item: HydratedSubmenuItem, data: { [key: string]: any }): void {
    if (!data.body || !data.body.items || !(data.body.items || []).length) {
      return;
    }

    const reItem = data.body.items[0];

    // znów wyjątek
    const type = item.contentType === 'events' ? 'news' : item.contentType;
    item.link = this.urlService.link(type) + '/' + reItem.id;

    item.title = reItem.name[this.urlService.langCode];

    item.remoteObject = data.body.items[0];
  }

  protected groupChildrenByColumn(children: HydratedSubmenuItem[]): HydratedSubmenuItem[] {
    const columnOne: HydratedSubmenuItem[] = [];
    const columnTwo: HydratedSubmenuItem[] = [];
    const len = (children || []).length;

    let groupedChildren: HydratedSubmenuItem[];

    let i = 0;
    let child: HydratedSubmenuItem;

    for (; i < len; ++i) {
      child = children[i];

      if (child.column === 0) {
        columnOne.push(child);
      } else {
        columnTwo.push(child);
      }
    }

    groupedChildren = columnOne.concat(columnTwo);

    return groupedChildren;
  }

  private hydrateChildItem(rawItem: RawSubmenuItem): HydratedSubmenuItem | null {
    const item: HydratedSubmenuItem = {
      title: '',
      type: 'text-page',
      url: '',
      target: '',
      slug: '',
      link: '',
      contentType: '',
      params: {},
      column: 0,
      isFeatured: false,
      external: false,
      index: 0,
      isRemote: false,
      remoteObject: null,
      styles: [],
    };

    const lang = this.urlService.lang;
    let prop: string;

    const skipProp = ['children', 'isRemote', 'isFeatured'];

    // małe wyjątki
    if ('auctions' === rawItem.type) {
      rawItem.type = 'auctions-current';
    } else if ('exhibitions' === rawItem.type) {
      rawItem.type = 'exhibitions-current';
    }

    for (prop in item) {
      if (skipProp.indexOf(prop) > -1 || typeof rawItem[prop] === 'undefined' || rawItem[prop] === null) {
        continue;
      }

      if (typeof rawItem[prop] === 'string' || typeof rawItem[prop] === 'number' || typeof rawItem[prop] === 'boolean') {

        item[prop] = rawItem[prop];

      } else if (typeof rawItem[prop][lang] !== 'undefined' || typeof rawItem[prop]['en'] !== 'undefined') {

        if ('slug' === prop && typeof rawItem[prop]['en'] !== 'undefined') {
          item[prop] = rawItem[prop]['en'];
        } else {
          item[prop] = rawItem[prop][lang];
        }

      }
    }

    item.contentType = MenuService.getContentType(item.type);

    const improper = !item.contentType ||
      (!this.globalService.settings.offer.show_archive && (item.type === 'offer-archive' || item.type === 'works-archive'));

    if (improper) {
      return null;
    }

    if (item.column > 0) {
      item.column = 1;
    }

    if (featuredChildrenTypes.indexOf(item.type) > -1) {
      item.isFeatured = true;
      item.isRemote = true;
    }

    switch (item.contentType) {
      case 'text-page':
        if (!(item.url || []).length) {
          item.url = (item.slug || []).length ? item.slug : item.type;
        }
        break;

      case 'external':
        item.external = true;
        item.url = typeof item.url === 'string' ? item.url : item.url[this.urlService.lang];
        break;

      default:
        item.url = item.contentType;
    }

    if (item.external) {
      item.link = item.url.replace('//', '\/\/');
      return item;
    }

    // znów wyjątek
    if (item.url === 'events') {
      item.url = 'news';
    } else if (item.url === 'products') {
      item.url = 'offer';
    }

    const currentArchive = ['auctions-current', 'auctions-current', 'exhibitions-archive', 'exhibitions-current', 'works-archive'];

    if (rawItem.data && rawItem.data.category_id) {
      item.link = this.urlService.link(item.contentType);
      item.params.cat = rawItem.data.category_id;
    } else if (currentArchive.indexOf(item.type) > -1) {
      item.link = this.urlService.link(item.contentType);
      item.params.archive = item.type.indexOf('current') > -1 ? '0' : '1';
    } else {
      item.isRemote = featuredChildrenTypes.indexOf(item.type) > -1;

      if (!item.isRemote) {
        item.link = this.urlService.link(item.url);
      }
    }

    if (item.type.indexOf('archive') > -1) {
      item.params.archive = 1;
    }

    item.styles = ['underlining', 'lowercase', 'background'];
    if (item.link === '/pl/events') {
      item.link = '/pl/wydarzenia';
    }
    if (item.link === '/pl/auction-terms-and-conditions') {
      item.link = '/pl/regulamin-aukcji';
    }
    return item;
  }

}
