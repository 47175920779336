import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { OwlCarousel } from 'ngx-owl-carousel';
import { GlobalService } from '../../model/global.service';
import { SugarService } from '../../model/sugar.service';
import { UrlService } from '../../model/url.service';
import { UniversalPlatformService } from '../../shared/platform/universal-platform.service';
import { ObjectUtils } from '../../shared/utils/object-utils';
import { Utils } from '../../shared/utils.class';
import { AuctionProductsColletionSetter, OfferColletionSetter } from '../../shared/collections/api-collection-setters';
import { AutoUnsubscribe } from '../../decorators/autounsubscribe.decorator';
import { Router } from '@angular/router';
var ProductsCarouselBlockComponent = /** @class */ (function () {
    function ProductsCarouselBlockComponent(globalService, urlService, platformService, sugarService, sanitizer, router) {
        this.globalService = globalService;
        this.urlService = urlService;
        this.platformService = platformService;
        this.sugarService = sugarService;
        this.sanitizer = sanitizer;
        this.router = router;
        this.activeTextElementsOnHoverEnabled = false;
        this.viewInit = new EventEmitter();
        this.zoomImages = this.globalService.settings.global.sliderRotation === 'true';
        this.activeTextElementsOnHoverEnabled = this.globalService.settings.global.activeTextElementsOnHoverEnabled === 'true';
        this.collection = Utils.uniqid('products-carousel-');
        this.block = {
            title: '',
            group: null,
        };
        this.enableOwl = false;
    }
    ProductsCarouselBlockComponent.prototype.ngOnInit = function () {
        var _this = this;
        var collectionParameters = {
            expand: 'images,artists',
            'filter[archive]': '0',
        };
        if (typeof this.blockConfig.number !== 'undefined') {
            collectionParameters['per-page'] = this.blockConfig.number;
        }
        var collectionInit = {
            sugarNode: 'products',
            parameters: collectionParameters,
        };
        var load = true;
        this.mode = this.blockConfig.type;
        this.options = {
            dragEndSpeed: 1000,
            navSpeed: 1500,
            margin: 10,
            nav: false,
            navText: ['', ''],
            dots: false,
            responsive: {
                0: {
                    items: 1,
                },
                576: {
                    items: 1,
                },
                768: {
                    items: 2,
                },
                992: {
                    items: 3,
                },
                1200: {
                    items: 4,
                },
            },
        };
        this.block.title = this.blockConfig.header;
        switch (this.mode) {
            case 'products_latest':
                collectionParameters.sort = '-created_at';
                break;
            case 'products_same_category':
                var parent_1;
                if (this.context === 'work') {
                    parent_1 = 'works';
                }
                else if (this.context === 'auction-product') {
                    parent_1 = 'works';
                    collectionParameters.expand += ',auction_item';
                }
                else {
                    load = false;
                }
                if (parent_1) {
                    if (this.sugarService.hasCollection(parent_1)) {
                        var filters = this.sugarService.getFilters(parent_1);
                        if (filters && filters.cat) {
                            var index_1 = 0;
                            ObjectUtils.map(filters.cat, function (fltr) {
                                collectionParameters['filter[cat][' + index_1 + ']'] = fltr.id;
                                ++index_1;
                            });
                        }
                    }
                }
                break;
            case 'products_featured':
                this.block.group = 'recommended-items';
                collectionParameters['filter[group.type]'] = 'exhibition_category_recommended';
                break;
            case 'products_auction_featured':
                this.block.navContainerId = Utils.uniqid('auction-products-');
                this.options.nav = true;
                this.options.navContainer = '#' + this.block.navContainerId + ' .recommendations-nav';
                // collectionInit.sugarNode = "auction_items";
                collectionParameters['filter[highlighted]'] = '1';
                collectionParameters.expand += ',auction_item';
                break;
        }
        this.sugarService.setCollection(this.collection, collectionInit);
        var collectionSetter;
        if (this.mode === 'products_auction_featured') {
            collectionSetter = new AuctionProductsColletionSetter(this.urlService, this.sugarService, this.platformService, this.globalService, this.sanitizer);
        }
        else {
            collectionSetter = new OfferColletionSetter(this.urlService, this.sugarService, this.platformService, this.globalService, this.sanitizer);
        }
        this.dataSub = this.sugarService.getData().subscribe(function (data) {
            if (data.collectionId !== _this.collection) {
                return;
            }
            _this.items = collectionSetter.setup(data.data, _this.collection);
        });
        this.sugarService.refreshData(this.collection);
    };
    ProductsCarouselBlockComponent.prototype.ngOnDestroy = function () {
        if (this.dataSub) {
            this.dataSub.unsubscribe();
        }
        this.sugarService.unsetCollection(this.collection);
    };
    ProductsCarouselBlockComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        var waiter = new Utils.waiter(function () {
            return typeof _this.nav !== 'undefined' && typeof _this.nav.nativeElement !== 'undefined' && _this.nav.nativeElement;
        }, this.createLoadedCb(true), this.createLoadedCb(false));
        waiter.setMaxTicks(500);
        waiter.setTickInterval(50);
        waiter.start();
    };
    ProductsCarouselBlockComponent.prototype.getLink = function (item) {
        var slug = this.urlService.objectLink(item, '', true);
        if (this.mode === 'products_auction_featured' || this.mode === 'products_featured') {
            var parts = window.location.href.split('?');
            var url = parts[0].replace(window.location.origin, '');
            var urlArr = url.split('/');
            if (urlArr[1] === '#') {
                urlArr.splice(1, 1);
            }
            var lastSlug = urlArr[urlArr.length - 1];
            var newUrl = urlArr.join('/');
            return newUrl.replace(lastSlug, slug);
        }
        else {
            var menu = this.platformService.localStorage.getItem('menu');
            if (menu) {
                try {
                    var offer = JSON.parse(menu).find(function (it) { return it.content_type === 'products'; });
                    if (offer) {
                        return '/' + this.urlService.lang + '/' + offer.url + '/' + slug;
                    }
                }
                catch (e) {
                    return slug;
                }
            }
            return slug;
        }
    };
    ProductsCarouselBlockComponent.prototype.openProduct = function (item, queryParams, e) {
        e.preventDefault();
        this.router.navigate([this.getLink(item)], { queryParams: queryParams });
    };
    ProductsCarouselBlockComponent.prototype.onClickItem = function (offset) {
        this.sugarService.updateParam('works', 'offset', offset + '');
    };
    ProductsCarouselBlockComponent.prototype.createLoadedCb = function (makeSuccess) {
        var _this = this;
        return makeSuccess
            ? function () {
                _this.onSuccessfullyLoaded();
            }
            : function () {
                _this.onSuccessfullyLoaded(true);
            };
    };
    ProductsCarouselBlockComponent.prototype.onSuccessfullyLoaded = function (fakeSuccess) {
        if (fakeSuccess === void 0) { fakeSuccess = false; }
        if (this.successCalled) {
            return;
        }
        if (!fakeSuccess) {
            this.options.nav = true;
            this.options.navContainer = this.nav.nativeElement;
            this.enableOwl = true;
        }
        this.viewInit.emit(this.mode);
        this.successCalled = true;
    };
    ProductsCarouselBlockComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [GlobalService,
            UrlService,
            UniversalPlatformService,
            SugarService,
            DomSanitizer,
            Router])
    ], ProductsCarouselBlockComponent);
    return ProductsCarouselBlockComponent;
}());
export { ProductsCarouselBlockComponent };
