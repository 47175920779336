import { Injectable } from '@angular/core';
import { UniversalPlatformService } from './universal-platform.service';
import { UniversalRendererHelper } from './universal-renderer.helper';
import { ElementServiceBase } from './element-service-base';

@Injectable()
export class BodyService extends ElementServiceBase {

  protected root: any;

  constructor(
    protected platformService: UniversalPlatformService,
    protected rendererHelper: UniversalRendererHelper,
  ) {
    super(rendererHelper);
    this.root = platformService.document.body;
  }

  get body(): any {
    return this.root;
  }

  get bodyClass(): string {
    return this.className;
  }

  set bodyClass(className: string) {
    this.className = className;
  }

  resetAndAddClass(add: string | string[], force = false): void {
    const keep = force
      ? ''
      : 'has-fixed-banner';

    this.bodyClass = this.rendererHelper.classNameHelper.removeAllButKeep(
      this.bodyClass,
      keep,
    );

    this.addClass(add);
  }

}
