<!-- TEMPLATKI -->
<ng-template #slideContent let-item="item">
    <header *ngIf="item.title" class="carousel-article-header">
        <div class="carousel-article-title">{{ item.title }}</div>
    </header>

    <div *ngIf="item.bodySanitized" [innerHTML]="item.bodySanitized" class="carousel-article-text"></div>
</ng-template>

<ng-template #slideContentBlock let-item="item" let-wow="wow">
    <div [attr.data-wow-delay]="wow ? wow.delay : null" [attr.data-wow-duration]="wow ? wow.duration : null"
         [attr.data-wow-iteration]="wow ? wow.iteration : null"
         [attr.data-wow-offset]="wow ? wow.offset : null" [ngClass]="wowClasses"
         class="carousel-caption d-block">

        <ng-container *ngIf="item.url">

            <ng-container *ngIf="item.isInternal">
                <a [routerLink]="item.url" class="carousel-article-link">
                    <ng-container *ngTemplateOutlet="slideContent; context: {item:item}"></ng-container>
                </a>
            </ng-container>

            <ng-container *ngIf="! item.isInternal">
                <a [href]="item.url" class="carousel-article-link">
                    <ng-container *ngTemplateOutlet="slideContent; context: {item:item}"></ng-container>
                </a>
            </ng-container>

        </ng-container>

        <ng-container *ngIf="! item.url">
            <ng-container *ngTemplateOutlet="slideContent; context: {item:item}"></ng-container>
        </ng-container>
    </div>
</ng-template>

<!-- MAIN -->
<section *ngIf="slides && slides?.length" class="page-top">
    <div [ngClass]="styles" class="carousel-home-top">

        <owl-carousel #owl [carouselClasses]="['carousel', 'slide', 'carousel-home-top', 'owl-theme']"
                      [options]="options">
            <!-- data-owl-carousel="top-hero" -->

            <div *ngFor="let item of slides" class="owl-slide">
                <div class="carousel-item-bg-position">
                    <div [ngStyle]="rotation ? {
                    'background-image': 'url('+item.image+')'
                    } : {
                    'background-image': 'url('+item.image+')',
                    'transform': 'none'
                    }" class="carousel-item-bg"></div>
                </div>

                <article class="carousel-article">
                    <ng-container *ngIf="item.url">
                        <ng-container *ngIf="item.isInternal">
                            <a [routerLink]="item.url" class="carousel-bg-link"></a>
                        </ng-container>

                        <ng-container *ngIf="! item.isInternal">
                            <a [href]="item.url" class="carousel-bg-link"></a>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="item.title || item.bodySanitized">
                        <ng-container
                            *ngTemplateOutlet="slideContentBlock; context:{item:item, wow:wow}"></ng-container>
                    </ng-container>
                </article>
            </div>

        </owl-carousel>

        <div [attr.id]="dotsContainerId" class="wrapper-container carousel-indicators-wrapper">
            <div class="carousel-indicators"></div>
        </div>

    </div>
</section>
