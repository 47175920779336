import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LimitToPipe } from './limit-to.pipe';
import { Nl2BrPipe } from './nl2br.pipe';
import { KeysPipe } from './keys.pipe';
import { MailtoReplacePipe } from './mailto-replace.pipe';
import { FormDownloadLinkPipe } from './artsaas-pipes/form-download-link.pipe';
import { ArtistsAZPipe } from './artists-a-z.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    LimitToPipe,
    Nl2BrPipe,
    KeysPipe,
    MailtoReplacePipe,
    FormDownloadLinkPipe,
    ArtistsAZPipe,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    LimitToPipe,
    Nl2BrPipe,
    KeysPipe,
    MailtoReplacePipe,
    FormDownloadLinkPipe,
    ArtistsAZPipe,
  ],
  providers: [],
})
export class SharedModule {
}
