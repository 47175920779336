<div [class.show]="showPreloader" class="page-preloader show">
    <div class="loader-image"></div>
</div>

<div class="container">
    <div class="row">
        <header class="col-12 e404-header">
            <div class="page-title">{{ 'not-found-page.title' | translate:urlService.lang }}</div>
            <p>{{ 'not-found-page.message' | translate:urlService.lang }}</p>
        </header>
    </div>
</div>

<app-blocks-wrapper></app-blocks-wrapper>
