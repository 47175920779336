import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateDeclination',
  pure: true,
})
export class DateDeclinationPipe implements PipeTransform {

  private _monthDeclination: { [key: string]: string };

  constructor() {
    this._monthDeclination = {
      styczeń: 'stycznia',
      luty: 'lutego',
      marzec: 'marca',
      kwiecień: 'kwietnia',
      maj: 'maja',
      czerwiec: 'czerwca',
      lipiec: 'lipca',
      sierpień: 'sieprnia',
      wrzesień: 'września',
      październik: 'października',
      listopad: 'listopada',
      grudzień: 'grudnia',
    };
  }

  transform(value: string): string {
    let word: string;
    let repl: string;

    for (const m in this._monthDeclination) {
      if (!this._monthDeclination[m]) {
        continue;
      }
      word = ' ' + m + ' ';
      repl = ' ' + this._monthDeclination[m] + ' ';

      while (value.indexOf(word) > 1) {
        value = value.replace(word, repl);
      }
    }

    return value;
  }

}
