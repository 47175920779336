export class TypeUtils {

  static isFunction(obj: any): boolean {
    // Support: Chrome <=57, Firefox <=52
    // In some browsers, typeof returns "function" for HTML <object> elements
    // (i.e., `typeof document.createElement( "object" ) === "function"`).
    // We don't want to classify *any* DOM node as a function.
    return typeof obj === 'function' && typeof obj.nodeType !== 'number';
  }

  static isPlainObject(obj: any): boolean {
    let proto, Ctor;

    // Detect obvious negatives
    // Use toString instead of jQuery.type to catch host objects
    if (!obj || toString.call(obj) !== '[object Object]') {
      return false;
    }

    proto = Object.getPrototypeOf(obj);

    // Objects with no prototype (e.g., `Object.create( null )`) are plain
    if (!proto) {
      return true;
    }

    // Objects with prototype are plain iff they were constructed by a global Object function
    Ctor = Object.hasOwnProperty.call(proto, 'constructor') && proto.constructor;
    return typeof Ctor === 'function' && Object.toString.call(Ctor) === Object.hasOwnProperty.toString.call(Object);
  }

  static isEmptyObject(obj: any): boolean {
    return Object.getOwnPropertyNames(obj).length === 0;
  }

  static type(obj: any) {
    if (obj == null) {
      return obj + '';
    }

    // Support: Android <=2.3 only (functionish RegExp)
    return typeof obj === 'object' || typeof obj === 'function' ?
      Object[toString.call(obj)] || 'object' :
      typeof obj;
  }

}
