import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReCaptchaModule } from 'angular5-recaptcha';
import { ArtsaasPipesModule } from '../../../shared/artsaas-pipes.module';
import { TranslatePipesModule } from '../../../shared/translate-pipes.module';
import { WorkContactFormComponent } from './work-contact-form.component';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ReCaptchaModule,
    ArtsaasPipesModule,
    TranslatePipesModule,
    SharedModule,
  ],
  exports: [
    WorkContactFormComponent,
  ],
  declarations: [
    WorkContactFormComponent,
  ],
})
export class WorkContactFormModule {
}
