import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UrlService } from '../../model/url.service';
import { UniversalPlatformService } from '../../shared/platform/universal-platform.service';
import { DateDeclinationPipe } from './date-declination.pipe';

@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(
    private _urlService: UrlService,
    private _declinator: DateDeclinationPipe,
    private _platformService: UniversalPlatformService,
  ) {
  }

  transform(value: any, pattern = 'mediumDate'): string {
    if (value.includes !== undefined && value.includes(' ')) {
      value = value.split(' ')[0];
    }
    const datePipe = new DatePipe('pl');
    let date: string;

    if (this._platformService.isBrowser) {
      date = datePipe.transform(value, pattern);
    } else {
      date = value;
    }

    return this._declinator.transform(date);
  }

}
