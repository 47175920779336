import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReCaptchaModule } from 'angular5-recaptcha';
import { ArtsaasPipesModule } from '../../../shared/artsaas-pipes.module';
import { TranslatePipesModule } from '../../../shared/translate-pipes.module';
import { ContactFormComponent } from './contact-form.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ReCaptchaModule,
    ArtsaasPipesModule,
    TranslatePipesModule,
  ],
  exports: [
    ContactFormComponent,
  ],
  declarations: [
    ContactFormComponent,
  ],
})
export class ContactFormModule {
}
