import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ReCaptchaModule } from 'angular5-recaptcha';
import { TranslatePipesModule } from '../../shared/translate-pipes.module';
import { ArtsaasPipesModule } from '../../shared/artsaas-pipes.module';
import { ModalFormModule } from '../../modal-form/modal-form.module';
import { OfferFormBlockComponent } from './offer-form-block.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslatePipesModule,
    ArtsaasPipesModule,
    ReCaptchaModule,
    ModalModule,
    ModalFormModule,
  ],
  exports: [
    OfferFormBlockComponent,
  ],
  declarations: [
    OfferFormBlockComponent,
  ],
})
export class OfferFormBlockModule {
}
