import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { UniversalPlatformService } from '../shared/platform/universal-platform.service';
import { MetaData } from '../shared/metadata/metadata.service';
import { SettingDataService } from './setting-data.service';
import { SettingsModelInterface } from '../interfaces/settings-model.interface';

@Injectable()
export class GlobalService {

  public apiHost: string;

  public sugarApiHost: string;

  private update: BehaviorSubject<{ [key: string]: any }>;

  constructor(
    private _platformService: UniversalPlatformService,
    private _metadata: MetaData,
    private _settingsDataService: SettingDataService,
  ) {
    this.apiHost = environment.apiHost;
    this.sugarApiHost = environment.sugarApiHost;

    this.update = new BehaviorSubject(this.settings);

    this._settingsDataService.settingsRefresh.subscribe((settings) => {
      this.update.next(settings);
    });
  }

  get updated(): BehaviorSubject<any> {
    return this.update;
  }

  get settings(): SettingsModelInterface {
    if (!this._settingsDataService.settings.__loaded) {
      this.loadGlobalSettings();
    }

    return this._settingsDataService.settings;
  }

  loadGlobalSettings(): BehaviorSubject<{ [key: string]: any }> {
    return this._settingsDataService.refresh();
  }

}
