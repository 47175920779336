import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { ArtsaasPipesModule } from '../shared/artsaas-pipes.module';
import { ModalFormComponent } from './modal-form.component';
import { ContactFormModule } from './forms/contact-form/contact-form.module';
import { RecommendationFormModule } from './forms/recommendation-form/recommendation-form.module';
import { AuctionBidFormModule } from './forms/auction-bid-form/auction-bid-form.module';
import { AuctionAskFormModule } from './forms/auction-ask-form/auction-ask-form.module';
import { AuctionCatalogueFormModule } from './forms/auction-catalogue-form/auction-catalogue-form.module';
import { WorkContactFormModule } from './forms/work-contact-form/work-contact-form.module';
import { WorkOrderFormModule } from './forms/work-order-form/work-order-form.module';

@NgModule({
  imports: [
    CommonModule,
    ScrollToModule,
    ArtsaasPipesModule,
    ContactFormModule,
    RecommendationFormModule,
    AuctionBidFormModule,
    AuctionAskFormModule,
    AuctionCatalogueFormModule,
    WorkContactFormModule,
    WorkOrderFormModule,
    ModalModule.forRoot(),
  ],
  exports: [
    ModalFormComponent,
  ],
  declarations: [
    ModalFormComponent,
  ],
})
export class ModalFormModule {
}
