import { Injectable } from '@angular/core';

@Injectable()
export class LangService {

  private static _lang = 'en';

  public static get lang(): string {
    return LangService._lang;
  }

  public static set lang(lang: string) {
    LangService._lang = lang;
  }
}
