<section *ngIf="slides && slides?.length" class="hero-slider small-slider-block container-fluid">
    <div class="carousel-home-hero" id="hero-carousel">
        <owl-carousel [carouselClasses]="['owl-carousel', 'owl-theme']" [options]="options">
            <!-- data-owl-carousel="bottom-hero" -->

            <div *ngFor="let item of slides" class="owl-slide">
                <div [style.background-image]="sanitizer.bypassSecurityTrustStyle('url(' + item.image + ')')"
                     class="owl-item-wrapper">
                    <div class="carousel-content">
                        <div *ngIf="item.title" class="owl-item-title">{{item.title}}</div>
                        <a *ngIf="item.url" class="owl-item-link"
                           href="{{item.url}}">{{'general.more' | translate:urlService.lang}}</a>
                    </div>
                </div>
            </div>

        </owl-carousel>

        <div class="wrapper-container carousel-indicators-wrapper">
            <div class="carousel-indicators"></div>
        </div>
    </div>
</section>
