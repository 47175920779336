import { MenuItemAbstract } from './menu-item-abstract.class';
import { HydratedSubmenuItem } from './menu-item.interfaces';

export class MenuItemChild extends MenuItemAbstract {

  protected external: boolean;
  protected featured: boolean;

  constructor(
    protected hydratedItem: HydratedSubmenuItem,
  ) {
    super(hydratedItem);
  }

  get type(): string {
    return this.getValue('type');
  }

  get isFeatured(): boolean {
    return this.featured;
  }

  get contentType(): string {
    return this.hydratedItem.contentType;
  }

  get column(): number {
    return this.hydratedItem.column;
  }

  setup(hydratedItem: HydratedSubmenuItem): void {
    this.featured = false;
  }

}
