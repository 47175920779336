/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./text-image-block.component";
var styles_TextImageBlockComponent = [];
var RenderType_TextImageBlockComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TextImageBlockComponent, data: {} });
export { RenderType_TextImageBlockComponent as RenderType_TextImageBlockComponent };
function View_TextImageBlockComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "page-title text-image-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_TextImageBlockComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "page-content text-image-excerpt"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.excerpt; _ck(_v, 1, 0, currVal_0); }); }
function View_TextImageBlockComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "page-content text-image-content"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 1, 0, currVal_0); }); }
function View_TextImageBlockComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["appLazyLoad", ""], ["class", "img-fluid services-figure"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.image, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_TextImageBlockComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "section", [["class", "page-section text-image-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 10, "div", [["class", "row justify-content-between"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "col-12 col-lg-6 order-lg-2 about-services-content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TextImageBlockComponent_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TextImageBlockComponent_2)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TextImageBlockComponent_3)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [["class", "col-12 col-lg-6 order-lg-1 about-services-figure"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TextImageBlockComponent_4)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.excerpt; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.text; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.image; _ck(_v, 12, 0, currVal_3); }, null); }
export function View_TextImageBlockComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-text-image-block", [], null, null, null, View_TextImageBlockComponent_0, RenderType_TextImageBlockComponent)), i0.ɵdid(1, 4308992, null, 0, i2.TextImageBlockComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TextImageBlockComponentNgFactory = i0.ɵccf("app-text-image-block", i2.TextImageBlockComponent, View_TextImageBlockComponent_Host_0, { title: "title", excerpt: "excerpt", text: "text", image: "image", context: "context" }, { viewInit: "viewInit" }, []);
export { TextImageBlockComponentNgFactory as TextImageBlockComponentNgFactory };
