import * as tslib_1 from "tslib";
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { UrlService } from '../model/url.service';
import { ComponentsService } from '../model/components.service';
import { SugarService } from '../model/sugar.service';
import { GlobalService } from '../model/global.service';
import { BodyService } from '../shared/platform/body.service';
import { UniversalPlatformService } from '../shared/platform/universal-platform.service';
import { UniversalRendererHelper } from '../shared/platform/universal-renderer.helper';
import { MetaData } from '../shared/metadata/metadata.service';
import { AutoUnsubscribe } from '../decorators/autounsubscribe.decorator';
var TextPageComponent = /** @class */ (function () {
    function TextPageComponent(domSanitizer, urlService, globalService, bodyService, platformService, renderer, componentsService, sugarService, rootComp, metadata, http, router, route) {
        this.domSanitizer = domSanitizer;
        this.urlService = urlService;
        this.globalService = globalService;
        this.bodyService = bodyService;
        this.platformService = platformService;
        this.renderer = renderer;
        this.componentsService = componentsService;
        this.sugarService = sugarService;
        this.rootComp = rootComp;
        this.metadata = metadata;
        this.http = http;
        this.router = router;
        this.route = route;
        this.blocksInitializing = true;
        this.displayPreloader = true;
        this.viewInitialized = false;
    }
    Object.defineProperty(TextPageComponent.prototype, "title", {
        get: function () {
            return this.page.title;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextPageComponent.prototype, "content", {
        get: function () {
            return this.page.content;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextPageComponent.prototype, "contentSanitized", {
        get: function () {
            return this.page.sanitized.content;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextPageComponent.prototype, "hasTopImage", {
        get: function () {
            return (this.page.image || []).length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextPageComponent.prototype, "topImage", {
        get: function () {
            return this.page.image;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextPageComponent.prototype, "showPreloader", {
        get: function () {
            return this.displayPreloader && this.blocksInitializing;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextPageComponent.prototype, "contentType", {
        get: function () {
            return this.page.type;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextPageComponent.prototype, "slug", {
        get: function () {
            return this.page.slug;
        },
        enumerable: true,
        configurable: true
    });
    TextPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initialize();
        this.rootComp.fbService.updateCommonOg();
        this.sub = this.componentsService.getRootComponents().subscribe(function (data) {
            var apiData = data ? data : null;
            if (apiData.main_contents) {
                var mc_1 = apiData.main_contents;
                _this.displayPreloader = true;
                clearTimeout(_this.updateTimeout);
                _this.updateTimeout = setTimeout(function () {
                    _this.page.image = mc_1.image && (mc_1.image || []).length ? mc_1.image : '';
                    _this.page.title = mc_1.title ? mc_1.title[_this.urlService.lang] : '';
                    _this.page.content = mc_1.text ? mc_1.text[_this.urlService.lang] : '';
                    _this.page.slug = mc_1.slug ? mc_1.slug[_this.urlService.lang] : '';
                    _this.page.type = mc_1.content_type ? mc_1.content_type : 'nieznany';
                    _this.page.sanitized.title = _this.page.title ? _this.domSanitizer.bypassSecurityTrustHtml(_this.page.title) : '';
                    _this.page.sanitized.content = _this.page.content ? _this.domSanitizer.bypassSecurityTrustHtml(_this.page.content) : '';
                    _this.page.sanitized.image = _this.page.image ? _this.domSanitizer.bypassSecurityTrustUrl(_this.page.image) : '';
                    _this.displayPreloader = false;
                    _this.updateBodyClass();
                }, 100);
                // meta
                var metaData = {
                    title: typeof mc_1.title !== 'undefined' ? mc_1.title : '',
                    description: typeof mc_1.meta_description !== 'undefined' ? mc_1.meta_description : '',
                    keywords: typeof mc_1.meta_keywords !== 'undefined' ? mc_1.meta_keywords : '',
                };
                if (_this.hasTopImage) {
                    metaData.ogImage = mc_1.image;
                }
                _this.metadata.setTextPageMeta(metaData);
            }
        });
        this.updateBodyClass();
    };
    TextPageComponent.prototype.ngOnDestroy = function () {
        this.sub.unsubscribe();
        clearTimeout(this.updateTimeout);
    };
    TextPageComponent.prototype.ngAfterViewInit = function () {
        this.viewInitialized = true;
        this.callViewInit();
    };
    TextPageComponent.prototype.onBlocksInit = function () {
        this.blocksInitializing = false;
        this.callViewInit();
    };
    TextPageComponent.prototype.initialize = function () {
        this.page = {
            title: '',
            content: '',
            image: '',
            type: '',
            slug: '',
            sanitized: {
                title: '',
                content: '',
                image: '',
            },
            extra: {},
        };
        this.classes = {
            basic: 'text-page',
            withImage: ['main-at-top', 'has-hero-image'],
        };
    };
    TextPageComponent.prototype.updateBodyClass = function () {
        var _this = this;
        setTimeout(function () {
            _this.bodyService.resetAndAddClass(_this.classes.basic);
            if (_this.hasTopImage) {
                _this.bodyService.addClass(_this.classes.withImage);
            }
        });
    };
    TextPageComponent.prototype.callViewInit = function () {
        if (!this.platformService.isBrowser) {
            return;
        }
        if (!this.blocksInitializing && this.viewInitialized) {
            this.platformService.document.dispatchEvent(new Event('view-initialized'));
        }
    };
    TextPageComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [DomSanitizer,
            UrlService,
            GlobalService,
            BodyService,
            UniversalPlatformService,
            UniversalRendererHelper,
            ComponentsService,
            SugarService,
            AppComponent,
            MetaData,
            HttpClient,
            Router,
            ActivatedRoute])
    ], TextPageComponent);
    return TextPageComponent;
}());
export { TextPageComponent };
