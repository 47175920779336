import { BehaviorSubject, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { ObjectUtils } from '../shared/utils/object-utils';
import { HttpClient } from '@angular/common/http';
import { LangService } from './lang.service';
var SettingDataService = /** @class */ (function () {
    function SettingDataService(http) {
        this.http = http;
        this.defaults = {
            __loaded: false,
        };
        this.data = ObjectUtils.copy(this.defaults);
        this.dataRefresh = new BehaviorSubject(this.data);
    }
    Object.defineProperty(SettingDataService.prototype, "settings", {
        get: function () {
            return this.data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SettingDataService.prototype, "settingsRefresh", {
        get: function () {
            return this.dataRefresh;
        },
        enumerable: true,
        configurable: true
    });
    SettingDataService.prototype.ngOnInit = function () {
        if (!this.data.__loaded) {
            this.refresh();
        }
    };
    SettingDataService.prototype.ngOnDestroy = function () {
        if (this.refreshSub) {
            this.refreshSub.unsubscribe();
        }
    };
    SettingDataService.prototype.refresh = function () {
        var _this = this;
        if (this.isRefreshing) {
            return this.dataRefresh;
        }
        this.isRefreshing = true;
        this.loadFromApi().subscribe(function (settings) {
            _this.isRefreshing = false;
            _this.refreshedSettings(settings);
        });
        return this.dataRefresh;
    };
    SettingDataService.prototype.refreshedSettings = function (settings) {
        if (typeof settings === 'undefined' || !settings) {
            this.data = ObjectUtils.copy(this.defaults);
            return;
        }
        this.data = ObjectUtils.copy(settings);
        this.data.__loaded = true;
        this.dataRefresh.next(this.data);
    };
    SettingDataService.prototype.loadFromApi = function () {
        var _this = this;
        var url = environment.apiHost + '/settings?lang=' + LangService.lang;
        var params = {};
        return this.http.get(url, params).pipe(catchError(function (error) {
            return _this.handleError(error, url, params);
        }));
    };
    SettingDataService.prototype.handleError = function (error, url, params) {
        var errorMessage;
        if (error.status === 0) {
            errorMessage = {
                success: false,
                status: 0,
                data: 'Sorry, there has an connection error occurred while trying to load settings from API. Please try again.',
                url: url,
                params: params,
            };
        }
        else if (error.json) {
            errorMessage = error;
        }
        else {
            errorMessage = {
                success: false,
                status: error.status,
                data: error.message,
                url: url,
                params: params,
            };
        }
        return observableThrowError(errorMessage);
    };
    return SettingDataService;
}());
export { SettingDataService };
