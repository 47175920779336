var MenuItemAbstract = /** @class */ (function () {
    function MenuItemAbstract(hydratedItem) {
        this.hydratedItem = hydratedItem;
        this.setup(hydratedItem);
    }
    Object.defineProperty(MenuItemAbstract.prototype, "item", {
        get: function () {
            return this.hydratedItem;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuItemAbstract.prototype, "index", {
        get: function () {
            return this.menuIndex;
        },
        set: function (index) {
            this.menuIndex = index;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuItemAbstract.prototype, "title", {
        get: function () {
            return this.getValue('title');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuItemAbstract.prototype, "url", {
        get: function () {
            return this.getValue('url');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuItemAbstract.prototype, "link", {
        get: function () {
            return this.hydratedItem.link;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuItemAbstract.prototype, "params", {
        get: function () {
            return this.hydratedItem.params;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuItemAbstract.prototype, "isExternal", {
        get: function () {
            return this.hydratedItem.external;
        },
        enumerable: true,
        configurable: true
    });
    MenuItemAbstract.prototype.getValue = function (name) {
        return typeof this.hydratedItem[name] !== 'undefined' ? this.hydratedItem[name] : '';
    };
    return MenuItemAbstract;
}());
export { MenuItemAbstract };
