import { BehaviorSubject, Subject, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { UrlService } from './url.service';
import { UniversalPlatformService } from '../shared/platform/universal-platform.service';
import { HttpClient } from '@angular/common/http';
var CmsService = /** @class */ (function () {
    function CmsService(_http, _urlService, _globalService, _platformService) {
        var _this = this;
        this._http = _http;
        this._urlService = _urlService;
        this._globalService = _globalService;
        this._platformService = _platformService;
        this.contents = {};
        this.menu = [];
        this.requestError = new Subject();
        _urlService.langChange.subscribe(function () {
            _this.refresh();
        });
        this._menuItems = new BehaviorSubject(null);
    }
    Object.defineProperty(CmsService.prototype, "menuItems", {
        get: function () {
            return this._menuItems;
        },
        enumerable: true,
        configurable: true
    });
    CmsService.prototype.getMenu = function () {
        var _this = this;
        var langParam = this._urlService.lang;
        var url = this._globalService.apiHost + '/menu?lang=' + langParam;
        var params = {};
        return this._http.get(url, params).pipe(map(function (response) { return response; }), map(function (response) {
            return response;
        }), catchError(function (error) {
            return _this.handleError(error, url, params);
        }));
    };
    CmsService.prototype.refresh = function () {
        var _this = this;
        this.getMenu().subscribe(function (menu) {
            _this._platformService.localStorage
                .setItem('menu', JSON.stringify(menu), _this._platformService.localStorage.countMinutes(2));
            var client = _this._globalService.apiHost.split('.')[0].split('//')[1];
            // if (this._urlService.lang === 'en' && client === 'wejmangallery') {
            //   menu.splice(1, 0, {
            //     children: [],
            //     content_type: 'products',
            //     parameters: { category_id: '2' },
            //     target: '_self',
            //     title: 'Painting',
            //     url: 'offer',
            //   });
            // }
            menu = menu.filter(function (item, index) {
                var _item = JSON.stringify(item);
                return index === menu.findIndex(function (obj) {
                    return JSON.stringify(obj) === _item;
                });
            });
            _this.menu = menu;
            _this._menuItems.next(menu);
        });
    };
    CmsService.prototype.handleError = function (error, url, params) {
        var errorMessage;
        if (error.status === 0) {
            errorMessage = {
                success: false,
                status: 0,
                data: 'Sorry, there was a connection error. Please try again.',
                url: url,
                params: params,
            };
        }
        else if (error.json) {
            errorMessage = error;
        }
        else {
            errorMessage = {
                success: false,
                status: error.status,
                data: error.message,
                url: url,
                params: params,
            };
        }
        this.requestError.next(errorMessage);
        return observableThrowError(errorMessage);
    };
    return CmsService;
}());
export { CmsService };
