import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Utils } from '../utils.class';

@Pipe({
  name: 'keepHtml',
  pure: false,
})
export class EscapeHtmlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(content, decodeEntities?: boolean): SafeHtml {
    if (decodeEntities) {
      content = Utils.htmlEntityDecode(content);
    }

    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

}
