import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '../translate-pipes/translate.pipe';

@Pipe({
  name: 'workDates',
  pure: true,
})
export class WorkDatesPipe implements PipeTransform {
  constructor(
    private translatePipe: TranslatePipe,
  ) {
  }

  transform(work: any, langCode = 'pl_pl'): string {
    const lang = langCode.substring(0, 2);

    let dates = '';

    if ((work.is_year_text === 1 || work.is_year_text === '1') && work.year_text[langCode]) {

      dates = work.year_text[langCode];

    } else {

      if (work.year) {
        dates += (
          work.year_circa === 1 || work.year_circa === '1' ?
            this.translatePipe.transform('artists.circa-abbr', lang) + ' ' :
            ''
        ) + work.year;
      }

      if (work.year_to) {
        if ((work.year || []).length === 0) {
          dates += '?';
        }

        dates += ' - ';
        dates += (
          work.year_circa === '1' || work.year_circa === 1 ?
            this.translatePipe.transform('artists.circa-abbr', lang) + ' ' :
            ''
        ) + work.year_to;
      }
      if (dates.trim().length) {
        dates += ' ' + this.translatePipe.transform('offer.year-abbr', lang);
      }
      dates = dates.trim();
    }

    return dates;
  }

}
