import { filter } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { UrlUtils } from './utils/url-utils';

@Injectable()
export class QueryService implements OnDestroy {

  public routerSub: Subscription;

  public params: { [key: string]: any };

  public state: BehaviorSubject<{ [key: string]: any }>;
  public change: Subject<{ [key: string]: any }>;

  constructor(
    private router: Router,
  ) {
    const extr = UrlUtils.extractUrlParams(router.routerState.snapshot.url);
    this.params = extr.params;

    this.routerSub = this.router.events.pipe(
      filter(evt => evt instanceof NavigationEnd))
      .subscribe((evt) => {
        const data = <{ id: number, url: string, urlAfterRedirects: string }>evt;
        const extracted = UrlUtils.extractUrlParams(data.url);

        this.params = extracted.params;

        this.change.next(this.params);
        this.state.next(this.params);
      });

    this.state = new BehaviorSubject(this.params);
    this.change = new Subject();
  }

  ngOnDestroy(): void {
    this.routerSub.unsubscribe();
  }

}
