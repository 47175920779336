import * as tslib_1 from "tslib";
import { MenuItemChild } from './menu-item-child.class';
var MenuItemFeatured = /** @class */ (function (_super) {
    tslib_1.__extends(MenuItemFeatured, _super);
    function MenuItemFeatured(hydratedItem, remoteObject) {
        var _this = _super.call(this, hydratedItem) || this;
        _this.hydratedItem = hydratedItem;
        _this.remoteObject = remoteObject;
        _this.valid = remoteObject !== null;
        _this.featured = true;
        return _this;
    }
    Object.defineProperty(MenuItemFeatured.prototype, "isValid", {
        get: function () {
            return this.valid;
        },
        enumerable: true,
        configurable: true
    });
    MenuItemFeatured.prototype.setup = function (hydratedItem) {
        this.featured = false;
    };
    return MenuItemFeatured;
}(MenuItemChild));
export { MenuItemFeatured };
