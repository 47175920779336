import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { UrlService } from '../../model/url.service';
import { CmsPageMetaData, CollectionItemPageMetaData, CollectionPageMetaData, PageMetaData } from './metadata.interfaces';
import { Lang } from '../../interfaces/settings-model.interface';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class MetaData {

  private _siteTitle: string;
  private _pageTitle: string;
  private _separator = '|';

  private static _determineOgProp(name: string): string {
    if (name.indexOf('fb:') === 0 || name.indexOf('og:') === 0) {
      return name;
    }

    return 'og:' + name;
  }

  private static _sanitizeTagContent(content: string): string {
    return content.replace(/<[^>]*>/g, '').trim();
  }

  constructor(
    private _urlService: UrlService,
    private _title: Title,
    private _meta: Meta,
    @Inject(DOCUMENT) private document,
  ) {
  }

  setTitle(pageTitle?: string | { [key: string]: string }): void {
    this._pageTitle = this._getContentValue(pageTitle);
    this.updateTitle();
  }

  updateTitle(): void {
    let title = '';

    if (this._pageTitle) {
      title = this._pageTitle + ' ' + this._separator + ' ';
    }

    if (this._siteTitle && (this._siteTitle || '').length) {
      title += this._siteTitle;
    }

    if ((title || '').length) {
      this._title.setTitle(title);
    } else {
      console.error('tytuł strony ma zerową długość');
    }
  }

  setSiteTitle(title: string | Lang | { [key: string]: string; }): void {
    this._siteTitle = this._getContentValue(title);
    this.updateTitle();
  }

  getTitle(): string {
    return this._title.getTitle();
  }

  setDescription(content: string | { [key: string]: string }): void {
    this.setTag('description', content);
  }

  setKeywords(content: string | { [key: string]: string }): void {
    this.setTag('keywords', content);
  }

  setTag(name: string | MetaDefinition, content?: string | { [key: string]: string }): void {
    const tag = typeof name === 'string' ? this.getTag(name) : this.getTag(name.name);
    let metaTag: MetaDefinition;

    if (typeof name === 'string') {
      const cnt = this._getContentValue(content);

      metaTag = {
        name: name,
        content: cnt,
      };

    } else {
      metaTag = name;
    }

    if ('title' === metaTag.name) {
      this.setTitle(metaTag.content);
      return;
    }

    if ('meta_title' === metaTag.name) {
      this.setSiteTitle(metaTag.content);
      return;
    }

    metaTag.content = MetaData._sanitizeTagContent(metaTag.content);

    if (tag === null) {
      this._meta.addTag(metaTag);
    } else {
      this._meta.updateTag(metaTag);
    }
  }

  getTag(which: string): HTMLMetaElement | MetaDefinition | null {
    if ('title' === which) {
      return {
        name: 'title',
        content: this.getTitle(),
      };
    }

    const selector = 'name=\'' + which + '\'';
    return this._meta.getTag(selector);
  }

  getTagContent(which: string): string {
    const tag = this.getTag(which);

    if (null === tag) {
      return '';
    }

    return typeof tag.content !== 'undefined' ? tag.content : '';
  }

  setOg(which: string, content: string | { [key: string]: string }): void {
    if (!content || !String(content).length) {
      return;
    }

    const ogProp = MetaData._determineOgProp(which);
    const tag = this.getOg(ogProp);

    const cnt = this._getContentValue(content);

    if (null === tag) {
      this._meta.addTag({
        property: ogProp,
        content: cnt,
      });
    } else {
      this._meta.updateTag({
        property: ogProp,
        content: cnt,
      });
    }
  }

  getOg(which: string): HTMLMetaElement | null {
    const ogProp = MetaData._determineOgProp(which);
    return this._meta.getTag('property=\'' + ogProp + '\'');
  }

  setFbAppID(content: string): void {
    this.setOg('fb:app_id', content);
  }

  setTextPageMeta(meta: CmsPageMetaData): void {
    const pageMeta = this._grabCommonMetaData(meta);
    this.setPageMeta(pageMeta);
  }

  setCollectionPageMetaFromMainContents(mainContents: { [key: string]: any }): void {
    const metaData: CollectionPageMetaData = {
      title: typeof mainContents.title !== 'undefined' ? mainContents.title : '',
      description: typeof mainContents.meta_description !== 'undefined' ? mainContents.meta_description : '',
      keywords: typeof mainContents.meta_keywords !== 'undefined' ? mainContents.meta_keywords : '',
    };

    const pageMeta: PageMetaData = this._grabCommonMetaData(metaData);
    this.setPageMeta(pageMeta);
  }

  setItemPageMetaFromProperties(item: { [key: string]: any }, imageSrc: 'images' | 'image' | 'banner' = 'images'): void {
    const metaData: CollectionItemPageMetaData = {
      title: typeof item.meta_title !== 'undefined' ? item.meta_title : '',
      description: typeof item.meta_description !== 'undefined' ? item.meta_description : '',
      keywords: typeof item.meta_keywords !== 'undefined' ? item.meta_keywords : '',
    };

    if (imageSrc === 'images') {

      if (item[imageSrc] && Array.isArray(item[imageSrc]) && (item[imageSrc] || []).length > 0) {
        metaData.ogImage = item[imageSrc][0] ? item[imageSrc][0].thumbnails.original.url : 'assets/images/placeholder-450x340.jpg';
      }

    } else if (imageSrc === 'image') {

      if (item[imageSrc]) {
        metaData.ogImage = item[imageSrc].url;
      } else { // fallback
        metaData.ogImage = item.images && Array.isArray(item.images) && (item.images || []).length > 0
          ? item.images[0].thumbnails.original.url
          : null;
      }

    } else if (imageSrc === 'banner') {

      if (typeof item.banner === 'object' && item.banner !== null && item.banner.thumbnails) {
        metaData.ogImage = item.banner.thumbnails.original.url;
      } else if (item.image && item.image.url) { // fallback 1
        metaData.ogImage = item.image.url;
      } else if ((item.images || []).length) { // fallback 2
        metaData.ogImage = item.images[0] ? item.images[0].thumbnails.original.url : 'assets/images/placeholder-450x340.jpg';
      }

    }

    const pageMeta: PageMetaData = this._grabCommonMetaData(metaData);
    this.setPageMeta(pageMeta);
  }

  setPageMeta(meta: PageMetaData): void {

    // tytuł
    const title = this._determineValue(meta, 'title');

    if ((title || '').length) {
      this.setTitle(title);

      // if (typeof meta.ogTitle === "undefined" && (title||[]).length) {
      //   meta.ogTitle = title;
      // }
    }

    // opis
    const desc = this._determineValue(meta, 'description');
    this.setDescription(desc);

    if (typeof meta.ogDesc === 'undefined' && (desc || []).length) {
      meta.ogDesc = desc;
    }

    // słowa kluczowe
    const kw = this._determineValue(meta, 'keywords');
    this.setKeywords(kw);

    // tagi OG
    const ogTitle = this._determineValue(meta, 'ogTitle');

    if ((ogTitle || []).length) {
      this.setOg('title', ogTitle);
    } else {
      this.setOg('title', this.getTitle());
    }

    const ogDesc = this._determineValue(meta, 'ogDesc');
    this.setOg('description', ogDesc);

    const ogImage = this._determineValue(meta, 'ogImage');
    this.setOg('image', ogImage);

    this.updateLangTags();

    this.updateCanonicalURL();
  }

  private updateLangTags() {
    this.removeLangTags();
    if (this._urlService.isMultilingual()) {
      const langSlugs: any[] = [];
      this._urlService.allowedLanguages.forEach(lang => {
        langSlugs.push({
          lang: lang.lang,
          slug: this._urlService.getCurrentSiteSlugByLang(lang.lang).slug,
        });
      });
      this.createLangTags(langSlugs);
    }
  }

  private createLangTags(langSlugs: { lang: string; slug: string }[]) {
    langSlugs.forEach(item => {
      const link: HTMLLinkElement = this.document.createElement('link');
      link.setAttribute('rel', 'alternate');
      link.setAttribute('hreflang', item.lang);
      link.setAttribute('href', item.slug);
      this.document.head.appendChild(link);
    });
  }

  private removeLangTags() {
    const alternateNodes: any = this.document.querySelectorAll('link[rel ="alternate"]');
    if (alternateNodes && alternateNodes.length) {
      alternateNodes.forEach(node => {
        node.parentElement.removeChild(node);
      });
    }
  }

  private updateCanonicalURL() {
    this.removeCanonicalURL();
    this.createCanonicalURL();
  }

  private createCanonicalURL() {
    const link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.document.head.appendChild(link);
    link.setAttribute('href', this.document.URL);
  }

  private removeCanonicalURL() {
    const canonical: any = this.document.querySelectorAll('link[rel ="canonical"]');
    if (canonical && canonical.length) {
      canonical[0].parentElement.removeChild(canonical[0]);
    }
  }

  private _determineValue(meta: PageMetaData, what: string): string {
    let content = '';
    let metaProp: any;

    if (typeof meta[what] !== 'undefined') {
      metaProp = meta[what];
      content = this._getContentValue(metaProp);
    }

    return content;
  }

  private _getContentValue(content: string | Lang | { [key: string]: string; } | null): string {
    let cnt = '';

    if (typeof content === 'undefined' || content === null) {
      cnt = '';
    } else if (typeof content === 'string') {
      cnt = content;
    } else {
      if (typeof content[this._urlService.lang] === 'string') {
        cnt = content[this._urlService.lang];
      } else if (typeof content[this._urlService.langCode] === 'string') {
        cnt = content[this._urlService.langCode];
      }
    }

    cnt = MetaData._sanitizeTagContent(cnt);
    return cnt;
  }

  private _grabCommonMetaData(meta: PageMetaData): { [key: string]: any } {
    const commonKeys = ['title', 'description', 'keywords', 'ogTitle', 'ogDesc', 'ogImage'];
    const commonMeta: { [key: string]: any } = {};

    let i = 0;
    let key: string;
    let metaKey: string;

    for (; i < (commonKeys || []).length; ++i) {
      key = commonKeys[i];
      metaKey = 'meta_' + key;

      if (typeof meta[metaKey] !== 'undefined') {
        commonMeta[key] = meta[metaKey];
      }

      if (!commonMeta[key] && typeof meta[key] !== 'undefined') {
        commonMeta[key] = meta[key];
      }
    }

    return commonMeta;
  }

}
