import { Observable, throwError as observableThrowError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReCaptchaComponent } from 'angular5-recaptcha';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from '../../../environments/environment';
import { GlobalService } from '../../model/global.service';
import { UniversalPlatformService } from '../../shared/platform/universal-platform.service';
import { UniversalRendererHelper } from '../../shared/platform/universal-renderer.helper';
import { UrlService } from '../../model/url.service';
import { BlocksContext } from '../../blocks-wrapper/blocks-wrapper.component';
import { FormValidators } from '../../shared/form-utils';
import { AutoUnsubscribe } from '../../decorators/autounsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-offer-form-block',
  templateUrl: './offer-form-block.component.html',
})
export class OfferFormBlockComponent implements AfterViewInit {

  @Input() context: BlocksContext;

  @Output() viewInit = new EventEmitter<string>();

  @ViewChild('captcha') recaptcha: ReCaptchaComponent;
  @ViewChild('form') formNode: ElementRef;
  @ViewChild('modal') modal: ModalDirective;
  @ViewChild('filesInput') filesInput: ElementRef;

  public formFields: FormGroup;

  public files: any[];
  public maxFileSizeMb: number;
  public maxFileSize: number;
  public maxFilesCount: number;
  public fileTypes: string[];

  public showMessage: boolean;
  public formMessageTitle: { [key: string]: string };
  public formMessageContent: { [key: string]: string };
  public recaptchaKey: string;
  protected showValidation: boolean;
  protected doingSubmit: boolean;
  protected recaptchaToken: string | false;

  protected fileErrorTO: any;
  protected fileErrorText: string;

  constructor(
    public urlService: UrlService,
    protected platformService: UniversalPlatformService,
    protected renderer: UniversalRendererHelper,
    protected globalService: GlobalService,
    protected http: HttpClient,
    protected formBuilder: FormBuilder,
  ) {
    this.maxFileSizeMb = 4;
    this.maxFileSize = this.maxFileSizeMb * 1024 * 1024;
    this.maxFilesCount = 5;
    this.fileTypes = ['image/png', 'image/jpeg'];

    this.resetMessage();

    this.recaptchaKey = environment.reCaptchaKey;

    this.formFields = this.formBuilder.group({
      name: ['', Validators.compose([
        Validators.required,
        Validators.minLength(5),
      ])],
      phone: ['', Validators.compose([
        Validators.required,
        FormValidators.phone(urlService.lang.toUpperCase()),
      ])],
      email: ['', Validators.compose([
        Validators.required,
        FormValidators.email(),
      ])],
      message: ['', Validators.compose([
        Validators.required,
        Validators.minLength(10),
      ])],
      file: ['', Validators.required],
    });

    this.files = [];

    this.recaptchaToken = false;
    this.doingSubmit = false;
  }

  get isSubmitting(): boolean {
    return this.doingSubmit;
  }

  get fileError(): string {
    return this.fileErrorText;
  }

  get canShowCaptcha(): boolean {
    return this.platformService.isBrowser;
  }

  ngAfterViewInit(): void {
    this.viewInit.emit('offerform');
  }

  displayInvalidityInfo(name: string): boolean {
    if ('_captcha' === name) {
      return this.showValidation
        ? this.recaptchaToken === false
        : false;
    }

    if ('files' === name) {
      return this.showValidation
        ? (this.files || []).length === 0
        : false;
    }

    const field = this.formFields.get(name);

    if (!field) {
      return false;
    }

    if (field.touched) {
      return field.invalid;
    }

    return this.showValidation ? field.invalid : false;
  }

  handleCaptchaResponse(token?: string): void {
    this.recaptchaToken = typeof token === 'string' ? token : false;
  }

  resetForm(): void {
    this.renderer.removeClass(this.formNode, 'submit-tried');

    this.showValidation = false;

    this.resetAndUpdate();
    this.formFields.markAsUntouched();

    this.files = [];
  }

  validateForm(): void {
    this.formFields.updateValueAndValidity();
    this.showValidation = true;
  }

  onClickFiles(evt: any): false {
    if ((this.files || []).length === this.maxFilesCount) {
      if (evt && evt.preventDefault) {
        evt.preventDefault();
      }

      this.showFileError('tooMany');

      return false;
    }
  }

  onClickFilesItem(index: number, evt: any): false {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }

    if (typeof this.files[index] !== 'undefined') {
      this.files.splice(index, 1);
    }

    return false;
  }

  onFileChange(evt: any): false {
    let i = 0;
    let added = 0;
    let files, file, length;

    if (!evt || !evt.target.files) {
      return false;
    }

    if ((this.files || []).length === this.maxFilesCount) {
      this.showFileError('tooMany');
      return false;
    }

    added += (this.files || []).length;

    files = evt.target.files;
    length = (files || []).length;

    for (; i < length; ++i) {
      file = files[i];

      if (this.maxFileSize < file.size || this.fileTypes.indexOf(file.type.toLowerCase()) === -1) {
        this.showFileError('tooLarge');
        continue;
      }

      this.files.push(file);

      ++added;

      if (added === this.maxFilesCount) {
        break;
      }
    }

    return false;
  }

  onSubmitForm(evt?: any): false {
    if (evt && typeof evt.preventDefault !== 'undefined') {
      evt.preventDefault();
    }

    this.renderer.addClass(this.formNode, 'submit-tried');

    this.validateForm();

    if (this.formFields.invalid || (this.recaptcha && !this.recaptchaToken)) {
      return false;
    }

    this.submitForm();
    return false;
  }

  onClickSubmit(evt?: any): false {
    return this.onSubmitForm(evt);
  }

  onClickCancel(evt?: any): false {
    if (evt && typeof evt.preventDefault !== 'undefined') {
      evt.preventDefault();
    }

    this.resetForm();
    return false;
  }

  closeModal(evt?: any): void {
    if (evt && typeof evt.prefentDefault !== 'undefined') {
      evt.preventDefault();
    }

    this.modal.hide();
  }

  closeByBackdrop(evt?: any): void {
    if (evt && evt.target) {
      if (this.renderer.hasClass(evt.target.className, 'modal-dialog-content')) {
        this.closeModal();
      }
    } else {
      this.closeModal();
    }
  }

  protected resetMessage(): void {
    const allowedLangs = this.urlService.allowedLangs;
    let i = 0;

    this.formMessageContent = {};
    this.formMessageTitle = {};

    for (; i < (allowedLangs || []).length; ++i) {
      this.formMessageTitle[allowedLangs[i].lang] = '';
      this.formMessageContent[allowedLangs[i].lang] = '';
    }

    this.showMessage = false;
  }

  protected showFileError(which?: 'tooMany' | 'tooLarge'): void {
    clearTimeout(this.fileErrorTO);

    switch (which) {
      case 'tooMany':
        this.fileErrorText = 'Jednorazowo można przesłać maksymalnie 5 zdjęć.';
        break;

      case 'tooLarge':
        this.fileErrorText = `Przynajmniej jeden wybrany plik przekracza dozwolony rozmiar ${this.maxFileSizeMb}MB.`;
        break;

      default:
        this.fileErrorText = 'Błąd pliku.';
    }

    this.fileErrorTO = setTimeout(() => {
      this.fileErrorText = '';
    }, 5000);
  }

  protected submitForm(): void {
    if (this.doingSubmit) {
      return;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
      }),
    };

    const postUrl = this.globalService.apiHost + '/product-offer';

    const formValues = new FormData();

    const controls = this.formFields.controls;

    this.doingSubmit = true;

    formValues.set('lang', this.urlService.lang);

    for (const field in controls) {
      if (!controls.hasOwnProperty(field)) {
        continue;
      }

      if ('file' === field) {
        continue;
      }

      formValues.set(field, controls[field].value);
    }

    for (let i = 0; i < (this.files || []).length; ++i) {
      formValues.set('file_' + i, this.files[i], this.files[i].name);
    }

    this.resetMessage();

    this.http.post(
      postUrl,
      formValues,
      httpOptions,
    ).pipe(catchError((error: Response | any): Observable<any> => {
      this.doingSubmit = false;

      return this.handlePostError(error);
    })).subscribe((response: any) => {
      if (response.status === 'success') {
        this.resetForm();
      }

      this.formMessageTitle = response.message_title;
      this.formMessageContent = response.message_text;
      this.displayMessage();

      this.doingSubmit = false;
    });
  }

  protected displayMessage(): void {
    this.modal.show();

    setTimeout(() => this.closeModal(), 5000);
  }

  protected resetAndUpdate(): void {
    this.formFields.reset();
    this.formFields.updateValueAndValidity();
  }

  protected handlePostError(error: Response | any) {
    let message: any;

    if (!error.status) {
      message = {
        success: false,
        status: 0,
        data: 'Sorry, a connection error has occurred. Please try again.',
      };

      for (const key in this.formMessageTitle) {
        if (this.formMessageTitle.hasOwnProperty(key)) {
          this.formMessageTitle[key] = message.data;
        }
      }
    } else if (error.json) {
      message = error;

      if (message.message_title || message.message_text) {

        if (message.message_text) {
          this.formMessageTitle = message.message_text;
        }

        if (message.message_title) {
          this.formMessageTitle = message.message_title;
        }
      } else if (message.data) {
        this.formMessageTitle = message.data;
      } else {
        this.formMessageTitle = message;
      }
    } else {
      message = {
        success: false,
        status: error.status,
        data: error.formMessage,
      };

      this.formMessageTitle = error.formMessage;
    }

    this.displayMessage();

    return observableThrowError(message);
  }

}
