// import {UrlService} from '../../../model/url.service';
import { HydratedMenuItemBase } from './menu-item.interfaces';

export abstract class MenuItemAbstract {

  protected menuIndex: number;

  protected constructor(
    protected hydratedItem: HydratedMenuItemBase,
  ) {
    this.setup(hydratedItem);
  }

  get item(): HydratedMenuItemBase {
    return this.hydratedItem;
  }

  get index(): number {
    return this.menuIndex;
  }

  set index(index: number) {
    this.menuIndex = index;
  }

  get title(): string {
    return this.getValue('title');
  }

  get url(): string {
    return this.getValue('url');
  }

  get link(): string {
    return this.hydratedItem.link;
  }

  get params(): { [key: string]: any } {
    return this.hydratedItem.params;
  }

  get isExternal(): boolean {
    return this.hydratedItem.external;
  }

  getValue(name: string): string {
    return typeof this.hydratedItem[name] !== 'undefined' ? this.hydratedItem[name] : '';
  }

  protected abstract setup(item: HydratedMenuItemBase): void;

}
