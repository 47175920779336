<!-- TEMPLATKI -->
<!-- submenu - lista -->
<ng-template #submenuList let-menuItem="item">
    <ul *ngIf="menuItem.colOneLength" class="submenu-list">
        <ng-container *ngFor="let childItem of menuItem.colOne; let childIndex = index">
            <ng-container *ngTemplateOutlet="submenuListItem; context: {item:childItem, itemIndex:childIndex}">
            </ng-container>
        </ng-container>
    </ul>

</ng-template>

<!-- submenu - kolumny -->
<ng-template #submenuColumns let-menuItem="item">

    <ul *ngIf="menuItem.colOneLength" class="submenu-list">
        <ng-container *ngFor="let childItem of menuItem.colOne; let childIndex = index">
            <ng-container *ngTemplateOutlet="submenuListItem; context: {item:childItem, itemIndex:childIndex}">
            </ng-container>
        </ng-container>
    </ul>

    <ul *ngIf="menuItem.colTwoLength" class="submenu-list">
        <ng-container *ngFor="let childItem of menuItem.colTwo; let childIndex = index">
            <ng-container *ngTemplateOutlet="submenuListItem; context: {item:childItem, itemIndex:childIndex}">
            </ng-container>
        </ng-container>
    </ul>

</ng-template>

<!-- submenu - element -->
<ng-template #submenuListItem let-index="itemIndex" let-item="item">
    <li class="submenu-item" title="{{item.title}}">
        <a *ngIf="item.isExternal" [attr.href]="[item.link]" class="submenu-link">{{item.title}}</a>
        <a (click)="onClickSubmenuItem()" *ngIf="! item.isExternal"
           [fragment]="item.params.archive && item.params.archive == '1' ? 'archived' : null"
           [queryParams]="item.params"
           [routerLink]="[item.link]"
           class="submenu-link">{{item.title}}</a>
    </li>

</ng-template>

<!-- submenu - specjalny -->
<ng-template #menuFeaturedItem let-item="menuItem">

    <ng-container [ngSwitch]="item.contentType">

        <div *ngSwitchCase="'auctions'" class="submenu-featured-block submenu-featured-auction">
            <span class="submenu-featured-cat">
                <ng-container
                    *ngIf="urlService.langCode == 'pl_pl'">Aktualna aukcja</ng-container>
                <ng-container
                    *ngIf="urlService.langCode == 'en_us'">Upcoming auction</ng-container>
            </span>

            <a (click)="onClickSubmenuItem()"
               [routerLink]="urlService.link('auctions', item?.hydratedItem?.featured?.remoteObject)"
               class="submenu-featured-link">
                <ng-container
                    *ngTemplateOutlet="featuredObjectImageTmpl; context:{featuredObject:item.hydratedItem?.featured}">
                </ng-container>

                <span
                    class="submenu-featured-title">{{ item.hydratedItem?.featured.remoteObject?.name[urlService.langCode] }}</span>

                <span
                    *ngIf="item.hydratedItem?.featured.remoteObject?.dates?.length" class="submenu-featured-dates">
                {{ item.hydratedItem?.featured.remoteObject?.dates[0] | localizedDate:'d MMMM yyyy' }}
                    <ng-container *ngIf="item.hydratedItem?.featured.remoteObject.dates[1]">
                            &ndash; {{ item.hydratedItem?.featured.remoteObject.dates[1] | localizedDate:'d MMMM yyyy' }}
                        , {{ item.hydratedItem?.featured.remoteObject.dates[1] | date:'H:mm' | timeFixIE }}</ng-container></span>
            </a>
        </div>

        <div *ngSwitchCase="'events'" class="submenu-featured-block submenu-featured-event">
            <span class="submenu-featured-cat">Najnowszy wpis</span>

            <a (click)="onClickSubmenuItem()"
               [routerLink]="urlService.link('news', item?.hydratedItem?.featured?.remoteObject)"
               class="submenu-featured-link">
                <ng-container
                    *ngTemplateOutlet="featuredObjectImageTmpl; context:{featuredObject:item.hydratedItem?.featured}">
                </ng-container>

                <span
                    class="submenu-featured-title">{{item.hydratedItem?.featured.remoteObject.name[urlService.langCode]}}</span>
            </a>
        </div>

        <div *ngSwitchCase="'exhibitions'" class="submenu-featured-block submenu-featured-exhibition">
            <span class="submenu-featured-cat">Aktualnie w galerii</span>
            <a (click)="onClickSubmenuItem()"
               [routerLink]="urlService.link('news', item?.hydratedItem?.featured?.remoteObject.id)"
               class="submenu-featured-link">
                <ng-container
                    *ngTemplateOutlet="featuredObjectImageTmpl; context:{featuredObject:item.hydratedItem?.featured}">
                </ng-container>

                <span
                    class="submenu-featured-title">{{item.hydratedItem?.featured.remoteObject.name[urlService.langCode]}}</span>
            </a>
        </div>

    </ng-container>

</ng-template>

<ng-template #featuredObjectImageTmpl let-featuredObject="featuredObject">
    <!-- <img appLazyLoad *ngIf="item.featuredObject.image" class="img-fluid submenu-featured-figure" src="{{item.featuredObject.image?.thumbnails?.slideshow.url}}" /> -->
    <!-- <img appLazyLoad *ngIf="!item.featuredObject.image" class="img-fluid submenu-featured-figure" src="assets/images/placeholder-450x340.jpg" /> -->
    <span [ngStyle]="{
        'background-image': 'url('+(featuredObject?.remoteObject?.banner?.thumbnails?.slideshow?.url ? featuredObject.remoteObject.banner.thumbnails.slideshow.url : 'assets/images/placeholder-450x340.jpg')+')'
    }" class="img-fluid submenu-featured-figure"></span>

</ng-template>

<ng-template #menuCustomFeaturedItem let-item="menuItem">

    <div class="submenu-featured-block submenu-featured-auction">

        <a (click)="onClickSubmenuItem()"
           [routerLink]="urlService.link('auctions', item)"
           class="submenu-featured-link">
            <ng-container
                *ngTemplateOutlet="featuredCustomObjectImageTmpl; context:{featuredObject:item}">
            </ng-container>

            <span
                class="submenu-featured-title">{{ item?.name[urlService.langCode] }}</span>
            <span
                *ngIf="item?.dates?.length" class="submenu-featured-dates">
                {{ item?.dates[0] | localizedDate:'d MMMM yyyy' }}
                <ng-container *ngIf="item.dates[1]">
                            &ndash; {{ item.dates[1] | localizedDate:'d MMMM yyyy' }}
                    , {{ item.dates[1] | date:'H:mm' | timeFixIE }}</ng-container></span>
        </a>
    </div>

</ng-template>

<ng-template #featuredCustomObjectImageTmpl let-featuredObject="featuredObject">
    <span [ngStyle]="{
        'background-image': 'url('+(featuredObject?.banner?.thumbnails?.slideshow?.url ? featuredObject?.banner?.thumbnails?.slideshow?.url : 'assets/images/placeholder-450x340.jpg')+')'
    }" class="img-fluid submenu-featured-figure"></span>

</ng-template>

<!-- MAIN -->
<header #header [ngClass]="{'single-menu-version': isSingleMenuVersion}" class="site-header fixed-top d-lg-flex"
        data-site-header>
    <nav class="navbar navbar-expand-lg navbar-items-{{cmsService.menu?.length}}">
        <div class="container">

            <a #brand (click)="scrollTop()" (transitionend)="onTransitionendBrand()"
               *ngIf="!isLogoLink"
               [class.navbar-brand-inactive]="urlService.isHome()" [routerLink]="urlService.link('')"
               class="navbar-brand order-1">
                <img *ngIf="logoLarge.url?.length" [ngStyle]="{width: logoLarge.width, height: logoLarge.height}"
                     alt="{{ globalService.settings.seo.title[urlService.lang] }}" appLazyLoad
                     class="brand-logo brand-logo-large"
                     src="{{ logoLarge.url }}"/>
                <img *ngIf="logoSmall.url?.length" [ngStyle]="{width: logoSmall.width, height: logoSmall.height}"
                     alt="{{ globalService.settings.seo.title[urlService.lang] }}" appLazyLoad
                     class="brand-logo brand-logo-small"
                     src="{{ logoSmall.url }}"/>
            </a>

            <a #brand (transitionend)="onTransitionendBrand()" *ngIf="isLogoLink"
               [attr.href]="globalService.settings.global.logoLink" class="navbar-brand order-1">
                <img *ngIf="logoLarge.url?.length" [ngStyle]="{width: logoLarge.width, height: logoLarge.height}"
                     alt="{{ globalService.settings.seo.title[urlService.lang] }}" appLazyLoad
                     class="brand-logo brand-logo-large"
                     src="{{ logoLarge.url }}"/>
                <img *ngIf="logoSmall.url?.length" [ngStyle]="{width: logoSmall.width, height: logoSmall.height}"
                     alt="{{ globalService.settings.seo.title[urlService.lang] }}" appLazyLoad
                     class="brand-logo brand-logo-small"
                     src="{{ logoSmall.url }}"/>
            </a>

            <button (click)="openMenu()" aria-controls="top-navbar-menus" aria-expanded="false"
                    aria-label="Przełącz menu"
                    class="navbar-toggler order-11" type="button">
                <span class="material-icons hamburger-icon">menu</span>
            </button>

            <div class="navbar-search d-flex d-lg-none order-2 mr-0 ml-auto">
                <a (click)="openSearchModal(); false"
                   class="navbar-search-link-custom  material-icons"
                   href="#">search</a>
            </div>

            <div #navbar (transitionend)="onTransitionEndMenu($event)" *ngIf="cmsService.menu"
                 class="navbar-collapse order-12" id="top-navbar-menus">
                <div class="navbar-menu-backdrop">
                    <button (click)="closeMenu()" aria-label="Zamknij menu" class="close d-lg-none" type="button">
                        <span class="material-icons">close</span>
                    </button>

                    <div class="navbar-menus-wrapper">
                        <div class="navbar-menu">
                            <ul class="navbar-nav">

                                <ng-container *ngFor="let menuItem of menu.items; let mIndex = index;">
                                    <li (mouseenter)="onMouseenterMenuItem($event, mIndex)"
                                        (mouseleave)="onMouseleaveMenuItem($event, mIndex)"
                                        [class.with-submenu]="menuItem.hasChildren"
                                        [ngClass]="menuItem.styles"
                                        class="nav-item nav-menu-item">
                                        <a *ngIf="!menuItem.isExternal" [queryParams]="menuItem.params"
                                           [routerLink]="menuItem.link"
                                           class="nav-link"
                                           routerLinkActive="active"
                                           title="{{menuItem.title}}"
                                        >
                                            <span (click)="onClickMenuItem($event, mIndex)"
                                                  class="nav-link-inner">{{ menuItem.title }}</span>
                                            <!-- trzeba pozwolić na otwieranie submenu -->
                                        </a>
                                        <a *ngIf="menuItem.isExternal" [attr.href]="menuItem.link" class="nav-link"
                                           title="{{menuItem.title}}">
                                            <span (click)="onClickMenuItem($event, mIndex)"
                                                  class="nav-link-inner">{{ menuItem.title }}</span>
                                            <!-- trzeba pozwolić na otwieranie submenu -->
                                        </a>

                                        <ng-container *ngIf="menuItem.hasChildren">
                                            <div #submenu (transitionend)="onTransitionEndSubmenu($event, mIndex)"
                                                 [attr.data-index]="menuItem.index" [ngClass]="{
                                                'show': isSubmenuState(mIndex, 'open'),
                                                'opening': isSubmenuState(mIndex, 'opening'),
                                                'closing': isSubmenuState(mIndex, 'closing'),
                                                'hide': isSubmenuState(mIndex, 'closed')
                                            }" class="submenu">
                                                <div class="full-width-underlay"></div>
                                                <div class="submenu-row">
                                                    <ng-container *ngIf="menuItem.columns === -1">
                                                        <ng-container *ngTemplateOutlet="submenuList; context: {
                                                            item: menuItem
                                                        }"></ng-container>
                                                    </ng-container>

                                                    <ng-container *ngIf="menuItem.columns > -1">
                                                        <ng-container *ngTemplateOutlet="submenuColumns; context: {
                                                            item: menuItem
                                                        }"></ng-container>
                                                    </ng-container>

                                                    <ng-container *ngIf="menuItem.manyFeaturedItems">
                                                        <div class="auctions-wrapper">
                                                            <span class="submenu-featured-cat">
                                                                <ng-container
                                                                    *ngIf="globalService.settings.global.auctionsSubmenuMaxCount == '1' || menuItem.manyFeaturedItemsList == 1; else manyAuctionsLabel">
                                                                    <ng-container
                                                                        *ngIf="urlService.langCode == 'pl_pl'">Aktualna aukcja</ng-container>
                                                                    <ng-container
                                                                        *ngIf="urlService.langCode == 'en_us'">Upcoming auction</ng-container>
                                                                </ng-container>
                                                                <ng-template #manyAuctionsLabel>
                                                                    <ng-container
                                                                        *ngIf="urlService.langCode == 'pl_pl'">Aktualne aukcje</ng-container>
                                                                    <ng-container
                                                                        *ngIf="urlService.langCode == 'en_us'">Upcoming auctions</ng-container>
                                                                </ng-template>
                                                            </span>
                                                            <div class="list">
                                                                <ng-container
                                                                    *ngFor=" let featured of menuItem.manyFeaturedItemsList">
                                                                    <div class="submenu-featured-wrapper">
                                                                        <ng-container *ngTemplateOutlet="menuCustomFeaturedItem; context: {
                                                                        menuItem: featured
                                                                    }"></ng-container>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container *ngIf="!menuItem.manyFeaturedItems">
                                                        <div *ngIf="menuItem.hasFeatured"
                                                             class="submenu-featured-wrapper">
                                                            <ng-container *ngTemplateOutlet="menuFeaturedItem; context: {
                                                                menuItem: menuItem
                                                            }"></ng-container>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </li>
                                </ng-container>

                            </ul>
                        </div>


                        <ul class="lang-menu d-flex">

                            <li [ngClass]="{
                            'has-separator': urlService.isMultilingual()
                        }" class="navbar-search d-none d-lg-flex">
                                <a (click)="openSearchModal(); false"
                                   class="navbar-search-link-custom  material-icons"
                                   href="#">search</a>
                            </li>
                            <ng-container *ngIf="urlService.isMultilingual()">
                                <li *ngFor="let lang of urlService.allowedLanguages" [ngClass]="{
                                'lang-item-current': urlService.lang === lang.lang
                            }" class="lang-item">
                                    <a (click)="changeLang(lang.lang); false" class="lang-link"
                                       href="#">{{ lang.lang }}</a>
                                </li>
                            </ng-container>
                        </ul>

                    </div>

                </div>
            </div>
        </div>
    </nav>
</header>
