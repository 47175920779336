import { MenuItemAbstract } from './menu-item-abstract.class';
import { MenuItemChild } from './menu-item-child.class';
import { MenuItemFeatured } from './menu-item-featured.class';
import { HydratedMenuItem } from './menu-item.interfaces';

export class MenuItem extends MenuItemAbstract {

  public styles: string[] = ['underlining', 'lowercase', 'background'];
  public manyFeaturedItemsList?: any[];
  protected hasFeaturedItem: boolean;
  protected ct: string;
  protected cols: number;
  protected colOne: MenuItemChild[];
  protected colOneLength: number;
  protected colTwo: MenuItemChild[];
  protected colTwoLength: number;

  constructor(
    protected hydratedItem: HydratedMenuItem,
    protected childItems: MenuItemChild[],
    protected featuredObj: MenuItemFeatured | null,
  ) {
    super(hydratedItem);

    this.hasFeaturedItem = false;

    this.colOne = [];
    this.colTwo = [];

    let ci = 0;
    const cLen = (childItems || []).length;

    for (; ci < cLen; ++ci) {
      if (childItems[ci].column === 0) {
        this.colOne.push(childItems[ci]);
      } else {
        this.colTwo.push(childItems[ci]);
      }
    }

    this.colOneLength = (this.colOne || []).length;
    this.colTwoLength = (this.colTwo || []).length;

    if (featuredObj && featuredObj.isValid) {
      this.hasFeaturedItem = true;
    }
  }

  get manyFeaturedItems(): boolean {
    return !!(this.manyFeaturedItemsList && (this.manyFeaturedItemsList || []).length);
  }

  get target(): string {
    return this.getValue('target');
  }

  get children(): MenuItemChild[] {
    return this.childItems;
  }

  get hasChildren(): boolean {
    return (this.childItems || []).length > 0 || this.featuredObj !== null;
  }

  get hasFeatured(): boolean {
    return this.hasFeaturedItem;
  }

  get contentType(): string {
    return this.ct;
  }

  get columns(): number {
    return this.cols;
  }

  setup(hydratedItem: HydratedMenuItem): void {
    this.ct = hydratedItem.contentType;
    this.cols = hydratedItem.columns;

  }

  // TODO: ZLIKWIDOWAĆ WYJĄTKI
  // private _doExceptionalResponseOperations(dataBody: any): void{
  //   if (this.contentType === "events" || this.contentType === "products" || this.contentType === "offer") {
  //     if (dataBody.filters && dataBody.filters.categories) {
  //       let cats = dataBody.filters.categories;
  //
  //       for (let prop in cats) {
  //         let index = (this._item.children || []).length;
  //
  //         let cat = cats[prop];
  //         let params: {[key: string]: any} = {};
  //
  //         if ("events" === this.contentType) {
  //           params.cat = prop;
  //         } else {
  //           params.cat = cat.id;
  //         //   params.catName = cat.name[this._urlService.langCode]
  //         }
  //
  //         let item = new MenuItemChild(
  //           {
  //             type: "events" === this.contentType ? "event-category" : "offer-category",
  //             title: "events" === this.contentType ? cat[this._urlService.langCode] : cat.name[this._urlService.langCode],
  //             url: this.url,
  //             params: params
  //           },
  //           this._urlService
  //         );
  //
  //         item.index = index;
  //
  //         this._item.children.push(item);
  //       }
  //     }
  //   }
  // }

}
