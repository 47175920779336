import { BehaviorSubject, Observable, Subscription, throwError as observableThrowError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { ObjectUtils } from '../shared/utils/object-utils';
import { SettingsModelInterface } from '../interfaces/settings-model.interface';
import { HttpClient } from '@angular/common/http';
import { LangService } from './lang.service';

@Injectable()
export class SettingDataService implements OnDestroy, OnInit {

  protected defaults = {
    __loaded: false,
  };

  protected data: SettingsModelInterface;
  protected dataRefresh: BehaviorSubject<{ [key: string]: any }>;

  protected isRefreshing: boolean;
  protected refreshSub: Subscription;

  constructor(
    private http: HttpClient,
  ) {
    this.data = ObjectUtils.copy(this.defaults);
    this.dataRefresh = new BehaviorSubject(this.data);
  }

  get settings(): SettingsModelInterface {
    return this.data;
  }

  get settingsRefresh(): BehaviorSubject<{ [key: string]: any }> {
    return this.dataRefresh;
  }

  ngOnInit(): void {
    if (!this.data.__loaded) {
      this.refresh();
    }
  }

  ngOnDestroy(): void {
    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
    }
  }

  refresh(): BehaviorSubject<{ [key: string]: any }> {
    if (this.isRefreshing) {
      return this.dataRefresh;
    }

    this.isRefreshing = true;
    this.loadFromApi().subscribe((settings) => {
      this.isRefreshing = false;
      this.refreshedSettings(settings);
    });

    return this.dataRefresh;
  }

  protected refreshedSettings(settings?: { [key: string]: any }): void {
    if (typeof settings === 'undefined' || !settings) {
      this.data = ObjectUtils.copy(this.defaults);
      return;
    }

    this.data = ObjectUtils.copy(settings);
    this.data.__loaded = true;

    this.dataRefresh.next(this.data);
  }

  protected loadFromApi(): Observable<any[]> {
    const url = environment.apiHost + '/settings?lang=' + LangService.lang;
    const params = {};

    return this.http.get(url, params).pipe(
      catchError((error: Response | any): Observable<any> => {
        return this.handleError(error, url, params);
      }));
  }

  private handleError(error: Response | any, url: string, params: { [key: string]: any } | null) {
    let errorMessage: any;

    if (error.status === 0) {
      errorMessage = {
        success: false,
        status: 0,
        data: 'Sorry, there has an connection error occurred while trying to load settings from API. Please try again.',
        url: url,
        params: params,
      };
    } else if (error.json) {
      errorMessage = error;
    } else {
      errorMessage = {
        success: false,
        status: error.status,
        data: error.message,
        url: url,
        params: params,
      };
    }

    return observableThrowError(errorMessage);
  }
}
