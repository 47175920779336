import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { UniversalPlatformService } from '../shared/platform/universal-platform.service';
import { MetaData } from '../shared/metadata/metadata.service';
import { SettingDataService } from './setting-data.service';
var GlobalService = /** @class */ (function () {
    function GlobalService(_platformService, _metadata, _settingsDataService) {
        var _this = this;
        this._platformService = _platformService;
        this._metadata = _metadata;
        this._settingsDataService = _settingsDataService;
        this.apiHost = environment.apiHost;
        this.sugarApiHost = environment.sugarApiHost;
        this.update = new BehaviorSubject(this.settings);
        this._settingsDataService.settingsRefresh.subscribe(function (settings) {
            _this.update.next(settings);
        });
    }
    Object.defineProperty(GlobalService.prototype, "updated", {
        get: function () {
            return this.update;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "settings", {
        get: function () {
            if (!this._settingsDataService.settings.__loaded) {
                this.loadGlobalSettings();
            }
            return this._settingsDataService.settings;
        },
        enumerable: true,
        configurable: true
    });
    GlobalService.prototype.loadGlobalSettings = function () {
        return this._settingsDataService.refresh();
    };
    return GlobalService;
}());
export { GlobalService };
