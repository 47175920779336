import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Layouts
import { SimpleLayoutComponent } from './layouts/simple-layout.component';
import { CanActivateLanguage } from './can-activate-language';
import { environment } from '../environments/environment';

export const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: environment.langs.default,
  //   pathMatch: 'full',
  // },
  {
    path: ':lang',
    component: SimpleLayoutComponent,
    canActivate: [CanActivateLanguage],
    children: [
      {
        path: '',
        // component: HomeComponent,
        loadChildren: 'app/home/home.module#HomeModule',
      },
      {
        path: 'how-to-buy' + (environment.htmlSuffix ? '.html' : ''),
        loadChildren: 'app/how-to-buy/how-to-buy.module#HowToBuyModule',
        data: {
          name: 'how-to-buy',
        },
      },
      {
        path: 'how-to-sell' + (environment.htmlSuffix ? '.html' : ''),
        loadChildren: 'app/how-to-sell/how-to-sell.module#HowToSellModule',
        data: {
          name: 'how-to-sell',
        },
      },
      {
        path: 'text-page' + (environment.htmlSuffix ? '.html' : ''),
        loadChildren: 'app/text-page/text-page.module#TextPageModule',
        data: {
          name: 'text-page',
        },
      },
      {
        path: 'news' + (environment.htmlSuffix ? '.html' : ''),
        loadChildren: 'app/news/news.module#NewsModule',
        data: {
          name: 'news',
        },
      },
      {
        path: 'exhibitions' + (environment.htmlSuffix ? '.html' : ''),
        loadChildren: 'app/exhibitions/exhibitions.module#ExhibitionsModule',
        data: {
          name: 'exhibitions',
        },
      },
      {
        path: 'artists' + (environment.htmlSuffix ? '.html' : ''),
        loadChildren: 'app/artists/artists.module#ArtistsModule',
        data: {
          name: 'artists',
        },
      },
      {
        path: 'offer' + (environment.htmlSuffix ? '.html' : ''),
        loadChildren: 'app/offer/offer.module#OfferModule',
        data: {
          name: 'offer',
        },
      },
      {
        path: 'auctions' + (environment.htmlSuffix ? '.html' : ''),
        loadChildren: 'app/auctions/auctions.module#AuctionsModule',
        data: {
          name: 'auctions',
        },
      },
      {
        path: 'results' + (environment.htmlSuffix ? '.html' : ''),
        loadChildren: 'app/search-results/search-results.module#SearchResultsModule',
        data: {
          name: 'search-results',
        },
      },
      {
        path: 'verify-email' + (environment.htmlSuffix ? '.html' : ''),
        loadChildren: 'app/text-page/text-page.module#TextPageModule',
        data: {
          name: 'newsletter-verify',
        },
      },
      {
        path: '404' + (environment.htmlSuffix ? '.html' : ''),
        loadChildren: 'app/p404/p404.module#P404Module',
        data: {
          name: '404',
        },
      },
      {
        path: '**',
        // redirectTo: '404' + (environment.htmlSuffix ? '.html' : ''),
        redirectTo: environment.langs.default,
      },
    ],
  },
  {
    path: '**',
    redirectTo: environment.langs.default,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: false,
    useHash: true,
    enableTracing: false,
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
