import { PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalService } from '../../model/global.service';
import { TranslatePipe } from '../translate-pipes/translate.pipe';
import { CutFloatZeroesPipe } from './cut-float-zeroes.pipe';
var WorkDimensionsPipe = /** @class */ (function () {
    function WorkDimensionsPipe(formatter, sanitizer, translate, globalService) {
        this.formatter = formatter;
        this.sanitizer = sanitizer;
        this.translate = translate;
        this.globalService = globalService;
    }
    Object.defineProperty(WorkDimensionsPipe.prototype, "separator", {
        get: function () {
            return typeof this.globalService.settings.general !== 'undefined' && typeof this.globalService.settings.general.decimal_separator ?
                (this.globalService.settings.general.decimal_separator.pl || this.globalService.settings.general.decimal_separator.en) :
                ',';
        },
        enumerable: true,
        configurable: true
    });
    WorkDimensionsPipe.clearArray = function (arr) {
        var ret = [];
        for (var i = 0; i < (arr || []).length; i++) {
            if ((arr[i] || '').trim().length) {
                ret.push(arr[i]);
            }
        }
        return ret;
    };
    WorkDimensionsPipe.prototype.transform = function (work, lang, newLineBreak, addFrameText) {
        if (newLineBreak === void 0) { newLineBreak = false; }
        if (!work) {
            return '';
        }
        var texts = {
            diameterText: this.translate.transform('offer.diameter', lang) + ':',
            weightText: this.translate.transform('offer.weight', lang) + ':',
        };
        if (addFrameText) {
            texts.frameText = this.translate.transform('offer.frame-dimensions', lang) + ':';
        }
        var dims = [];
        // 3D
        var whd = [work.size_height, work.size_width, work.size_depth];
        var d = WorkDimensionsPipe.clearArray(whd).join(' x ');
        d = this.replaceSeparator(d);
        var f = [work.frame_size_height, work.frame_size_width];
        var frame = WorkDimensionsPipe.clearArray(f).join(' x ');
        frame = this.replaceSeparator(frame);
        if ((d || []).length) {
            d += '&nbsp;cm';
            if ((frame || []).length && false !== texts.frameText) {
                d += ' (' + (typeof texts.frameText === 'undefined' ? '' : texts.frameText + ' ') + this.formatter.transform(frame) + '&nbsp;cm)';
            }
        }
        dims.push(d);
        // średnica
        var diameter = '';
        if (work.diameter) {
            diameter = texts.diameterText + ' ' + this.formatter.transform(work.diameter) + '&nbsp;cm';
        }
        diameter = this.replaceSeparator(diameter);
        dims.push(diameter);
        // waga
        var weight = '';
        if (work.weight) {
            weight = texts.weightText + ' ' + this.formatter.transform(work.weight) + '&nbsp;g';
        }
        weight = this.replaceSeparator(weight);
        dims.push(weight);
        dims = WorkDimensionsPipe.clearArray(dims);
        var returnString = newLineBreak ? dims.join(',<br />') : dims.join(', ');
        returnString = returnString.trim();
        return (returnString || []).length ? this.sanitizer.bypassSecurityTrustHtml(returnString) : returnString;
    };
    WorkDimensionsPipe.prototype.replaceSeparator = function (dims) {
        if (this.separator !== '.') {
            do {
                dims = dims.replace('.', this.separator);
            } while (dims.indexOf('.') > -1);
        }
        return dims;
    };
    return WorkDimensionsPipe;
}());
export { WorkDimensionsPipe };
