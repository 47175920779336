<section class="page-section text-image-wrapper">

    <div class="container">
        <div class="row justify-content-between">

            <div class="col-12 col-lg-6 order-lg-2 about-services-content">
                <div *ngIf="title" class="page-title text-image-title">{{ title }}</div>

                <div *ngIf="excerpt" class="page-content text-image-excerpt">
                    {{ excerpt }}
                </div>

                <div *ngIf="text" class="page-content text-image-content">
                    {{ text }}
                </div>
            </div>

            <div class="col-12 col-lg-6 order-lg-1 about-services-figure">
                <img *ngIf="image" appLazyLoad class="img-fluid services-figure" src="{{ image }}">
            </div>

        </div>
    </div>

</section>
