import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslatePipesModule } from '../../../shared/translate-pipes.module';
import { MenuService } from './menu/menu.service';
import { HeaderComponent } from './header.component';

@NgModule({
  imports: [
    CommonModule,
    TranslatePipesModule,
    RouterModule,
  ],
  declarations: [
    HeaderComponent,
  ],
  exports: [
    HeaderComponent,
  ],
  providers: [
    MenuService,
  ],
})
export class HeaderModule {
}
