import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { BlocksPlacement } from '../../blocks-wrapper/blocks-wrapper.component';
import { AutoUnsubscribe } from '../../decorators/autounsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-big-image-block',
  templateUrl: './big-image-block.component.html',
})
export class BigImageBlockComponent implements OnInit, AfterViewInit {

  public backgroundStyle: SafeStyle = '';

  @Input() context: BlocksPlacement | any;
  @Input() url = '';
  @Input() title = '';
  @Input() text = '';
  @Input() background = '';

  @Output() viewInit = new EventEmitter<string>();

  constructor(
    private sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit(): void {
    this.backgroundStyle = this.sanitizer.bypassSecurityTrustStyle('url(' + this.background + ')');
  }

  ngAfterViewInit(): void {
    this.viewInit.emit('bigimage');
  }

}
