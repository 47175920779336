import { NgModule } from '@angular/core';
import { AppModule } from './app.module';
import { AppComponent } from './app.component';
// Cache
import { CACHE } from '@ngx-cache/core';
// import {BrowserCacheModule, MemoryCacheService} from '@ngx-cache/platform-browser';
import { BrowserCacheModule, LocalStorageCacheService } from '@ngx-cache/platform-browser';

@NgModule({
  imports: [
    BrowserCacheModule.forRoot([
      {
        provide: CACHE,
        useClass: LocalStorageCacheService,
      },
    ]),
    AppModule,
  ],
  bootstrap: [AppComponent],
})
export class AppBrowserModule {
}
