/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./app.component";
import * as i3 from "@angular/common";
import * as i4 from "./model/global.service";
import * as i5 from "./model/cms.service";
import * as i6 from "./model/url.service";
import * as i7 from "./shared/platform/body.service";
import * as i8 from "./shared/platform/universal-renderer.helper";
import * as i9 from "./shared/platform/universal-platform.service";
import * as i10 from "./model/sugar.service";
import * as i11 from "./model/components.service";
import * as i12 from "@angular/platform-browser";
import * as i13 from "@angular/forms";
import * as i14 from "./model/setting-data.service";
import * as i15 from "./shared/cookies.service";
import * as i16 from "ng2-scroll-to-el/scrollTo.service";
import * as i17 from "ngx-wow/services/wow.service";
import * as i18 from "./shared/metadata/metadata.service";
import * as i19 from "./shared/fb.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 4440064, null, 0, i2.AppComponent, [i3.DOCUMENT, i4.GlobalService, i5.CmsService, i6.UrlService, i7.BodyService, i8.UniversalRendererHelper, i9.UniversalPlatformService, i10.SugarService, i11.ComponentsService, i1.Router, i3.PlatformLocation, i12.DomSanitizer, i13.FormBuilder, i14.SettingDataService, i15.CookiesService, i16.ScrollToService, i17.NgwWowService, i18.MetaData, i19.FbService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i2.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
