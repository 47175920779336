import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { OwlCarousel } from 'ngx-owl-carousel';
import { UrlService } from '../../model/url.service';
import { UniversalPlatformService } from '../../shared/platform/universal-platform.service';
import { Utils } from '../../shared/utils.class';
import { BlocksPlacement } from '../../blocks-wrapper/blocks-wrapper.component';
import { GlobalService } from '../../model/global.service';
import { AutoUnsubscribe } from '../../decorators/autounsubscribe.decorator';

declare var WOW: any;

@AutoUnsubscribe()
@Component({
  selector: 'app-slider-block',
  templateUrl: './slider-block.component.html',
  styles: [`
        .carousel-caption {
            visibility: visible !important
        }
    `],
})
export class SliderBlockComponent implements OnInit, AfterViewInit {

  public options: { [key: string]: any };
  public dotsContainerId: string;
  public wowClasses: any = {};
  @Input() context: BlocksPlacement;
  @Input() wow: any;
  @Input() block: any;
  @Input() slides: any[];
  @Output() viewInit = new EventEmitter<string>();
  @ViewChild('owl') owlElement: OwlCarousel;
  styles: string[];
  rotation: boolean;

  constructor(
    public sanitizer: DomSanitizer,
    public urlService: UrlService,
    private platformService: UniversalPlatformService,
    public globalService: GlobalService,
  ) {
    this.rotation = this.globalService.settings.global.sliderRotation === 'true';
    this.dotsContainerId = Utils.uniqid('slider-');

    this.options = {
      items: 1,
      margin: 0,
      autoHeight: true,
      height: 200,
      dragEndSpeed: 1000,
      nav: false,
      dots: true,
      dotsSpeed: 1500,
      dotsContainer: '#' + this.dotsContainerId + ' .carousel-indicators',
      loop: true,
      autoplay: true,
      autoplaySpeed: 1500,
      autoplayTimeout: 15000,
    };
  }

  ngOnInit(): void {
    this.setupSlides();

    // parameters can be taken from block object

    this.styles = ['container'];
    // this.styles = ['full-size'];

    if (this.wow) {
      this.wow = JSON.parse(this.wow);

      this.wowClasses = {
        wow: this.wow.enabled,
      };

      if (this.wow.enabled) {
        this.wowClasses[this.wow.effect] = true;
      }
    }
  }

  ngAfterViewInit(): void {
    this.setupSlides();

    if (this.platformService.isBrowser && this.owlElement && this.wow) {

      this.owlElement.$owlChild.$owl.on('change.owl.carousel', function (evt) {
        $('.carousel-caption', evt.target)
          .css('visibility', 'hidden')
          .removeClass('animated');
      });

      this.owlElement.$owlChild.$owl.on('changed.owl.carousel', function () {
        const wow = new WOW();

        wow.stop();
        wow.init();
      });

    }

    setTimeout(() => {
      this.viewInit.emit('slider');
    }, 300);
  }

  private setupSlides(): void {
    if (!this.slides || !(this.slides || []).length) {
      return;
    }

    for (let i = 0; i < (this.slides || []).length; ++i) {
      const item = this.slides[i];

      if (item.body) {
        item.bodySanitized = this.sanitizer.bypassSecurityTrustHtml(item.body);
      }

      if (typeof item.isInternal !== 'undefined') {
        continue;
      }

      if (item.url) {
        item.isInternal = this.urlService.isInternalURL(item.url);

        if (item.isInternal) {
          item.url = this.urlService.internalizeURL(item.url);
        }
      }
    }
  }

}
