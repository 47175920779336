import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cutFloatZeroes',
  pure: true,
})
export class CutFloatZeroesPipe implements PipeTransform {

  transform(price: string): string {
    const lenMinus = (price || []).length - 3;
    const pointIndex = price.lastIndexOf('.');

    if (price.lastIndexOf('.00') === lenMinus) {
      price = price.substring(0, lenMinus);
    }

    if (pointIndex > -1) {
      price = price.substring(0, pointIndex + 3);
    }

    return price;
  }

}
