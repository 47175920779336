import { Observable, throwError as observableThrowError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UrlService } from '../model/url.service';
import { GlobalService } from '../model/global.service';
import { FormValidators } from '../shared/form-utils';
import { Nl2BrPipe } from '../shared/nl2br.pipe';
import { ReplacePlaceholderPipe } from '../shared/translate-pipes/replace-placeholder.pipe';
import { UniversalPlatformService } from '../shared/platform/universal-platform.service';
import { AutoUnsubscribe } from '../decorators/autounsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-newsletter-form',
  templateUrl: './newsletter-form.component.html',
})
export class NewsletterFormComponent implements OnInit {

  public newsletterFields: FormGroup;
  @ViewChild('modal') modal: ModalDirective;
  @ViewChild('confirmTitle') confirmTitle: ElementRef;
  @ViewChild('confirmMessage') confirmMessage: ElementRef;
  private _showValidation: boolean;
  private _doingSubmit: boolean;

  constructor(
    public urlService: UrlService,
    public globalService: GlobalService,
    private _platformService: UniversalPlatformService,
    private _http: HttpClient,
    private _formBuilder: FormBuilder,
  ) {
    this._doingSubmit = false;
  }

  ngOnInit(): void {
    this._createForm();
  }

  openModal(evt?: any): void {

    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }

    if (!this.modal.isShown) {
      this.modal.show();
    }
  }

  closeModal(evt?: any): void {

    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }

    if (this.modal.isShown) {
      this.modal.hide();
    }
  }

  maybeCloseModal(evt?: any): void {

    if (!evt || !evt.target) {
      return;
    }

    this.closeModal(evt);
  }

  onClickSubmit(evt?: any): void {

    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }

    this.onSubmit();
  }

  onSubmit(evt?: any): void {

    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }

    this.newsletterFields.get('email').markAsTouched();

    if (!this.newsletterFields.valid || this._doingSubmit) {
      return;
    }

    this._submitForm();
  }

  displayInvalidityInfo(name: string): boolean {

    const field = this.newsletterFields.get(name);

    if (!field) {
      return false;
    }

    if (field.touched) {
      return field.invalid;
    }

    return this._showValidation ? field.invalid : false;
  }

  doingSubmit(): boolean {
    return this._doingSubmit;
  }

  handlePostError(error: Response | any, url: string, params: { [key: string]: any } | null): Observable<any> {
    let errorMessage: any;

    if (!error.status) {
      errorMessage = {
        success: false,
        status: 0,
        data: {
          message_title: 'Sorry, there was a connection error occurred. Please try again.',
        },
        url: url,
        params: params,
      };
    } else if (error.json) {
      errorMessage = error;

      errorMessage.url = url;
      errorMessage.params = params;
    } else {
      errorMessage = {
        success: false,
        status: error.status,
        data: {
          message_title: error.message_title || error.message_text || error.message,
        },
        url: url,
        params: params,
      };
    }

    this._showMessage(errorMessage.data);
    return observableThrowError(errorMessage);
  }

  private _createForm(): void {

    this.newsletterFields = this._formBuilder.group({
      email: ['', Validators.compose([Validators.required, FormValidators.email()])],
    });

    this._showValidation = false;
  }

  private _submitForm(): void {

    this._doingSubmit = true;
    this.modal.show();

    const self = this;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    const postUrl = this.globalService.apiHost + '/newsletter-add?lang=' + this.urlService.lang;
    const formValues = {
      email: this.newsletterFields.get('email').value,
    };

    this._http.post(
      postUrl,
      formValues,
      httpOptions,
    ).pipe(catchError((error: Response | any): Observable<any> => {
      self._doingSubmit = false;
      return self.handlePostError(error, postUrl, formValues);
    })).subscribe((response: any) => {
      this._showMessage(response);

      this._doingSubmit = false;

      if (response.status === 'success') {
        this.newsletterFields.get('email').reset();
      }
    });
  }

  private _showMessage(response: { [key: string]: any }): void {

    const nl2br = new Nl2BrPipe();
    const placeholderReplace = new ReplacePlaceholderPipe(this.urlService, this.globalService);

    let openModal = false;
    let title = '';
    let content = '';

    if (response.message_title || response.message_text) {
      if (response.message_title) {
        title = typeof response.message_title === 'string' ? response.message_title : response.message_title[this.urlService.lang];
      } else {
        title = '';
      }

      if (response.message_text) {
        content = typeof response.message_text === 'string' ? response.message_text : response.message_text[this.urlService.lang];
      } else {
        content = '';
      }

      openModal = true;
    } else if (response.message) {
      title = typeof response.message === 'string' ? response.message : response.message[this.urlService.lang];

      openModal = true;
    }

    if (this._platformService.isBrowser) {
      this.confirmTitle.nativeElement.innerHTML = placeholderReplace.transform(nl2br.transform(title));
      this.confirmMessage.nativeElement.innerHTML = placeholderReplace.transform(nl2br.transform(content));
    }

    if (openModal) {
      setTimeout(() => this.openModal());
    }
  }

}
