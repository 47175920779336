import { DomSanitizer } from '@angular/platform-browser';
import { UrlService } from '../../model/url.service';
import { Nl2BrPipe } from '../nl2br.pipe';
import { SanitizedDataCollection } from './sanitized-data-collection.class';
import { Sanitizer } from './api-collection-setters';

export declare type DataSanitizationType = 'html' | 'url' | 'style' | 'script' | 'resource';

export declare type DataMapItem = string | [string, false] | [string, boolean, DataSanitizationType];

export interface DataMap {
  [key: string]: DataMapItem
}

export class DataExtractor {

  protected sanitizer: DomSanitizer;
  protected urlService: UrlService;

  constructor(
    sanitizer: DomSanitizer,
    urlService: UrlService,
  ) {
    this.sanitizer = sanitizer;
    this.urlService = urlService;
  }

  extract(data: { [key: string]: any }, dataMap: DataMap, skipEmpty = false): SanitizedDataCollection {
    const collection = new SanitizedDataCollection();
    const nl2br = new Nl2BrPipe();

    let dataProp: string;
    let confProp: DataMapItem;
    let sanitize: boolean;
    let sanitizationType: any;
    let key: string;
    let value: any;

    const sanitizer = new Sanitizer(this.sanitizer);

    for (key in dataMap) {
      if (!dataMap.hasOwnProperty(key)) {
        continue;
      }

      confProp = dataMap[key];

      if (typeof confProp === 'string') {
        dataProp = confProp;
        sanitize = false;
      } else {
        dataProp = confProp[0];
        sanitize = confProp[1];
        sanitizationType = typeof confProp[2] === 'undefined' ? null : confProp[2];
      }

      if (data[dataProp]) {
        if (typeof data[dataProp] === 'string') {
          value = data[dataProp];
        } else if (typeof data[dataProp] === 'object' && typeof data[dataProp][this.urlService.lang] !== 'undefined') {
          value = data[dataProp][this.urlService.lang];
        } else if (typeof data[dataProp] !== 'undefined') {
          value = data[dataProp];
        } else {
          value = null;
        }
      } else {
        value = null;
      }

      if (!value && skipEmpty) {
        continue;
      }

      if (value !== null && sanitize) {
        value = nl2br.transform(value);
        value = sanitizer.sanitize(value, sanitizationType);
      }

      collection.set(key, value, sanitize);
    }

    return collection;
  }

}

export class ContactDataExtractor extends DataExtractor {

  create(contactSettings: { [key: string]: any }): SanitizedDataCollection {
    const dataMap: DataMap = {
      name: ['gallery_name', true, 'html'],
      street: 'address',
      place: 'place',
      postcode: 'postcode',
      city: 'city',
      phone: 'phone',
      phone2: 'phone2',
      fax: 'fax',
      email: 'email',
      bank_account: 'bankaccountnumber',
      openinghours: ['openinghours', true, 'html'],
      nip: 'nip',
      regon: 'regon',
    };

    return this.extract(contactSettings, dataMap);
  }

}

export class SocialsDataExtractor extends DataExtractor {

  create(socialsSettings: { [key: string]: any }): SanitizedDataCollection {
    const dataMap: DataMap = {};
    let socialName: string;

    for (socialName in socialsSettings) {
      if (!socialsSettings.hasOwnProperty(socialName) || 'fb_app_id' === socialName) {
        continue;
      }

      dataMap[socialName] = [socialName, true, 'url'];
    }

    return this.extract(socialsSettings, dataMap, true);
  }

}
