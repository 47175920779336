/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../node_modules/ngx-owl-carousel/src/owl-carousel.component.ngfactory";
import * as i3 from "ngx-owl-carousel/src/owl-carousel.component";
import * as i4 from "../../shared/translate-pipes/translate.pipe";
import * as i5 from "../../model/global.service";
import * as i6 from "../../model/url.service";
import * as i7 from "../../shared/translate-pipes/replace-placeholder.pipe";
import * as i8 from "./small-slider-block.component";
import * as i9 from "@angular/platform-browser";
var styles_SmallSliderBlockComponent = [];
var RenderType_SmallSliderBlockComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SmallSliderBlockComponent, data: {} });
export { RenderType_SmallSliderBlockComponent as RenderType_SmallSliderBlockComponent };
function View_SmallSliderBlockComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "owl-item-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 1, 0, currVal_0); }); }
function View_SmallSliderBlockComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["class", "owl-item-link"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.url, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent, 0), "general.more", _co.urlService.lang)); _ck(_v, 1, 0, currVal_1); }); }
function View_SmallSliderBlockComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "owl-slide"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "owl-item-wrapper"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "carousel-content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SmallSliderBlockComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SmallSliderBlockComponent_4)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.url; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sanitizer.bypassSecurityTrustStyle((("url(" + _v.context.$implicit.image) + ")")); _ck(_v, 1, 0, currVal_0); }); }
function View_SmallSliderBlockComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "section", [["class", "hero-slider small-slider-block container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "carousel-home-hero"], ["id", "hero-carousel"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "owl-carousel", [], null, null, null, i2.View_OwlCarousel_0, i2.RenderType_OwlCarousel)), i0.ɵdid(3, 311296, null, 0, i3.OwlCarousel, [i0.IterableDiffers], { carouselClasses: [0, "carouselClasses"], options: [1, "options"] }, null), i0.ɵpad(4, 2), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_SmallSliderBlockComponent_2)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "wrapper-container carousel-indicators-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "div", [["class", "carousel-indicators"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, "owl-carousel", "owl-theme"); var currVal_1 = _co.options; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.slides; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_SmallSliderBlockComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i4.TranslatePipe, [i5.GlobalService, i6.UrlService, i7.ReplacePlaceholderPipe]), i0.ɵqud(402653184, 1, { owlElement: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SmallSliderBlockComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.slides && ((_co.slides == null) ? null : _co.slides.length)); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_SmallSliderBlockComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-small-slider-block", [], null, null, null, View_SmallSliderBlockComponent_0, RenderType_SmallSliderBlockComponent)), i0.ɵdid(1, 4308992, null, 0, i8.SmallSliderBlockComponent, [i9.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SmallSliderBlockComponentNgFactory = i0.ɵccf("app-small-slider-block", i8.SmallSliderBlockComponent, View_SmallSliderBlockComponent_Host_0, { context: "context", slides: "slides" }, { viewInit: "viewInit" }, []);
export { SmallSliderBlockComponentNgFactory as SmallSliderBlockComponentNgFactory };
