<section *ngIf="news && news?.length" class="home-articles articles-carousel-section container-fluid">
    <div class="carousel-home-mid">
        <owl-carousel #owl [carouselClasses]="['owl-carousel', 'owl-theme', 'news-carousel']" [options]="options">
            <!-- data-owl-carousel="home-articles" -->

            <div *ngFor="let item of news" class="owl-slide">
                <article class="carousel-article">
                    <div class="carousel-content row">
                        <div class="carousel-article-col carousel-content-col col-12 col-md-6">
                            <header class="carousel-article-header">
                                <small *ngIf="item.category_label[urlService.langCode]"
                                       class="carousel-article-tag">{{ item.category_label[urlService.langCode] }}</small>

                                <div class="carousel-article-title">
                                    <a [routerLink]="item.link[urlService.langCode]"
                                       class="carousel-article-link">{{ item.name[urlService.langCode] }}</a>
                                </div>

                                <p *ngIf="item.date_from" class="carousel-article-dates">
                                    <span
                                        *ngIf="item.date_from">{{ item.date_from | localizedDate:'dd.MM.yyyy' }}</span>
                                    <span
                                        *ngIf="item.date_to"> &ndash; {{ item.date_to | localizedDate:'dd.MM.yyyy' }}</span>
                                </p>
                            </header>

                            <div [innerHTML]="item.description_short_sanitized[urlService.langCode]"
                                 class="carousel-article-text"></div>

                            <a [routerLink]="item.link[urlService.langCode]"
                               class="carousel-article-more">{{ 'general.more' | translate:urlService.lang }}</a>
                        </div>

                        <div class="carousel-article-col carousel-figure-col col-12 col-md-6">
                            <div class="slider-figure-wrapper">
                                <a [routerLink]="item.link[urlService.langCode]" class="carousel-article-image-link">

                                    <span *ngIf="item.hasIcon" [ngStyle]="{
                                        'background-image':'url('+item.image?.thumbnails?.medium.url+')'
                                    }" class="carousel-article-figure"></span>

                                    <span *ngIf="! item.hasIcon" class="carousel-article-figure"
                                          style="background-image:url('assets/images/placeholder-600x450.jpg');"></span>

                                </a>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </owl-carousel>

        <div [attr.id]="dotsContainerId" class="wrapper-container carousel-indicators-wrapper">
            <div class="carousel-indicators"></div>
        </div>
    </div>
</section>
