import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OwlModule } from 'ngx-owl-carousel';
import { ArtsaasPipesModule } from '../../shared/artsaas-pipes.module';
import { TranslatePipesModule } from '../../shared/translate-pipes.module';
import { SliderBlockComponent } from './slider-block.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    OwlModule,
    ArtsaasPipesModule,
    TranslatePipesModule,
  ],
  exports: [
    SliderBlockComponent,
  ],
  declarations: [
    SliderBlockComponent,
  ],
})
export class SliderBlockModule {
}
