import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxMasonryModule } from 'ngx-masonry';
import { ArtsaasPipesModule } from '../../shared/artsaas-pipes.module';
import { SoldItemsBlockComponent } from './sold-items-block.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ArtsaasPipesModule,
    NgxMasonryModule,
    SharedModule,
  ],
  exports: [
    SoldItemsBlockComponent,
  ],
  declarations: [
    SoldItemsBlockComponent,
  ],
})
export class SoldItemsBlockModule {
}
