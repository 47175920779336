import { Injectable } from '@angular/core';
import { UniversalPlatformService } from '../platform/universal-platform.service';

@Injectable()
export class CollectionsPerPageService {

  protected collectionsPerPage: { [key: string]: number };
  protected collectionsSaving: { [key: string]: boolean };
  protected collectionsDefaults: { [key: string]: number };

  constructor(
    private platformService: UniversalPlatformService,
  ) {
    this.collectionsPerPage = {};
    this.collectionsSaving = {};
    this.collectionsDefaults = {};
  }

  setValue(collection: string, num: number): void {
    const lsKey = this.createLsKey(collection);

    this.collectionsPerPage[collection] = typeof num === 'number' ? num : parseInt(num, 10);

    if (this.shouldBeSaved(collection)) {

      this.platformService.localStorage.setItem(
        lsKey,
        this.collectionsPerPage[collection],
        this.platformService.localStorage.countDays(1),
      );

    }
  }

  getValue(collection: string): number {
    if (typeof this.collectionsPerPage[collection] === 'undefined') {
      if (this.shouldBeSaved(collection)) {
        const lsKey = this.createLsKey(collection);
        const pp = this.platformService.localStorage.getItem(lsKey);

        if (pp) {
          this.collectionsPerPage[collection] = parseInt(pp, 10);
        } else {
          this.collectionsPerPage[collection] = this.getDefault(collection);
        }
      } else {
        this.collectionsPerPage[collection] = this.getDefault(collection);
      }
    }

    return this.collectionsPerPage[collection];
  }

  setDefault(collection: string, dflt: number): void {
    this.collectionsDefaults[collection] = dflt;
  }

  getDefault(collection: string): number {
    return this.collectionsDefaults[collection] ? this.collectionsDefaults[collection] : 0;
  }

  setSaving(collection: string, flag: boolean): void {
    this.collectionsSaving[collection] = flag;
  }

  shouldBeSaved(collection: string): boolean {
    return typeof this.collectionsSaving[collection] === 'undefined' || this.collectionsSaving[collection];
  }

  protected createLsKey(collection: string) {
    return collection + '_per-page';
  }

}
