import { AbstractControl, ValidatorFn } from '@angular/forms';
import { CountryCode, isValidNumber } from 'libphonenumber-js';

const validator = require('validator');

export class FormValidators {

  public static phone(lang = 'PL'): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      const typedLang = <CountryCode>lang.toUpperCase();

      if (value === '' || !value) {
        return null;
      }

      return !isValidNumber(value, typedLang) ? { phone: { value } } : null;
    };
  }

  public static postalCode(lang = 'PL'): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      lang = lang.toUpperCase();

      if (value === '' || !value) {
        return null;
      }

      return !validator.isPostalCode(value, lang) ? { postalCode: { value } } : null;
    };
  }

  public static email(): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;

      if (value === '' || !value) {
        return null;
      }

      return !validator.isEmail(value) ? { email: { value } } : null;
    };
  }
}
