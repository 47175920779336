import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BlocksPlacement } from '../../blocks-wrapper/blocks-wrapper.component';
import { AutoUnsubscribe } from '../../decorators/autounsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-text-block',
  templateUrl: './text-block.component.html',
})
export class TextBlockComponent implements OnInit, AfterViewInit {

  @Input() title = '';
  @Input() text = '';
  @Input() context: BlocksPlacement;

  @Output() viewInit = new EventEmitter<string>();

  public titleSanitized: SafeHtml;
  public textSanitized: SafeHtml;

  constructor(
    protected _sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.title) {
        this.titleSanitized = this._sanitizer.bypassSecurityTrustHtml(this.title);
      }

      if (this.text) {
        this.textSanitized = this._sanitizer.bypassSecurityTrustHtml(this.text);
      }

      this.viewInit.emit('textblock');
    });
  }

}
