import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from './translate-pipes/translate.pipe';
import { ReplacePlaceholderPipe } from './translate-pipes/replace-placeholder.pipe';
import { LocalizedDatePipe } from './translate-pipes/localized-date.pipe';
import { DateDeclinationPipe } from './translate-pipes/date-declination.pipe';
import { TimeFixIEPipe } from './translate-pipes/time-fix-ie.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    TranslatePipe,
    ReplacePlaceholderPipe,
    LocalizedDatePipe,
    DateDeclinationPipe,
    TimeFixIEPipe,
  ],
  exports: [
    TranslatePipe,
    ReplacePlaceholderPipe,
    LocalizedDatePipe,
    DateDeclinationPipe,
    TimeFixIEPipe,
  ],
  providers: [
    TranslatePipe,
    ReplacePlaceholderPipe,
    DateDeclinationPipe,
  ],
})
export class TranslatePipesModule {
}
