var SimpleCollection = /** @class */ (function () {
    function SimpleCollection() {
        this.data = {};
        this.dataKeys = [];
        this.dataKeysCopy = [];
        this.count = 0;
    }
    Object.defineProperty(SimpleCollection.prototype, "length", {
        get: function () {
            return this.count;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SimpleCollection.prototype, "keys", {
        get: function () {
            return this.dataKeysCopy;
        },
        enumerable: true,
        configurable: true
    });
    SimpleCollection.prototype.set = function (name, value) {
        ++this.count;
        if (!this.has(name)) {
            this.dataKeys.push(name);
            this.dataKeysCopy = JSON.parse(JSON.stringify(this.dataKeys)); // kopia, nie referencja
        }
        this.data[name] = value;
    };
    SimpleCollection.prototype.get = function (name) {
        if (this.has(name)) {
            return this.data[name];
        }
        return null;
    };
    SimpleCollection.prototype.has = function (name) {
        return typeof this.data[name] !== 'undefined' && null !== this.data[name];
    };
    SimpleCollection.prototype.empty = function (name) {
        if (!this.has(name)) {
            return true;
        }
        var val = this.data[name];
        return null === val ||
            (typeof val === 'number' && 0 === val) ||
            ((typeof val === 'string' || Array.isArray(val)) && (val || []).length === 0) ||
            (typeof val === 'object' && Object.keys(val).length === 0);
    };
    SimpleCollection.prototype.hasAny = function () {
        return this.count > 0;
    };
    SimpleCollection.prototype.atLeastOneNotEmpty = function (names) {
        var emptyOnes = this.countEmptyOnes(names);
        return emptyOnes < (names || []).length;
    };
    SimpleCollection.prototype.countEmptyOnes = function (names) {
        var emptyOnes = 0;
        for (var i = 0; i < (names || []).length; ++i) {
            if (this.empty(names[i])) {
                ++emptyOnes;
            }
        }
        return emptyOnes;
    };
    return SimpleCollection;
}());
export { SimpleCollection };
