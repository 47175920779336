import { map } from 'rxjs/operators';
import { OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { UrlService } from '../../../../model/url.service';
import { CmsService } from '../../../../model/cms.service';
import { SugarService } from '../../../../model/sugar.service';
import { GlobalService } from '../../../../model/global.service';
import { UniversalPlatformService } from '../../../../shared/platform/universal-platform.service';
import { MenuItem } from './menu-item.class';
import { MenuItemChild } from './menu-item-child.class';
import { MenuItemFeatured } from './menu-item-featured.class';
import { HttpClient } from '@angular/common/http';
var featuredChildrenTypes = [
    'auction-newest',
    'auction-current',
    'exhibition-newest',
    'exhibition-current',
    'offer-newest',
    'offer-current',
    'news-newest',
    'news-current',
];
var contentTypes = {
    auctions: [
        'auction-newest',
        'auction-current',
        'auctions-current',
        'auction-archive',
        'auctions-archive',
        'auctions',
    ],
    exhibitions: [
        'exhibition-newest',
        'exhibition-current',
        'exhibitions-current',
        'exhibition-archive',
        'exhibitions-archive',
        'exhibitions',
    ],
    products: [
        'offer-newest',
        'offer-current',
        'works-current',
        'offer-archive',
        'works-archive',
        'offer-category',
        'products-category',
        'offer',
        'products',
    ],
    events: [
        'news',
        'news-newest',
        'news-current',
        'news-category',
        'event-category',
        'events',
    ],
    artists: [
        'artist',
        'artists',
    ],
    'text-page': [
        'text-page',
        'contact',
        'how-to-sell',
        'how-to-buy',
        'sold',
    ],
    external: ['external'],
};
var ctCache = {};
var MenuService = /** @class */ (function () {
    function MenuService(urlService, cmsService, sugarService, globalService, platformService, _http) {
        var _this = this;
        this.urlService = urlService;
        this.cmsService = cmsService;
        this.sugarService = sugarService;
        this.globalService = globalService;
        this.platformService = platformService;
        this._http = _http;
        this.catCache = {};
        this.menuItems = [];
        this.hydratedItems = [];
        this.backgroundColor = '#333';
        this.remoteCount = 0;
        this.remoteLoaded = 0;
        this.ready = new BehaviorSubject(false);
        this.menuSub = this.cmsService.menuItems.subscribe(function (menu) {
            var sub;
            if (!_this.globalService.settings.__loaded) {
                sub = globalService.updated.subscribe(function (settings) {
                    if (!settings.__loaded) {
                        _this.setupMenu(menu);
                        sub.unsubscribe();
                    }
                });
            }
            else {
                _this.setupMenu(menu);
            }
        });
    }
    Object.defineProperty(MenuService.prototype, "items", {
        get: function () {
            return this.menuItems;
        },
        enumerable: true,
        configurable: true
    });
    MenuService.getContentType = function (keyword) {
        if (typeof ctCache[keyword] === 'string') {
            return ctCache[keyword];
        }
        if (typeof contentTypes[keyword] !== 'undefined') {
            ctCache[keyword] = keyword;
            return keyword;
        }
        var prop;
        for (prop in contentTypes) {
            if (!contentTypes.hasOwnProperty(prop)) {
                continue;
            }
            if (contentTypes[prop].indexOf(keyword) > -1) {
                ctCache[keyword] = prop;
                return prop;
            }
        }
        return '';
    };
    MenuService.prototype.ngOnDestroy = function () {
        if (this.menuSub) {
            this.menuSub.unsubscribe();
        }
    };
    MenuService.prototype.reset = function (full) {
        this.menuItems = [];
        this.remoteCount = 0;
        this.remoteLoaded = 0;
        if (full) {
            this.hydratedItems = [];
        }
    };
    MenuService.prototype.setupMenu = function (menu) {
        if (!menu || !Array.isArray(menu)) {
            return;
        }
        var rawItem;
        var hydratedItem;
        var mLen = (menu || []).length;
        var i = 0;
        this.reset(true);
        for (; i < mLen; ++i) {
            rawItem = menu[i];
            hydratedItem = this.hydrateMenuItem(menu[i]);
            if (!hydratedItem) {
                continue;
            }
            this.hydratedItems.push(hydratedItem);
        }
        if (this.remoteCount > 0) {
            var hLen = (this.hydratedItems || []).length;
            var item = void 0;
            var hcLen = void 0;
            for (i = 0; i < hLen; ++i) {
                var child = void 0;
                var ci = void 0;
                item = this.hydratedItems[i];
                hcLen = (item.children || []).length;
                for (ci = 0; ci < hcLen; ++ci) {
                    child = item.children[ci];
                    if (!child.isRemote) {
                        continue;
                    }
                    this.fetchRemoteItem(child);
                }
                if (item.featured && item.featured.isRemote) {
                    this.fetchRemoteItem(item.featured);
                }
            }
        }
        else {
            this.onRemoteLoaded();
        }
    };
    MenuService.prototype.hydrateMenuItem = function (rawItem) {
        // główna pozycja
        var item = {
            title: '',
            type: 'text-page',
            url: '',
            target: '',
            slug: '',
            link: '',
            contentType: '',
            params: null,
            children: [],
            external: false,
            featured: null,
            columns: 1,
            index: 0,
            hasRemote: false,
            remoteCount: 0,
            styles: [],
        };
        var skipProp = ['children', 'featured', 'hasRemote', 'remoteCount'];
        var lang = this.urlService.lang;
        var prop;
        for (prop in item) {
            if (skipProp.indexOf(prop) > -1 || typeof rawItem[prop] === 'undefined' || rawItem[prop] === null) {
                continue;
            }
            if (typeof rawItem[prop] === 'string' || typeof rawItem[prop] === 'number' || typeof rawItem[prop] === 'boolean') {
                item[prop] = rawItem[prop];
            }
            else if (typeof rawItem[prop][lang] !== 'undefined' || typeof rawItem[prop]['en'] !== 'undefined') {
                if ('slug' === prop && typeof rawItem[prop]['en'] !== 'undefined') {
                    item[prop] = rawItem[prop]['en'];
                }
                else {
                    item[prop] = rawItem[prop][lang];
                }
            }
        }
        item.contentType = MenuService.getContentType(rawItem.content_type);
        item.type = item.contentType;
        item.params = rawItem.parameters ? { cat: rawItem.parameters.category_id } : null;
        var improper = (!this.globalService.settings.offer.show_archive
            && (item.type === 'offer-archive' || item.type === 'works-archive'));
        if (improper) {
            return null;
        }
        if (!item.contentType) {
            item.external = true;
            item.link = item.url;
        }
        else {
            item.link = this.urlService.link(item.url);
        }
        item.index = (this.hydratedItems || []).length;
        // dziecka
        var children = typeof rawItem.children !== 'undefined' && (rawItem.children || []).length ? rawItem.children : [];
        var itemChildren = [];
        var cLen = (children || []).length;
        var ci = 0;
        var child;
        var featuredIndex = -1;
        var columns = 1;
        var in2ndColumn = 0;
        if (rawItem.content_type === 'auctions') {
            children = children.filter(function (it) { return it.type !== '"auction-current"'; });
            cLen = (children || []).length;
        }
        for (; ci < cLen; ++ci) {
            children[ci].index = ci;
            child = this.hydrateChildItem(children[ci]);
            if (!child) {
                continue;
            }
            if (child.isRemote) {
                item.hasRemote = true;
                ++item.remoteCount;
                ++this.remoteCount;
            }
            if (child.column > 0) {
                ++in2ndColumn;
                columns = 2;
            }
            if (child.isFeatured) {
                featuredIndex = ci;
            }
            itemChildren.push(child);
        }
        if (featuredIndex > -1 && in2ndColumn === 1) {
            var spliced = itemChildren.splice(featuredIndex, 1);
            item.featured = spliced[0];
            columns = -1;
        }
        item.children = this.groupChildrenByColumn(itemChildren);
        item.columns = columns;
        return item;
    };
    MenuService.prototype.fetchRemoteItem = function (hydratedItem) {
        var _this = this;
        if (hydratedItem.isFeatured) {
            this.getFeaturedObject(hydratedItem).subscribe(function (data) {
                _this.setRemoteObject(hydratedItem, data);
                ++_this.remoteLoaded;
                _this.startWhenReady();
            });
        }
        else {
            this.getCategories(hydratedItem).subscribe(function (cats) {
                _this.setCategories(hydratedItem, cats);
                ++_this.remoteLoaded;
                _this.startWhenReady();
            });
        }
    };
    MenuService.prototype.startWhenReady = function () {
        if (this.remoteLoaded === this.remoteCount) {
            this.onRemoteLoaded();
        }
    };
    MenuService.prototype.onRemoteLoaded = function () {
        var _this = this;
        var hydrated;
        var childrenItems;
        var ii = 0;
        var hLen = (this.hydratedItems || []).length;
        this.reset();
        for (; ii < hLen; ++ii) {
            hydrated = this.hydratedItems[ii];
            var cii = void 0;
            var child = void 0;
            var cLen = (hydrated.children || []).length;
            var childItem = void 0;
            childrenItems = [];
            for (cii = 0; cii < cLen; ++cii) {
                child = hydrated.children[cii];
                childItem = new MenuItemChild(child);
                childrenItems.push(childItem);
            }
            var featured = void 0;
            if (hydrated.featured) {
                featured = new MenuItemFeatured(hydrated.featured, hydrated.featured.remoteObject);
            }
            else {
                featured = null;
            }
            var item = new MenuItem(hydrated, childrenItems, featured);
            item.index = (this.menuItems || []).length;
            this.menuItems.push(item);
        }
        this.menuItems.forEach(function (val, index) {
            if (val.ct === 'auctions') {
                var maxCount = _this.globalService.settings.global.auctionsSubmenuMaxCount || 1;
                var url = _this.globalService.sugarApiHost + '/auctions?expand=images&sort=date&filter[status][0]=live_auction_status_ongoing&filter[status][1]=live_auction_status_in_preparation&per-page=' + maxCount + '&page=1';
                var params = {
                    observe: 'response',
                };
                _this._http
                    .get(url, params).pipe(map(function (resp) { return ({ body: resp.body, headers: [] }); }))
                    .subscribe(function (data) {
                    _this.menuItems[index].manyFeaturedItemsList = data.body.items;
                });
            }
        });
        this.ready.next(true);
    };
    MenuService.prototype.setCategories = function (item, categories) {
        if (!categories) {
            return;
        }
        var i = 0;
        if (null === item.params) {
            item.params = {};
        }
        for (; i < (categories || []).length; ++i) {
            if ('events' === item.contentType) {
                if (item.title === categories[i].name) {
                    item.params.cat = categories[i].slug;
                }
            }
            else {
                item.params.cat = categories[i].id;
                item.params.catName = categories[i].name[this.urlService.langCode];
            }
        }
    };
    MenuService.prototype.getCategories = function (hydrated) {
        var _this = this;
        var subject = new Subject();
        var endpoint = hydrated.contentType;
        var params;
        if (typeof this.catCache[endpoint] !== 'undefined') {
            var categories_1 = this.catCache[endpoint];
            setTimeout(function () {
                subject.next(categories_1);
            });
            return subject;
        }
        switch (hydrated.contentType) {
            case 'text-page':
            case 'external':
                params = null;
                break;
            default:
                params = 'expand=filter_categories&per-page=1';
        }
        if (!params) {
            setTimeout(function () {
                subject.next(null);
            });
            return subject;
        }
        this.sugarService.getItems(endpoint, params, '', 1).subscribe(function (data) {
            var categories = [];
            if (data && data.body && data.body && data.body.filters && data.body.filters.categories) {
                var cats = data.body.filters.categories;
                var prop = void 0;
                if (Array.isArray(cats)) {
                    for (prop = 0; prop < (cats || []).length; ++prop) {
                        categories.push({
                            id: cats[prop].id ? cats[prop].id : null,
                            name: typeof cats[prop].name === 'string' ? cats[prop].name : cats[prop].name[_this.urlService.langCode],
                            slug: cats[prop].ckey,
                        });
                    }
                }
                else {
                    for (prop in cats) {
                        if (!cats[prop]) {
                            continue;
                        }
                        categories.push({
                            id: cats[prop].id ? cats[prop].id : null,
                            name: typeof cats[prop].name === 'undefined' ?
                                cats[prop][_this.urlService.langCode]
                                : cats[prop].name[_this.urlService.langCode],
                            slug: prop,
                        });
                    }
                }
                if ((categories || []).length) {
                    var i = 0;
                    if (null === hydrated.params) {
                        hydrated.params = {};
                    }
                    for (; i < (categories || []).length; ++i) {
                        if ('events' === hydrated.contentType) {
                            if (hydrated.title === categories[i].name) {
                                hydrated.params.cat = categories[i].slug;
                            }
                        }
                        else {
                            hydrated.params.cat = categories[i].id;
                            hydrated.params.catName = categories[i].name[_this.urlService.langCode];
                        }
                    }
                }
            }
            _this.catCache[endpoint] = categories;
            subject.next(categories);
        });
        return subject;
    };
    MenuService.prototype.getFeaturedObject = function (item) {
        var endpoint = item.contentType;
        var params;
        if (!item.isFeatured) {
            return;
        }
        switch (item.type) {
            case 'auction-newest':
            case 'auction-current':
                params = 'expand=images&sort=-date&filter[status][0]=live_auction_status_in_preparation&filter[status][1]=live_auction_status_ongoing&per-page=1';
                break;
            case 'exhibition-newest':
            case 'exhibition-current':
                params = 'expand=images&sort=-date_from&filter[status][0]=exhibition_status_in_preparation&filter[status][1]=exhibition_status_ongoing&per-page=1';
                break;
            case 'offer-newest':
            case 'offer-current':
            case 'offer-archive':
                endpoint = '';
                break;
            case 'offer':
                params = 'expand=images&sort=-created_at';
                break;
            case 'news-newest':
            case 'news-current':
                params = 'expand=images&sort=-date_from&per-page=1';
                break;
        }
        if (!(endpoint || []).length) {
            return null;
        }
        return this.sugarService.getItems(endpoint, params, '', 1);
    };
    MenuService.prototype.setRemoteObject = function (item, data) {
        if (!data.body || !data.body.items || !(data.body.items || []).length) {
            return;
        }
        var reItem = data.body.items[0];
        // znów wyjątek
        var type = item.contentType === 'events' ? 'news' : item.contentType;
        item.link = this.urlService.link(type) + '/' + reItem.id;
        item.title = reItem.name[this.urlService.langCode];
        item.remoteObject = data.body.items[0];
    };
    MenuService.prototype.groupChildrenByColumn = function (children) {
        var columnOne = [];
        var columnTwo = [];
        var len = (children || []).length;
        var groupedChildren;
        var i = 0;
        var child;
        for (; i < len; ++i) {
            child = children[i];
            if (child.column === 0) {
                columnOne.push(child);
            }
            else {
                columnTwo.push(child);
            }
        }
        groupedChildren = columnOne.concat(columnTwo);
        return groupedChildren;
    };
    MenuService.prototype.hydrateChildItem = function (rawItem) {
        var item = {
            title: '',
            type: 'text-page',
            url: '',
            target: '',
            slug: '',
            link: '',
            contentType: '',
            params: {},
            column: 0,
            isFeatured: false,
            external: false,
            index: 0,
            isRemote: false,
            remoteObject: null,
            styles: [],
        };
        var lang = this.urlService.lang;
        var prop;
        var skipProp = ['children', 'isRemote', 'isFeatured'];
        // małe wyjątki
        if ('auctions' === rawItem.type) {
            rawItem.type = 'auctions-current';
        }
        else if ('exhibitions' === rawItem.type) {
            rawItem.type = 'exhibitions-current';
        }
        for (prop in item) {
            if (skipProp.indexOf(prop) > -1 || typeof rawItem[prop] === 'undefined' || rawItem[prop] === null) {
                continue;
            }
            if (typeof rawItem[prop] === 'string' || typeof rawItem[prop] === 'number' || typeof rawItem[prop] === 'boolean') {
                item[prop] = rawItem[prop];
            }
            else if (typeof rawItem[prop][lang] !== 'undefined' || typeof rawItem[prop]['en'] !== 'undefined') {
                if ('slug' === prop && typeof rawItem[prop]['en'] !== 'undefined') {
                    item[prop] = rawItem[prop]['en'];
                }
                else {
                    item[prop] = rawItem[prop][lang];
                }
            }
        }
        item.contentType = MenuService.getContentType(item.type);
        var improper = !item.contentType ||
            (!this.globalService.settings.offer.show_archive && (item.type === 'offer-archive' || item.type === 'works-archive'));
        if (improper) {
            return null;
        }
        if (item.column > 0) {
            item.column = 1;
        }
        if (featuredChildrenTypes.indexOf(item.type) > -1) {
            item.isFeatured = true;
            item.isRemote = true;
        }
        switch (item.contentType) {
            case 'text-page':
                if (!(item.url || []).length) {
                    item.url = (item.slug || []).length ? item.slug : item.type;
                }
                break;
            case 'external':
                item.external = true;
                item.url = typeof item.url === 'string' ? item.url : item.url[this.urlService.lang];
                break;
            default:
                item.url = item.contentType;
        }
        if (item.external) {
            item.link = item.url.replace('//', '\/\/');
            return item;
        }
        // znów wyjątek
        if (item.url === 'events') {
            item.url = 'news';
        }
        else if (item.url === 'products') {
            item.url = 'offer';
        }
        var currentArchive = ['auctions-current', 'auctions-current', 'exhibitions-archive', 'exhibitions-current', 'works-archive'];
        if (rawItem.data && rawItem.data.category_id) {
            item.link = this.urlService.link(item.contentType);
            item.params.cat = rawItem.data.category_id;
        }
        else if (currentArchive.indexOf(item.type) > -1) {
            item.link = this.urlService.link(item.contentType);
            item.params.archive = item.type.indexOf('current') > -1 ? '0' : '1';
        }
        else {
            item.isRemote = featuredChildrenTypes.indexOf(item.type) > -1;
            if (!item.isRemote) {
                item.link = this.urlService.link(item.url);
            }
        }
        if (item.type.indexOf('archive') > -1) {
            item.params.archive = 1;
        }
        item.styles = ['underlining', 'lowercase', 'background'];
        if (item.link === '/pl/events') {
            item.link = '/pl/wydarzenia';
        }
        if (item.link === '/pl/auction-terms-and-conditions') {
            item.link = '/pl/regulamin-aukcji';
        }
        return item;
    };
    return MenuService;
}());
export { MenuService };
