import { Component } from '@angular/core';
import { TextPageComponent } from '../text-page/text-page.component';
import { AutoUnsubscribe } from '../decorators/autounsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  templateUrl: 'p404.component.html',
})
export class P404Component extends TextPageComponent {

  initialize(): void {
    super.initialize();
    this.classes.basic = 'e404';
  }

}
