import { BehaviorSubject, Observable, Subject, throwError as observableThrowError } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../shared/utils.class';
import { ObjectUtils } from '../shared/utils/object-utils';
import { UniversalPlatformService } from '../shared/platform/universal-platform.service';
import { GlobalService } from './global.service';

@Injectable()
export class SugarService {

  public _sugarContents: any = {};
  public requestError = new Subject<any>();

  private data = new BehaviorSubject<{ [key: string]: any }>({});
  private configuration = new BehaviorSubject<{ [key: string]: any }>({});

  private readonly _defaultConfiguration = {
    data: [],
    pagination: { current: 1, all: 1, pages: [1], allRecords: 0 },
    availableSorting: [],
    filters: {},
  };

  constructor(
    private _http: HttpClient,
    private _globalService: GlobalService,
    private _platformService: UniversalPlatformService,
  ) {
    // zapobieżenie przeładowaniu localStorage
    if (_platformService.isBrowser) {
      const keys = _platformService.localStorage.keys;

      let key: string;
      let m: RegExpMatchArray | null;

      const regex = /collection-[a-zA-Z1-9\-]+-state/;

      for (let ki = 0; ki < (keys || []).length; ++ki) {
        key = keys[ki];

        m = key.match(regex);

        if (m && m[0]) {
          _platformService.localStorage.removeItem(key);
        }
      }
    }
  }

  setCollection(collectionID: string, configuration: any): void {
    const defaults = ObjectUtils.copy(this._defaultConfiguration);
    this._sugarContents[collectionID] = ObjectUtils.extend(true, defaults, configuration);
    this._refreshCollectionConfiguration(collectionID);
  }

  unsetCollection(collectionId): void {
    if (this.hasCollection(collectionId)) {
      this.unstoreCollection(collectionId);
      delete this._sugarContents[collectionId];
    }
  }

  hasCollection(collectionID: string): boolean {
    return typeof this._sugarContents[collectionID] !== 'undefined';
  }

  updateParam(collectionID: string, param: string, value: string | string[]): boolean {
    if (!this.hasCollection(collectionID)) {
      return false;
    }

    this._sugarContents[collectionID].parameters[param] = value;

    this.storeCollection(collectionID);
    return true;
  }

  removeParam(collectionID: string, param: string, paramVal?: string | string[]): boolean {
    if (!this.hasParam(collectionID, param, paramVal)) {
      return false;
    }

    delete this._sugarContents[collectionID].parameters[param];
    this.storeCollection(collectionID);
    return true;
  }

  getParam(collectionID: string, param: string): any {
    if (!this.hasCollection(collectionID)
      || !this.hasParam(collectionID, param)) {
      return null;
    }

    return this._sugarContents[collectionID].parameters[param];
  }

  hasParam(collectionID: string, param: string, paramVal?: string | string[]): boolean {
    if (!this.hasCollection(collectionID)) {
      return false;
    }

    if (typeof paramVal === 'undefined') {
      return typeof this._sugarContents[collectionID].parameters[param] !== 'undefined';
    }

    return typeof this._sugarContents[collectionID].parameters[param] !== 'undefined'
      && this._sugarContents[collectionID].parameters[param] === paramVal;
  }

  addFilter(collectionID: string, param: string | { [key: string]: string }, paramVal: string, atIndex?: number): number {
    if (!this.hasCollection(collectionID)) {
      return;
    }

    const paramName = typeof param === 'string' ? param : param.name;
    const paramKey = typeof param === 'string' ? param : param.key;

    let filterName = 'filter[' + paramName + ']';
    let index = 0;

    // tutaj wyjątek dla kategorii w aktualnościach :(
    if (filterName === 'filter[categories]') {
      filterName = 'filter[category]';
    }

    // tutaj wyjątek dla stylów oraz tematow :(
    if (filterName === 'filter[style]' || filterName === 'filter[subject]' || filterName === 'filter[material]') {
      filterName = 'filter[cat]';
    }

    for (const prop in this._sugarContents[collectionID].parameters) {
      if (!this._sugarContents[collectionID].parameters[prop]) {
        continue;
      }
      if (atIndex === index) {
        break;
      }

      if (prop.indexOf(filterName) === 0) {
        if (paramVal === this._sugarContents[collectionID].parameters[prop]) {
          return index;
        }

        ++index;
      }
    }

    filterName += '[' + index + ']';

    this._sugarContents[collectionID].parameters[filterName] = paramVal; // .join(',');

    if (typeof this._sugarContents[collectionID].filters[paramKey] === 'undefined') {
      this._sugarContents[collectionID].filters[paramKey] = [];
    }

    this._sugarContents[collectionID].filters[paramKey].push(this._getFilterOptionObject(collectionID, paramKey, paramVal));
    this.storeCollection(collectionID);
    return index;
  }

  removeFilter(collectionID: string, param: string | { [key: string]: string }, paramVal?: string): void {
    if (!this.hasCollection(collectionID)) {
      return;
    }

    const paramName = typeof param === 'string' ? param : param.name;
    const paramKey = typeof param === 'string' ? param : param.key;

    let filterName = 'filter[' + paramName + ']';

    // tutaj wyjątek dla stylów oraz tematow :(
    if (filterName === 'filter[style]' || filterName === 'filter[subject]' || filterName === 'filter[material]') {
      filterName = 'filter[cat]';
    }

    for (const prop in this._sugarContents[collectionID].parameters) {
      if (prop.indexOf(filterName) === 0) {
        if (typeof paramVal !== 'undefined' && paramVal === this._sugarContents[collectionID].parameters[prop]) {
          delete this._sugarContents[collectionID].parameters[prop];
        } else if (typeof paramVal === 'undefined') {
          delete this._sugarContents[collectionID].parameters[prop];
        }
      }
    }

    if (typeof paramVal === 'undefined') {
      this._sugarContents[collectionID].filters[paramKey] = [];
    } else if (typeof this._sugarContents[collectionID].filters[paramKey] !== 'undefined') {
      for (let i = 0; i < (this._sugarContents[collectionID].filters[paramKey] || []).length; ++i) {
        if (this._sugarContents[collectionID].filters[paramKey][i].value === paramVal) {
          this._sugarContents[collectionID].filters[paramKey] = this._sugarContents[collectionID]
            .filters[paramKey].slice(i, i + 1);
          --i;
        }
      }
    }

    this.storeCollection(collectionID);
  }

  getItems(dataType: string, parameters: string, sorting: string, page: number, withFilterActive = true): Observable<any> {
    if (!parameters.includes('per-page')) {
      if (!(dataType.startsWith('exhibitions') && dataType.endsWith('products'))) {
        parameters += '&per-page=' + 100;
      }
    }
    let url = this._globalService.sugarApiHost + '/' + dataType + '?' + parameters + '&page=' + page;
    if (withFilterActive) {
      url += '&filter[active]=1';
    }
    const params: { [key: string]: any } = {
      observe: 'response',
    };

    return this._http
      .get(url, params).pipe(
        map((resp: any) => ({ body: resp.body, headers: [] })),
        catchError((error: Response | any): Observable<any> => {
          return withFilterActive
            ? this.getItems(dataType, parameters, sorting, page, false)
            : this.handleError(error, url, params);
        }),
      );
  }

  getItem(dataType: string, id: number, parameters?: string): Observable<any> {
    let url = this._globalService.sugarApiHost + '/' + dataType + '/' + id;

    if (parameters && (parameters || []).length) {
      url += '?' + parameters;
    }

    const params: { [key: string]: any } = {
      observe: 'response',
    };

    return this._http
      .get(url, params).pipe(
        map((resp: any) => resp.body),
        catchError((error: Response | any): Observable<any> => this.handleError(error, url, params)),
      );
  }

  _refreshColection(collectionId: string): void {
    if (!this.hasCollection(collectionId)) {
      console.error('kolekcja \'' + collectionId + '\' nie istnieje, nie może więc zostać odświeżona');
      return;
    }

    const parameters: string[] = [];

    for (let parameter in this._sugarContents[collectionId].parameters) {

      // sprawdzenie czy parameter przypadkiem nie jest pusty
      if (typeof this._sugarContents[collectionId].parameters[parameter] === 'undefined') {
        continue;
      }

      // jeżeli wartość parametru jest tablicą wysyłamy w URL-u tablicę
      // tj. zamiast filter[x]=[A,B,C] wysyłamy filter[x][]=A&filter[x][]=B&filter[x][]=C
      if (typeof this._sugarContents[collectionId].parameters[parameter] !== 'string'
        && typeof this._sugarContents[collectionId].parameters[parameter] !== 'number') {

        // wyjątek dla wyszukiwania
        if (parameter === 'filter[value]') {

          // jesli wartość niepusta
          if ((this._sugarContents[collectionId].parameters[parameter] || []).length) {
            parameters.push('filter[value]=' + this._sugarContents[collectionId].parameters[parameter]);
          }

          // wyjątek dla price i dat
        } else if (parameter === 'filter[price]' || parameter === 'filter[year]') {

          if ((this._sugarContents[collectionId].parameters[parameter] || []).length) {
            const range = this._sugarContents[collectionId].parameters[parameter][0].split('-');

            if ((range || []).length === 2) {
              parameters.push(parameter + '[gte]=' + range[0]);
              parameters.push(parameter + '[lte]=' + range[1]);
            }
          }

        } else {
          for (const value of this._sugarContents[collectionId].parameters[parameter]) {

            // tutaj wyjątek dla kategorii w aktualnościach :(
            if (parameter === 'filter[categories]') {
              parameter = 'filter[category]';
            }

            // tutaj wyjątek dla stylów oraz tematow :(
            if (parameter === 'filter[style]' || parameter === 'filter[subject]' || parameter === 'filter[material]') {
              parameter = 'filter[cat]';
            }

            parameters.push(parameter + '[]=' + value);
          }
        }

      } else {
        parameters.push(parameter + '=' + this._sugarContents[collectionId].parameters[parameter]);
      }
    }

    this.getItems(
      this._sugarContents[collectionId].sugarNode,
      parameters.join('&'),
      this._sugarContents[collectionId].parameters['sort'],
      this._sugarContents[collectionId].pagination.current,
    ).subscribe(data => {
      // to callback, kolekcja mogła zniknąć
      if (!this.hasCollection(collectionId)) {
        return;
      }

      const pagination = {
        current: data.body._meta ? parseInt(data.body._meta.currentPage, 10) : 1, // resp.headers.get('x-pagination-current-page'),
        all: data.body._meta ? parseInt(data.body._meta.pageCount, 10) : 1, // resp.headers.get('X-Pagination-Page-Count'),
        allRecords: data.body._meta ? parseInt(data.body._meta.totalCount, 10) : 1, // resp.headers.get('X-Pagination-Total-Count'),
        pages: [],
      };

      // pages = [1,2,3,4,...,all]
      pagination.pages = Array.apply(null, { length: pagination.all }).map((value, index) => index + 1);

      const apiFilters = {};

      if (data.body.filters) {
        for (const filterName in data.body.filters) {
          if (!data.body.filters.hasOwnProperty(filterName)) {
            continue;
          }
          apiFilters[filterName] = [];

          for (const apiFilterOption in data.body.filters[filterName]) {
            if (!data.body.filters[filterName].hasOwnProperty(apiFilterOption)) {
              continue;
            }
            if (Utils.isNumeric(apiFilterOption)) {

              apiFilters[filterName].push({
                id: data.body.filters[filterName][apiFilterOption].id,
                name: data.body.filters[filterName][apiFilterOption].name,
              });

            } else if ('min' === apiFilterOption || 'max' === apiFilterOption) {

              apiFilters[filterName].push({
                id: data.body.filters[filterName][apiFilterOption],
                name: apiFilterOption,
              });

            } else {

              apiFilters[filterName].push({
                id: apiFilterOption,
                name: 'news' === collectionId
                  ? data.body.filters[filterName][apiFilterOption]
                  : data.body.filters[filterName][apiFilterOption].name,
              });
            }
          }
        }
      }

      const xpnd = this._sugarContents[collectionId].parameters.expand && this._sugarContents[collectionId].parameters.expand.indexOf('items') === -1;

      const collectionData = {
        collectionId: collectionId,
        pagination: pagination,
        data: data.body.items && xpnd
          ? data.body.items
          : data.body,
        apiFilters: apiFilters,
        filters: this._sugarContents[collectionId].filters,
      };

      this._sugarContents[collectionId].data = collectionData.data;
      this._sugarContents[collectionId].apiFilters = collectionData.apiFilters;
      this._sugarContents[collectionId].pagination = pagination;

      this.storeCollection(collectionId);
      this._refreshCollectionConfiguration(collectionId);
      this.data.next(collectionData);
    });
  }

  getData(): Observable<any> {
    return this.data.asObservable();
  }

  getCollectionContents(collectionID: string): { [key: string]: any } | null {
    if (!this.hasCollection(collectionID) || typeof this._sugarContents[collectionID] === 'undefined') {
      return null;
    }

    return this._sugarContents[collectionID];
  }

  getNumberOfAllRecords(collectionId: string): number {
    return this._sugarContents[collectionId].pagination.allRecords;
  }

  _refreshCollectionConfiguration(collectionId: string): void {
    const collectionData = {
      collectionId: collectionId,
      pagination: this._sugarContents[collectionId].pagination,
      data: this._sugarContents[collectionId].json,
      filters: this._sugarContents[collectionId].filters,
      availableFilters: this._sugarContents[collectionId].availableFilters,
    };

    this.configuration.next(collectionData);
  }

  getConfiguration(): Observable<any> {
    return this.configuration.asObservable();
  }

  refreshData(collectionId: string): void {
    this._refreshColection(collectionId);
  }

  getAvailableSorting(collectionId: string): any[] {
    let availableSorting = [];

    if (this._sugarContents[collectionId] && this._sugarContents[collectionId].availableSorting) {
      availableSorting = this._sugarContents[collectionId].availableSorting;
    }

    return availableSorting;
  }

  getAvailableFilterOptions(collectionId: string, filterName: string): any[] {
    let availableFilterOptions = [];

    if (this._sugarContents[collectionId] && this._sugarContents[collectionId].availableFilters[filterName]) {
      availableFilterOptions = this._sugarContents[collectionId].availableFilters[filterName];
    }

    return availableFilterOptions;
  }

  getDefaultFilterValue(collectionId: string, filterName: string): string {
    let defaultValue = '';

    if (collectionId
      && this._sugarContents[collectionId]
      && filterName
      && this._sugarContents[collectionId].availableFilters[filterName]) {

      for (const option of this._sugarContents[collectionId].availableFilters[filterName]) {
        if (option.default) {
          defaultValue = option.id;
          break;
        }
      }

    }

    return defaultValue;
  }

  changePage(collectionId: string, page: number): void {
    if (collectionId && page && this._sugarContents[collectionId] && this._sugarContents[collectionId].pagination.current !== page) {
      this._sugarContents[collectionId].pagination.current = page;
      this.storeCollection(collectionId);
      this._refreshColection(collectionId);
    }
  }

  changeSorting(collectionId: string, sortBy: string): void {
    if (!this.hasCollection(collectionId)) {
      return;
    }

    const sugarParams = this._sugarContents[collectionId].parameters;

    if (typeof sugarParams.sort !== 'undefined' && sugarParams.sort !== sortBy) {
      this._sugarContents[collectionId].parameters.sort = sortBy;
      this._refreshColection(collectionId);
      this.storeCollection(collectionId);
    }
  }

  // zmiena wartości filtru
  changeFiltering(collectionId: string, filterName: string, value: any): void {
    const filterParameter = 'filter[' + filterName + ']';

    // @todo: szybki wyjątek, wymyślić rozwiązanie
    if (filterName === 'value') {
      this._sugarContents[collectionId].filters['value'] = (value || []).length ? [{ id: value[0], title: value[0] }] : [];
      this._sugarContents[collectionId].parameters[filterParameter] = value;
      this._refreshColection(collectionId);
      return;
    }

    if (this.hasCollection(collectionId) && this._sugarContents[collectionId].availableFilters[filterName]) {
      // sprawdzenie czy wartość się w ogóle zmieniła. Jeśli nie, to nie odświeżamy danych
      if (this._sugarContents[collectionId].parameters[filterParameter]
        && this._sugarContents[collectionId].parameters[filterParameter] === value) {
        return;
      }

      this._sugarContents[collectionId].filters[filterName] = [];
      this._sugarContents[collectionId].parameters[filterParameter] = value;

      const added = [];

      for (const id of value) {
        const add = this._getFilterOptionObject(collectionId, filterName, id);

        if (added.indexOf(add.id) > -1) {
          continue;
        }

        this._sugarContents[collectionId].filters[filterName].push(add);
        added.push(add.id);
      }

      this.storeCollection(collectionId);
      this._refreshColection(collectionId);
    }
  }

  getFilters(collectionID: string): { [key: string]: any } | false {
    if (!this.hasCollection(collectionID)) {
      return false;
    }

    return this._sugarContents[collectionID].filters;
  }

  cleanFilters(collectionId: string, filters: string[]): void {
    for (const filterName of filters) {
      this._sugarContents[collectionId].filters[filterName] = [];

      if (filterName.indexOf('filter') === 0) {
        this._sugarContents[collectionId].parameters[filterName] = [];
      } else {
        this._sugarContents[collectionId].parameters['filter[' + filterName + ']'] = [];
      }
    }

    this.storeCollection(collectionId);
    this._refreshColection(collectionId);
  }

  setFilterOptions(collectionId: string, filterName: string, newOptions: any[]): void {
    if (!this.hasCollection(collectionId)) {
      return;
    }

    if (typeof this._sugarContents[collectionId].availableFilters === 'undefined') {
      this._sugarContents[collectionId].availableFilters = {};
    }

    this._sugarContents[collectionId].availableFilters[filterName] = newOptions;

    this.storeCollection(collectionId);
    this._refreshCollectionConfiguration(collectionId);
  }

  getStoredCollection(collectionID: string): { [key: string]: any } | false {
    const stored = this._platformService.localStorage.getItem('collection-' + collectionID + '-state');
    return stored === null ? false : stored;
  }

  protected storeCollection(collectionID: string): void {
    if (this._platformService.isBrowser && this.hasCollection(collectionID)) {
      this._platformService.localStorage.setItem(
        'collection-' + collectionID + '-state',
        this._sugarContents[collectionID],
        this._platformService.localStorage.countMinutes(30),
      );
    }
  }

  protected unstoreCollection(collectionID: string): void {
    if (this._platformService.isBrowser && this.hasCollection(collectionID)) {
      this._platformService.localStorage.removeItem('collection-' + collectionID + '-state');
    }
  }

  private _getFilterOptionObject(collectionId: string, filterName: string, filterOptionValue: string): any {
    if (!this.hasCollection(collectionId) || typeof this._sugarContents[collectionId].availableFilters === 'undefined') {
      return {
        id: filterOptionValue,
        title: '',
      };
    }

    let title = '';

    for (const option of this._sugarContents[collectionId].availableFilters[filterName]) {
      if (option.id === filterOptionValue) {
        title = option.title;
        break;
      }
    }

    let objTitle: string;

    // wyjątki dla pól typu "range"
    if (filterName === 'price') {
      objTitle = filterOptionValue + ' PLN';
    } else if (filterName === 'year') {
      objTitle = filterOptionValue;
    } else {
      objTitle = title;
    }

    return {
      id: filterOptionValue,
      title: objTitle,
    };
  }

  private handleError(error: Response | any, url: string, params: { [key: string]: any } | null): Observable<any> {
    let errorMessage: any;

    if (error.status === 0) {
      errorMessage = {
        success: false,
        status: 0,
        data: 'Sorry, there has an connection error occurred. Please try again.',
        error: error,
        url: url,
        params: params,
      };
    } else if (error.json) {
      errorMessage = error;
    } else {
      errorMessage = {
        success: false,
        status: error.status,
        data: error.message,
        url: url,
        params: params,
      };
    }

    this.requestError.next(errorMessage);
    return observableThrowError(errorMessage);
  }

}
