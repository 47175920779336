<section class="upload-form">

    <form #form (ngSubmit)="onSubmitForm($event)" [formGroup]="formFields" method="post">

        <div class="container">

            <div class="row">
                <div class="col-12">
                    <div class="upload-form__title">Prześlij zdjęcia oferowanej pracy</div>
                </div>
            </div>

            <div class="row justify-content-center upload-form__body">

                <div class="col-12 col-md-9 col-lg-6 col-xl-5 upload-form__col upload-form__col--first">
                    <div class="row justify-content-end no-gutters">
                        <div class="col-12 col-sm-8 col-md-8">
                            <div class="upload-form__subtitle">Podaj swoje dane</div>
                        </div>
                    </div>

                    <div class="row justify-content-center">

                        <div class="col-12 col-sm-11 col-lg-12">

                            <div class="form-group form-row upload-form__row">
                                <label class="col-12 col-sm-4 col-md-4 col-form-label upload-form__label"
                                       for="nameSurname">{{ 'forms.name-and-surname' | translate:urlService.lang }}
                                    :</label>
                                <div class="col-12 col-sm-8 col-md-8 form-control-outer">
                                    <input class="form-control upload-form__control" formControlName="name"
                                           id="nameSurname"
                                           type="text"/>
                                    <span [class.show]="displayInvalidityInfo('name')" class="form-control-invalid">
                                        {{ 'form-validation.fill-in-required-field' | translate:urlService.lang }}
                                    </span>
                                </div>
                            </div>

                            <div class="form-group form-row upload-form__row">
                                <label class="col-12 col-sm-4 col-md-4 col-form-label upload-form__label"
                                       for="phoneNumber">{{ 'forms.telephone-number' | translate:urlService.lang }}
                                    :</label>
                                <div class="col-12 col-sm-8 col-md-8 form-control-outer">
                                    <input class="form-control upload-form__control" formControlName="phone"
                                           id="phoneNumber"
                                           type="tel"/>
                                    <span [class.show]="displayInvalidityInfo('phone')" class="form-control-invalid">
                                        <span
                                            [hidden]="!formFields.get('phone').hasError('required')">{{ 'form-validation.fill-in-required-field' | translate:urlService.lang }} </span>
                                        <span
                                            [hidden]="!formFields.get('phone').hasError('phone')">{{ 'form-validation.enter-correct-number' | translate:urlService.lang }} </span>
                                    </span>
                                </div>
                            </div>

                            <div class="form-group form-row upload-form__row">
                                <label class="col-12 col-sm-4 col-md-4 col-form-label upload-form__label"
                                       for="emailAddress">{{ 'forms.email' | translate:urlService.lang }}
                                    :</label>
                                <div class="col-12 col-sm-8 col-md-8 form-control-outer">
                                    <input class="form-control upload-form__control" formControlName="email"
                                           id="emailAddress"
                                           type="email"/>
                                    <span [class.show]="displayInvalidityInfo('email')" class="form-control-invalid">
                                        <span
                                            [hidden]="!formFields.get('email').hasError('required')">{{ 'form-validation.fill-in-required-field' | translate:urlService.lang }} </span>
                                        <span
                                            [hidden]="!formFields.get('email').hasError('email')">{{ 'form-validation.enter-correct-email' | translate:urlService.lang }} </span>
                                    </span>
                                </div>
                            </div>

                            <div class="form-group form-row upload-form__row">
                                <label class="col-12 col-sm-4 col-md-4 col-form-label upload-form__label"
                                       for="contactMessage">Opis
                                    obiektu:</label>
                                <div class="col-12 col-sm-8 col-md-8 form-control-outer">
                                    <textarea class="form-control upload-form__control" formControlName="message"
                                              id="contactMessage"
                                              rows="4"></textarea>
                                    <span [class.show]="displayInvalidityInfo('message')" class="form-control-invalid">
                                        <span
                                            [hidden]="!formFields.get('message').hasError('required')">{{ 'form-validation.fill-in-required-field' | translate:urlService.lang }} </span>
                                        <span
                                            [hidden]="!formFields.get('message').hasError('minlength')">{{ 'form-validation.enter-message-text' | translate:urlService.lang }} </span>
                                    </span>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="col-12 col-lg-5 col-xl-4 upload-form__col upload-form__col--second">

                    <div class="row no-gutters justify-content-end">
                        <div class="col-12 col-sm-8 col-md-8 col-lg-10 col-xl-11">
                            <div class="upload-form__subtitle">Dołącz zdjęcia</div>
                        </div>
                    </div>

                    <div class="row justify-content-center justify-content-lg-end">
                        <div class="col-12 col-sm-11 col-md-8 col-lg-11 upload-form__section-wrap">

                            <div class="form-group form-row upload-form__row">

                                <div class="col-12 upload-form__files">
                                    <label (click)="onClickFiles($event)"
                                           [class.is-invalid]="displayInvalidityInfo('files')"
                                           class="upload-form__label upload-form__label--file">
                                        <span class="upload-form__files-field-wrapper">
                                            <input #filesInput (change)="onFileChange($event)"
                                                   class="upload-form__files-field" formControlName="file"
                                                   id="file" multiple="multiple"
                                                   type="file"/>
                                        </span>

                                        <span *ngIf="!fileError && files?.length < maxFilesCount"
                                              class="upload-form__files-info">Wybierz do {{ maxFilesCount }}  zdjęć z dysku</span>

                                        <span *ngIf="fileError"
                                              class="upload-form__files-info upload-form__files-info--error">{{ fileError }} </span>

                                        <span (click)="onClickFilesItem(i, $event)"
                                              *ngFor="let file of files; let i = index"
                                              class="upload-form__files-item">
                                            <span class="upload-form__file-name">{{ file.name }} </span>
                                            <span class="upload-form__file-close">&times;</span>
                                        </span>

                                    </label>
                                </div>

                            </div>

                            <div *ngIf="recaptchaKey" class="form-group form-row upload-form__row">
                                <div class="form-input-col form-control-outer ml-auto ml-xl-0 mr-0 mr-xl-auto">
                                    <span class="upload-form__label upload-form__label--captcha">Potwierdź, że nie jesteś robotem</span>

                                    <ng-container *ngIf="canShowCaptcha">
                                        <div [class.is-invalid]="displayInvalidityInfo('_captcha')"
                                             class="recaptcha-wrapper">
                                            <jhi-re-captcha #captcha (captchaExpired)="handleCaptchaResponse()"
                                                            (captchaResponse)="handleCaptchaResponse($event)"
                                                            [site_key]="recaptchaKey" theme="clean"></jhi-re-captcha>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div
                        class="form-row justify-content-center justify-content-lg-end upload-form__row upload-form__row--buttons">

                        <div class="col-12 col-sm-11 col-md-8 col-lg-10 col-xl-11">
                            <div class="row justify-content-end justify-content-lg-start no-gutters">

                                <div class="col-12 col-sm-4 col-md-4 col-lg-6">
                                    <button (click)="onClickCancel($event)" [disabled]="isSubmitting"
                                            [ngClass]="{'disabled': isSubmitting}"
                                            class="btn btn-link btn-wide btn-cancel"
                                            type="button">{{ 'forms.cancel-button-text' | translate:urlService.lang }} </button>
                                </div>

                                <div class="col-12 col-sm-4 col-md-4 col-lg-6">
                                    <button (click)="onClickSubmit($event)" [disabled]="isSubmitting"
                                            [ngClass]="{'disabled': isSubmitting}"
                                            class="btn btn-primary btn-wide btn-apply"
                                            type="submit">{{ 'forms.send-button-text' | translate:urlService.lang }} </button>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                <div class="col-12 col-lg-11">
                    <p class="upload-form__disclaimer">{{ 'forms.user-data-administrator-info' | translate:urlService.lang }} </p>
                </div>

            </div>

        </div>

    </form>

    <div class="modal-wrapper">

        <div #modal="bs-modal" (click)="closeByBackdrop($event)" bsModal class="modal fade modal-form">

            <div class="modal-dialog-content">

                <button (click)="closeModal($event); false" aria-label="Zamknij" class="close close-icon"
                        type="button"></button>

                <div class="modal-dialog-inner modal-dialog" role="document">

                    <div #formMessage class="modal-step modal-step-ty show" data-modal-step="2">

                        <div class="modal-step-inner">
                            <div #messageTitle *ngIf="formMessageTitle[urlService.lang]"
                                 class="art-contact-title">{{ formMessageTitle[urlService.lang] }} </div>
                            <p #messageContent
                               *ngIf="formMessageContent[urlService.lang]">{{ formMessageContent[urlService.lang] }} </p>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</section>
