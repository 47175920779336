import { Routes } from '@angular/router';
import { P404Component } from './p404.component';
var ɵ0 = {
    cmsCategory: 'p404',
};
var routes = [
    {
        path: '',
        component: P404Component,
        data: ɵ0,
    },
];
var P404RoutingModule = /** @class */ (function () {
    function P404RoutingModule() {
    }
    return P404RoutingModule;
}());
export { P404RoutingModule };
export { ɵ0 };
