import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OwlModule } from 'ngx-owl-carousel';
import { TranslatePipesModule } from '../../shared/translate-pipes.module';
import { ArtsaasPipesModule } from '../../shared/artsaas-pipes.module';
import { NewsSliderBlockComponent } from './news-slider-block.component';

@NgModule({
  imports: [
    CommonModule,
    OwlModule,
    RouterModule,
    TranslatePipesModule,
    ArtsaasPipesModule,
  ],
  exports: [
    NewsSliderBlockComponent,
  ],
  declarations: [NewsSliderBlockComponent],
})
export class NewsSliderBlockModule {
}
