import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { AppComponent } from '../app.component';
import { UrlService } from '../model/url.service';
import { ComponentsService } from '../model/components.service';
import { SugarService } from '../model/sugar.service';
import { GlobalService } from '../model/global.service';
import { BodyService } from '../shared/platform/body.service';
import { UniversalPlatformService } from '../shared/platform/universal-platform.service';
import { UniversalRendererHelper } from '../shared/platform/universal-renderer.helper';
import { MetaData } from '../shared/metadata/metadata.service';
import { CmsPageMetaData } from '../shared/metadata/metadata.interfaces';
import { AutoUnsubscribe } from '../decorators/autounsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-text-page',
  templateUrl: './text-page.component.html',
})
export class TextPageComponent implements OnInit, OnDestroy, AfterViewInit {

  protected page: {
    title: string,
    content: string,
    image: string,
    type: string,
    slug: string,
    sanitized: {
      title: SafeHtml | string,
      content: SafeHtml | string,
      image: SafeHtml | string,
    },
    extra: { [key: string]: any }
  };

  protected classes: {
    basic: string | string[],
    withImage: string | string[]
  };

  protected sub: Subscription;

  protected displayPreloader: boolean;
  protected blocksInitializing: boolean;
  protected viewInitialized: boolean;

  protected updateTimeout: any;

  constructor(
    public domSanitizer: DomSanitizer,
    public urlService: UrlService,
    public globalService: GlobalService,
    protected bodyService: BodyService,
    protected platformService: UniversalPlatformService,
    protected renderer: UniversalRendererHelper,
    protected componentsService: ComponentsService,
    protected sugarService: SugarService,
    protected rootComp: AppComponent,
    protected metadata: MetaData,
    protected http: HttpClient,
    protected router: Router,
    protected route: ActivatedRoute,
  ) {
    this.blocksInitializing = true;
    this.displayPreloader = true;
    this.viewInitialized = false;
  }

  get title(): string | null {
    return this.page.title;
  }

  get content(): string | null {
    return this.page.content;
  }

  get contentSanitized(): SafeHtml | string {
    return this.page.sanitized.content;
  }

  get hasTopImage(): boolean {
    return (this.page.image || []).length > 0;
  }

  get topImage(): string | null {
    return this.page.image;
  }

  get showPreloader(): boolean {
    return this.displayPreloader && this.blocksInitializing;
  }

  get contentType(): string {
    return this.page.type;
  }

  get slug(): string {
    return this.page.slug;
  }

  ngOnInit(): void {
    this.initialize();

    this.rootComp.fbService.updateCommonOg();

    this.sub = this.componentsService.getRootComponents().subscribe((data) => {
      const apiData = data ? <any>data : null;
      if (apiData.main_contents) {
        const mc = apiData.main_contents;

        this.displayPreloader = true;

        clearTimeout(this.updateTimeout);

        this.updateTimeout = setTimeout(() => {
          this.page.image = mc.image && (mc.image || []).length ? mc.image : '';
          this.page.title = mc.title ? mc.title[this.urlService.lang] : '';
          this.page.content = mc.text ? mc.text[this.urlService.lang] : '';
          this.page.slug = mc.slug ? mc.slug[this.urlService.lang] : '';

          this.page.type = mc.content_type ? mc.content_type : 'nieznany';

          this.page.sanitized.title = this.page.title ? this.domSanitizer.bypassSecurityTrustHtml(this.page.title) : '';
          this.page.sanitized.content = this.page.content ? this.domSanitizer.bypassSecurityTrustHtml(this.page.content) : '';
          this.page.sanitized.image = this.page.image ? this.domSanitizer.bypassSecurityTrustUrl(this.page.image) : '';

          this.displayPreloader = false;

          this.updateBodyClass();
        }, 100);

        // meta
        const metaData: CmsPageMetaData = {
          title: typeof mc.title !== 'undefined' ? mc.title : '',
          description: typeof mc.meta_description !== 'undefined' ? mc.meta_description : '',
          keywords: typeof mc.meta_keywords !== 'undefined' ? mc.meta_keywords : '',
        };

        if (this.hasTopImage) {
          metaData.ogImage = mc.image;
        }

        this.metadata.setTextPageMeta(metaData);
      }
    });

    this.updateBodyClass();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    clearTimeout(this.updateTimeout);
  }

  ngAfterViewInit(): void {
    this.viewInitialized = true;
    this.callViewInit();
  }

  onBlocksInit(): void {
    this.blocksInitializing = false;
    this.callViewInit();
  }

  protected initialize(): void {
    this.page = {
      title: '',
      content: '',
      image: '',
      type: '',
      slug: '',
      sanitized: {
        title: '',
        content: '',
        image: '',
      },
      extra: {},
    };

    this.classes = {
      basic: 'text-page',
      withImage: ['main-at-top', 'has-hero-image'],
    };
  }

  protected updateBodyClass(): void {
    setTimeout(() => {
      this.bodyService.resetAndAddClass(this.classes.basic);

      if (this.hasTopImage) {
        this.bodyService.addClass(this.classes.withImage);
      }
    });
  }

  protected callViewInit(): void {
    if (!this.platformService.isBrowser) {
      return;
    }

    if (!this.blocksInitializing && this.viewInitialized) {
      this.platformService.document.dispatchEvent(
        new Event('view-initialized'),
      );
    }
  }

}
