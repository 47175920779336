import * as tslib_1 from "tslib";
import { MenuItemAbstract } from './menu-item-abstract.class';
var MenuItemChild = /** @class */ (function (_super) {
    tslib_1.__extends(MenuItemChild, _super);
    function MenuItemChild(hydratedItem) {
        var _this = _super.call(this, hydratedItem) || this;
        _this.hydratedItem = hydratedItem;
        return _this;
    }
    Object.defineProperty(MenuItemChild.prototype, "type", {
        get: function () {
            return this.getValue('type');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuItemChild.prototype, "isFeatured", {
        get: function () {
            return this.featured;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuItemChild.prototype, "contentType", {
        get: function () {
            return this.hydratedItem.contentType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuItemChild.prototype, "column", {
        get: function () {
            return this.hydratedItem.column;
        },
        enumerable: true,
        configurable: true
    });
    MenuItemChild.prototype.setup = function (hydratedItem) {
        this.featured = false;
    };
    return MenuItemChild;
}(MenuItemAbstract));
export { MenuItemChild };
