import * as tslib_1 from "tslib";
import { ArtistNamePipe } from '../artsaas-pipes/artist-name.pipe';
import { ArtistDatesPipe } from '../artsaas-pipes/artist-dates.pipe';
import { TranslatePipe } from '../translate-pipes/translate.pipe';
import { ReplacePlaceholderPipe } from '../translate-pipes/replace-placeholder.pipe';
import { Utils } from '../utils.class';
import { ObjectUtils } from '../utils/object-utils';
import { UrlUtils } from '../utils/url-utils';
import { maybeFixDates } from '../date-functions';
var Sanitizer = /** @class */ (function () {
    function Sanitizer(sanitizer) {
        this.sanitizer = sanitizer;
    }
    Sanitizer.prototype.createSanitizedFields = function (item, sanitizedProps, sanitizationType) {
        var propName, propValue;
        var sanitized;
        var sPropName;
        var pi = 0;
        for (; pi < (sanitizedProps || []).length; pi++) {
            propName = sanitizedProps[pi];
            if (!item.hasOwnProperty(propName)) {
                continue;
            }
            propValue = item[propName] ? item[propName] : null;
            sPropName = propName + '_sanitized';
            sanitized = this.sanitize(propValue, sanitizationType);
            item[sPropName] = sanitized;
        }
        return item;
    };
    Sanitizer.prototype.sanitize = function (value, sanitizationType) {
        if (sanitizationType === void 0) { sanitizationType = 'html'; }
        if (!sanitizationType) {
            sanitizationType = 'html';
        }
        if (typeof value === 'string') {
            return this.sanitizeValue(value, sanitizationType);
        }
        var sanitizedValue = {};
        for (var prop in value) {
            if (!value.hasOwnProperty(prop)) {
                continue;
            }
            sanitizedValue[prop] = value[prop]
                ? this.sanitizeValue(value[prop], sanitizationType)
                : '';
        }
        return sanitizedValue;
    };
    Sanitizer.prototype.sanitizeValue = function (value, sanitizationType) {
        var sanitizedValue = '';
        if (!value) {
            return sanitizedValue;
        }
        switch (sanitizationType) {
            case 'html':
                sanitizedValue = this.sanitizer.bypassSecurityTrustHtml(value);
                break;
            case 'url':
                sanitizedValue = this.sanitizer.bypassSecurityTrustUrl(value);
                break;
            case 'style':
                sanitizedValue = this.sanitizer.bypassSecurityTrustStyle(value);
                break;
            case 'script':
                sanitizedValue = this.sanitizer.bypassSecurityTrustScript(value);
                break;
            case 'resource':
                sanitizedValue = this.sanitizer.bypassSecurityTrustResourceUrl(value);
                break;
        }
        return sanitizedValue;
    };
    return Sanitizer;
}());
export { Sanitizer };
/**
 * --- element kolekcji ---
 */
var ApiCollectionItemSetter = /** @class */ (function () {
    function ApiCollectionItemSetter(urlService, globalService, platformService, sanitizer) {
        this.urlService = urlService;
        this.globalService = globalService;
        this.platformService = platformService;
        this.sanitizer = sanitizer;
    }
    ApiCollectionItemSetter.prototype.setupLink = function (item) {
        if (typeof item.link === 'undefined') {
            item.link = {};
        }
        var link = item.link;
        var allowedLangs = this.urlService.allowedLangs;
        var li = 0;
        for (; li < (allowedLangs || []).length; ++li) {
            var langCode = allowedLangs[li].code;
            link[langCode] = this.urlService.link(this.getEndpoint(item), item);
        }
        return link;
    };
    return ApiCollectionItemSetter;
}());
export { ApiCollectionItemSetter };
// element kolekcji: news
var NewsColletionItemSetter = /** @class */ (function (_super) {
    tslib_1.__extends(NewsColletionItemSetter, _super);
    function NewsColletionItemSetter(urlService, globalService, platformService, sanitizer, artistNamePipe, artistDatesPipe) {
        var _this = _super.call(this, urlService, globalService, platformService, sanitizer) || this;
        _this.artistNamePipe = artistNamePipe;
        _this.artistDatesPipe = artistDatesPipe;
        return _this;
    }
    NewsColletionItemSetter.prototype.setup = function (rawItem, isCopy) {
        var item = isCopy ? rawItem : ObjectUtils.copy(rawItem);
        var sanitizer = new Sanitizer(this.sanitizer);
        item.hasIcon = item.image !== null && item.image && item.image.thumbnails;
        if (this.platformService.isBrowser) {
            maybeFixDates(item);
        }
        if (item.description_short) {
            sanitizer.createSanitizedFields(item, ['description_short']);
            if (this.platformService.isBrowser) {
                item.description_short = item.description_short_sanitized; // zgodność
            }
        }
        this.setupLink(item);
        return item;
    };
    NewsColletionItemSetter.prototype.getEndpoint = function () {
        return NewsSingleItemSetter.getEndpointName();
    };
    return NewsColletionItemSetter;
}(ApiCollectionItemSetter));
export { NewsColletionItemSetter };
// element kolekcji: wystawa
var ExhibitionsColletionItemSetter = /** @class */ (function (_super) {
    tslib_1.__extends(ExhibitionsColletionItemSetter, _super);
    function ExhibitionsColletionItemSetter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ExhibitionsColletionItemSetter.prototype.setup = function (rawItem, isCopy) {
        var item = isCopy
            ? rawItem
            : ObjectUtils.copy(rawItem);
        item.isCurrent = typeof rawItem.is_actual !== 'undefined' && parseInt(rawItem.is_actual, 10) === 1
            || rawItem.status === 'exhibition_status_in_preparation'
            || rawItem.status === 'exhibition_status_ongoing';
        // daty
        if (this.platformService.isBrowser) {
            maybeFixDates(item);
        }
        item.url = item.link;
        this.setupLink(item);
        return item;
    };
    ExhibitionsColletionItemSetter.prototype.getEndpoint = function (item) {
        return ExhibitionSingleItemSetter.getEndpointName();
    };
    return ExhibitionsColletionItemSetter;
}(ApiCollectionItemSetter));
export { ExhibitionsColletionItemSetter };
// element kolekcji: oferta
var OfferColletionItemSetter = /** @class */ (function (_super) {
    tslib_1.__extends(OfferColletionItemSetter, _super);
    function OfferColletionItemSetter(urlService, globalService, platformService, sanitizer, artistNamePipe, artistDatesPipe) {
        var _this = _super.call(this, urlService, globalService, platformService, sanitizer) || this;
        _this.artistNamePipe = artistNamePipe;
        _this.artistDatesPipe = artistDatesPipe;
        return _this;
    }
    OfferColletionItemSetter.prototype.setup = function (rawItem, isCopy) {
        var item = isCopy ? rawItem : ObjectUtils.copy(rawItem);
        var sanitizer = new Sanitizer(this.sanitizer);
        var allowedLangs = this.urlService.allowedLangs;
        // obrazy
        item.hasImages = typeof item.images !== 'undefined' && item.images && (item.images || []).length;
        // podpis
        if (item.signature) {
            sanitizer.createSanitizedFields(item, ['signature']);
        }
        // nazwy artystów / linki
        var displayArtistDates = this.globalService.settings.__loaded
            && this.globalService.settings.offer
            && this.globalService.settings.offer.display_artist_dates;
        item.artistsNames = {};
        var li = 0;
        for (; li < (allowedLangs || []).length; ++li) {
            var langCode = allowedLangs[li].code;
            var lang = allowedLangs[li].lang;
            item.artistsNames[langCode] = [];
            if (item.artists && (item.artists || []).length) {
                for (var ai = 0; ai < (item.artists || []).length; ++ai) {
                    var artist = item.artists[ai];
                    var name_1 = this.artistNamePipe.transform(artist, langCode, 'asHtml');
                    if (displayArtistDates) {
                        name_1 += ' ' + this.artistDatesPipe.transform(artist, lang, true, false);
                    }
                    item.artistsNames[langCode].push(name_1);
                }
            }
        }
        this.setupLink(item);
        return item;
    };
    OfferColletionItemSetter.prototype.getEndpoint = function (item) {
        return OfferSingleItemSetter.getEndpointName();
    };
    return OfferColletionItemSetter;
}(ApiCollectionItemSetter));
export { OfferColletionItemSetter };
// element kolekcji: produkt aukcyjny
var AuctionProductsColletionItemSetter = /** @class */ (function (_super) {
    tslib_1.__extends(AuctionProductsColletionItemSetter, _super);
    function AuctionProductsColletionItemSetter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AuctionProductsColletionItemSetter.prototype.setup = function (rawItem, isCopy) {
        var item = _super.prototype.setup.call(this, rawItem, isCopy);
        return item;
    };
    AuctionProductsColletionItemSetter.prototype.getEndpoint = function (item) {
        return AuctionSingleProductSetter.getEndpointName().replace(':auctionId', item.auction_item.auction_id);
    };
    return AuctionProductsColletionItemSetter;
}(OfferColletionItemSetter));
export { AuctionProductsColletionItemSetter };
/**
 * --- pojedynczy element z API ---
 */
var ApiSingleItemSetter = /** @class */ (function () {
    function ApiSingleItemSetter(urlService, platformService, sanitizer, sugarService) {
        this.urlService = urlService;
        this.platformService = platformService;
        this.sanitizer = sanitizer;
        this.sugarService = sugarService;
    }
    ApiSingleItemSetter.prototype.setup = function (rawItem, sanitizedProps) {
        var item = ObjectUtils.extend(true, {}, rawItem);
        item.id = parseInt(item.id, 10);
        // atrybut alt
        var iLen = (item.images || []).length;
        if (iLen > 0) {
            var index = 0;
            for (; index < iLen; ++index) {
                item.images[index].alt = item.name[this.urlService.langCode];
            }
        }
        // poprzedni / następny
        var prevNext = {
            prev: {
                id: 0,
                slug: '',
                link: '',
                routerLink: [],
                url: '',
                query: null,
                parent: null,
            },
            next: {
                id: 0,
                slug: '',
                link: '',
                routerLink: [],
                url: '',
                query: null,
                parent: null,
            },
        };
        if (typeof item._links !== 'undefined') {
            var links = {
                prev: item._links.previous,
                next: item._links.next,
            };
            var prop = void 0;
            var url = void 0;
            var id = void 0;
            for (prop in links) {
                if (!links.hasOwnProperty(prop)) {
                    continue;
                }
                if (typeof item.parent === 'object') {
                    prevNext[prop].parent = item.parent;
                }
                url = decodeURIComponent(links[prop].href);
                id = UrlUtils.extractIdFromEndpointUrl(url, this.endpoint);
                if (item.id !== id && item.id > 0) {
                    prevNext[prop].url = url;
                    prevNext[prop].id = id;
                    prevNext[prop].query = UrlUtils.extractUrlParams(url);
                    this.setPrevNextLink(prevNext[prop], prop);
                }
            }
        }
        // właściwe ustawienia
        item.prev = prevNext.prev;
        item.next = prevNext.next;
        // sanityzacja
        if (sanitizedProps && Array.isArray(sanitizedProps)) {
            this.createSanitizedFields(item, sanitizedProps);
        }
        return item;
    };
    ApiSingleItemSetter.prototype.createSanitizedFields = function (theItem, sanitizedProps) {
        var sanitizer = new Sanitizer(this.sanitizer);
        sanitizer.createSanitizedFields(theItem, sanitizedProps);
    };
    ApiSingleItemSetter.prototype.setPrevNextLink = function (targetObject, direction) {
        var _this = this;
        var httpParams = targetObject.query.hasParams
            ? targetObject.query.httpParams.toString()
            : '';
        this.sugarService.getItem(this.endpoint, targetObject.id, httpParams).subscribe(function (item) {
            targetObject.slug = typeof item.slug === 'undefined'
                ? {}
                : item.slug;
            targetObject.link = _this.getPrevNextItemLink(targetObject, direction, false);
            targetObject.routerLink = _this.getPrevNextItemLink(targetObject, direction, true);
            _this.standardizePrevNext(targetObject);
        });
    };
    ApiSingleItemSetter.prototype.transformSearchParam = function (item) {
        if (typeof item.query.params['filter[value]'] !== 'undefined') {
            item.query.params.search = item.query.params['filter[value]'];
            delete item.query.params['filter[value]'];
        }
    };
    ApiSingleItemSetter.prototype.makeBannerFirst = function (item) {
        // dodanie banneru na początku
        if (typeof item.banner === 'object' && item.banner !== null && item.banner.thumbnails) {
            var iLen = (item.images || []).length;
            var foundBanner = false;
            var index = 0;
            var banner = void 0;
            // jeśli jest banner to przesuń go na początek tablicy images;
            for (; index < iLen; ++index) {
                if (item.images[index].type === 'banner') {
                    banner = item.images[index];
                    banner.alt = item.name[this.urlService.langCode]; // alt
                    item.images.splice(index, 1);
                    item.images.unshift(banner);
                    foundBanner = true;
                    break;
                }
            }
            if (!foundBanner) {
                item.images.unshift(item.banner);
            }
        }
    };
    ApiSingleItemSetter.prototype.setBanner = function (item) {
        var sanitizedBanner = {
            type: 'empty',
            thumbnails: null,
            content: null,
        };
        var bannerType = typeof item.banner;
        if (bannerType === 'undefined' || item.banner === null) {
            bannerType = null;
        }
        if (bannerType === 'string') {
            sanitizedBanner.type = 'html';
            sanitizedBanner.content = (item.banner || []).length
                ? this.sanitizer.bypassSecurityTrustHtml(Utils.htmlEntityDecode(item.banner))
                : '';
        }
        else if (bannerType !== null) {
            // wideo nie ma określonego typu
            if (typeof item.banner.type === 'undefined') {
                sanitizedBanner.type = 'embed';
                sanitizedBanner.content = {};
                var hasUrl = typeof item.banner.url !== 'undefined';
                var lang = void 0, code = void 0;
                for (lang in this.urlService.allowedLangs) {
                    if (!this.urlService.allowedLangs.hasOwnProperty(lang)) {
                        continue;
                    }
                    code = this.urlService.allowedLangs[lang].code;
                    sanitizedBanner.content[code] = hasUrl && typeof item.banner.url[code] !== 'undefined'
                        ? item.banner.url[code]
                        : '';
                }
            }
            else {
                sanitizedBanner.type = 'image';
                sanitizedBanner.thumbnails = ObjectUtils.extend(true, {
                    detail: '',
                    slideshow: '',
                    miniature: '',
                    medium: '',
                    original: '',
                }, item.banner.thumbnails);
            }
        }
        item.sanitized_banner = sanitizedBanner;
    };
    ApiSingleItemSetter.prototype.standardizePrevNext = function (item) {
        if (typeof item.query.params.offset === 'undefined') {
            item.query.params.offset = null;
        }
        if (typeof item.query.params['filter[group.type]'] !== 'undefined') {
            delete item.query.params['filter[group.type]'];
        }
        if (typeof item.query.params['filter[archive]'] !== 'undefined') {
            item.query.params.archive = item.query.params['filter[archive]'];
            delete item.query.params['filter[archive]'];
        }
        if (this.urlService.queryParams.group) {
            item.query.params.group = this.urlService.queryParams.group;
        }
    };
    ApiSingleItemSetter.prototype.getPrevNextItemLink = function (prevNextItem, direction, forRouter) {
        var linkParts = [];
        var link = '';
        if ((prevNextItem.url || []).length > 0) {
            if (typeof prevNextItem.parent !== 'undefined' && prevNextItem.parent) {
                linkParts.push(this.urlService.link(this.type));
                linkParts.push(this.urlService.objectLink(prevNextItem.parent, '', true));
                linkParts.push(this.urlService.objectLink(prevNextItem, '', true));
            }
            else {
                var oLink = this.urlService.link(this.type, prevNextItem);
                if (typeof oLink === 'string') {
                    linkParts.push(oLink);
                }
                else {
                    linkParts = linkParts.concat(oLink);
                }
            }
            link = linkParts.join('/');
        }
        return forRouter ? linkParts : link;
    };
    ApiSingleItemSetter.endPointName = '';
    return ApiSingleItemSetter;
}());
export { ApiSingleItemSetter };
// pojedynczy element z API: artysta
var ArtistSingleItemSetter = /** @class */ (function (_super) {
    tslib_1.__extends(ArtistSingleItemSetter, _super);
    function ArtistSingleItemSetter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.endpoint = 'artists';
        _this.type = 'artists-single';
        return _this;
    }
    ArtistSingleItemSetter.prototype.setup = function (item, sanitizedProps) {
        var theItem = _super.prototype.setup.call(this, item, sanitizedProps);
        this.setBanner(theItem);
        this.makeBannerFirst(theItem);
        if (this.platformService.isBrowser) {
            maybeFixDates(theItem);
        }
        return theItem;
    };
    ArtistSingleItemSetter.prototype.standardizePrevNext = function (item) {
        _super.prototype.standardizePrevNext.call(this, item);
        this.transformSearchParam(item);
    };
    ArtistSingleItemSetter.endPointName = 'artists-single';
    return ArtistSingleItemSetter;
}(ApiSingleItemSetter));
export { ArtistSingleItemSetter };
// pojedynczy element z API: aukcja
var AuctionSingleItemSetter = /** @class */ (function (_super) {
    tslib_1.__extends(AuctionSingleItemSetter, _super);
    function AuctionSingleItemSetter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.endpoint = 'auctions';
        _this.type = 'auctions-single';
        return _this;
    }
    AuctionSingleItemSetter.sortListIds = function (a, b) {
        if (parseInt(a.auction_item.sort, 10) < parseInt(b.auction_item.sort, 10)) {
            return -1;
        }
        if (parseInt(a.auction_item.sort, 10) > parseInt(b.auction_item.sort, 10)) {
            return 1;
        }
        return 0;
    };
    AuctionSingleItemSetter.prototype.setup = function (item, sanitizedProps) {
        var theItem = _super.prototype.setup.call(this, item, sanitizedProps);
        return theItem;
    };
    AuctionSingleItemSetter.prototype.setupProducts = function (items) {
        var products = [];
        var product;
        for (var i = 0; i < (items || []).length; ++i) {
            product = ObjectUtils.copy(items[i]);
            AuctionSingleProductSetter.mapProductAuctionItem(product);
            products.push(product);
        }
        return products.sort(AuctionSingleItemSetter.sortListIds);
    };
    AuctionSingleItemSetter.endPointName = 'auctions';
    return AuctionSingleItemSetter;
}(ApiSingleItemSetter));
export { AuctionSingleItemSetter };
// pojedynczy element z API: produkt z aukcji
var AuctionSingleProductSetter = /** @class */ (function (_super) {
    tslib_1.__extends(AuctionSingleProductSetter, _super);
    function AuctionSingleProductSetter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.endpoint = 'auctions/:auctionId/products';
        _this.type = 'auctions-product';
        return _this;
    }
    AuctionSingleProductSetter.mapProductAuctionItem = function (product) {
        if (typeof product.auction_item === 'undefined') {
            product.auction_item = {};
        }
        // referencja!
        var auctionItem = product.auction_item;
        var i;
        var prop;
        for (i = 0; i < (AuctionSingleProductSetter.toIntAuctionItemProps || []).length; ++i) {
            prop = AuctionSingleProductSetter.toIntAuctionItemProps[i];
            if (typeof auctionItem[prop] === 'undefined') {
                auctionItem[prop] = 0;
            }
            else {
                auctionItem[prop] = parseInt(auctionItem[prop], 10);
            }
        }
        for (i = 0; i < (AuctionSingleProductSetter.toBoolAuctionItemProps || []).length; ++i) {
            prop = AuctionSingleProductSetter.toBoolAuctionItemProps[i];
            if (typeof auctionItem[prop] === 'undefined') {
                auctionItem[prop] = 0;
            }
            else {
                auctionItem[prop] = !!auctionItem[prop];
            }
        }
        return product;
    };
    AuctionSingleProductSetter.getEndpointName = function () {
        return AuctionSingleProductSetter.endPointName;
    };
    AuctionSingleProductSetter.prototype.setup = function (item, sanitizedProps) {
        this.endpoint = this.endpoint.replace(':auctionId', item.auction_item.auction_id);
        // auction_item
        var _item = AuctionSingleProductSetter.mapProductAuctionItem(item);
        // aukcja - rodzic
        if (typeof _item.parentAuction === 'undefined') {
            _item.parentAuction = {};
        }
        // właściwe ustawianie
        var theItem = _super.prototype.setup.call(this, item, sanitizedProps);
        // banner
        this.setBanner(theItem);
        this.makeBannerFirst(theItem);
        return theItem;
    };
    AuctionSingleProductSetter.prototype.fullySetupItem = function (item, sanitizedProps) {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            var dataSub = _this.sugarService.getData().subscribe(function (data) {
                if (data.collectionId !== 'auction-parent') {
                    return;
                }
                item.parent = ObjectUtils.copy(data.data);
                var theItem = _this.setup(item, sanitizedProps);
                _this.setBanner(theItem);
                _this.makeBannerFirst(theItem);
                theItem.parentAuction = theItem.parent;
                delete theItem.parent;
                // daty
                if (_this.platformService.isBrowser) {
                    maybeFixDates(theItem.parentAuction);
                }
                if (typeof dataSub !== 'undefined') {
                    dataSub.unsubscribe();
                }
                resolve(theItem);
            });
        });
        // aukcja nadrzędna
        this.sugarService.setCollection('auction-parent', {
            sugarNode: 'auctions/' + item.auction_item.auction_id,
            expand: 'images',
            parameters: {},
        });
        this.sugarService.refreshData('auction-parent');
        return promise;
    };
    AuctionSingleProductSetter.prototype.setupRecommended = function (items) {
        var recommended = [];
        var item;
        for (var i = 0; i < (items || []).length; ++i) {
            item = ObjectUtils.copy(items[i]);
            recommended.push(item);
        }
        return recommended.sort(AuctionSingleItemSetter.sortListIds);
    };
    AuctionSingleProductSetter.prototype.standardizePrevNext = function (item) {
        _super.prototype.standardizePrevNext.call(this, item);
        this.transformSearchParam(item);
    };
    AuctionSingleProductSetter.toIntAuctionItemProps = [
        'product_id', 'auction_id', 'is_not_sold', 'highlighted', 'is_restore_in_offer',
        'is_droit_de_suite', 'is_conditional_sale', 'is_guarantee_price',
    ];
    AuctionSingleProductSetter.toBoolAuctionItemProps = [
        'is_not_sold', 'highlighted', 'is_restore_in_offer',
        'is_droit_de_suite', 'is_conditional_sale', 'is_guarantee_price',
    ];
    return AuctionSingleProductSetter;
}(ApiSingleItemSetter));
export { AuctionSingleProductSetter };
// pojedynczy element z API: news
var NewsSingleItemSetter = /** @class */ (function (_super) {
    tslib_1.__extends(NewsSingleItemSetter, _super);
    function NewsSingleItemSetter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.endpoint = 'events';
        _this.type = 'news';
        return _this;
    }
    NewsSingleItemSetter.getEndpointName = function () {
        return NewsSingleItemSetter.endPointName;
    };
    NewsSingleItemSetter.prototype.setup = function (item, sanitizedProps) {
        var theItem = _super.prototype.setup.call(this, item, sanitizedProps);
        if (this.platformService.isBrowser) {
            maybeFixDates(theItem);
        }
        this.setBanner(theItem);
        return theItem;
    };
    NewsSingleItemSetter.prototype.standardizePrevNext = function (item) {
        _super.prototype.standardizePrevNext.call(this, item);
        this.transformSearchParam(item);
    };
    NewsSingleItemSetter.endPointName = 'events';
    return NewsSingleItemSetter;
}(ApiSingleItemSetter));
export { NewsSingleItemSetter };
// pojedynczy element z API: wystawa
var ExhibitionSingleItemSetter = /** @class */ (function (_super) {
    tslib_1.__extends(ExhibitionSingleItemSetter, _super);
    function ExhibitionSingleItemSetter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.endpoint = 'exhibitions';
        _this.type = 'exhibitions';
        return _this;
    }
    ExhibitionSingleItemSetter.getEndpointName = function () {
        return ExhibitionSingleItemSetter.endPointName;
    };
    ExhibitionSingleItemSetter.prototype.setup = function (item, sanitizedProps) {
        var theItem = _super.prototype.setup.call(this, item, sanitizedProps);
        if (this.platformService.isBrowser) {
            maybeFixDates(theItem);
        }
        this.setBanner(theItem);
        this.makeBannerFirst(theItem);
        theItem.isCurrent = parseInt(theItem.active, 10) === 1
            || theItem.status === 'exhibition_status_in_preparation'
            || theItem.status === 'exhibition_status_ongoing';
        return theItem;
    };
    ExhibitionSingleItemSetter.prototype.setupEvents = function (rawEvents) {
        var events = [];
        var ev;
        for (var e = 0; e < (rawEvents || []).length; ++e) {
            ev = ObjectUtils.copy(rawEvents[e]);
            if (this.platformService.isBrowser) {
                maybeFixDates(ev);
            }
            events.push(ev);
        }
        return events;
    };
    ExhibitionSingleItemSetter.prototype.setupWorks = function (rawWorks) {
        var works = [];
        var work;
        for (var w = 0; w < (rawWorks || []).length; ++w) {
            work = ObjectUtils.copy(rawWorks[w]);
            this.setupExhibitionItem(work);
            if (this.platformService.isBrowser) {
                maybeFixDates(work);
            }
            works.push(work);
        }
        // works.sort(this.sortWorksCb);
        // ponowne indeksowanie
        var index = 0;
        works.map(function (item) {
            item.sortKey = ++index;
        });
        return works;
    };
    ExhibitionSingleItemSetter.prototype.standardizePrevNext = function (item) {
        _super.prototype.standardizePrevNext.call(this, item);
        this.transformSearchParam(item);
    };
    ExhibitionSingleItemSetter.prototype.setupExhibitionItem = function (work) {
        if (!work.exhibition_item) {
            work.exhibition_item = {};
        }
        work.product_id = typeof work.product_id === 'undefined' ? parseInt(work.id, 10) : parseInt(work.product_id, 10);
        work.sort = typeof work.sort === 'undefined' ? -1 : parseInt(work.sort, 10);
    };
    ExhibitionSingleItemSetter.prototype.sortWorksCb = function (itemA, itemB) {
        if (itemA.exhibition_item.sort < 0 && itemB.exhibition_item.sort < 0) {
            return 0;
        }
        else if (itemA.exhibition_item.sort < 0) {
            return -1;
        }
        else if (itemB.exhibition_item.sort < 0) {
            return 1;
        }
        if (itemA.exhibition_item.sort < itemB.exhibition_item.sort) {
            return -1;
        }
        if (itemA.exhibition_item.sort > itemB.exhibition_item.sort) {
            return 1;
        }
        return 0;
    };
    ExhibitionSingleItemSetter.endpointName = '';
    return ExhibitionSingleItemSetter;
}(ApiSingleItemSetter));
export { ExhibitionSingleItemSetter };
// pojedynczy element z API: oferta
var OfferSingleItemSetter = /** @class */ (function (_super) {
    tslib_1.__extends(OfferSingleItemSetter, _super);
    function OfferSingleItemSetter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.endpoint = 'products';
        _this.type = 'products-single';
        return _this;
    }
    OfferSingleItemSetter.getEndpointName = function () {
        return OfferSingleItemSetter.endPointName;
    };
    OfferSingleItemSetter.prototype.setup = function (item, sanitizedProps) {
        var theItem = _super.prototype.setup.call(this, item, sanitizedProps);
        this.setBanner(theItem);
        this.makeBannerFirst(theItem);
        theItem.isReserved = item.availability === 'object_availabilty_reservated';
        theItem.isAvailable = item.availability === 'object_availabilty_available';
        // theItem.isAvailable = item.availability !== 'object_availabilty_sold' && item.availability !== 'object_availabilty_reservated';
        theItem.isSold = item.availability === 'object_availabilty_sold';
        return theItem;
    };
    OfferSingleItemSetter.prototype.standardizePrevNext = function (item) {
        _super.prototype.standardizePrevNext.call(this, item);
        this.transformSearchParam(item);
    };
    OfferSingleItemSetter.endPointName = 'products';
    return OfferSingleItemSetter;
}(ApiSingleItemSetter));
export { OfferSingleItemSetter };
/**
 * -- kolekcje ---
 */
var ApiCollectionSetter = /** @class */ (function () {
    function ApiCollectionSetter(urlService, sugarService, platformService, globalService, sanitizer) {
        this.urlService = urlService;
        this.sugarService = sugarService;
        this.platformService = platformService;
        this.globalService = globalService;
        this.sanitizer = sanitizer;
        var artistNamePipe = new ArtistNamePipe();
        var replacePlaceholderPipe = new ReplacePlaceholderPipe(this.urlService, this.globalService);
        var translatePipe = new TranslatePipe(this.globalService, this.urlService, replacePlaceholderPipe);
        var artistDatesPipe = new ArtistDatesPipe(translatePipe, this.sanitizer, this.globalService);
        this.pipes = {
            replacePlaceholder: replacePlaceholderPipe,
            translate: translatePipe,
            artistNames: artistNamePipe,
            artistDates: artistDatesPipe,
        };
        this.setSetter();
    }
    ApiCollectionSetter.prototype.setup = function (items, collectionName) {
        var collection = [];
        // const copy = ObjectUtils.copy(items);
        var copy = items.slice(0);
        var len = (copy || []).length;
        var i = 0;
        var item;
        for (; i < len; ++i) {
            item = this.setter.setup(copy[i], true);
            collection.push(item);
        }
        return collection;
    };
    return ApiCollectionSetter;
}());
export { ApiCollectionSetter };
// kolekcje: oferta
var OfferColletionSetter = /** @class */ (function (_super) {
    tslib_1.__extends(OfferColletionSetter, _super);
    function OfferColletionSetter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OfferColletionSetter.prototype.setSetter = function () {
        this.setter = new OfferColletionItemSetter(this.urlService, this.globalService, this.platformService, this.sanitizer, this.pipes.artistNames, this.pipes.artistDates);
    };
    return OfferColletionSetter;
}(ApiCollectionSetter));
export { OfferColletionSetter };
// kolekcje: produkty aukcyjne
var AuctionProductsColletionSetter = /** @class */ (function (_super) {
    tslib_1.__extends(AuctionProductsColletionSetter, _super);
    function AuctionProductsColletionSetter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AuctionProductsColletionSetter.prototype.setSetter = function () {
        this.setter = new AuctionProductsColletionItemSetter(this.urlService, this.globalService, this.platformService, this.sanitizer, this.pipes.artistNames, this.pipes.artistDates);
    };
    return AuctionProductsColletionSetter;
}(OfferColletionSetter));
export { AuctionProductsColletionSetter };
// kolekcje: newsy
var NewsColletionSetter = /** @class */ (function (_super) {
    tslib_1.__extends(NewsColletionSetter, _super);
    function NewsColletionSetter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NewsColletionSetter.prototype.setSetter = function () {
        this.setter = new NewsColletionItemSetter(this.urlService, this.globalService, this.platformService, this.sanitizer, this.pipes.artistNames, this.pipes.artistDates);
    };
    return NewsColletionSetter;
}(ApiCollectionSetter));
export { NewsColletionSetter };
// kolekcje: wystawy
var ExhibitionsColletionSetter = /** @class */ (function (_super) {
    tslib_1.__extends(ExhibitionsColletionSetter, _super);
    function ExhibitionsColletionSetter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ExhibitionsColletionSetter.prototype.setSetter = function () {
        this.setter = new ExhibitionsColletionItemSetter(this.urlService, this.globalService, this.platformService, this.sanitizer);
    };
    ExhibitionsColletionSetter.prototype.setupArchive = function (items, collectionName) {
        return this.setup(items, collectionName);
    };
    return ExhibitionsColletionSetter;
}(ApiCollectionSetter));
export { ExhibitionsColletionSetter };
