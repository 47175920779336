<section [style.background-image]="backgroundStyle" class="big-image-wrapper hero-image-fixed page-section">

    <div class="big-image-content">

        <div class="big-image-title">
            <a *ngIf="url" href="{{ url }}">{{ title }}</a>
            <ng-container *ngIf="! url">{{ title }}</ng-container>
        </div>

        <div *ngIf="text" class="big-image-text">{{ text }}</div>

    </div>

</section>
