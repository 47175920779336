<!--<ng-container *ngIf="globalService.settings.__loaded">-->
<ng-container>
    <div class="page-wrapper">
        <app-header #header></app-header>

        <main class="site-main">

            <router-outlet></router-outlet>

        </main>

        <section class="footer-modal-section">
            <div class="sr-only">Wyszukaj w witrynie</div>

            <div #searchModal="bs-modal" (click)="maybeCloseSearchModal($event)" bsModal
                 class="modal fade search-modal" role="dialog"
                 tabindex="-1">
                <div class="modal-dialog-content">
                    <button (click)="closeSearchModal($event); false" aria-label="Zamknij" class="close close-icon"
                            type="button"></button>

                    <div class="modal-dialog-content" role="document">

                        <div class="modal-step modal-stepless">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <form (submit)="globalSearch()" [formGroup]="searchFields"
                                          action="wyniki.html" class="form-inline search-form">

                                        <div class="modal-body">
                                            <div class="form-group row no-gutters">

                                                <div class="col-12 col-sm-10 form-control-outer">
                                                    <input #searchInput (keyup)="searchEvent($event)"
                                                           [class.invalid-search-phrase]="displaySearchInvalidityInfo('phrase')"
                                                           aria-label="Szukaj"
                                                           class="form-control-plaintext search-phrase"
                                                           formControlName="phrase"
                                                           placeholder="{{'search.form-label' | translate:urlService.lang}}"
                                                           type="search">
                                                </div>

                                                <div class="col-12 col-sm-2 col-form-label">
                                                    <button (click)="globalSearch(); false" class="search-submit"
                                                            type="submit">{{'general.submit' | translate:urlService.lang}}</button>
                                                </div>

                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <footer *ngIf="isFooterVisible" class="site-footer">
            <div class="container">
                <div [ngClass]="{'custom-footer-content' : showCustomFooterContent }"
                     class="row">
                    <ng-container *ngIf="showCustomFooterContent">
                        <div [innerHTML]="footerContent.left[urlService.lang]"
                             [ngClass]="customFooterClasses.left"
                             class="col-12 order-2 order-md-1"></div>
                        <div [innerHTML]="footerContent.center[urlService.lang]"
                             [ngClass]="customFooterClasses.center"
                             class="col-12 order-3 order-md-1"></div>
                    </ng-container>
                    <div *ngIf="!showCustomFooterContent" class="col-12 col-md-6 order-2 order-md-1">
                        <!-- mikroformat: http://microformats.org/wiki/hcard -->
                        <address *ngIf="contact && contact.hasAny()" class="vcard footer-address">
                            <div class="footer-title localization-title">
                                {{ 'general.our-address' | translate:urlService.lang }}
                            </div>

                            <p>
                                <span *ngIf="! contact.empty('name')" [innerHTML]="contact.get('name')"
                                      class="fn"></span><br/>
                                <span class="adr">
                                    <span class="adr">
                                        <span *ngIf="! contact.empty('street')"
                                              class="street-address">{{ contact.get('street') }}
                                            <ng-container
                                                *ngIf="! contact.empty('place')">/{{ contact.get('place') }}
                                            </ng-container></span><br/>
                                        <span class="postal-code">{{ contact.get('postcode') }}</span> <span
                                        *ngIf="! contact.empty('city')"
                                        class="locality">{{ contact.get('city') }}</span>
                                    </span>
                                </span>
                            </p>

                            <ul *ngIf="contact.atLeastOneNotEmpty(['phone', 'phone2', 'email', 'fax'])"
                                class="footer-contacts-list contacts-list">
                                <li *ngIf="! contact.empty('phone')">
                                    tel.:&nbsp;<span class="tel">{{ contact.get('phone') }}</span>
                                </li>
                                <li *ngIf="! contact.empty('phone2')">
                                    tel.:&nbsp;<span class="tel">{{ contact.get('phone2') }}</span>
                                </li>
                                <li *ngIf="! contact.empty('fax')">
                                    tel.:&nbsp;<span class="tel">{{ contact.get('fax') }}</span>
                                </li>
                                <li *ngIf="! contact.empty('email')">
                                    <a class="footer-link" href="mailto:{{contact.get('email')}}">e-mail:&nbsp;<span
                                        class="email">{{ contact.get('email') }}</span></a>
                                </li>
                            </ul>
                        </address>
                    </div>

                    <div [ngClass]="customFooterClasses.right"
                         class="col-12  order-1 order-md-3">
                        <section class="footer-newsletter-form site-footer-item">
                            <div
                                class="footer-newsletter-form-title">{{ 'general.subscribe' | translate:urlService.lang }}</div>

                            <app-newsletter-form></app-newsletter-form>
                        </section>

                        <section class="footer-socials">
                            <div *ngIf="socialMedia?.length > 0"
                                 class="footer-socials-title">{{ 'general.visit-us' | translate:urlService.lang }}
                                :
                            </div>

                            <ul class="footer-socials-list">
                                <ng-container *ngFor="let socialName of socialMedia.keys">

                                    <li *ngIf="socialMedia.get(socialName) && socialMedia.get(socialName) != '[object Object]' && socialName !== 'fb_api_version'"
                                        class="socials-item">
                                        <a [href]="socialMedia.get(socialName)"
                                           class="socials-link socials-{{ socialName }}" target="_blank"></a>
                                    </li>

                                </ng-container>
                            </ul>

                            <p class="footer-privacy-policy"><a
                                [routerLink]="[urlService.link('privacy-policy')]"
                                class="footer-privacy-policy-link"
                                rel="nofollow"
                                title="{{'general.privacy-policy' | translate:urlService.lang}}">{{'general.privacy-policy' | translate:urlService.lang}}</a>
                            </p>
                        </section>
                    </div>

                </div>
            </div>


        </footer>
        <a #scroller (click)="scrollTop($event); false" class="scrolltop-outer" href="#top"><span
            class="scrolltop-inner">Przewiń do góry okna</span></a>

        <div class="footer-notes">
            <p *ngIf="copyrightNote" [innerHTML]="copyrightNote" class="footer-copy-note"></p>
            <p class="footer-powered-by">Powered by <a href="http://artsaas.pl/" target="_blank">ArtSaas</a></p>
        </div>

    </div>

    <section class="footer-section">
        <div [class.show]="! hasCookieConsent()" class="cookies-message collapse" id="cookiesMessage">
            <div class="container cookies-message-container">
                <div class="cookies-message-content">
                    <div class="cookie-image"></div>
                    <div class="cookies-message-title">{{settings.general.cookie_title[urlService.lang]}}</div>
                    <p [innerHTML]="settings.general.cookie_text[urlService.lang]" class="cookies-message-text"></p>
                </div>

                <button (click)="consent(true, $event)" aria-label="Zamknij komunikat"
                        class="btn btn-primary cookies-close"
                        type="button">{{settings.general.cookie_button_text[urlService.lang]}}
                </button>
            </div>
        </div>
    </section>
</ng-container>
