import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap';
import { ArtsaasPipesModule } from '../shared/artsaas-pipes.module';
import { TranslatePipesModule } from '../shared/translate-pipes.module';
import { NewsletterFormComponent } from './newsletter-form.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    ArtsaasPipesModule,
    TranslatePipesModule,
  ],
  exports: [
    NewsletterFormComponent,
  ],
  declarations: [
    NewsletterFormComponent,
  ],
})
export class NewsletterFormModule {
}
