import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
// Locale
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { ModalModule } from 'ngx-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FacebookModule, FacebookService } from 'ngx-facebook';
import { NgwWowModule } from 'ngx-wow';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
// Routing Module
import { AppInitService } from './app-init.service';
import { AppRoutingModule } from './app.routing';
// Layouts
import { SimpleLayoutComponent } from './layouts/simple-layout.component';
import { P404Module } from './p404/p404.module';
// Shared
import { SharedModule } from './shared/shared.module';
import { TranslatePipesModule } from './shared/translate-pipes.module';
import { UrlReuseStrategy } from './shared/url-reuse-strategy.class';
import { FbService } from './shared/fb.service';
import { QueryService } from './shared/query.service';
import { CollectionsPerPageService } from './shared/collections/collections-per-page.service';
import { HeaderModule } from './layouts/partials/header/header.module';
import { NewsletterFormModule } from './newsletter-form/newsletter-form.module';
// Model & Services
import { GlobalService } from './model/global.service';
import { SettingDataService } from './model/setting-data.service';
import { CmsService } from './model/cms.service';
import { RoutingDataService } from './model/routing-data.service';
import { UrlService } from './model/url.service';
import { ComponentsService } from './model/components.service';
import { SugarService } from './model/sugar.service';
import { MetaData } from './shared/metadata/metadata.service';
import { UniversalPlatformService } from './shared/platform/universal-platform.service';
import { CookiesService } from './shared/cookies.service';
// Cache
import { CacheModule } from '@ngx-cache/core';
import { CacheService } from './shared/platform/cache.service';
import { UniversalStorage } from './shared/platform/universal-storage.class';
// Global
import { WINDOW_PROVIDERS } from './shared/platform/window.providers';
import { BodyService } from './shared/platform/body.service';
import { UniversalRendererHelper } from './shared/platform/universal-renderer.helper';
import localePl from '@angular/common/locales/pl';
import localeEn from '@angular/common/locales/pl';
import { CanActivateLanguage } from './can-activate-language';
import { ConvertEntitiesInterceptor } from './shared/convertEntities.interceptor';
import { LazyLoadDirective } from './direectives/lazy-load.directive';
import { CurrencyService } from './model/currency.service';
import { ArtsaasPipesModule } from './shared/artsaas-pipes.module';

registerLocaleData(localePl);
registerLocaleData(localeEn);

// export function cacheFactory(): CacheLoader {
//   return new CacheStaticLoader({
//     key: 'NGX_CACHE',
//     lifeSpan: {
//       "expiry": Number.MAX_VALUE,
//       "TTL":  Number.MAX_VALUE
//     }
//   });
// }

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    NgwWowModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    FacebookModule.forRoot(),
    ScrollToModule.forRoot(),
    // TransferState,
    BrowserTransferStateModule,
    CacheModule.forRoot(
      // {
      //   provide: CacheLoader,
      //   useFactory: (cacheFactory)
      // }
    ),
    TranslatePipesModule,
    P404Module,
    TransferHttpCacheModule,
    HeaderModule,
    NewsletterFormModule,
    ArtsaasPipesModule,
    // HomeModule,
  ],
  declarations: [
    AppComponent,
    SimpleLayoutComponent,
    LazyLoadDirective,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ConvertEntitiesInterceptor, multi: true },
    {
      provide: LocationStrategy,
      useClass: environment.locationStrategy === 'path' ? PathLocationStrategy : HashLocationStrategy,
    },
    // {
    //   provide: APP_BASE_HREF,
    //   useValue: '!'
    // },
    {
      provide: LOCALE_ID,
      useValue: 'pl',
    },
    {
      provide: RouteReuseStrategy,
      useClass: UrlReuseStrategy,
    },
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitService: AppInitService) => {
        return (): Promise<any> => {
          return appInitService.init();
        };
      },
      deps: [AppInitService],
      multi: true,
    },
    GlobalService,
    ComponentsService,
    SettingDataService,
    MetaData,
    UniversalPlatformService,
    CmsService,
    RoutingDataService,
    UrlService,
    SugarService,
    CookiesService,
    FacebookService,
    CanActivateLanguage,
    CacheService,
    UniversalStorage,
    UniversalRendererHelper,
    BodyService,
    FbService,
    QueryService,
    CollectionsPerPageService,
    WINDOW_PROVIDERS,
    CurrencyService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [],
})
export class AppModule {
}
