export const environment = {
  production: true,
  apiHost: "https://wejmangallery.digitalartgallery.artsaas.com/api",
  sugarApiHost: "https://apiwejmangallery.artsaas.pl/v1",
  url: "https://wejmangallery.com/",
  langs: {
    allowed: [
      {lang: "pl", code: "pl_pl"},
      // {lang: "en", code: "en_us"},
    ],
    default: "pl",
  },
  googleMapsKey: "AIzaSyD4AGyQZ85ZnW-zCrYuk3fsor8sM0sYO-4",
  reCaptchaKey: "6LdARXEUAAAAAGquT8_6CBc-m3fNNCuijqdohlaR",
  locationStrategy: "path",
  settingsRefreshInterval: 2 * 60 * 1000,
  priceUnitMap: {
    "PLN": "zł",
  }, // tymczasowo
  slugJoin: "-",
  htmlSuffix: false,
};
