import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { FacebookService, InitParams } from 'ngx-facebook';
import { GlobalService } from '../model/global.service';
import { UrlService } from '../model/url.service';
import { MetaData } from './metadata/metadata.service';
import { UniversalPlatformService } from './platform/universal-platform.service';
import { UniversalRendererHelper } from './platform/universal-renderer.helper';

@Injectable()
export class FbService implements OnDestroy {

  get initialized(): BehaviorSubject<number> {
    return this._fbInitialized;
  }

  get fbRemoved(): Subject<any> {
    return this._scriptRemoved;
  }

  get fbRefreshed(): Subject<any> {
    return this._scriptRefreshed;
  }

  get script(): ElementRef | null {
    return this._script;
  }

  private get _fbScriptUrl(): string {
    const langCode = this._urlService.langCode.substr(0, 3) + this._urlService.langCode.substr(3).toUpperCase();
    return 'https://connect.facebook.net/' + langCode + '/sdk.js';
  }

  public _settingsSub: Subscription;
  public _scriptRemoved: Subject<any>;
  public _scriptRefreshed: Subject<any>;
  private _fbInitialized: BehaviorSubject<number>;
  private _sic: number; // script initialization count
  private _parseTimeout: any;
  private _langSub: Subscription;

  private _script: ElementRef;

  constructor(
    private _rendererHelper: UniversalRendererHelper,
    private _platformService: UniversalPlatformService,
    private _globalService: GlobalService,
    private _urlService: UrlService,
    private _metadata: MetaData,
    private _fbService: FacebookService,
  ) {
    this._sic = 0;
    this._fbInitialized = new BehaviorSubject(0);

    this._scriptRemoved = new Subject();
    this._scriptRefreshed = new Subject();

    this._langSub = _urlService.langChange.subscribe(() => {
      if (this._platformService.isBrowser) {
        this.reload();
      }

      this.updateCommonOg();
    });
  }

  ngOnDestroy(): void {
    this._langSub.unsubscribe();

    if (this._settingsSub && !this._settingsSub.closed) {
      this._settingsSub.unsubscribe();
    }
  }

  updateCommonOg(): void {
    if (this._globalService.settings.__loaded) {
      this._metadata.setFbAppID(this._globalService.settings.general.fb_app_id);

      this._metadata.setOg('type', 'website');
      this._metadata.setOg('url', this._urlService.currentURL);
    }
  }

  initialize(): void {
    if (this._platformService.isBrowser) {
      this._insertFbConnectScript();
    }
  }

  parse(): void {
    clearTimeout(this._parseTimeout);

    this._parseTimeout = setTimeout(() => {
      if (typeof this._platformService.window['FB'] !== 'undefined'
        && this._platformService.window['FB'] && this._platformService.window['FB'].XFBML) {
        this._platformService.window['FB'].XFBML.parse();
      }
    }, 1000);
  }

  reload(): void {
    clearTimeout(this._parseTimeout);

    if (this._platformService.isBrowser) {
      const fbRoot = this._platformService.document.getElementById('fb-root');

      if (fbRoot) {
        fbRoot.parentNode.removeChild(fbRoot);
        this._scriptRemoved.next();
      }

      // this._rendererHelper.removeChild(this._script.nativeElement.parentNode, this._script);
      this._script.nativeElement.parentNode.removeChild(this._script.nativeElement);

      if (typeof this._platformService.window['FB'] !== 'undefined') {
        delete this._platformService.window['FB'];
      }

      this._insertFbConnectScript(true);
    }
  }

  private _insertFbConnectScript(replay = false): void {
    const script = this._platformService.document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.charset = 'utf-8';
    script.id = 'facebook-jssdk';
    script.async = true;

    this._script = new ElementRef(script);

    this._rendererHelper.addEventListener(this._script, 'load', () => {
      this._fbInit();

      if (replay) {
        this.fbRefreshed.next();
      }
    });

    script.src = this._fbScriptUrl;

    this._platformService.document.head.appendChild(script);
  }

  private _fbInit(): void {
    this._settingsSub = this._globalService.updated.subscribe((settings) => {
      if (!settings.__loaded || !settings.general.fb_app_id) {
        return;
      }

      const params: InitParams = {
        appId: settings.general.fb_app_id,
        version: settings.general.fb_api_version,
        xfbml: true,
      };

      this._fbService.init(params).then(
        // ładowanie zakończone sukcesems:
        () => {
          ++this._sic;
          this._fbInitialized.next(this._sic);
          // this.parse();
        },
      );
    });
  }

}
