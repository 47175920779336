import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { UrlService } from './model/url.service';
import { Observable } from 'rxjs';

@Injectable()
export class CanActivateLanguage implements CanActivate {

  constructor(
    private router: Router,
    private urlService: UrlService,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    // only allowed languages
    if (this.urlService.isAllowedLang(route.params.lang)) {
      return true;
    }

    // this.router.navigate([
    // this.urlService.has404()
    //   ? this.urlService.lang + '/404'
    //   : '',
    // ]);

    return false;
  }
}

//
