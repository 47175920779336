// Fork kodu z @ngx-cache - konieczny ze wzglęcdu na brak metody keys() w źródle
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
// module
import { Cache, CACHE, CacheLoader, CacheValue, LifeSpan } from '@ngx-cache/core';
import { ReturnType } from './models/return-type';

@Injectable()
export class CacheService {

  get key(): string {
    return this.loader.key;
  }

  get keys(): string[] {
    return this.cache.keys;
  }

  private static instance: CacheService = undefined;
  protected readonly cache: Cache;
  protected readonly lifeSpan: LifeSpan;

  static normalizeKey(key: string | number): string {
    if (CacheService.validateKey(key)) {
      throw new Error('Please provide a valid key to save in the CacheService');
    }

    return `${key}`;
  }

  private static validateKey(key: string | number): boolean {
    return !key
      || typeof key === 'boolean'
      || Number.isNaN(key as number);
  }

  private static validateValue(value: CacheValue): boolean {
    return value.lifeSpan.expiry && value.lifeSpan.expiry > Date.now();
  }

  constructor(readonly loader: CacheLoader,
              @Inject(PLATFORM_ID) private readonly platformId: any,
              private readonly injector: Injector) {
    CacheService.instance = this;

    this.cache = this.injector.get(CACHE);
    this.lifeSpan = loader.lifeSpan;
  }

  has(key: string | number): boolean {
    key = CacheService.normalizeKey(key);

    return this.cache.keys.indexOf(key) !== -1;
  }

  set(key: string | number, value: any, returnType: ReturnType = ReturnType.Scalar, lifeSpan?: LifeSpan): boolean {
    key = CacheService.normalizeKey(key);
    lifeSpan = lifeSpan || this.lifeSpan;

    return this.cache.setItem(key, {
      data: value,
      returnType,
      lifeSpan: this.parseLifeSpan(lifeSpan),
    });
  }

  get(key: string | number): any {
    key = CacheService.normalizeKey(key);
    const cached = this.cache.getItem(key);
    let res;

    if (cached) {
      if (CacheService.validateValue(cached)) {
        res = cached.data;
      } else {
        this.remove(key);
      }
    }

    return res;
  }

  remove(key: string | number, wild = false): void {
    key = CacheService.normalizeKey(key);

    this.cache.removeItem(key, wild);
  }

  clear(): void {
    this.cache.clear();
  }

  private parseLifeSpan(lifeSpan: LifeSpan): LifeSpan {
    return {
      expiry: lifeSpan.expiry || (lifeSpan.TTL ? Date.now() + (lifeSpan.TTL * 1000) : this.lifeSpan.expiry),
      TTL: lifeSpan.TTL || this.lifeSpan.TTL,
    };
  }
}
