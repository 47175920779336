<div *ngIf="hasPartners()" class="partners-block">

    <div class="partners-title">{{'general.our-partners' | translate:urlService.lang}}</div>

    <ul class="partners-list">
        <li *ngFor="let partner of partners" class="partners-item">
            <a *ngIf="partner.url; else noLinkPartner" class="partner-link" href="{{ partner.url }}"
               title="{{ partner.title[urlService.lang] }}">
                <img appLazyLoad class="img-fluid partner-logo" src="{{ partner.image }}"/>
            </a>

            <ng-template #noLinkPartner>
                <span class="partner-nolink">
                    <img appLazyLoad class="img-fluid partner-logo" src="{{ partner.image }}"/>
                </span>
            </ng-template>
        </li>
    </ul>

</div>
