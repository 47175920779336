import { map } from 'rxjs/operators';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export const currenciesArray = ['PLN', 'USD', 'GBP', 'EUR', 'zł'];
export declare type currenciesType = 'PLN' | 'USD' | 'GBP' | 'EUR' | 'zł';

export interface CurrencyExchangeValuesInterface {
  base: currenciesType,
  rates: {
    [K in currenciesType]: number
  }
  date: string,
}

@Injectable()
export class CurrencyService {
  public static currencyExchangeValues?: CurrencyExchangeValuesInterface = null;
  @Output() onCurrencyChange: EventEmitter<currenciesType> = new EventEmitter();
  public apiHost: string;
  private currencies = ['USD', 'GBP', 'EUR', 'PLN'];
  private apiKey = '4ba14594b38635b1116d660af8e3665c';

  constructor(
    private _http: HttpClient,
  ) {
    this.apiHost = `https://data.fixer.io/api/latest`;
  }

  changeTargetCurrency(targetCurrency: currenciesType) {
    this.getCurrencyExchangeValues().subscribe(() => {
      this.onCurrencyChange.emit(targetCurrency);
    });
  }

  getCurrencyExchangeValues(baseCurrency: currenciesType = 'PLN'): Observable<CurrencyExchangeValuesInterface> {
    baseCurrency = baseCurrency === 'zł' ? 'PLN' : baseCurrency;

    const url = this.apiHost + '?access_key=' + this.apiKey + '&symbols=' + this.getSymbols(baseCurrency) + '&base=' + baseCurrency;

    if ((CurrencyService.currencyExchangeValues && CurrencyService.currencyExchangeValues.base === baseCurrency)) {
      return of(CurrencyService.currencyExchangeValues);
    }
    return this._http.get<any>(url).pipe(
      map(response => {
        CurrencyService.currencyExchangeValues = response;
        return CurrencyService.currencyExchangeValues;
      }),
      map((response) => {
        return <any>response;
      }),
    );
  }

  getSymbols(excludeCurrency?: currenciesType) {
    return this.currencies.filter(value => value !== excludeCurrency);
  }
}
