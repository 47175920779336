import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { UniversalStorage } from './universal-storage.class';
var UniversalPlatformService = /** @class */ (function () {
    function UniversalPlatformService(_platformID, _window, _document, _localStorage) {
        this._platformID = _platformID;
        this._window = _window;
        this._document = _document;
        this._localStorage = _localStorage;
        this._isBrowser = isPlatformBrowser(_platformID);
        this._isServer = isPlatformServer(_platformID);
    }
    Object.defineProperty(UniversalPlatformService.prototype, "localStorage", {
        get: function () {
            return this._localStorage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UniversalPlatformService.prototype, "window", {
        get: function () {
            return this._window;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UniversalPlatformService.prototype, "document", {
        get: function () {
            return this._document;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UniversalPlatformService.prototype, "windowWidth", {
        get: function () {
            return this.isBrowser
                ? (this.window.innerWidth
                    || this.document.documentElement.clientWidth
                    || this.document.getElementsByTagName('body')[0].clientWidth)
                : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UniversalPlatformService.prototype, "isBrowser", {
        get: function () {
            return this._isBrowser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UniversalPlatformService.prototype, "isServer", {
        get: function () {
            return this._isServer;
        },
        enumerable: true,
        configurable: true
    });
    return UniversalPlatformService;
}());
export { UniversalPlatformService };
