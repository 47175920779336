import {Component, Input, SecurityContext} from '@angular/core';
import {currenciesArray, currenciesType, CurrencyExchangeValuesInterface, CurrencyService} from '../model/currency.service';
import {thousandSeparator} from '../helpers/helpers';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {GlobalService} from '../model/global.service';
import {LangChangeData, UrlService} from '../model/url.service';

@Component({
  selector: 'app-price-exchange',
  templateUrl: './price-exchange.component.html',
  styles: [`
    div {
      font-weight: 300;
      font-size: 14px;
      padding-top: 5px;
      text-align: right;
    }
  `],
})
export class PriceExchangeComponent {

  get price(): number | string | SafeHtml {
    return Number(this._price);
  }

  @Input() set price(price: number | string | SafeHtml) {
    const priceString = this.domSanitizer.sanitize(SecurityContext.HTML, price);
    const priceArr = priceString.split('&nbsp;');

    if (Number.isInteger(Number(priceArr[(priceArr || []).length - 1]))) {
      this._price = Number(priceArr.join(''));
    } else {
      this.fromCurrency = priceArr.pop() as currenciesType;
      this._price = Number(priceArr.join(''));
    }
    if (this.type) {
      this.countValue(this.selectedCurrency);
    }
  }

  private get fromCurrency(): currenciesType {
    return this._fromCurrency;
  }

  private set fromCurrency(currency: currenciesType) {
    if (!currenciesArray.includes(currency)) {
      currency = 'PLN';
    }
    currency = currency === 'zł' ? 'PLN' : currency;
    this._fromCurrency = currency;
  }

  @Input() type: 'work' | 'auction' = 'work';
  @Input() item = null;
  @Input() paddingLeft = '0';
  afterExchange = null;
  selectedCurrency: currenciesType = 'PLN';

  private _price: number | string;

  _fromCurrency: currenciesType = 'PLN';

  constructor(
    public currencyService: CurrencyService,
    private domSanitizer: DomSanitizer,
    public globalService: GlobalService,
    public urlService: UrlService,
  ) {
    currencyService.onCurrencyChange.subscribe((value: currenciesType) => this.countValue(value));
    if (urlService.lang === 'en') {
      currencyService.changeTargetCurrency('EUR');
    }
    urlService.langChange.subscribe((lang: LangChangeData) => {
      if (lang.langTo === 'en') {
        currencyService.changeTargetCurrency('EUR');
      }
    });
  }

  countValue(currency: currenciesType) {
    this.selectedCurrency = currency;
    if (this.selectedCurrency === this.fromCurrency) {
      this.afterExchange = null;
      return;
    }

    switch (this.type) {
      case 'auction':
        this.countEstimation(currency);
        break;
      case 'work':
        this.countPrice(currency);
        break;
      default:
        return;
    }
  }

  private countPrice(currency: currenciesType) {
    this.currencyService.getCurrencyExchangeValues(this.fromCurrency)
      .subscribe((value: CurrencyExchangeValuesInterface) => {
        const val = Math.ceil((Number(this.price) * value.rates[currency]) / 10) * 10;
        this.afterExchange = `${thousandSeparator(val)} ${this.selectedCurrency}`;
      });
  }

  private countEstimation(currency: currenciesType) {
    this.currencyService.getCurrencyExchangeValues(this.fromCurrency).subscribe((value: CurrencyExchangeValuesInterface) => {
      let from = (this.item.auction_item.estimation_from && (this.item.auction_item.estimation_from || []).length)
        ? this.item.auction_item.estimation_from : '';
      let to = (this.item.auction_item.estimation_to && (this.item.auction_item.estimation_to || []).length)
        ? this.item.auction_item.estimation_to : '';

      from = Math.ceil((from * value.rates[currency]) / 10) * 10;
      from = `${thousandSeparator(from)}`;
      to = Math.ceil((to * value.rates[currency]) / 10) * 10;
      to = `${thousandSeparator(to)} ${this.selectedCurrency}`;
      this.afterExchange = `${from} - ${to}`;
    });
  }

}
