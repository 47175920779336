import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { OwlCarousel } from 'ngx-owl-carousel';
import { GlobalService } from '../../model/global.service';
import { SugarService } from '../../model/sugar.service';
import { UrlService } from '../../model/url.service';
import { UniversalPlatformService } from '../../shared/platform/universal-platform.service';
import { ObjectUtils } from '../../shared/utils/object-utils';
import { Utils } from '../../shared/utils.class';
import { ApiCollectionSetter, AuctionProductsColletionSetter, OfferColletionSetter } from '../../shared/collections/api-collection-setters';
import { BlocksContext } from '../../blocks-wrapper/blocks-wrapper.component';
import { AutoUnsubscribe } from '../../decorators/autounsubscribe.decorator';
import { Router } from '@angular/router';

export declare type ProductsCarouselMode = 'products_latest' | 'products_featured' | 'products_auction_featured' | 'products_same_category';

@AutoUnsubscribe()
@Component({
  selector: 'app-products-carousel-block',
  templateUrl: './products-carousel-block.component.html',
})
export class ProductsCarouselBlockComponent implements OnInit, AfterViewInit, OnDestroy {

  public items: { [key: string]: any }[];
  public options: { [key: string]: any };

  activeTextElementsOnHoverEnabled = false;
  public block: {
    title: string,
    group: string | null,
    navContainerId?: string,
  };

  public enableOwl: boolean;
  public mode: ProductsCarouselMode;
  @Input()
  blockConfig: {
    type: ProductsCarouselMode,
    header: string,
    number?: number,
    animation_main: { [key: string]: string },
  };
  @Input()
  context: BlocksContext;
  @ViewChild('owl')
  owl: OwlCarousel;
  @ViewChild('nav')
  nav: ElementRef;
  @Output()
  viewInit = new EventEmitter<string>();
  zoomImages: boolean;
  protected dataSub: Subscription;
  protected collection: string;
  protected successCalled: boolean;

  constructor(
    public globalService: GlobalService,
    public urlService: UrlService,
    private platformService: UniversalPlatformService,
    private sugarService: SugarService,
    private sanitizer: DomSanitizer,
    private router: Router,
  ) {
    this.zoomImages = this.globalService.settings.global.sliderRotation === 'true';
    this.activeTextElementsOnHoverEnabled = this.globalService.settings.global.activeTextElementsOnHoverEnabled === 'true';
    this.collection = Utils.uniqid('products-carousel-');

    this.block = {
      title: '',
      group: null,
    };

    this.enableOwl = false;
  }

  ngOnInit(): void {
    const collectionParameters: { [key: string]: string | number } = {
      expand: 'images,artists',
      'filter[archive]': '0',
    };

    if (typeof this.blockConfig.number !== 'undefined') {
      collectionParameters['per-page'] = this.blockConfig.number;
    }

    const collectionInit = {
      sugarNode: 'products',
      parameters: collectionParameters,
    };

    let load = true;

    this.mode = this.blockConfig.type;

    this.options = {
      dragEndSpeed: 1000,
      navSpeed: 1500,
      margin: 10,
      nav: false,
      navText: ['', ''],
      dots: false,
      responsive: {
        0: {
          items: 1,
        },
        576: {
          items: 1,
        },
        768: {
          items: 2,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 4,
        },
      },
    };

    this.block.title = this.blockConfig.header;

    switch (this.mode) {
      case 'products_latest':
        collectionParameters.sort = '-created_at';
        break;

      case 'products_same_category':
        let parent: string;

        if (this.context === 'work') {
          parent = 'works';
        } else if (this.context === 'auction-product') {
          parent = 'works';
          collectionParameters.expand += ',auction_item';
        } else {
          load = false;
        }

        if (parent) {

          if (this.sugarService.hasCollection(parent)) {
            const filters = this.sugarService.getFilters(parent);

            if (filters && filters.cat) {
              let index = 0;

              ObjectUtils.map(filters.cat, (fltr) => {
                collectionParameters['filter[cat][' + index + ']'] = fltr.id;
                ++index;
              });

            }
          }

        }
        break;

      case 'products_featured':
        this.block.group = 'recommended-items';

        collectionParameters['filter[group.type]'] = 'exhibition_category_recommended';
        break;

      case 'products_auction_featured':
        this.block.navContainerId = Utils.uniqid('auction-products-');

        this.options.nav = true;
        this.options.navContainer = '#' + this.block.navContainerId + ' .recommendations-nav';

        // collectionInit.sugarNode = "auction_items";
        collectionParameters['filter[highlighted]'] = '1';
        collectionParameters.expand += ',auction_item';
        break;
    }

    this.sugarService.setCollection(this.collection, collectionInit);

    let collectionSetter: ApiCollectionSetter;

    if (this.mode === 'products_auction_featured') {

      collectionSetter = new AuctionProductsColletionSetter(
        this.urlService,
        this.sugarService,
        this.platformService,
        this.globalService,
        this.sanitizer,
      );

    } else {

      collectionSetter = new OfferColletionSetter(
        this.urlService,
        this.sugarService,
        this.platformService,
        this.globalService,
        this.sanitizer,
      );

    }

    this.dataSub = this.sugarService.getData().subscribe(data => {
      if (data.collectionId !== this.collection) {
        return;
      }

      this.items = collectionSetter.setup(data.data, this.collection);
    });

    this.sugarService.refreshData(this.collection);
  }

  ngOnDestroy(): void {
    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }

    this.sugarService.unsetCollection(this.collection);
  }

  ngAfterViewInit(): void {
    const waiter = new Utils.waiter(
      () => {
        return typeof this.nav !== 'undefined' && typeof this.nav.nativeElement !== 'undefined' && this.nav.nativeElement;
      },
      this.createLoadedCb(true),
      this.createLoadedCb(false),
    );

    waiter.setMaxTicks(500);
    waiter.setTickInterval(50);
    waiter.start();
  }

  getLink(item: any) {
    const slug = this.urlService.objectLink(item, '', true) as string;
    if (this.mode === 'products_auction_featured' || this.mode === 'products_featured') {
      const parts = window.location.href.split('?');
      const url = parts[0].replace(window.location.origin, '');
      const urlArr = url.split('/');
      if (urlArr[1] === '#') {
        urlArr.splice(1, 1);
      }
      const lastSlug = urlArr[urlArr.length - 1];
      const newUrl = urlArr.join('/');
      return newUrl.replace(lastSlug, slug);
    } else {
      const menu = this.platformService.localStorage.getItem('menu');
      if (menu) {
        try {
          const offer = JSON.parse(menu).find((it: any) => it.content_type === 'products');
          if (offer) {
            return '/' + this.urlService.lang + '/' + offer.url + '/' + slug;
          }
        } catch (e) {
          return slug;
        }
      }
      return slug;
    }
  }

  openProduct(item: any, queryParams: object, e: any) {
    e.preventDefault();
    this.router.navigate([this.getLink(item)], { queryParams });
  }

  onClickItem(offset: number): void {
    this.sugarService.updateParam('works', 'offset', offset + '');
  }

  protected createLoadedCb(makeSuccess: boolean): any {
    return makeSuccess
      ? () => {
        this.onSuccessfullyLoaded();
      }
      : () => {
        this.onSuccessfullyLoaded(true);
      };
  }

  protected onSuccessfullyLoaded(fakeSuccess = false): void {
    if (this.successCalled) {
      return;
    }

    if (!fakeSuccess) {
      this.options.nav = true;
      this.options.navContainer = this.nav.nativeElement;

      this.enableOwl = true;
    }

    this.viewInit.emit(this.mode);
    this.successCalled = true;
  }
}
