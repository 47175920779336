import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArtsaasPipesModule } from '../shared/artsaas-pipes.module';
import { TranslatePipesModule } from '../shared/translate-pipes.module';
import { TextBlockComponent } from '../blocks/text-block/text-block.component';
import { PartnersBlockComponent } from '../blocks/partners-block/partners-block.component';
import { BigImageBlockComponent } from '../blocks/big-image-block/big-image-block.component';
import { TextImageBlockComponent } from '../blocks/text-image-block/text-image-block.component';
import { ProductCounterBlockComponent } from '../blocks/product-counter-block/product-counter-block.component';
import { SliderBlockModule } from '../blocks/slider-block/slider-block.module';
import { SmallSliderBlockModule } from '../blocks/small-slider-block/small-slider-block.module';
import { NewsSliderBlockModule } from '../blocks/news-slider-block/news-slider-block.module';
import { ProductsCarouselBlockModule } from '../blocks/products-carousel-block/products-carousel-block.module';
import { OfferFormBlockModule } from '../blocks/offer-form-block/offer-form-block.module';
import { SoldItemsBlockModule } from '../blocks/sold-items-block/sold-items-block.module';
import { BlocksWrapperComponent } from './blocks-wrapper.component';

@NgModule({
  imports: [
    CommonModule,
    SliderBlockModule,
    SmallSliderBlockModule,
    NewsSliderBlockModule,
    ArtsaasPipesModule,
    TranslatePipesModule,
    ProductsCarouselBlockModule,
    OfferFormBlockModule,
    SoldItemsBlockModule,
  ],
  exports: [
    BlocksWrapperComponent,
  ],
  declarations: [
    BlocksWrapperComponent,
    TextBlockComponent,
    PartnersBlockComponent,
    BigImageBlockComponent,
    TextImageBlockComponent,
    ProductCounterBlockComponent,
  ],
})
export class BlocksWrapperModule {
}
