import { filter } from 'rxjs/operators';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT, PlatformLocation } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ScrollToService } from 'ng2-scroll-to-el';
import { CmsService } from '../model/cms.service';
import { SugarService } from '../model/sugar.service';
import { GlobalService } from '../model/global.service';
import { UrlService } from '../model/url.service';
import { ComponentsService } from '../model/components.service';
import { UniversalPlatformService } from '../shared/platform/universal-platform.service';
import { UniversalRendererHelper } from '../shared/platform/universal-renderer.helper';
import { CookiesService } from '../shared/cookies.service';
import { Utils } from '../shared/utils.class';
import { ContactDataExtractor, SocialsDataExtractor } from '../shared/collections/data-extractors';
import { SanitizedDataCollection } from '../shared/collections/sanitized-data-collection.class';
import { HeaderComponent } from './partials/header/header.component';
import { AppComponent } from '../app.component';
import { Lang, SettingsModelInterface } from '../interfaces/settings-model.interface';
import { removeDiacritics } from '../helpers/string-helpers';
import { AutoUnsubscribe } from '../decorators/autounsubscribe.decorator';


@AutoUnsubscribe()
@Component({
  selector: 'app-simple',
  templateUrl: './simple-layout.component.html',
})
export class SimpleLayoutComponent implements OnInit, OnDestroy, AfterViewInit {

  public searchFields: FormGroup;
  public _href: string;
  public contact: SanitizedDataCollection;
  public copyrightNote: SafeHtml;
  public _logoLargeUrl = '';
  public _logoSmallUrl = '';
  public settings: SettingsModelInterface;
  @ViewChild('header')
  header: HeaderComponent;
  @ViewChild('scroller')
  scroller: ElementRef;
  @ViewChild('searchModal')
  searchModal: ModalDirective;
  @ViewChild('searchInput')
  searchInput: ElementRef;
  public isFooterVisible: boolean;
  showCustomFooterContent: boolean;
  footerContent: {
    left: Lang<SafeHtml>,
    center: Lang<SafeHtml>
  };
  footerBlocksWidth: {
    left: string,
    center: string,
    right: string,
  };
  customFooterClasses = {
    left: {},
    center: {},
    right: {},
  };
  protected socialData: SanitizedDataCollection;
  private _hasCookieConsent: boolean;
  private _showSearchValidation: boolean;
  private _pageY: number;
  private _lastpageY: number;
  private _sugarErrorSub: Subscription;
  private _cmsErrorSub: Subscription;
  private _searchTriggerSub: Subscription;
  private _langChangeSub: Subscription;
  private _routerSub: Subscription;
  private _sdsSub: Subscription;
  private _popSub: boolean;
  private isSafari = false;

  constructor(
    @Inject(DOCUMENT) private _document,
    public globalService: GlobalService,
    public cmsService: CmsService,
    public urlService: UrlService,
    private _rendererHelper: UniversalRendererHelper,
    private _platformService: UniversalPlatformService,
    private _sugarService: SugarService,
    private _componentsService: ComponentsService,
    private _router: Router,
    private _platformLocation: PlatformLocation,
    private _sanitizer: DomSanitizer,
    private _formBuilder: FormBuilder,
    private _cookiesService: CookiesService,
    private _scrollService: ScrollToService,
    public _rootComp: AppComponent,
  ) {
    this.checkIfIsSafari();
    this._href = _platformService.window.location ? _platformService.window.location.href : '';

    this._hasCookieConsent = _platformService.isServer ? true : this._cookiesService.getCookie('cookie-consent') === 'yes';

    this._createForms();

    this._sugarErrorSub = _sugarService.requestError.subscribe(() => {
      this._router.navigateByUrl(this.urlService.link('404'));
    });

    this._cmsErrorSub = cmsService.requestError.subscribe(() => {
      this._router.navigateByUrl(this.urlService.link('404'));
    });

    this.socialData = new SanitizedDataCollection();

    if (_platformService.isBrowser) {
      this._routerSub = _router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(() => {
        const pageYHistory = this._platformService.localStorage.getItem('pageYHistory') || [];
        const pageY = this._getPageY();
        pageYHistory.push(pageY);

        if ((pageYHistory || []).length === 50) {
          pageYHistory.shift();
        }

        this._platformService.localStorage.setItem(
          'pageYHistory',
          pageYHistory,
          this._platformService.localStorage.countDays(1),
        );

        this._rendererHelper.addEventListener(this._platformService.window, 'beforeunload', () => {
          this._platformService.localStorage.removeItem('pageYHistory');
        });

      });

      _platformLocation.onPopState(() => {
        if (this._popSub) {
          const pageYHistory = this._platformService.localStorage.getItem('pageYHistory') || [];
          const pageY = (pageYHistory || []).length > 0 ? pageYHistory.pop() : 0;

          setTimeout(() => {
            this._platformService.window.scrollTo(0, pageY);
          });

          this._platformService.localStorage.setItem(
            'pageYHistory',
            pageYHistory,
            this._platformService.localStorage.countDays(1),
          );
        }
      });

      this._popSub = true;
    }

    this.contact = new SanitizedDataCollection;

    this._sdsSub = this.globalService.updated.subscribe(settings => {
      if (settings.__loaded) {
        this.settings = settings;
        this.isFooterVisible = this.settings.global.footerVisible === 'true';
        this.showCustomFooterContent = this.settings.global.showCustomFooterContent === 'true';
        this.footerBlocksWidth = this.settings.global.footerBlocksWidth;
        this.customFooterClass();
        this.footerContent = {
          left: {
            pl: this._sanitizer.bypassSecurityTrustHtml(
              typeof this.settings.global.footerContent.left.pl === 'string'
                ? this.settings.global.footerContent.left.pl
                : '',
            ),
            en: this._sanitizer.bypassSecurityTrustHtml(
              typeof this.settings.global.footerContent.left.en === 'string'
                ? this.settings.global.footerContent.left.en
                : '',
            ),
          },
          center: {
            pl: this._sanitizer.bypassSecurityTrustHtml(
              typeof this.settings.global.footerContent.center.pl === 'string'
                ? this.settings.global.footerContent.center.pl
                : '',
            ),
            en: this._sanitizer.bypassSecurityTrustHtml(
              typeof this.settings.global.footerContent.center.en === 'string'
                ? this.settings.global.footerContent.center.en
                : '',
            ),
          },
        };
        this._logoLargeUrl = this.settings.general.logo ? this.settings.general.logo : '/assets/images/logo.png';
        this._logoSmallUrl = this.settings.general.logo_mini ? this.settings.general.logo_mini : '/assets/images/logo-alt.png';
        // favicon
        if (settings.general.favicon) {
          this._setFavicon(settings.general.favicon);
        }

        this.onChangeLangOrData();
      }
      this.urlService.langChange.subscribe(() => this.onChangeLangOrData());
    });
  }

  get socialMedia(): SanitizedDataCollection {
    return this.socialData;
  }

  customFooterClass() {
    const leftClass = this.footerBlocksWidth ? (this.footerBlocksWidth.left || 'col-md-4') : 'col-md-4';
    const centerClass = this.footerBlocksWidth ? (this.footerBlocksWidth.center || 'col-md-4') : 'col-md-4';
    const rightClass = this.showCustomFooterContent
      ? (this.footerBlocksWidth
        ? (this.footerBlocksWidth.right || 'col-md-4')
        : 'col-md-4')
      : 'col-md-6';

    this.customFooterClasses.left[leftClass] = true;
    this.customFooterClasses.center[centerClass] = true;
    this.customFooterClasses.right[rightClass] = true;
  }

  checkIfIsSafari() {
    if (this._platformService.isBrowser) {
      this.isSafari = navigator && navigator.vendor && navigator.vendor.includes('Apple') &&
        navigator.userAgent &&
        !navigator.userAgent.includes('CriOS') &&
        !navigator.userAgent.includes('FxiOS');
    }
  }

  ngOnInit(): void {
    this.checkIfIsSafari();
    this._rootComp.fbService.updateCommonOg();

    this._getPageY();
    this._lastpageY = this._pageY;

    // @TODO: powiązać z ustawieniami
    this._logoLargeUrl = '/assets/images/logo.png';
    this._logoSmallUrl = '/assets/images/logo-alt.png';
  }

  ngOnDestroy(): void {
    this._sugarErrorSub.unsubscribe();
    this._cmsErrorSub.unsubscribe();

    if (this._searchTriggerSub) {
      this._searchTriggerSub.unsubscribe();
    }

    if (this._langChangeSub) {
      this._langChangeSub.unsubscribe();
    }

    if (this._sdsSub) {
      this._sdsSub.unsubscribe();
    }

    if (this._platformService.isBrowser) {
      if (this._routerSub) {
        this._routerSub.unsubscribe();
      }

      this._popSub = false;
    }
  }

  ngAfterViewInit(): void {
    if (this._platformService.window.location.hash.endsWith('#archived')) {
      setTimeout(() => {
        try {
          this._document.querySelector('#archived').scrollIntoView();
        } catch (e) {
        }
      }, 1500);
    }

    const headerSubs = () => {
      this._searchTriggerSub = this.header.searchTrigger.subscribe(() => {
        this.openSearchModal();
      });

      this._langChangeSub = this.header.langChange.subscribe((lang) => {
        this.changeLang(lang);
      });
    };

    const headerWaiter = new Utils.waiter(
      () => {
        return this.header !== null && typeof this.header !== 'undefined';
      },
      headerSubs,
      () => {
      },
    );

    headerWaiter.start();

    const searchSubs = () => {
      this.searchModal.onShown.subscribe(() => {
        this._rootComp.bodyClass = this._rendererHelper.classNameHelper.add(
          this._rootComp.bodyClass,
          ['search-modal-open', 'modal-open'],
        );

        this._rendererHelper.trigger(this.searchInput, 'focus');
      });

      this.searchModal.onHide.subscribe(() => {
        this._rootComp.bodyClass = this._rendererHelper.classNameHelper.remove(
          this._rootComp.bodyClass,
          ['search-modal-open', 'modal-open'],
        );
      });
    };

    const searchWaiter = new Utils.waiter(
      () => {
        return this.searchModal !== null && typeof this.searchModal !== 'undefined';
      },
      searchSubs,
      () => {
      },
    );

    searchWaiter.start();

    setTimeout(() => {
      const siteTransitionPreloader = this._platformService.document.getElementById('site-transition-preloader');

      if (siteTransitionPreloader) {
        this._rendererHelper.removeClass(siteTransitionPreloader, 'site-transition-preloader--visible');
      }
    }, 300);
  }

  /** SEARCH **/
  searchEvent(evt: any): void | boolean {
    const searchPhrase = this.searchFields.get('phrase');

    if (evt && evt.keyCode === 13) {

      if (!searchPhrase.valid) {
        if ((searchPhrase.value || []).length) {
          this._showSearchValidation = true;
        }

        evt.preventDefault();
        return false;
      }

      this._showSearchValidation = false;
      this.globalSearch();

    } else {

      if ((searchPhrase.value || []).length === 0) {
        this._showSearchValidation = false;
      }

    }
  }

  globalSearch(evt?: any): void | boolean {
    const searchPhrase = this.searchFields.get('phrase');

    if (!searchPhrase.valid) {
      if (evt && evt.preventDefault) {
        evt.preventDefault();
      }

      this._showSearchValidation = true;
      return false;
    }

    this._showSearchValidation = false;

    const urlPhrase: string = searchPhrase.value;

    this.searchFields.patchValue({
      phrase: '',
    });

    this.closeSearchModal();
    this._router.navigateByUrl(this.urlService.link('search-results') + '/' + urlPhrase);
  }

  displaySearchInvalidityInfo(name: string): boolean {
    const field = this.searchFields.get(name);

    if (!field) {
      return false;
    }

    //    if (field.touched) {
    //      return field.invalid;
    //    }

    return this._showSearchValidation ? field.invalid : false;
  }

  openSearchModal(evt?: any): void {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }

    if (!this.searchModal.isShown) {
      this.searchModal.show();
    }
  }

  maybeCloseSearchModal(evt): void {
    if (!evt || !evt.target) {
      return;
    }

    const targetTag = evt.target.tagName.toLowerCase();

    if (targetTag === 'input' || targetTag === 'button') {
      return;
    }

    this.closeSearchModal(evt);
  }

  closeSearchModal(evt?: any): void {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }

    if (this.searchModal.isShown) {
      this.searchModal.hide();
    }
  }

  /** COOKIES **/
  consent(isConsent: boolean, evt?: any): void | boolean {
    if (isConsent) {
      evt.preventDefault();
      this._cookiesService.setCookie('cookie-consent', 'yes', 30);
      this._hasCookieConsent = true;
    } else {
      return this._hasCookieConsent;
    }
  }

  hasCookieConsent(): boolean {
    return this._hasCookieConsent;
  }

  scrollTop(evt?: any): void {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }

    this._scrollService.scrollTo('#top', 600);
  }

  /** VARIA **/
  changeLang(lang: string): void {
    this.urlService.changeLanguage(lang);
  }

  private onChangeLangOrData() {

    // kontakt
    const contactExtractor = new ContactDataExtractor(this._sanitizer, this.urlService);
    this.contact = contactExtractor.create(this.settings.contact);

    // społeczności
    const socialsExtractor = new SocialsDataExtractor(this._sanitizer, this.urlService);
    this.socialData = socialsExtractor.create(this.settings.social);

    // notka o prawach autorskich
    this.copyrightNote = this.settings.contact.copyright && this.settings.contact.copyright[this.urlService.lang]
      ? this._sanitizer.bypassSecurityTrustHtml(this.settings.contact.copyright[this.urlService.lang])
      : '';


    const stringsToReplace = ['polityka prywatnosci', 'polityki prywatnosci', 'polityce prywatnosci', 'privacy policy'];
    let cookie_text = this.settings.general.cookie_text[this.urlService.lang];
    if (cookie_text) {
      for (const str of stringsToReplace) {
        const index = removeDiacritics(cookie_text).toLowerCase().indexOf(str);
        if (index >= 0) {
          const replaceInParagraph = cookie_text.substr(index, 20);
          const replaceWith = `<a rel="nofollow" href="${this.urlService.link('privacy-policy')}" class="cookies-message-link">${replaceInParagraph}</a>`;
          cookie_text = cookie_text.replace(new RegExp(replaceInParagraph, 'g'), replaceWith);
        }
      }
      this.settings.general.cookie_text[this.urlService.lang] = cookie_text;
    }
  }

  private _setFavicon(url: string): void {
    if (typeof url === 'object') {
      url = '';
    }
    const ext = url.split('.').pop();
    let type: string;

    if (ext === 'jpg' || ext === 'jpeg') {
      type = 'image/jpeg';
    } else if (ext === 'png') {
      type = 'image/png';
    } else if (ext === 'bmp') {
      type = 'image/bmp';
    } else {
      type = 'image/x-icon';
    }

    let favicon = this._document.getElementById('website-favicon');

    if (!favicon) {
      favicon = this._document.createElement('link');

      favicon.setAttribute('id', 'website-favicon');
      favicon.setAttribute('rel', 'shortcut icon');

      this._document.head.appendChild(favicon);
    }

    favicon.setAttribute('type', type);
    favicon.setAttribute('href', url);
  }

  /** FORMS **/
  private _createForms(): void {
    this.searchFields = this._formBuilder.group({
      phrase: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
    });

    this._showSearchValidation = false;
  }

  /** SCROLL **/
  private _getPageY(): number {
    if (this.isSafari) {
      return;
    }
    if (typeof this._platformService.window.pageYOffset !== 'undefined') {
      this._pageY = this._platformService.window.pageYOffset;
    } else {
      const d = this._document,
        r = d.documentElement,
        b = d.body;

      this._pageY = r.scrollTop || b.scrollTop || 0;
    }

    return this._pageY;
  }
}
