import {Component, Input, OnInit, SecurityContext} from '@angular/core';
import {currenciesType, CurrencyService} from '../../model/currency.service';
import {ActivatedRoute} from '@angular/router';
import {GlobalService} from '../../model/global.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-currencies-select',
  templateUrl: './currencies-select.component.html',
  styles: [`
    select {
      border: none;
      cursor: pointer;
      height: 20px;
      width: 20px;
      -webkit-appearance: none;
      -moz-appearance: none;
      /* padding-right: 25px; */
      background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIPnI+py+0/hJzz0IruwjsVADs=) no-repeat 40% 30%;
      text-indent: -100vw;
      outline: none;
    }

    select option {
      color: #f4a701;
      background: #000;
    }

    label {
      margin: 0;
    }
  `],
})
export class CurrenciesSelectComponent implements OnInit {
  @Input() price = null;
  currencies: currenciesType[] = ['PLN', 'USD', 'GBP', 'EUR'];
  selectedCurrency: currenciesType;

  constructor(
    public currencyService: CurrencyService,
    private route: ActivatedRoute,
    public globalService: GlobalService,
    private domSanitizer: DomSanitizer,
  ) {
  }

  ngOnInit(): void {
    let currency: string;
    if (this.price !== null) {
      const priceString = this.domSanitizer.sanitize(SecurityContext.HTML, this.price);
      const priceArr = priceString.split('&nbsp;');
      currency = priceArr.pop() as currenciesType;
    } else {
      const queryParams = this.route.snapshot.queryParams;
      currency = queryParams['currency'];
    }
    if (currency === 'zł') {
      currency = 'PLN'
    }
    if (currency && ['PLN', 'USD', 'GBP', 'EUR'].includes(currency)) {
      this.selectedCurrency = <currenciesType>currency;
      this.currencyService.changeTargetCurrency(this.selectedCurrency);
    } else {
      localStorage.setItem('targetCurrencyExchange', 'PLN');
      this.selectedCurrency = 'PLN'
    }
  }

  changeTargetCurrency($event: Event, currency: currenciesType) {
    $event.stopPropagation();
    $event.preventDefault();
    localStorage.setItem('targetCurrencyExchange', currency);
    this.currencyService.changeTargetCurrency(currency);
  }

}
