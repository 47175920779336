import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { OwlCarousel } from 'ngx-owl-carousel';
import { SugarService } from '../../model/sugar.service';
import { UrlService } from '../../model/url.service';
import { GlobalService } from '../../model/global.service';
import { UniversalPlatformService } from '../../shared/platform/universal-platform.service';
import { Utils } from '../../shared/utils.class';
import { NewsColletionSetter } from '../../shared/collections/api-collection-setters';
import { BlocksPlacement } from '../../blocks-wrapper/blocks-wrapper.component';
import { AutoUnsubscribe } from '../../decorators/autounsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-news-slider-block',
  templateUrl: './news-slider-block.component.html',
})
export class NewsSliderBlockComponent implements OnInit, AfterViewInit, OnDestroy {

  public news: { [key: string]: any }[];
  public options: { [key: string]: any };
  public dotsContainerId: string;
  @ViewChild('owl') owlElement: OwlCarousel;
  @Input() context: BlocksPlacement;
  @Input() perPage: number;
  @Output() viewInit = new EventEmitter<string>();
  private collection: string;
  private dataSub: Subscription;

  constructor(
    public domSanitizer: DomSanitizer,
    public urlService: UrlService,
    private platformService: UniversalPlatformService,
    private sugarService: SugarService,
    private globalService: GlobalService,
  ) {
    this.dotsContainerId = Utils.uniqid('news-slider-');
    this.collection = Utils.uniqid('latest-news-');

    this.options = {
      items: 1,
      margin: 15,
      autoHeight: false,
      nav: false,
      dots: true,
      dotsSpeed: 1500,
      dotsContainer: '#' + this.dotsContainerId + ' .carousel-indicators',
      loop: false,
    };
  }

  ngOnInit(): void {
    if (!this.perPage) {
      this.perPage = 6;
    }

    this.sugarService.setCollection(this.collection, {
      sugarNode: 'events',
      parameters: {
        expand: 'images',
        sort: '-date_from',
        'per-page': this.perPage,
        'filter[active]': '1',
      },
    });

    const collectionSetter = new NewsColletionSetter(
      this.urlService,
      this.sugarService,
      this.platformService,
      this.globalService,
      this.domSanitizer,
    );

    this.dataSub = this.sugarService.getData().subscribe(data => {
      if (data.collectionId !== this.collection) {
        return;
      }

      this.news = collectionSetter.setup(data.data, this.collection);
    });

    this.sugarService.refreshData(this.collection);
  }

  ngOnDestroy(): void {
    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }

    this.sugarService.unsetCollection(this.collection);
  }

  ngAfterViewInit(): void {
    this.viewInit.emit('latestnews');
  }

}
