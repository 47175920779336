import * as tslib_1 from "tslib";
import { SimpleCollection } from './simple-collection.class';
var SanitizedDataCollection = /** @class */ (function (_super) {
    tslib_1.__extends(SanitizedDataCollection, _super);
    function SanitizedDataCollection() {
        var _this = _super.call(this) || this;
        _this.sanitized = [];
        return _this;
    }
    SanitizedDataCollection.prototype.set = function (name, value, safeValue) {
        _super.prototype.set.call(this, name, value);
        if (safeValue) {
            this.sanitized.push(name);
        }
    };
    return SanitizedDataCollection;
}(SimpleCollection));
export { SanitizedDataCollection };
