const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const MONTH = 30 * DAY;

export abstract class UniversalStorageAbstract {

  static SECOND = SECOND;
  static MINUTE = MINUTE;

  static QUARTER_HOUR = 15 * MINUTE;
  static HALF_HOUR = 30 * MINUTE;
  static HOUR = HOUR;

  static DAY = DAY;
  static WEEK = 7 * DAY;
  static MONTH = MONTH;

  static YEAR = 12 * MONTH;

  static seconds(num: number): number {
    return UniversalStorageAbstract.SECOND * (num ? num : 1);
  }

  static minutes(num: number): number {
    return UniversalStorageAbstract.MINUTE * (num ? num : 1);
  }

  static hours(num: number): number {
    return UniversalStorageAbstract.HOUR * (num ? num : 1);
  }

  static days(num: number): number {
    return UniversalStorageAbstract.DAY * (num ? num : 1);
  }

  [key: string]: any;

  [index: number]: string;

  static weeks(num: number): number {
    return UniversalStorageAbstract.WEEK * (num ? num : 1);
  }

  static months(num: number): number {
    return UniversalStorageAbstract.MONTH * (num ? num : 1);
  }

  // readonly length: number;
  abstract clear(): void;

  abstract getItem(key: string): any | null;

  abstract removeItem(key: string): void;

  abstract setItem(key: string, data: any, time?: number): void;

  countMinutes(num: number): number {
    return UniversalStorageAbstract.minutes(num);
  }

  countDays(num: number): number {
    return UniversalStorageAbstract.days(num);
  }
}
