/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../shared/translate-pipes/localized-date.pipe";
import * as i2 from "../../model/url.service";
import * as i3 from "../../shared/translate-pipes/date-declination.pipe";
import * as i4 from "../../shared/platform/universal-platform.service";
import * as i5 from "@angular/common";
import * as i6 from "@angular/router";
import * as i7 from "../../../../node_modules/ngx-owl-carousel/src/owl-carousel.component.ngfactory";
import * as i8 from "ngx-owl-carousel/src/owl-carousel.component";
import * as i9 from "../../shared/translate-pipes/translate.pipe";
import * as i10 from "../../model/global.service";
import * as i11 from "../../shared/translate-pipes/replace-placeholder.pipe";
import * as i12 from "./news-slider-block.component";
import * as i13 from "@angular/platform-browser";
import * as i14 from "../../model/sugar.service";
var styles_NewsSliderBlockComponent = [];
var RenderType_NewsSliderBlockComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewsSliderBlockComponent, data: {} });
export { RenderType_NewsSliderBlockComponent as RenderType_NewsSliderBlockComponent };
function View_NewsSliderBlockComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "small", [["class", "carousel-article-tag"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.category_label[_co.urlService.langCode]; _ck(_v, 1, 0, currVal_0); }); }
function View_NewsSliderBlockComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(0, i1.LocalizedDatePipe, [i2.UrlService, i3.DateDeclinationPipe, i4.UniversalPlatformService])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_v.parent.parent.context.$implicit.date_from, "dd.MM.yyyy")); _ck(_v, 1, 0, currVal_0); }); }
function View_NewsSliderBlockComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" \u2013 ", ""])), i0.ɵpid(0, i1.LocalizedDatePipe, [i2.UrlService, i3.DateDeclinationPipe, i4.UniversalPlatformService])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_v.parent.parent.context.$implicit.date_to, "dd.MM.yyyy")); _ck(_v, 1, 0, currVal_0); }); }
function View_NewsSliderBlockComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "p", [["class", "carousel-article-dates"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NewsSliderBlockComponent_5)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NewsSliderBlockComponent_6)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.date_from; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.date_to; _ck(_v, 4, 0, currVal_1); }, null); }
function View_NewsSliderBlockComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "carousel-article-figure"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i5.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(2, { "background-image": 0 })], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, (("url(" + ((_v.parent.context.$implicit.image == null) ? null : ((_v.parent.context.$implicit.image.thumbnails == null) ? null : _v.parent.context.$implicit.image.thumbnails.medium.url))) + ")")); _ck(_v, 1, 0, currVal_0); }, null); }
function View_NewsSliderBlockComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "carousel-article-figure"], ["style", "background-image:url('assets/images/placeholder-600x450.jpg');"]], null, null, null, null, null))], null, null); }
function View_NewsSliderBlockComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 25, "div", [["class", "owl-slide"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 24, "article", [["class", "carousel-article"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 23, "div", [["class", "carousel-content row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 14, "div", [["class", "carousel-article-col carousel-content-col col-12 col-md-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 8, "header", [["class", "carousel-article-header"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NewsSliderBlockComponent_3)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["class", "carousel-article-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "a", [["class", "carousel-article-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(9, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(10, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NewsSliderBlockComponent_4)), i0.ɵdid(12, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(13, 0, null, null, 0, "div", [["class", "carousel-article-text"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 3, "a", [["class", "carousel-article-more"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 15).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(15, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(16, null, ["", ""])), i0.ɵppd(17, 2), (_l()(), i0.ɵeld(18, 0, null, null, 7, "div", [["class", "carousel-article-col carousel-figure-col col-12 col-md-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 6, "div", [["class", "slider-figure-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 5, "a", [["class", "carousel-article-image-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 21).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(21, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NewsSliderBlockComponent_7)), i0.ɵdid(23, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NewsSliderBlockComponent_8)), i0.ɵdid(25, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.category_label[_co.urlService.langCode]; _ck(_v, 6, 0, currVal_0); var currVal_3 = _v.context.$implicit.link[_co.urlService.langCode]; _ck(_v, 9, 0, currVal_3); var currVal_5 = _v.context.$implicit.date_from; _ck(_v, 12, 0, currVal_5); var currVal_9 = _v.context.$implicit.link[_co.urlService.langCode]; _ck(_v, 15, 0, currVal_9); var currVal_13 = _v.context.$implicit.link[_co.urlService.langCode]; _ck(_v, 21, 0, currVal_13); var currVal_14 = _v.context.$implicit.hasIcon; _ck(_v, 23, 0, currVal_14); var currVal_15 = !_v.context.$implicit.hasIcon; _ck(_v, 25, 0, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵnov(_v, 9).target; var currVal_2 = i0.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_1, currVal_2); var currVal_4 = _v.context.$implicit.name[_co.urlService.langCode]; _ck(_v, 10, 0, currVal_4); var currVal_6 = _v.context.$implicit.description_short_sanitized[_co.urlService.langCode]; _ck(_v, 13, 0, currVal_6); var currVal_7 = i0.ɵnov(_v, 15).target; var currVal_8 = i0.ɵnov(_v, 15).href; _ck(_v, 14, 0, currVal_7, currVal_8); var currVal_10 = i0.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i0.ɵnov(_v.parent.parent, 0), "general.more", _co.urlService.lang)); _ck(_v, 16, 0, currVal_10); var currVal_11 = i0.ɵnov(_v, 21).target; var currVal_12 = i0.ɵnov(_v, 21).href; _ck(_v, 20, 0, currVal_11, currVal_12); }); }
function View_NewsSliderBlockComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "section", [["class", "home-articles articles-carousel-section container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "carousel-home-mid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "owl-carousel", [], null, null, null, i7.View_OwlCarousel_0, i7.RenderType_OwlCarousel)), i0.ɵdid(3, 311296, [[1, 4], ["owl", 4]], 0, i8.OwlCarousel, [i0.IterableDiffers], { carouselClasses: [0, "carouselClasses"], options: [1, "options"] }, null), i0.ɵpad(4, 3), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_NewsSliderBlockComponent_2)), i0.ɵdid(6, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "wrapper-container carousel-indicators-wrapper"]], [[1, "id", 0]], null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "div", [["class", "carousel-indicators"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, "owl-carousel", "owl-theme", "news-carousel"); var currVal_1 = _co.options; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.news; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.dotsContainerId; _ck(_v, 7, 0, currVal_3); }); }
export function View_NewsSliderBlockComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i9.TranslatePipe, [i10.GlobalService, i2.UrlService, i11.ReplacePlaceholderPipe]), i0.ɵqud(671088640, 1, { owlElement: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NewsSliderBlockComponent_1)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.news && ((_co.news == null) ? null : _co.news.length)); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_NewsSliderBlockComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-news-slider-block", [], null, null, null, View_NewsSliderBlockComponent_0, RenderType_NewsSliderBlockComponent)), i0.ɵdid(1, 4440064, null, 0, i12.NewsSliderBlockComponent, [i13.DomSanitizer, i2.UrlService, i4.UniversalPlatformService, i14.SugarService, i10.GlobalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewsSliderBlockComponentNgFactory = i0.ɵccf("app-news-slider-block", i12.NewsSliderBlockComponent, View_NewsSliderBlockComponent_Host_0, { context: "context", perPage: "perPage" }, { viewInit: "viewInit" }, []);
export { NewsSliderBlockComponentNgFactory as NewsSliderBlockComponentNgFactory };
