import { Pipe, PipeTransform } from '@angular/core';
import { ArtistNamePipe } from './artsaas-pipes/artist-name.pipe';

@Pipe({ name: 'artistsAZ' })
export class ArtistsAZPipe implements PipeTransform {
  constructor(private artistNamePipe: ArtistNamePipe) {
  }

  transform(list: any[]): any {
    return list.sort((a, b) => {
      const nameA = this.artistNamePipe.transform(a).toLowerCase();
      const nameB = this.artistNamePipe.transform(b).toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
}
