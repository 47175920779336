import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from '../../model/global.service';
import { UrlService } from '../../model/url.service';
import { ReplacePlaceholderPipe } from './replace-placeholder.pipe';

@Pipe({
  name: 'translate',
  pure: true,
})
export class TranslatePipe implements PipeTransform {

  constructor(
    private globalService: GlobalService,
    private urlService: UrlService,
    private replacePlaceholder: ReplacePlaceholderPipe,
  ) {
  }

  transform(textId: string, lang: string = this.urlService.lang, replacePlaceholder = true): string {
    if (!this.globalService.settings.__loaded) {
      console.error('tłumaczenia jeszcze nie gotowe');
      return '';
    }

    const translations = this.globalService.settings.translations;

    if (typeof translations[textId] === 'undefined') {
      console.error('brak tłumaczenia dla id: ' + textId);
      return '';
    }

    if (typeof translations[textId][lang] === 'undefined') {
      console.error('brak tłumaczenia dla id: ' + textId + ', język: ' + lang);
      return '';
    }

    let t9n = translations[textId][lang];

    if (replacePlaceholder) {
      t9n = this.replacePlaceholder.transform(t9n);
    }

    return t9n;
  }

}
