import * as tslib_1 from "tslib";
import { UniversalStorageAbstract } from './universal-storage-abstract.class';
import { CacheService } from './cache.service';
var UniversalStorage = /** @class */ (function (_super) {
    tslib_1.__extends(UniversalStorage, _super);
    function UniversalStorage(cache) {
        var _this = _super.call(this) || this;
        _this.cache = cache;
        return _this;
    }
    Object.defineProperty(UniversalStorage.prototype, "keys", {
        get: function () {
            return this.cache.keys;
        },
        enumerable: true,
        configurable: true
    });
    UniversalStorage.prototype.getItem = function (key) {
        var has;
        // błąd. WTF?!
        try {
            has = this.cache.has(key);
        }
        catch (error) {
            has = false;
        }
        if (has) {
            var value = void 0;
            try {
                value = this.cache.get(key);
            }
            catch (error) {
                this.removeItem(key);
                value = null;
            }
            return value;
        }
        return null;
    };
    UniversalStorage.prototype.setItem = function (key, val, time) {
        var lifeSpan = {
            expiry: typeof time === 'undefined' ? Date.now() + UniversalStorage.MINUTE : Date.now() + time,
        };
        this.cache.set(key, val, 0, lifeSpan);
    };
    UniversalStorage.prototype.removeItem = function (key) {
        this.cache.remove(key);
    };
    UniversalStorage.prototype.clear = function () {
        this.cache.clear();
    };
    return UniversalStorage;
}(UniversalStorageAbstract));
export { UniversalStorage };
