/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../shared/translate-pipes/translate.pipe";
import * as i2 from "../model/global.service";
import * as i3 from "../model/url.service";
import * as i4 from "../shared/translate-pipes/replace-placeholder.pipe";
import * as i5 from "../blocks-wrapper/blocks-wrapper.component.ngfactory";
import * as i6 from "../blocks-wrapper/blocks-wrapper.component";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../shared/platform/universal-platform.service";
import * as i9 from "../model/components.service";
import * as i10 from "./p404.component";
import * as i11 from "../shared/platform/body.service";
import * as i12 from "../shared/platform/universal-renderer.helper";
import * as i13 from "../model/sugar.service";
import * as i14 from "../app.component";
import * as i15 from "../shared/metadata/metadata.service";
import * as i16 from "@angular/common/http";
import * as i17 from "@angular/router";
var styles_P404Component = [];
var RenderType_P404Component = i0.ɵcrt({ encapsulation: 2, styles: styles_P404Component, data: {} });
export { RenderType_P404Component as RenderType_P404Component };
export function View_P404Component_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.TranslatePipe, [i2.GlobalService, i3.UrlService, i4.ReplacePlaceholderPipe]), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "page-preloader show"]], [[2, "show", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "loader-image"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 8, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 6, "header", [["class", "col-12 e404-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "page-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), i0.ɵppd(8, 2), (_l()(), i0.ɵeld(9, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), i0.ɵppd(11, 2), (_l()(), i0.ɵeld(12, 0, null, null, 1, "app-blocks-wrapper", [], null, null, null, i5.View_BlocksWrapperComponent_0, i5.RenderType_BlocksWrapperComponent)), i0.ɵdid(13, 4440064, null, 0, i6.BlocksWrapperComponent, [i7.DomSanitizer, i8.UniversalPlatformService, i9.ComponentsService], null, null)], function (_ck, _v) { _ck(_v, 13, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showPreloader; _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i0.ɵnov(_v, 0), "not-found-page.title", _co.urlService.lang)); _ck(_v, 7, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i0.ɵnov(_v, 0), "not-found-page.message", _co.urlService.lang)); _ck(_v, 10, 0, currVal_2); }); }
export function View_P404Component_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_P404Component_0, RenderType_P404Component)), i0.ɵdid(1, 4440064, null, 0, i10.P404Component, [i7.DomSanitizer, i3.UrlService, i2.GlobalService, i11.BodyService, i8.UniversalPlatformService, i12.UniversalRendererHelper, i9.ComponentsService, i13.SugarService, i14.AppComponent, i15.MetaData, i16.HttpClient, i17.Router, i17.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var P404ComponentNgFactory = i0.ɵccf("ng-component", i10.P404Component, View_P404Component_Host_0, {}, {}, []);
export { P404ComponentNgFactory as P404ComponentNgFactory };
