/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./product-counter-block.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../model/url.service";
var styles_ProductCounterBlockComponent = [];
var RenderType_ProductCounterBlockComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProductCounterBlockComponent, data: {} });
export { RenderType_ProductCounterBlockComponent as RenderType_ProductCounterBlockComponent };
function View_ProductCounterBlockComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.firstLineSanitized; _ck(_v, 0, 0, currVal_0); }); }
function View_ProductCounterBlockComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.secondLineSanitized; _ck(_v, 0, 0, currVal_0); }); }
export function View_ProductCounterBlockComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "section", [["class", "jumbotron jumbotron-fluid home-top-jumbotron product-counter"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProductCounterBlockComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProductCounterBlockComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.firstLine; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.secondLine; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ProductCounterBlockComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-product-counter-block", [], null, null, null, View_ProductCounterBlockComponent_0, RenderType_ProductCounterBlockComponent)), i0.ɵdid(1, 4243456, null, 0, i2.ProductCounterBlockComponent, [i3.DomSanitizer, i4.UrlService], null, null)], null, null); }
var ProductCounterBlockComponentNgFactory = i0.ɵccf("app-product-counter-block", i2.ProductCounterBlockComponent, View_ProductCounterBlockComponent_Host_0, { context: "context", firstLine: "firstLine", secondLine: "secondLine" }, { viewInit: "viewInit" }, []);
export { ProductCounterBlockComponentNgFactory as ProductCounterBlockComponentNgFactory };
