import { Pipe, PipeTransform } from '@angular/core';
import { getArtistName } from '../../helpers/helpers';

@Pipe({
  name: 'artistName',
  pure: true,
})
export class ArtistNamePipe implements PipeTransform {

  transform(artist: any, langCode = 'pl_pl', asHtml: 'asHtml' | '' = ''): any {
    return getArtistName(artist, langCode, asHtml);
  }

}
