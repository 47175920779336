import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {FormatPricePipe} from './format-price.pipe';
import {environment} from '../../../environments/environment';

@Pipe({
  name: 'workPrice',
  pure: true,
})
export class WorkPricePipe implements PipeTransform {

  constructor(
    private _formatter: FormatPricePipe,
    private _sanitizer: DomSanitizer,
  ) {
  }

  transform(work: any, priceField = 'price', priceUnit = '', log = false): string | SafeHtml {
    if (log) {
      console.log({work, priceField, priceUnit})
    }
    let price = '';
    const fieldTrace = priceField.split(':'); // można tworzyć ścieżkę: fieldA:fieldB:fieldC
    let workPrice: any;
    let i = 0;
    let field = work;
    let unit = priceUnit ? priceUnit : (work.unit_price ? work.unit_price : '');

    do {
      field = typeof field[fieldTrace[i]] !== 'undefined' ? field[fieldTrace[i]] : false;
      ++i;
    } while (i < (fieldTrace || []).length && field && typeof fieldTrace[i] !== 'undefined');

    if (field) {
      workPrice = field;

      if (parseInt(workPrice, 10) === 0) {
        workPrice = '*';
      }
    }

    if (workPrice && (workPrice || []).length && workPrice !== '*') {
      if ((unit || []).length > 0 && typeof environment.priceUnitMap[unit] !== 'undefined') {
        unit = environment.priceUnitMap[unit];
      }

      price = this._formatter.transform('' + workPrice + '') + '&nbsp;' + unit;
    } else if (workPrice === '*') {
      price = workPrice;
    }

    return (price || []).length ? this._sanitizer.bypassSecurityTrustHtml(price) : price;
  }

}
