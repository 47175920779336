import { UniversalRendererHelper } from './universal-renderer.helper';

export abstract class ElementServiceBase {

  protected abstract root: any;

  constructor(
    protected rendererHelper: UniversalRendererHelper,
  ) {
  }

  get element(): any {
    return this.root;
  }

  get className(): string {
    return this.root.className;
  }

  set className(className: string) {
    this.rendererHelper.setAttribute(this.root, 'class', '');
    this.addClass(className);
  }

  hasClass(className: string | string[]): boolean {
    return this.rendererHelper.hasClass(this.root, className);
  }

  addClass(className: string | string[]): ElementServiceBase {
    this.rendererHelper.addClass(this.root, className);
    return this;
  }

  removeClass(className: string | string[]): ElementServiceBase {
    this.rendererHelper.removeClass(this.root, className);
    return this;
  }

  hasAttribute(name: string): boolean {
    return this.rendererHelper.hasAttribute(this.root, name);
  }

  setAttribute(name: string, value: string, namespace?: string | null): ElementServiceBase {
    this.rendererHelper.setAttribute(this.root, name, value, namespace);
    return this;
  }

  getAttribute(name: string): any | null {
    return this.rendererHelper.getAttribute(this.root, name);
  }

  removeAttribute(name: string, namespace?: string | null): ElementServiceBase {
    this.rendererHelper.removeAttribute(this.root, name, namespace);
    return this;
  }

  setProperty(name: string, value: any): ElementServiceBase {
    this.rendererHelper.setProperty(this.root, name, value);
    return this;
  }

}
