import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { LangChangeData, UrlService } from './url.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ComponentsService {

  public components: BehaviorSubject<{ [key: string]: any }>;

  protected currentPage = '';

  constructor(
    private http: HttpClient,
    private router: Router,
    private urlService: UrlService,
  ) {
    this.components = new BehaviorSubject({});

    router.events.subscribe((evt: any) => {
      if (evt instanceof NavigationEnd) {
        this.refreshComponents();
      }
    });

    urlService.langChange.subscribe((data: LangChangeData) => {
      // w tym wypadku nie ma zdarzenia NavigationEnd
      this.refreshComponents(data);
    });
  }

  refresh(): void {
    const url = environment.apiHost + '/pages/' + this.currentPage + '?lang=' + this.urlService.lang;
    const params = {};

    this.http.get(url, params).pipe(
      catchError((error: Response | any): Observable<any> => {
        return this.handleError(error, url, params);
      }))
      .subscribe(components => {
        this.components.next(components);
      });
  }

  getRootComponents(): Observable<Response> {
    const endpoint = this.urlService.getCurrentComponentsRootEndpoint();
    const url = environment.apiHost + '/pages/' + endpoint + '?lang=' + this.urlService.lang;
    const params = {};

    return this.http.get(url, params).pipe(
      catchError((error: Response | any): Observable<any> => {
        return this.handleError(error, url, params);
      }));
  }

  getComponents(): Observable<any> {
    return this.components.asObservable();
  }

  handleError(error: Response | any, url: string, params: { [key: string]: any } | null): Observable<any> {
    let errorMessage: any;

    if (error.status === 0) {
      errorMessage = {
        success: false,
        status: 0,
        data: 'Sorry, there was a connection error. Please try again.',
        url: url,
        params: params,
      };
    } else if (error.json) {
      errorMessage = error;
    } else {
      errorMessage = {
        success: false,
        status: error.status,
        data: error.message,
        url: url,
        params: params,
      };
    }

    return observableThrowError(errorMessage);
  }

  protected refreshComponents(langData?: LangChangeData): void {
    const lang = langData ? langData.langTo : this.urlService.lang;
    const endpoint = this.urlService.getCurrentComponentsEndpoint(lang);

    if (endpoint) {

      this.currentPage = endpoint;
      this.refresh();

    } else if (!endpoint) {

      if (langData) { // brak w panelu zdefiniowanego sluga - ale to zmiana języka, więc i tak spróbujemy odświeżyć
        this.refresh();
      } else {
        this.components.next({});
      }

    }
  }

}
