var LangService = /** @class */ (function () {
    function LangService() {
    }
    Object.defineProperty(LangService, "lang", {
        get: function () {
            return LangService._lang;
        },
        set: function (lang) {
            LangService._lang = lang;
        },
        enumerable: true,
        configurable: true
    });
    LangService._lang = 'en';
    return LangService;
}());
export { LangService };
