import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { ScrollToService } from 'ng2-scroll-to-el';
import { UrlService } from '../../../model/url.service';
import { GlobalService } from '../../../model/global.service';
import { CmsService } from '../../../model/cms.service';
import { UniversalPlatformService } from '../../../shared/platform/universal-platform.service';
import { UniversalRendererHelper } from '../../../shared/platform/universal-renderer.helper';
import { MenuService } from './menu/menu.service';
import { AutoUnsubscribe } from '../../../decorators/autounsubscribe.decorator';
import { SettingsModelInterface } from '../../../interfaces/settings-model.interface';

@AutoUnsubscribe()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements AfterViewInit {
  public logoLarge = {
    url: '',
    width: '',
    height: '',
  };
  public logoSmall = {
    url: '',
    width: '',
    height: '',
  };

  public backgroundColor: string;

  public searchTrigger: EventEmitter<any>;
  public langChange: EventEmitter<string>;
  @Input() mobileBreakpoint = 992;
  @ViewChild('header') header: ElementRef;
  @ViewChild('brand') brand: ElementRef;
  @ViewChild('navbar') navbar: ElementRef;
  @ViewChildren('submenu') submenus: QueryList<any>;
  isSingleMenuVersion: boolean;
  protected submenuStates: {
    exists: boolean,
    opening: boolean,
    open: boolean,
    closing: boolean,
    closed: boolean,
  }[];
  protected settingsSub: Subscription;
  protected menuSub: Subscription;
  private _isMenuOpen: boolean;

  constructor(
    public urlService: UrlService,
    public globalService: GlobalService,
    public cmsService: CmsService,
    private _platformService: UniversalPlatformService,
    private _rendererHelper: UniversalRendererHelper,
    private _scrollService: ScrollToService,
    private _menu: MenuService,
  ) {
    this._isMenuOpen = false;

    this.backgroundColor = this._menu.backgroundColor;

    this.searchTrigger = new EventEmitter();
    this.langChange = new EventEmitter();

    this.submenuStates = [];

    this.settingsSub = this.globalService.updated.subscribe((settings: SettingsModelInterface) => {
      if (settings.__loaded && settings.general) {
        this.logoLarge.url = settings.general.logo;
        this.logoLarge.width = settings.general.logo_width.pl || settings.general.logo_width.en;
        this.logoLarge.height = settings.general.logo_height.pl || settings.general.logo_height.en;

        this.logoSmall.url = settings.general.logo_mini;
        this.logoSmall.width = settings.general.logo_mini_width.pl || settings.general.logo_mini_width.en;
        this.logoSmall.height = settings.general.logo_mini_height.pl || settings.general.logo_mini_height.en;
        this.isSingleMenuVersion = settings.global.oneMenuVersion === 'true';
      }
    });

    this.menuSub = this._menu.ready.subscribe((ready: boolean) => {
      if (ready) {
        let i = 0;
        const len = (this._menu.items || []).length;

        for (; i < len; ++i) {
          this.submenuStates.push({
            exists: this._menu.items[i].hasChildren,
            opening: false,
            open: false,
            closing: false,
            closed: !!this._menu.items[i].hasChildren,
          });
        }
      }
    });
  }

  get isLogoLink(): boolean {
    if (typeof this.globalService.settings.global.logoLink === 'object') {
      return !!Object.keys(this.globalService.settings.global.logoLink).length;
    }
    return !!this.globalService.settings.global.logoLink;
  }

  get menu(): MenuService {
    return this._menu;
  }

  get menuIsMobile(): boolean {
    return this._platformService.windowWidth < this.mobileBreakpoint;
  }

  @HostListener('window:resize')
  onResizeWindow(): void {
    if (this.menuIsMobile) {
      this.closeSubmenus();
    }
  }

  ngAfterViewInit(): void {
    // fix dla przeskakującego menu
    setTimeout(() => {
      this._rendererHelper.addClass(this.header, 'site-header-animated');
    }, 300);
  }

  onClickMenuItem(evt: any, index: number): void | false {
    if (this.menuIsMobile && this.isSubmenuState(index, 'closed')) {
      evt.stopPropagation();
      evt.preventDefault();

      this.closeSubmenus();
      this.openSubmenu(index);

      return false;
    }

    this.closeMenu();
    this.scrollTop();
  }

  onClickSubmenuItem(): void {
    this.closeMenu();
    this.scrollTop();
  }

  openMenu(): void {
    if (!this._isMenuOpen) {
      this._isMenuOpen = true;

      this._rendererHelper.addClass(this.navbar, 'opening');

      if (this._platformService.isBrowser) {
        this._platformService.document.documentElement.className = this._rendererHelper.classNameHelper.add(
          this._platformService.document.documentElement.className,
          'has-open-menu',
        );
        // this.bodyService.bodyClass = this._rendererHelper.classNameHelper.add(this.bodyService.bodyClass, " has-open-menu");
      }
    }
  }

  closeMenu(): void {
    if (this._isMenuOpen) {
      this._isMenuOpen = false;

      this._rendererHelper.removeClass(this.navbar, 'show');
      this.closeSubmenus();

      if (this._platformService.isBrowser) {
        this._platformService.document.documentElement.className = this._rendererHelper.classNameHelper.remove(
          this._platformService.document.documentElement.className,
          'has-open-menu',
        );
      }
    }
  }

  onTransitionEndMenu(evt: any): void {
    if (this._isMenuOpen) {
      this._rendererHelper.addClass(this.navbar, 'show');

      if (this.navbar.nativeElement !== evt.target) {
        return;
      }

      this._rendererHelper.removeClass(this.navbar, 'opening');
    } else {
      if (this.navbar.nativeElement === evt.target) {
        this._rendererHelper.removeClass(this.navbar, 'opening');
      }
    }
  }

  onMouseenterMenuItem(evt: any, index: number): void {
    if (this.menuIsMobile || typeof this.submenuStates[index] === 'undefined') {
      return;
    }

    let open ;

    if (this._rendererHelper.hasAnyClass(evt.target, ['nav-link', 'nav-item'])) {
      open = true;
    } else {
      open = !this.isSubmenuState(index, 'closed');
    }

    if (open) {
      this.openSubmenu(index);
    }
  }

  onMouseleaveMenuItem(evt: any, index: number): void {
    if (!this.menuIsMobile) {
      this.closeSubmenu(index);
    }
  }

  openSubmenu(index: number): void {
    this.setSubmenuState(index, 'opening', true);
    this.setSubmenuState(index, 'closed', false);
    this.setSubmenuState(index, 'closing', false);
  }

  closeSubmenu(index: number): void {
    this.setSubmenuState(index, 'opening', false);
    this.setSubmenuState(index, 'open', false);
    this.setSubmenuState(index, 'closing', true);
  }

  onTransitionEndSubmenu(evt: any, index: number): void {
    if (this.isSubmenuState(index, 'opening')) {
      this.setSubmenuState(index, 'open', true);
      this.setSubmenuState(index, 'opening', false);
      this.setSubmenuState(index, 'closing', false);
      this.setSubmenuState(index, 'closed', false);
    } else if (this.isSubmenuState(index, 'closing')) {
      this.setSubmenuState(index, 'open', false);
      this.setSubmenuState(index, 'opening', false);
      this.setSubmenuState(index, 'closing', false);
      this.setSubmenuState(index, 'closed', true);
    }
  }

  scrollTop(): void {
    this._scrollService.scrollTo('#top', 600);
  }

  onTransitionendBrand(): void {
    if (this._rendererHelper.hasClass(this.brand, 'brand-small-in')
      && !this._rendererHelper.hasClass(this.brand, 'brand-small-delayed')) {
      this._rendererHelper.addClass(this.brand, 'brand-small-delayed');
    } else if (this._rendererHelper.hasClass(this.brand, 'brand-small-delayed')) {
      this._rendererHelper.removeClass(this.brand, 'brand-small-delayed');
    }
  }

  openSearchModal(): void {
    this.searchTrigger.emit({});
  }

  changeLang(lang: string): void {
    this.langChange.emit(lang);
  }

  isSubmenuState(index: number, state: 'open' | 'opening' | 'closing' | 'closed'): boolean {
    return this.submenuHasState(index, state) ? this.submenuStates[index][state] : false;
  }

  protected setSubmenuState(index: number, state: 'open' | 'opening' | 'closing' | 'closed', value: boolean): void {
    if (!this.submenuHasState(index, state)) {
      return;
    }

    this.submenuStates[index][state] = value;
  }

  protected submenuHasState(index: number, state: string): boolean {
    if (typeof this.submenuStates[index] === 'undefined' || !this.submenuStates[index].exists) {
      return false;
    }

    return typeof this.submenuStates[index][state] !== 'undefined';
  }

  protected closeSubmenus(): void {
    if (this.menu.items) {
      const menuLen = (this._menu.items || []).length;
      let i = 0;

      for (; i < menuLen; ++i) {
        this.closeSubmenu(i);
      }
    }
  }
}
