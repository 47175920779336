import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class ConvertEntitiesInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse && !String(event.url).includes('token')) {
        return event.clone({
          body: this.modifyObjEntities(event.body, Array.isArray(event.body)),
        });
      }

      return event;
    }));
  }

  modifyObjEntities(obj: object | any[], asArray = false) {
    const newObj = {};
    const newArr = [];
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {

        let modifiedVal = obj[prop];
        if (Array.isArray(modifiedVal)) {
          modifiedVal = this.modifyObjEntities(modifiedVal, true);
        } else if (typeof modifiedVal === 'object') {
          modifiedVal = this.modifyObjEntities(modifiedVal);
        } else if (typeof modifiedVal === 'string') {
          modifiedVal = this.decodeEntities(modifiedVal);
        }
        if (asArray) {
          newArr[prop] = modifiedVal;
        } else {
          newObj[prop] = modifiedVal;
        }
      }
    }

    return asArray ? newArr : newObj;
  }

  decodeEntities(encodedString: string): string {
    const translate_re = /&(nbsp|amp|quot|lt|gt);/g;
    const translate = {
      nbsp: ' ',
      amp: '&',
      quot: '"',
      lt: '<',
      gt: '>',
    };
    return encodedString.replace(translate_re, function (match, entity) {
      return translate[entity];
    }).replace(/&#(\d+);/gi, function (match, numStr) {
      const num = parseInt(numStr, 10);
      return String.fromCharCode(num);
    });
  }
}
