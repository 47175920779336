export class SimpleCollection {

  protected data: { [key: string]: any };

  protected dataKeys: string[];
  protected dataKeysCopy: string[];

  protected count: number;

  constructor() {
    this.data = {};

    this.dataKeys = [];
    this.dataKeysCopy = [];

    this.count = 0;
  }

  get length(): number {
    return this.count;
  }

  get keys(): string[] {
    return this.dataKeysCopy;
  }

  set(name: string, value: any): void {
    ++this.count;

    if (!this.has(name)) {
      this.dataKeys.push(name);
      this.dataKeysCopy = JSON.parse(JSON.stringify(this.dataKeys)); // kopia, nie referencja
    }

    this.data[name] = value;
  }

  get(name: string): any {
    if (this.has(name)) {
      return this.data[name];
    }

    return null;
  }

  has(name: string): boolean {
    return typeof this.data[name] !== 'undefined' && null !== this.data[name];
  }

  empty(name: string): boolean {
    if (!this.has(name)) {
      return true;
    }

    const val = this.data[name];

    return null === val ||
      (typeof val === 'number' && 0 === val) ||
      ((typeof val === 'string' || Array.isArray(val)) && (val || []).length === 0) ||
      (typeof val === 'object' && Object.keys(val).length === 0);
  }

  hasAny(): boolean {
    return this.count > 0;
  }

  atLeastOneNotEmpty(names: string[]): boolean {
    const emptyOnes = this.countEmptyOnes(names);
    return emptyOnes < (names || []).length;
  }

  protected countEmptyOnes(names: string[]): number {
    let emptyOnes = 0;

    for (let i = 0; i < (names || []).length; ++i) {
      if (this.empty(names[i])) {
        ++emptyOnes;
      }
    }

    return emptyOnes;
  }

}
