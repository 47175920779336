import { Injectable } from '@angular/core';
import { LifeSpan } from '@ngx-cache/core';
import { UniversalStorageAbstract } from './universal-storage-abstract.class';
import { CacheService } from './cache.service';

@Injectable()
export class UniversalStorage extends UniversalStorageAbstract {

  constructor(
    private readonly cache: CacheService,
  ) {
    super();
  }

  get keys(): string[] {
    return this.cache.keys;
  }

  getItem(key: string): any {
    let has: boolean;

    // błąd. WTF?!
    try {
      has = this.cache.has(key);
    } catch (error) {
      has = false;
    }

    if (has) {
      let value: any;

      try {
        value = this.cache.get(key);
      } catch (error) {
        this.removeItem(key);
        value = null;
      }

      return value;
    }

    return null;
  }

  setItem(key: string, val: any, time?: number): void {
    const lifeSpan: LifeSpan = {
      expiry: typeof time === 'undefined' ? Date.now() + UniversalStorage.MINUTE : Date.now() + time,
    };

    this.cache.set(key, val, 0, lifeSpan);
  }

  removeItem(key: string): void {
    this.cache.remove(key);
  }

  clear(): void {
    this.cache.clear();
  }

}
