import * as tslib_1 from "tslib";
import { UniversalPlatformService } from './universal-platform.service';
import { UniversalRendererHelper } from './universal-renderer.helper';
import { ElementServiceBase } from './element-service-base';
var BodyService = /** @class */ (function (_super) {
    tslib_1.__extends(BodyService, _super);
    function BodyService(platformService, rendererHelper) {
        var _this = _super.call(this, rendererHelper) || this;
        _this.platformService = platformService;
        _this.rendererHelper = rendererHelper;
        _this.root = platformService.document.body;
        return _this;
    }
    Object.defineProperty(BodyService.prototype, "body", {
        get: function () {
            return this.root;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BodyService.prototype, "bodyClass", {
        get: function () {
            return this.className;
        },
        set: function (className) {
            this.className = className;
        },
        enumerable: true,
        configurable: true
    });
    BodyService.prototype.resetAndAddClass = function (add, force) {
        if (force === void 0) { force = false; }
        var keep = force
            ? ''
            : 'has-fixed-banner';
        this.bodyClass = this.rendererHelper.classNameHelper.removeAllButKeep(this.bodyClass, keep);
        this.addClass(add);
    };
    return BodyService;
}(ElementServiceBase));
export { BodyService };
