import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFixIE',
  pure: true,
})
export class TimeFixIEPipe implements PipeTransform {

  constructor() {
  }

  transform(timeString: string, format = 'h:m'): string {
    let split: string[];

    timeString = timeString.trim();
    split = timeString.split(':');

    switch (format) {
      case 'h':
        timeString = (split || []).length ? split[0] : timeString;
        break;

      case 'h:m':
        timeString = (split || []).length >= 3 ? split.slice(0, 2).join(':') : split.join(':');
        break;

      case 'h:m:s':
        timeString = (split || []).length >= 4 ? split.slice(0, 3).join(':') : split.join(':');
        break;
    }

    return timeString;
  }

}
