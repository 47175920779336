import {filter} from 'rxjs/operators';
import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DOCUMENT, PlatformLocation} from '@angular/common';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {FormBuilder} from '@angular/forms';
import {ScrollToService} from 'ng2-scroll-to-el';
import {SugarService} from './model/sugar.service';
import {GlobalService} from './model/global.service';
import {SettingDataService} from './model/setting-data.service';
import {UrlService} from './model/url.service';
import {CmsService} from './model/cms.service';
import {ComponentsService} from './model/components.service';
import {NgwWowService} from 'ngx-wow';
import {UniversalPlatformService} from './shared/platform/universal-platform.service';
import {UniversalRendererHelper} from './shared/platform/universal-renderer.helper';
import {BodyService} from './shared/platform/body.service';
import {MetaData} from './shared/metadata/metadata.service';
import {FbService} from './shared/fb.service';
import {CookiesService} from './shared/cookies.service';
import {AutoUnsubscribe} from './decorators/autounsubscribe.decorator';
import {BehaviorSubject, Subscription} from 'rxjs';

@AutoUnsubscribe()
@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements AfterViewInit, OnDestroy, OnInit {

  private _navSub: Subscription;
  private _routerSubs: Subscription[];
  private _gsSub: Subscription;
  private _viewInit: BehaviorSubject<boolean>;

  private insertedTrackingCodes: { [key: string]: boolean };

  constructor(
    @Inject(DOCUMENT) private _document,
    public globalService: GlobalService,
    public cmsService: CmsService,
    public urlService: UrlService,
    public bodyService: BodyService,
    private _rendererHelper: UniversalRendererHelper,
    private _platformService: UniversalPlatformService,
    private _sugarService: SugarService,
    private _componentsService: ComponentsService,
    private _router: Router,
    private _platformLocation: PlatformLocation,
    private _sanitizer: DomSanitizer,
    private _formBuilder: FormBuilder,
    private _settingDataService: SettingDataService,
    private _cookiesService: CookiesService,
    private _scrollService: ScrollToService,
    private _wowService: NgwWowService,
    private _metadata: MetaData,
    private _fbService: FbService,
  ) {
    this._routerSubs = [];
    this._router.events.pipe(
      filter(event => event instanceof NavigationStart))
      .subscribe(() => {
        this._rendererHelper.removeClass(this._document.documentElement, 'has-modal');
      });
    this._viewInit = new BehaviorSubject(false);

    this._gsSub = this.globalService.loadGlobalSettings().subscribe(() => {
      const settings = this.globalService.settings;

      this._fbService.updateCommonOg();

      this.insertedTrackingCodes = {};

      if (settings.__loaded) {
        this.trackingCodesInit();
        this.structureDataCodesInit();

        this._metadata.setSiteTitle(this.globalService.settings.seo.title);
      }
    });

    cmsService.refresh();

    if (_platformService.isBrowser) {
      this._navSub = this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
        // Reload WoW animations when done navigating to page,
        // but you are free to call it whenever/wherever you like
        this._wowService.init();
      });
    }

    this._metadata.setDescription('');
    this._metadata.setKeywords('');
  }

  get fbService(): FbService {
    return this._fbService;
  }

  get windowWidth(): number {
    return this._platformService.windowWidth;
  }

  get bodyClass(): string {
    return this.bodyService.bodyClass;
  }

  set bodyClass(className: string) {
    this.bodyService.bodyClass = className;
  }

  ngOnInit(): void {
    this.fbService.updateCommonOg();

    if (this.globalService.settings.global.isFullSize === 'true'
      || this.globalService.settings.global.isFullSize === true) {
      this.updateContainer();
    }

    const font = 'Lexend Deca';
    if (font) {
      this.updateFont(font);
    }
    if (this.globalService.settings.global.externalFontsUrl) {
      this.updateFont(this.globalService.settings.global.externalFontsUrl);
    }

  }

  updateFont(font: string): void {
    font = typeof font === 'string' ? (font || '') : '';
    const link = this._document.createElement('link');
    font = font.replace(/,\s/ig, '|')
      .replace(/\s/ig, '+')
      .split('|')
      .map(name => name + ':200,300,400,500,600,700,800,900')
      .join('|');
    if (font.trim().length === 0) {
      return;
    }
    link.rel = 'stylesheet';
    link.href = `https://fonts.googleapis.com/css?family=${font}&display=swap`;
    this._document.head.appendChild(link);

  }

  createSheet() {
    const style = this._document.createElement('style');
    this._document.head.appendChild(style);
    return style;
  }

  updateContainer(): void {
    const sheet = this.createSheet();
    sheet.innerHTML = '.carousel-home-top.container { max-width: 100% !important }';
  }

  ngOnDestroy(): void {
    if (this._navSub) {
      this._navSub.unsubscribe();
    }

    if (this._gsSub) {
      this._gsSub.unsubscribe();
    }

    for (let i = 0; i < (this._routerSubs || []).length; ++i) {
      this._routerSubs[i].unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this._fbService.initialize();
  }

  protected structureDataCodesInit(): void {
    const settings = this.globalService.settings;

    // SEO
    if (settings.__loaded) {
      const seoSettings = typeof settings.seo === 'undefined' ? null : settings.seo;

      if (!seoSettings) {
        return;
      }

      const heads = this._platformService.document.documentElement.getElementsByTagName('head');

      const head = heads && (heads || []).length ? heads[0] : null;

      if (head) {

        if (this._platformService.isServer) {

          const div = this._platformService.document.createElement('div');

          if (!this.insertedTrackingCodes.structuredData) {
            const structureData = this.getStructureDataTrackingCode();

            if ((structureData || []).length) {
              div.innerHTML = structureData;

              while (div.firstChild) {
                head.appendChild(div.firstChild);
              }

              div.innerHTML = '';

              this.insertedTrackingCodes.structuredData = true;
            }
          }

        } else if (this._platformService.isBrowser) {

          const div = this._platformService.document.createElement('div');

          if (!this.insertedTrackingCodes.structuredData) {
            const structureData = this.getStructureDataTrackingCode();
            if ((structureData || []).length) {
              div.innerHTML = structureData;

              while (div.firstChild) {
                head.appendChild(div.firstChild);
              }

              div.innerHTML = '';

              this.insertedTrackingCodes.structuredData = true;
            }
          }
        }
      }
    }
  }

  protected trackingCodesInit(): void {
    const settings = this.globalService.settings;

    // SEO
    if (settings.__loaded) {
      const seoSettings = typeof settings.seo === 'undefined' ? null : settings.seo;

      if (!seoSettings) {
        return;
      }

      const heads = this._platformService.document.documentElement.getElementsByTagName('head');

      const head = heads && (heads || []).length ? heads[0] : null;
      const body = this.bodyService.body;

      if (head && this._platformService.isServer) {

        const div = this._platformService.document.createElement('div');

        if (!this.insertedTrackingCodes.fbPixel) {
          const fbPixelCode = this.getTrackingCode('fb_pixel_code');

          if ((fbPixelCode || []).length) {
            div.innerHTML = fbPixelCode;

            while (div.firstChild) {
              head.appendChild(div.firstChild);
            }

            div.innerHTML = '';

            this._rendererHelper.setAttribute(this._platformService.document.documentElement, 'data-has-fb-pixel', '1');
            this.insertedTrackingCodes.fbPixel = true;
          }
        }

        if (!this.insertedTrackingCodes.ga) {
          const gaCode = this.getTrackingCode('ga_code');

          if ((gaCode || []).length) {
            div.innerHTML = gaCode;

            while (div.firstChild) {
              head.appendChild(div.firstChild);
            }

            div.innerHTML = '';

            this._rendererHelper.setAttribute(this._platformService.document.documentElement, 'data-has-ga', '1');
            this.insertedTrackingCodes.ga = true;
          }
        }

        if (!this.insertedTrackingCodes.gtm) {
          const gtmHeadCode = this.getTrackingCode('gtm_code_head');
          const gtmBodyCode = this.getTrackingCode('gtm_code_body');

          if ((gtmHeadCode || []).length && (gtmBodyCode || []).length) {
            div.innerHTML = gtmHeadCode;

            while (div.firstChild) {
              head.appendChild(div.firstChild);
            }

            div.innerHTML = '';
            div.innerHTML = gtmBodyCode;

            let insertedFirst = false;

            while (div.firstChild) {
              if (!insertedFirst) {
                body.insertBefore(div.firstChild, body.firstChild);
                insertedFirst = true;
              } else {
                body.insertBefore(div.firstChild, body.firstChild.nextSibling); // coś jak insertAfter()
              }
            }

            div.innerHTML = '';

            this._rendererHelper.setAttribute(this._platformService.document.documentElement, 'data-has-gtm', '1');
            this.insertedTrackingCodes.gtm = true;
          }
        }

      } else if (head && this._platformService.isBrowser) {

        const range = this._platformService.document.createRange();
        range.selectNode(body); // required in Safari

        this.insertedTrackingCodes.fbPixel = this.insertedTrackingCodes.fbPixel ||
          this._rendererHelper.hasAttribute(this._platformService.document.documentElement, 'data-has-fb-pixel');

        if (!this.insertedTrackingCodes.fbPixel) {
          const fbPixelCode = this.getTrackingCode('fb_pixel_code');

          if ((fbPixelCode || []).length) {
            const fbFragm = range.createContextualFragment(fbPixelCode);

            head.appendChild(fbFragm);

            this._rendererHelper.setAttribute(this._platformService.document.documentElement, 'data-has-fb-pixel', '1');
            this.insertedTrackingCodes.fbPixel = true;
          }
        }

        this.insertedTrackingCodes.ga = this.insertedTrackingCodes.ga ||
          this._rendererHelper.hasAttribute(this._platformService.document.documentElement, 'data-has-ga');

        if (!this.insertedTrackingCodes.ga) {
          const gaCode = this.getTrackingCode('ga_code');

          if ((gaCode || []).length) {
            const gaFragm = range.createContextualFragment(gaCode);
            head.appendChild(gaFragm);

            this._routerSubs.push(
              this._router.events.subscribe(evt => {
                if (evt instanceof NavigationEnd) {

                  if (typeof this._platformService.window['ga'] !== 'undefined') {

                    this._platformService.window['ga']('set', 'page', evt.urlAfterRedirects);
                    this._platformService.window['ga']('send', 'pageview');

                  } else if (typeof this._platformService.window['dataLayer'] !== 'undefined') {

                    this._platformService.window['dataLayer'].push({
                      event: 'pageview',
                      url: evt.urlAfterRedirects,
                    });
                  }
                }
              }),
            );

            this._rendererHelper.setAttribute(this._platformService.document.documentElement, 'data-has-ga', '1');
            this.insertedTrackingCodes.ga = true;
          }
        }

        this.insertedTrackingCodes.gtm = this.insertedTrackingCodes.gtm ||
          this._rendererHelper.hasAttribute(this._platformService.document.documentElement, 'data-has-gtm');

        if (!this.insertedTrackingCodes.gtm) {
          const gtmHeadCode = this.getTrackingCode('gtm_code_head');
          const gtmBodyCode = this.getTrackingCode('gtm_code_body');

          if ((gtmHeadCode || []).length && (gtmBodyCode || []).length) {
            const gtmHeadFragm = range.createContextualFragment(gtmHeadCode);
            head.appendChild(gtmHeadFragm);

            const gtmBodyFragm = range.createContextualFragment(gtmBodyCode);
            body.insertBefore(gtmBodyFragm, body.firstChild);

            this._routerSubs.push(this._router.events.subscribe(evt => {
              if (evt instanceof NavigationEnd && typeof this._platformService.window['dataLayer'] !== 'undefined') {

                this._platformService.window['dataLayer'].push({
                  event: 'pageview',
                  url: evt.urlAfterRedirects,
                });

              }
            }));

            this._rendererHelper.setAttribute(this._platformService.document.documentElement, 'data-has-gtm', '1');
            this.insertedTrackingCodes.gtm = true;
          }
        }
      }
    }
  }

  private getTrackingCode(name: string): string {
    if (!this.globalService.settings.__loaded) {
      return '';
    }

    const seoSettings = this.globalService.settings.seo;
    let code: string;

    if (typeof seoSettings[name] === 'undefined') {
      code = '';
    } else if (typeof seoSettings[name] === 'string') {
      code = seoSettings[name];
    } else {
      code = seoSettings[name][this.urlService.lang];
    }

    return code;
  }

  private getStructureDataTrackingCode(): string {
    if (!this.globalService.settings.__loaded) {
      return '';
    }

    const structured_data = this.globalService.settings.seo.structured_data;
    let code: string;

    if (typeof structured_data === 'undefined') {
      code = '';
    } else if (typeof structured_data === 'string') {
      code = structured_data;
    } else {
      code = structured_data[this.urlService.lang];
    }

    return code;
  }

}
