import { MenuItemChild } from './menu-item-child.class';
import { HydratedSubmenuItem } from './menu-item.interfaces';

export class MenuItemFeatured extends MenuItemChild {

  protected valid: boolean;

  constructor(
    protected hydratedItem: HydratedSubmenuItem,
    protected remoteObject: { [key: string]: any } | null,
  ) {
    super(hydratedItem);

    this.valid = remoteObject !== null;
    this.featured = true;
  }

  get isValid(): boolean {
    return this.valid;
  }

  setup(hydratedItem: HydratedSubmenuItem): void {
    this.featured = false;
  }

}
